import React from 'react';
import { Header } from 'src/templates/header/Header';
import {
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { UsersForm } from 'src/templates/users-form/UsersForm';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';



export function UserManagementCreate(): JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <Box m={4}>
                <AdministrationHeader
                    labels={[t('menu.user-management'), t('user.create-user')]}
                    icon={<SettingsRoundedIcon fontSize="large"/>}
                />
                <UsersForm />
            </Box>
        </>
    );
}
