import axios from 'axios';
import { fetchEventSource, EventSourceMessage } from '@microsoft/fetch-event-source';

import { API_URL, API_CONFIG_URL } from 'src/utils/environment';
import * as storage from 'src/utils/storage';
import { Token } from 'src/models/authorizationModels';

const instance = axios.create({
    baseURL: API_URL,
});

export const configInstance = axios.create({
    baseURL: API_CONFIG_URL,
});

export default instance;

export async function sseInstance<MessageDataType>(
    endpoint: string,
    onMessage: (data: MessageDataType) => void
): Promise<void>
{
    const token = storage.read<Token>(storage.Keys.Token);
    fetchEventSource(`${API_URL}/${endpoint}`, {
        onmessage: ({ data }: EventSourceMessage) => onMessage(JSON.parse(data)),
        headers: {
            Authorization: token ? `Bearer ${token.accessToken}` : '',
        },
    });
}
