import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'src/styles/svg.css';
import { ScreenshotData } from 'src/models/captures/screenshotData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getScreenshotsMeta,
    selectFilters,
    changeScreenshot,
    selectScreenshot,
    selectScreenshotsStatus,
    getScreenshotsMetadevicebyid,
    changeFilters,
    selectScreenshotsDeviceId,
    changeScreenshotDeviceId,
    resetscreenshotFilterFlag,
    changescreenshotStatus,
    updateSeenListStatus,
    changescreenshotGridViewFilters,
    selectallPageData,
    selectScreenshotsMeta,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { DataDetailsGalleryModal } from '../data-type-detail/DataDetailGalleryModal';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { SCREENSHOTS } from 'src/models/alertsType.enum';
import { ScreenshotmetaGrid } from '../screenshot-alerts-table/ScreenshotmetaGrid';

export function ScreenshotmetaAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const screenshotsStatus = useSelector(selectScreenshotsStatus);
    const screenshotsData = useSelector(selectScreenshotsMeta);
    const screenshotData = useSelector(selectScreenshot);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [imageError, setImageError] = useState<string[]>([]);
    const screenshotsDataDeviceId = useSelector(selectScreenshotsDeviceId);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const [galleryViewData, setGalleryViewData] = useState({});
    const allPageData = useSelector(selectallPageData);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState({});
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [dataId, setDataId] = useState<string | undefined>(undefined);
    const prevPage = useRef(1);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };

    function onPageChange(pageNumber: number): void
    {
        if (screenshotsData &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(screenshotsData?.contents[screenshotsData?.contents.length - 1].id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                if (Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0)
                {
                    setSkipRecordId(lastRecordDatas[lastRecordDatas.length - 1].id);
                }
                else
                {
                    setSkipRecordId('');
                }
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        setImageError([]);
        prevPage.current = pageNumber;
    }
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);

        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    const prevFilters = useRef(filters);
    const handleGalleryOpen = (data: ScreenshotData): void =>
    {
        setGalleryViewData(data);
        openRecordData(data.id);
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: data.id,
                dataType: DataType.SCREENSHOTS,
            }));
        }
        setGalleryOpen(true);
        const mutableImages = [];
        mutableImages.push({
            createdAt: data.createdAtFirst,
        });
        mutableImages.push({
            createdAt: data.createdAtDate,
        });
        const sortedImages = mutableImages.sort((a, b) =>
            new Date(
                a?.createdAt as Date
            ).getTime() -
            new Date(
                b?.createdAt as Date
            ).getTime());
        const startDate = new Date(data.receivedDate);
        const endDate = new Date(data.receivedDate);
        // Set start of day
        startDate.setHours(0, 0, 0, 0);
        // Set end of day
        endDate.setHours(23, 59, 59, 999);
        dispatch(getScreenshotsMetadevicebyid({
            id: data.id,
            startDate: startDate.toString(),
            endDate: endDate.toString(),
            username: data.username,
            pageNumber: 1,
            entriesPerPage: 0,
            deviceIds: data.deviceId,
            isDateFilterChanged: false,
            dateFirst: sortedImages[0].createdAt,
            dateLast: sortedImages[1].createdAt,

        }));
        dispatch(changeScreenshot(screenshotsDataDeviceId?.contents[0]));
    };

    useEffect(() =>
    {
        dispatch(changescreenshotGridViewFilters(false));
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {

        if (dataId == null || undefined)
        {
            if (screenshotsStatus === RequestStatus.Success)
            {
                if (screenshotsData)
                {
                    const lastImages =
                        screenshotsData?.contents?.flatMap((e) =>
                        {
                            return { id: e.lastId, clientCreatedAt: e.clientCreatedAtLast };
                        });
                    const firstImages = screenshotsData?.contents?.flatMap((e) =>
                    {
                        return { id: e.id, clientCreatedAt: e.clientCreatedAtFirst };
                    });


                    lastImages.sort((a, b) =>
                    {
                        const dateA = new Date(a?.clientCreatedAt as Date);
                        const dateB = new Date(b?.clientCreatedAt as Date);
                        return dateB.getTime() - dateA.getTime();
                    });
                    firstImages.sort((a, b) =>
                    {
                        const dateA = new Date(a?.clientCreatedAt as Date);
                        const dateB = new Date(b?.clientCreatedAt as Date);
                        return dateB.getTime() - dateA.getTime();
                    });
                    if (lastImages.length > 0 && firstImages.length > 0)
                    {
                        const dateLastImage = new Date(lastImages[0]?.clientCreatedAt as Date);
                        const dateFirstImage = new Date(firstImages[0]?.clientCreatedAt as Date);
                        if (dateLastImage > dateFirstImage)
                        {
                            setDataId(lastImages[0].id);
                        }
                        else
                        {
                            setDataId(firstImages[0].id);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [dataId, screenshotsData, filters]);

    useEffect(() =>
    {
        if (galleryOpen === false)
        {
            dispatch(changescreenshotStatus());
            dispatch(changeScreenshotDeviceId(undefined));
            dispatch(changeScreenshot(undefined));
        }
        // eslint-disable-next-line
    }, [galleryOpen]);

    useEffect(() =>
    {
        if (galleryOpen === false)
        {
            const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
            // eslint-disable-next-line
            const filtersChanged = checkFilterChange(filters, prevFilters.current, SCREENSHOTS);

            if (filtersChanged)
            {
                dispatch(getScreenshotsMeta({
                    dataId,
                    skipRecordId,
                    pageNumber: checkFilterChange(currentFilter, filters, SCREENSHOTS)
                        ? currentPage : 1,
                    entriesPerPage: rowsPerPage,
                    isDateFilterChanged: false,
                    ...filters,
                }));

            }
            else
            {
                dispatch(getScreenshotsMeta({
                    pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
                    entriesPerPage: rowsPerPage,
                    isDateFilterChanged: false,
                    ...filters,
                }));
                if (screenshotsStatus === RequestStatus.Success)
                {
                    setDataId(undefined);
                }
            }
            prevFilters.current = filters;
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);


    const detailItems = [
        {
            label: t('data-type.table.captured-date'),
            text: (data: ScreenshotData) =>
                new Date(
                    data.images[data.imageIndex].clientCreatedAt.toLocaleString()
                ).toLocaleString(),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ScreenshotData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ScreenshotData) => data.username,
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            text: (data: ScreenshotData) => data.filesCount,
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: ScreenshotData) => data.alertWords?.join(', '),
        },
    ];
    const getImageColumn = (data: ScreenshotData): React.ReactNode => (
        imageError.includes(data.id) ? <AccessTimeIcon /> : (
            <img
                height="50"
                width="100"
                src={data.fileUrl}
                onError={() => setImageError([...imageError, data.id])}
                alt={new Date(data.capturedDate).toLocaleString()}
            />
        )
    );
    const getAiScanStatusColumn = (data: ScreenshotData): React.ReactNode =>
    {
        if (data.aiScanStatus === 2) return <ScoutRed />;
        else if (data.aiScanStatus === 0) return (
            <HourglassEmptyOutlinedIcon
                sx={{ fill: 'lightGrey' }}
            />
        );
        else return ('');
    };
    const getFlagColumn = (data: ScreenshotData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.SCREENSHOTS}
            enabled={false}
        />
    );

    const openDetailColumn = (data: ScreenshotData): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => handleGalleryOpen(data)}
        >
            <ManageSearchIcon />
        </IconButton>
    );


    useEffect(() =>
    {
        if (screenshotsData)
        {
            pdfExportSetter({
                type: 'Screenshots Meta',
                recordIds: screenshotsData.contents.map((item) => item.id),
                detailOpen: galleryOpen,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(screenshotsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.SCREENSHOTS_META)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotsData, pdfExportSetter]);

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: ScreenshotData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: ScreenshotData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: ScreenshotData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: ScreenshotData) =>
                data.capturedDate ? new Date(data.capturedDate)?.toLocaleString() : '',
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: ScreenshotData) =>
                data.createdAtDate ? new Date(data.createdAtDate)?.toLocaleString() : '',
            sortByType: 'createdAtDate',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: ScreenshotData) => data.deviceAlias,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.program'),
            size: 4,
            value: (data: ScreenshotData) => '',
            sortByType: 'appOnFocus',
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            align: 'right' as const,
            size: 3,
            value: (data: ScreenshotData) => data.filesCount,
        },
        {
            label: t('data-type.table.image'),
            size: 1,
            align: 'center' as const,
            format: (data: ScreenshotData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: ScreenshotData) =>
            {
                if (data.alertWords && data.alertWords.length > 0)
                {
                    const mergedAlertwords = data.alertWords.flatMap(innerArray => innerArray);
                    const alertwordsData = Array.from(new Set(mergedAlertwords));
                    return alertwordsData?.join(',');
                }
                else
                {
                    return '';
                }
            },
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            align: 'center' as const,
            size: 1,
            format: (data: ScreenshotData) => openDetailColumn(data),
        },
    ];

    return (
        <>
            <DataDetailsGalleryModal<ScreenshotData>
                title={t('data-type.details.screenshots-title')}
                open={galleryOpen}
                data={screenshotsDataDeviceId?.contents[0]}
                detailItems={detailItems}
                onClose={() =>
                {
                    setGalleryOpen(false);
                    dispatch(resetscreenshotFilterFlag());
                    dispatch(changescreenshotGridViewFilters(false));
                }
                }
                comments={{
                    contentId:
                        screenshotData?.images[screenshotData?.imageIndex ?? 0]?.id,
                    type: CommentType.SCREENSHOTS,
                }}
            >
                <ScreenshotmetaGrid
                    {...galleryViewData as ScreenshotData}
                />
            </DataDetailsGalleryModal>
            {useDesktopScreen() ? (
                <DataTable<ScreenshotData>
                    isLoading={screenshotsStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={updateEntriesPerPage}
                    datas={datas}
                    {...screenshotsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={screenshotsStatus === RequestStatus.InProgress}
                    contents={screenshotsData?.contents || []}
                    setOpenModal={handleGalleryOpen}
                    onChangeEntriesPerPage={updateEntriesPerPage}
                    entriesPerPage={entriesPerPage}
                    onPageChange={onPageChange}
                    numberOfEntries={screenshotsData?.numberOfEntries}
                    currentPage={screenshotsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
