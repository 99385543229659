import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ContentModal } from 'src/components/content-modal/ContentModal';
import { Report } from 'src/models/administration/reportData.model';

export interface DetailsModalProps {
    open: boolean,
    title?: string,
    data: Report | undefined,
    detailItems?: DetailedItem[],
    onClose?: () => void,
    children?: React.ReactElement
    isLoading?: boolean,
    showComments?: boolean,
}

export interface DetailedItem {
    label: string;
    text?: ((data: Report) => string | number);
}

export function ReportsModal(
    {
        open,
        title,
        onClose,
        detailItems,
        data,
        children,
        isLoading,
    }: Readonly<DetailsModalProps>
): React.ReactElement
{
    const { t } = useTranslation();
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() =>
    {
        if (open)
        {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null)
            {
                descriptionElement.focus();
            }
        }
    }, [open]);
    return (
        <ContentModal
            { ...{ open, title, isLoading, onClose }}
        >
            <Grid
                container
                height="100%"
                direction="row"
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                    height="100%"
                >
                    <Grid
                        container
                        direction="column"
                        height="100%"
                        wrap="nowrap"
                        spacing={2}
                    >
                        { detailItems ? (
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    {t('data-type.details.record-detail')}
                                </Typography>
                                <Grid container>
                                    {detailItems?.map((detailItem, key) => (
                                        <Grid
                                            key={`row-${key + 1}`}
                                            item
                                            xs={6}
                                        >
                                            <label style={{ marginRight: '5px' }}>
                                                {detailItem.label}
                                                :
                                            </label>
                                            <span>
                                                {data && detailItem?.text?.(data)}
                                            </span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ) : '' }
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexFlow: 'column nowrap',
                                flex: '1 0',
                            }}
                        >
                            {children ?
                                (
                                    <Box sx={{ flex: '1 0' }}>
                                        {children}
                                    </Box>
                                ) : ''
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentModal>
    );
}
