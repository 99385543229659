import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { LOGIN } from 'src/utils/routes';
import { LoginHeader } from 'src/templates/login-header/LoginHeader';
import { PasswordChangeForm } from 'src/templates/password-change-form/PasswordChangeForm';
import { AuthenticationPage } from 'src/templates/authentication-page/AuthenticationPage';
import { ReactComponent as PadlockImage } from 'src/assets/images/padlock.svg';
import
{
    resetPassword,
    selectResetPasswordStatus,
    selectVerifyTokenStatus,
}
    from 'src/state/user/userSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';

export function ResetPassword(): JSX.Element
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { forgotPasswordToken = '' } = useParams();
    const { t } = useTranslation();
    const verifyTokenStatus = useSelector(selectVerifyTokenStatus);

    const resetPasswordStatus = useSelector(selectResetPasswordStatus);

    function handleCancel(): void
    {
        navigate(LOGIN);
    }

    function handleChangePassword(password: string, confirmPassword: string): void
    {
        dispatch(resetPassword({ forgotPasswordToken, password, confirmPassword }));
    }

    if (resetPasswordStatus === RequestStatus.Success)
    {
        navigate(LOGIN);
    }

    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.common.darkGrey,
            }}
        >
            <LoginHeader />
            <AuthenticationPage image={<PadlockImage />}>
                <PasswordChangeForm
                    title={t('password-change.reset-password-title')}
                    handleCancel={handleCancel}
                    handleChangePassword={handleChangePassword}
                    isChangingPassword={resetPasswordStatus === RequestStatus.InProgress}
                    hasError={resetPasswordStatus === RequestStatus.Failure}
                    expiredError={verifyTokenStatus === RequestStatus.Failure}
                />
            </AuthenticationPage>
        </Box>
    );
}
