import React from 'react';
import { ColumnData } from 'src/components/data-table/DataTable';
import ModalExport from 'src/components/export-modal/ExportModal';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { noop } from 'src/utils/common';

function exportData<T>(
    data: T[],
    columns: ColumnData<T>[],
    selection: number[],
    filename: string,
    format: TableExportFormat
): void
{
    const selectedColumns = columns.filter((_, index) => selection.includes(index));

    switch (format)
    {
    case TableExportFormat.CSV:
        TableExporter.asCSV(data, selectedColumns, filename);
        break;
    case TableExportFormat.XLSX:
        TableExporter.asXLSX(data, selectedColumns, filename);
        break;
    default:
        // eslint-disable-next-line no-console
        console.error('Not supported.');
    }
}

export interface ColumnSelectorProps<T> {
    format: TableExportFormat;
    columns: ColumnData<T>[];
    data: PaginatedContent<T>;
    onClose?: () => void;
    filename?: string;
}

export function ColumnSelector<T>({
    format,
    columns,
    data,
    onClose = noop,
    filename = 'export',
}: Readonly<ColumnSelectorProps<T>>): React.ReactElement
{
    const exportableColumns = columns.filter((column) => column.value);
    const { contents } = data;
    const startIndex = ((data.currentPage - 1) * data.contents.length) + 1;
    const endIndex = startIndex + data.contents.length - 1;

    const finalFilename = `${filename}-${startIndex}-${endIndex}-${new Date().toISOString()}`;
    const options = exportableColumns.map((column, index) => ({
        label: column.label,
        value: index,
        checked: true,
    }));

    return (
        <ModalExport
            options={options}
            open={true}
            onExport={(selection) =>
            {
                exportData(contents, exportableColumns, selection, finalFilename, format);
                onClose();
            } }
            onCloseRequest={onClose}
            format={format}
        />
    );
}
