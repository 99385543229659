import { GenerateReportParams, GenerateReportParamsIds } from 'src/models/captures/report.model';

export interface APIGenerateReportParams {
    dataType?: string;
    dataIds: string[];
    deviceIds?: string[];
    userTimeZone?:string;
    sortBy?:string,
    alltoExport?:boolean
    deviceId?:string;
    userName?:string;
}

export interface APIGenerateReportIdParams {
    dataType: string;
    externalNumber: string;
    deviceNumber: string;
    startDate?: string;
    endDate?: string;
    device?:string;
}

export function transformGenerateReportParams({
    type,
    recordIds,
    sortBy,
    alltoExport,
    deviceId,
    userName
}: GenerateReportParams): APIGenerateReportParams
{
    return {
        dataType: type,
        dataIds: recordIds,
        sortBy,
        alltoExport,
        deviceId,
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userName
    };
}

export function transformGenerateReportIdParams({
    type,
    externalNumber,
    deviceNumber,
    startDate,
    endDate,
    device
}: GenerateReportParamsIds): APIGenerateReportIdParams
{
    return {
        dataType: type,
        externalNumber,
        deviceNumber,
        startDate,
        endDate,
        device
    };
}
