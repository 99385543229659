import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { TextSMSAlertsTable } from 'src/templates/text-sms-alerts-table/TextSMSAlertsTable';

export function DataTypeTextSMS(): React.ReactElement
{
    return (
        <DataTypePage>
            <TextSMSAlertsTable />
        </DataTypePage>
    );
}
