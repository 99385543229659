import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Flag, flagStringKey } from 'src/models/flag.enum';
import { AlertTriggerType, ALERTSPAGE } from 'src/models/alertsType.enum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { FlagIcon } from '../../components/flag-icon/FlagIcon';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DataType } from 'src/models/dataType.enum';
import {
    updateFlag,
    selectUpdateFlagStatus,
    clearFlagStatus,
    selectUpdatedFlag,
    changeAlertsFlag,
    selectRedFlagCount,
    changeRedFlagCount,
} from 'src/state/captures/capturesSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { CapturedData } from 'src/models/captures/capturedData.model';
import { AlertsData } from 'src/models/captures/alertsData.model';
import { CircularProgress } from '@mui/material';
import { sendpushnotificationConfiguration } from 'src/state/administration/administrationSlice';

interface ObjectWithId {
    _id: string;
    id: string;
}

export interface FlagTooltipProps {
    readonly getIds?: (data: CapturedData) => string[];
    readonly alertsdata?: AlertsData;
    readonly capturedData: CapturedData;
    readonly dataType: DataType;
    readonly onChange?: () => void;
    readonly enabled?: boolean;
    // eslint-disable-next-line
    readonly getAlerts?: any;
}

export interface DataTypeFlagItemProps {
    readonly flag: Flag;
}

export function DataTypeFlagItem({
    alertsdata,
    capturedData,
    dataType,
    onChange,
    enabled = true,
    getIds = () => [],
    getAlerts,
}: FlagTooltipProps): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const updateFlagStatus = useSelector(selectUpdateFlagStatus);
    const updatedFlag = useSelector(selectUpdatedFlag);
    const redflagValue = useSelector(selectRedFlagCount);
    const [updateFlagVal, setUpdateFlagVal] = useState<Flag>(capturedData.flag);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    useEffect(() =>
    {
        if (
            isObjectWithId(updatedFlag) &&
            (updatedFlag._id === capturedData.id ||
                updatedFlag.id === capturedData.id) &&
            updateFlagStatus === RequestStatus.Success
        )
        {
            dispatch(clearFlagStatus());
            if (onChange) onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedFlag]);

    function isObjectWithId(obj: unknown): obj is ObjectWithId
    {
        return (
            typeof updatedFlag === 'object' &&
            updatedFlag !== null &&
            ('_id' in updatedFlag || 'id' in updatedFlag)
        );
    }
    // eslint-disable-next-line
    function onFlagChange(id: string, flag: Flag, data:any): React.ReactNode
    {

        const formdata = {
            deviceId: data.deviceId,
            payload: {
                notificationTitle: 'Update flag',
                notificationMessage: `Record from ${dataType} were marked as "${flag}"`,
                dataType: window.location.pathname,
            },
        };
        flag === Flag.CRIMINAL_ACTIVITY &&
        dispatch( sendpushnotificationConfiguration(formdata) );

        dispatch(updateFlag({ id, flag, dataType, ids: getIds(capturedData) }));
        setUpdateFlagVal(flag);

        if (isAlertsPage)
        {
            let newRedflagCount;
            if (updateFlagVal && updateFlagStatus === RequestStatus.InProgress)
            {
                return (
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={24} style={{ marginRight: '10px' }} />
                    </Box>
                );
            }
            else
            {

                if (data.flag !== Flag.CRIMINAL_ACTIVITY &&
                    flag === Flag.CRIMINAL_ACTIVITY)
                {
                    newRedflagCount = Number(redflagValue?.redflagCount) + 1;
                }
                else if (
                    data.flag === Flag.CRIMINAL_ACTIVITY &&
                    flag !== Flag.CRIMINAL_ACTIVITY)
                {
                    newRedflagCount = Number(redflagValue?.redflagCount) - 1;
                }
                else
                {
                    newRedflagCount = redflagValue?.redflagCount;
                }

                dispatch(changeRedFlagCount({
                    recordCount: redflagValue?.recordCount,
                    redflagCount: newRedflagCount,
                }));
                dispatch(changeAlertsFlag({
                    id,
                    flag: AlertTriggerType.FLAG,
                    flagType: flag,
                }));
            }
        }
    }

    function LegendItem({ flag }: DataTypeFlagItemProps): React.ReactElement
    {
        return (
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() =>
                {
                    handleClose();
                    (isAlertsPage && alertsdata) ?
                        onFlagChange(alertsdata.id, flag, alertsdata) :
                        onFlagChange(capturedData.id, flag, capturedData);
                }}
                sx={{
                    justifyContent: 'flex-start',
                    '&.Mui-disabled': {
                        color: (theme) => theme.palette.primary.contrastText,
                    },
                }}
                disabled={
                    updateFlagVal === flag || flag === Flag.SOFTWARE || !enabled
                }
                startIcon={<FlagIcon flag={flag} />}
                endIcon={
                    (updateFlagVal === flag ||
                        (!updateFlagVal && flag === Flag.NO_FLAG)) && (
                        <CheckCircleIcon
                            sx={{ position: 'absolute', right: 10, top: 10 }}
                        />
                    )
                }
            >
                {t(flagStringKey(flag))}
            </Button>
        );
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = (): void =>
    {
        setOpen(false);
    };

    const handleOpen = (): void =>
    {
        setOpen(true);
    };

    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        [theme.breakpoints.up('md')]: {
                            maxWidth: 630,
                        },
                        [theme.breakpoints.down('md')]: {
                            maxWidth: 330,
                            marginLeft: '0 !important',
                        },
                    },
                },
            }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            TransitionProps={{ timeout: 100 }}
            title={(
                <Grid container spacing={2} padding={2}>
                    {Object.values(Flag).map((item) => (
                        <Grid
                            item
                            key={item.toString()}
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            {LegendItem({ flag: item })}
                        </Grid>
                    ))}
                </Grid>
            )}
            arrow
            placement="right"
        >
            <Box minHeight="100%">
                <FlagIcon flag={updateFlagVal} />
            </Box>
        </Tooltip>
    );
}
