import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearReports,
    getReports,
    getUsersNoPageable,
    selectReport,
    deleteReport,
    selectReportCreateStatus,
    selectReportFilters,
    selectReports,
    selectReportsStatus,
    selectReportUpdateStatus,
    selectReportDeleteStatus,
    selectUsersNoPageable,
    setReport,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Report } from 'src/models/administration/reportData.model';
import { REPORTS_EDIT } from 'src/utils/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { ContextMenu } from 'src/components/context-menu/ContextMenu';
import { ReportDetails } from './ReportDetails';
import { ReportsModal } from './ReportsModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
    getDeviceFilterOptions,
    selectDeviceFilterOptions,
} from 'src/state/captures/capturesSlice';
import { Box, Grid, Typography } from '@mui/material';
import { Modal } from 'src/components/modal/Modal';
import ConfirmationPanel from '../confirmation-panel/ConfirmationPanel';

export function ReportsTable(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectReports);
    const report = useSelector(selectReport);
    const filters = useSelector(selectReportFilters);
    const status = useSelector(selectReportsStatus);
    const reportCreateStatus = useSelector(selectReportCreateStatus);
    const reportEditStatus = useSelector(selectReportUpdateStatus);
    const reportDeleteStatus = useSelector(selectReportDeleteStatus);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const [reportCreated, setReportCreated] = useState(false);
    const [reportUpdated, setReportUpdated] = useState(false);
    const [reportDeleted, setReportDeleted] = useState(false);
    const users = useSelector(selectUsersNoPageable);
    const devices = useSelector(selectDeviceFilterOptions);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getReports({ pageNumber, entriesPerPage, ...filters }));
    }

    function DeleteSubmit(): void
    {
        if (report)
        {
            dispatch(deleteReport(report.id as string));
            setOpenAlertModal(false);
        }
    }
    function DeleteModal(): void
    {
        setOpenAlertModal(false);
    }

    useEffect(() =>
    {
        setTimeout(() => dispatch(clearReports()), 1000);
        setReportCreated(reportCreateStatus === RequestStatus.Success);
        setReportUpdated(reportEditStatus === RequestStatus.Success);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        setReportDeleted(
            reportDeleteStatus === RequestStatus.Success ||
            reportDeleteStatus === RequestStatus.Failure
        );

    }, [reportDeleteStatus]);

    useEffect(() =>
    {
        dispatch(getReports({ pageNumber: 1, entriesPerPage, ...filters }));
        dispatch(getUsersNoPageable({}));
        dispatch(getDeviceFilterOptions({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, entriesPerPage, filters, reportDeleteStatus === RequestStatus.Success]);

    const detailItems = [
        {
            label: t('form.name'),
            text: (data: Report) => data.name?.toString() ?? '',
        },
        {
            label: t('form.data-type'),
            text: (data: Report) => data.dataType?.toString() ?? '',
        },
        {
            label: t('form.frequency'),
            text: (data: Report) => data.frequency?.toString() ?? '',
        },
        {
            label: t('form.report-status'),
            text: (data: Report) =>
                data.isActive ? t('common.toggle.on') : t('common.toggle.off'),
        },
        {
            label: t('form.devices'),
            text: (data: Report) =>
            {
                const deviceNames = (data.devices ?? []).map((deviceId) =>
                {
                    const matchingDevice = devices ?
                        devices.find((device) => device.value === deviceId) : undefined;
                    return matchingDevice ? matchingDevice.name : '';
                });

                return deviceNames.join(', ');
            },
        },
        {
            label: t('form.reports-recipients'),
            text: (data: Report) =>
            {
                const recipientsInformations = (data.users ?? []).map((userId) =>
                {
                    const matchingUser = users ?
                        users.find((user) => user.id === userId) : undefined;
                    return matchingUser ?
                        `${matchingUser.firstName}
                        ${matchingUser.lastName}
                        (${matchingUser.email})` : '';
                });

                return recipientsInformations.join(', ');
            },
        },
    ];

    const options = [
        {
            label: t('reports.edit-report'),
            onClick: (data: Report) =>
            {
                dispatch(setReport(data));
                navigate(generatePath(REPORTS_EDIT, { id: data.id as string }));
            },
        },
        {
            label: t('reports.view-reports'),
            onClick: (data: Report) =>
            {
                dispatch(setReport(data));
                setOpen(true);
            },
        },
        {
            label: t('reports.delete-reports'),
            onClick: (data: Report) =>
            {
                dispatch(setReport(data));
                setOpenAlertModal(true);
            },
        },
    ];

    const getActionColumn = (data: Report): React.ReactNode => (
        <ContextMenu<Report> options={options} data={data} />
    );

    const columns: ColumnData<Report>[] = [
        {
            label: t('data-type.table.date-of-creation'),
            size: 3,
            value: (data: Report) =>
                new Date(data?.createdAt ?? '')?.toLocaleString(),
        },
        {
            label: t('data-type.table.report-title'),
            size: 8,
            value: (data: Report) => data.name,
        },
        {
            label: t('data-type.table.format'),
            size: 4,
            value: (data: Report) =>data.reportFormat ?? 'PDF',
        },
        {
            label: t('data-type.table.frequency'),
            size: 4,
            value: (data: Report) => data.frequency,
        },
        {
            label: t('data-type.table.action'),
            size: 1,
            align: 'center' as const,
            format: (data: Report) => getActionColumn(data),
        },
    ];

    return (
        <>
            <Snackbar
                open={reportCreated}
                autoHideDuration={6000}
                onClose={() => setReportCreated(false)}
            >
                <Alert severity="success">
                    {t('reports.report-created')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={reportUpdated}
                autoHideDuration={6000}
                onClose={() => setReportUpdated(false)}
            >
                <Alert severity="success">
                    {t('reports.report-updated')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={reportDeleted}
                autoHideDuration={3000}
                onClose={() => setReportDeleted(false)}
            >
                {reportDeleteStatus === RequestStatus.Success ? (
                    <Alert severity="success">
                        {t('reports.report-deleted')}
                    </Alert>
                ) : (
                    <Alert severity="error">
                        Error
                    </Alert>
                )}
            </Snackbar>
            <ReportsModal
                title={t('reports.report-list')}
                detailItems={detailItems}
                open={open}
                data={report}
                showComments={false}
                onClose={() => setOpen(false)}
            >
                <>
                    {report?.inActiveDevices && report.inActiveDevices.length > 0 && (
                        <Grid
                            style={{
                                border: '2px solid #fbd808',
                                padding: '15px',
                                width: '50%',
                                marginBottom: 10,
                            }}
                        >
                            <span style={{ maxWidth: '10px', marginBottom: 10 }}>
                                <Alert severity="warning" style={{ marginBottom: '10px' }}>
                                    {t('reports.device-deactivate-content')}
                                </Alert>
                            </span>
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                <div style={{ fontSize: '14px', margin: '0' }}>
                                    {report.inActiveDevices?.map((device, index) => (
                                        // eslint-disable-next-line max-len
                                        `${device.deviceName}(${device.deviceId})${index < (report.inActiveDevices?.length ?? 0) - 1 ? ',  ' : ''}`
                                    )).join('')}
                                </div>
                            </div>
                        </Grid>
                    )}
                    <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[500],
                        }}
                    >
                        {t('data-type.details.details')}
                    </Typography>
                    <ReportDetails />
                </>
            </ReportsModal>
            <DataTable<Report>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...items}
            />
            <Modal
                title={t('data-type.table.delete-report')}
                open={openAlertModal}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationPanel
                        data={report?.name ?? ''}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Report'}
                    />
                </Box>
            </Modal>
        </>
    );
}
