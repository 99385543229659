import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    FilesTrackingAlertsTable,
} from 'src/templates/files-tracking-alerts-table/FilesTrackingAlertsTable';

export function DataTypeFilesTracking(): React.ReactElement
{
    return (
        <DataTypePage>
            <FilesTrackingAlertsTable />
        </DataTypePage>
    );
}
