import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    WebsitesTable,
} from 'src/templates/websites-table/WebsitesTable';

export function DataTypeWebsites(): React.ReactElement
{
    return (
        <DataTypePage>
            <WebsitesTable />
        </DataTypePage>
    );
}
