import {
    Notification,
    NotificationConfiguration,
    NotificationConfigurationParams,
    NotificationParams,
    NotificationTriggers,
} from 'src/models/administration/notificationData.model';
import { NotificationType } from 'src/models/administration/notificationType.enum';
import { DataType } from 'src/models/dataType.enum';
import { Frequency } from 'src/models/frequency.enum';
import { APIPaginationParams, transformPaginationParams } from '../pagination';

export interface APINotificationConfigurationParams extends APIPaginationParams {
    name?: string;
    deviceId?: string;
    type?: NotificationType;
    frequency?: Frequency;
    userId?: string;
    startDate?: string;
    endDate?: string;
}

export interface APINotificationParams extends APIPaginationParams {
    startDate?: string;
    endDate?: string;
}

export interface APINotificationTriggers {
    alertWords?: string[];
}

export interface APINotificationConfigurationRequest {
    name?: string;
    deviceIds?: string[];
    type?: NotificationType;
    triggers?: NotificationTriggers;
    frequency?: Frequency;
    endDate?: Date;
    userIds?: string[];
    isActive?: boolean;
}

export interface APINotificationConfigurationResponse extends APINotificationConfigurationRequest {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    owner: string;
    devices?: string[];
    users?: string[];
}

export interface APINotificationResponse {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    notificationConfigurationId: string;
    deviceId: string;
    dataType: DataType;
    dataId: string;
    clientCreatedAt: Date;
    triggers: NotificationTriggers;
}

function formatEndDate(endDate: Date): Date
{
    return new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999)
    );
}

export function transformNotificationConfigurationParams(
    params: NotificationConfigurationParams
): APINotificationConfigurationParams
{
    const {
        startDate,
        endDate,
        ...paginatedParams
    } = params;

    return {
        startDate,
        endDate,
        ...transformPaginationParams(paginatedParams),
    };
}

export function transformNotificationParams(
    params: NotificationParams
): APINotificationParams
{
    const { startDate, endDate, ...paginatedParams } = params;

    return {
        ...transformPaginationParams(paginatedParams),
    };
}

export function readAPINotificationConfigurationResponse(
    data: APINotificationConfigurationResponse
): NotificationConfiguration
{
    return {
        id: data.id,
        name: data.name,
        type: data.type,
        devices: data.devices,
        endDate: data.endDate && new Date(data.endDate?.toLocaleString()),
        frequency: data.frequency,
        owner: data.owner,
        triggers: data.triggers,
        users: data.users,
        isActive: data.isActive,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
    };
}

export function transformNotificationConfigurationRequest(
    data: NotificationConfiguration
): APINotificationConfigurationRequest
{
    return {
        name: data.name,
        type: data.type,
        deviceIds: data.devices,
        endDate: data.endDate && formatEndDate(data.endDate),
        frequency: data.frequency,
        userIds: data.users,
        isActive: data.isActive,
        triggers: data.triggers,
    };
}

export function readAPINotificationResponse(data: APINotificationResponse): Notification
{
    return {
        id: data.id,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        notificationConfigurationId: data.notificationConfigurationId,
        deviceId: data.deviceId,
        dataType: data.dataType,
        dataId: data.dataId,
        clientCreatedAt: data.clientCreatedAt,
        triggers: data.triggers,
    };
}
