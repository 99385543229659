import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    Button,
    CircularProgress,
    Grid,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';
import {
    getAllAgencies,
    getAllClients,
    getAllProfiles,
    selectAllProfiles,
} from 'src/state/administration/administrationSlice';
import { noop, removeDuplicates } from 'src/utils/common';
import { read, utils } from 'xlsx';

interface CSVWord {
    word: string;
}

export interface AWLFormProps {
    initialValues?: GenericAWL;
    actionLabel: string;
    isLoading?: boolean;
    profileField: boolean;
    agencyField?: boolean;
    clientField?: boolean;
    officerField?: boolean;
    onSubmit?: (values: GenericAWL) => void;
    onBackToList?: () => void;

}

interface FormErrors {
    [key: string]: string[]|undefined;
}

export const DEFAULT_VALUES = { name: '', words: [], profileId: '' };

export function AWLForm({
    initialValues,
    actionLabel,
    profileField = false,
    agencyField = false,
    clientField = false,
    officerField = false,
    isLoading = false,
    onSubmit = noop,
    onBackToList = noop,
}: Readonly<AWLFormProps>): React.ReactElement
{
    const { t } = useTranslation();
    const [value, setValue] = useState<GenericAWL>(
        initialValues ?? DEFAULT_VALUES
    );
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const profiles = useSelector(selectAllProfiles);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    function updateField(fieldName: string, value: unknown): void
    {
        setValue((current) => ({
            ...current,
            [fieldName]: value,
        }));
    }

    function onWordsBlur(event: FocusEvent<HTMLInputElement>): void
    {
        setFormErrors((current) => ({
            ...current,
            words: value.words.length ? undefined : [t('common.form-messages.required')],
        }));
    }

    function onChange(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FocusEvent<HTMLInputElement>
    ): void
    {
        let { value } = event.target;
        value = event.type === 'blur' ? value.trim() : value;

        setFormErrors((current) => ({
            ...current,
            name: value ? undefined : [t('common.form-messages.required')],
        }));

        updateField('name', value);
    }

    function onSelectChange(fieldName: keyof GenericAWL): (
        event: SelectChangeEvent | FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    {
        return (
            event: SelectChangeEvent<string>|FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void =>
        {
            const { value } = event.target;

            setFormErrors((current) => ({
                ...current,
                [fieldName]: value ? undefined : [t('common.form-messages.required')],
            }));

            updateField(fieldName, value);
        };
    }

    async function onFileUpload(event: React.ChangeEvent<HTMLInputElement>): Promise<void>
    {
        const files = event.target.files;
        const file = files?.length ? files[0] : null;

        if (file)
        {
            const data = await file.arrayBuffer();
            const workbook = read(data);
            const contents: CSVWord[] = utils.sheet_to_json(
                workbook.Sheets[workbook.SheetNames[0]]
            );
            const words = contents.map((row: CSVWord) => row.word.trim());

            setFormErrors((current) => ({
                ...current,
                words: words.length ? undefined : [t('common.form-messages.required')],
            }));

            if (fileInputRef?.current)
            {
                fileInputRef.current.value = '';
            }

            updateField('words', removeDuplicates(words));
        }
    }

    function onChangeWordList(
        event: React.SyntheticEvent<Element, Event>,
        value: (string | string[])[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string[]> | undefined
    ): void
    {
        const words = value.map((word) =>
        {
            if (Array.isArray(word))
            {
                return word.join('');
            }
            return word;
        }).flat();

        setFormErrors((current) => ({
            ...current,
            words: words.length ? undefined : [t('common.form-messages.required')],
        }));

        updateField('words', removeDuplicates(words));
    }

    function onChangeEnabledStatus(
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void
    {
        updateField('isEnabled', checked);
    }

    function onSubmitHandler(event: React.FormEvent): void
    {
        event.preventDefault();

        if(!value.words.length)
        {
            setFormErrors((current) => ({
                ...current,
                words: [t('common.form-messages.required')],
            }));
            return;
        }

        if (!Object.values(formErrors).filter((error) => error).length)
        {
            onSubmit(value || DEFAULT_VALUES);
        }
    }

    function hasError(fieldName: keyof GenericAWL): boolean
    {
        return !!formErrors[fieldName]?.length;
    }

    function getErrorText(fieldName: keyof GenericAWL): string
    {
        return formErrors[fieldName]?.join('\n') ?? '';
    }

    useEffect(() =>
    {
        setValue(initialValues ?? DEFAULT_VALUES);
    }, [initialValues]);

    useEffect(() =>
    {
        dispatch(getAllProfiles());

        if (clientField)
        {
            dispatch(getAllClients());
        }
        if (agencyField)
        {
            dispatch(getAllAgencies());
        }
    }, [dispatch, agencyField, clientField, officerField]);

    return (
        <form onSubmit={onSubmitHandler}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6} >
                    <Grid container spacing={5} direction="column">
                        <Grid item>
                            <TextField
                                name="name"
                                label={t('alert-word.list-name')}
                                fullWidth
                                required
                                onChange={onChange}
                                onBlur={onChange}
                                autoComplete="off"
                                value={value.name}
                                error={hasError('name')}
                                helperText={getErrorText('name')}
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={[]}
                                filterSelectedOptions
                                freeSolo
                                onChange={onChangeWordList}
                                value={value.words}
                                disabled={isLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('alert-word.words')+ ' *'}
                                        placeholder={t('alert-word.word')}
                                        error={hasError('words')}
                                        helperText={getErrorText('words')}
                                        onBlur={onWordsBlur}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <input
                                type="file"
                                onChange={onFileUpload}
                                accept="text/csv"
                                ref={fileInputRef}
                                disabled={isLoading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={5} direction="column">
                        {
                            profileField
                                ? (

                                    <Grid item>
                                        <Autocomplete
                                            id="profile"
                                            options={profiles?.contents ?? []}
                                            getOptionLabel={(profile) => profile.name}
                                            onChange={(_, newValue) => onSelectChange('profileId')({
                                                target: { value: newValue?.id, name: 'profileId' },
                                            } as React.ChangeEvent<HTMLInputElement>)}
                                            value={
                                                profiles?.contents.find(
                                                    profile => profile.id === value.profileId
                                                ) ?? null}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('common.nouns.profile')+ ' *'}
                                                    error={hasError('profileId')}
                                                    helperText={getErrorText('profileId')}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                                : null
                        }
                        {
                            initialValues?.createdAt && (
                                <Grid item>
                                    <TextField
                                        name=""
                                        label={t('alert-word.creation-date')}
                                        fullWidth
                                        value={initialValues.createdAt}
                                        disabled
                                    />
                                </Grid>
                            )
                        }
                        {
                            initialValues?.authorId && (
                                <Grid item>
                                    <TextField
                                        name=""
                                        label={t('common.nouns.creator')}
                                        fullWidth
                                        value={initialValues.authorId}
                                        disabled
                                    />
                                </Grid>
                            )
                        }
                        {
                            initialValues?.authorName && (
                                <Grid item>
                                    <TextField
                                        name=""
                                        label={t('alert-word.creator-email')}
                                        fullWidth
                                        value={initialValues.authorName}
                                        disabled
                                    />
                                </Grid>
                            )
                        }
                        {
                            initialValues?.isEnabled !== undefined && (
                                <Grid item>
                                    <Stack direction="row" gap="1em" alignItems="center">
                                        <ToggleSwitch
                                            checked={value.isEnabled}
                                            onChange={onChangeEnabledStatus}
                                        />
                                        <Box>
                                            {t('alert-word.status')}
                                        </Box>
                                        <Box>
                                            {t(
                                                value.isEnabled
                                                    ? 'common.status.active'
                                                    : 'common.status.inactive'
                                            )}
                                        </Box>
                                    </Stack>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={1}
                padding="1em 0"
            >
                <Button
                    type="button"
                    variant="outlined"
                    onClick={onBackToList}
                >
                    {t('common.actions.cancel')}
                </Button>
                {
                    isLoading
                        ? (
                            <CircularProgress />
                        )
                        : (
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                {actionLabel}
                            </Button>
                        )
                }
            </Stack>
        </form>
    );
}
