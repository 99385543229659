import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GeolocationData } from 'src/models/captures/geolocationData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getGeolocations,
    changeGeolocation,
    selectFilters,
    selectGeolocation,
    selectGeolocations,
    selectGeolocationsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { GeolocationDetails } from './GeolocationDetails';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { USER_TIME_ZONE } from 'src/utils/environment';

export function GeolocationAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const geolocationsStatus = useSelector(selectGeolocationsStatus);
    const geolocationsData = useSelector(selectGeolocations);
    const geolocationData = useSelector(selectGeolocation);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const allPageData = useSelector(selectallPageData);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const prevPage = useRef(1);
    const [lastPage, setLastPage] = useState(0);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (data: GeolocationData): void =>
    {
        openRecordData(data.id);
        dispatch(changeGeolocation(data));
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus(
                { detailsId: data.id, dataType: DataType.GEOLOCATION }
            ));
        }
        setOpen(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (geolocationsData && pageNumber!==lastPage &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(geolocationsData?.contents[geolocationsData.contents.length - 1]
                    ?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getGeolocations({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ?
                currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: GeolocationData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.GEOLOCATION}
            onChange={() =>
            {
                onPageChange(geolocationsData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: GeolocationData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: GeolocationData) => data.receivedDate ?
                timeZoneFormat(new Date(data.receivedDate), USER_TIME_ZONE)
                : '',
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: GeolocationData) => data.capturedDate ?
                timeZoneFormat(new Date(data.capturedDate), USER_TIME_ZONE)
                : '',
        },
        {
            label: t('data-type.table.platform'),
            text: (data: GeolocationData) => data.platform,
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: GeolocationData) => data.device,
        },
        {
            label: t('data-type.table.latitude'),
            text: (data: GeolocationData) => data.latitude,
        },
        {
            label: t('data-type.table.longitude'),
            text: (data: GeolocationData) => data.longitude,
        },
    ];

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: GeolocationData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: GeolocationData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: GeolocationData) =>
                new Date(data.capturedDate)?.toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: GeolocationData) =>
                new Date(data.receivedDate)?.toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 8,
            value: (data: GeolocationData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.latitude'),
            size: 2,
            value: (data: GeolocationData) => data.latitude,
            sortByType: 'latitude',
        },
        {
            label: t('data-type.table.longitude'),
            size: 2,
            value: (data: GeolocationData) => data.longitude,
            sortByType: 'longitude',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: GeolocationData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (geolocationsData)
        {
            setLastPage(Math.floor(
                geolocationsData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.GEOLOCATION,
                recordIds: geolocationsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(geolocationsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.GEOLOCATION)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geolocationsData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<GeolocationData>
                title={t('data-type.details.geolocation-title')}
                open={open}
                data={geolocationData}
                dataType={DataType.GEOLOCATION}
                detailItems={detailItems}
                onClose={() => setOpen(false)}
            >
                <GeolocationDetails {...(geolocationData as GeolocationData)} />
            </DataDetailsModal>
            {useDesktopScreen() ? (
                <DataTable<GeolocationData>
                    isLoading={geolocationsStatus
                        === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...geolocationsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={geolocationsStatus
                        === RequestStatus.InProgress}
                    contents={geolocationsData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={geolocationsData?.numberOfEntries}
                    currentPage={geolocationsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
