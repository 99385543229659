import { APICaptureData, readAPICaptureData } from './captures';
import { PhotoData } from 'src/models/captures/photoData.model';


export interface APIPhotoResponse extends APICaptureData {
    aiScanStatus: number;
    clientTimeZone: string;
    fileId: string;
    fileUrl: string;
    thumbnailId: string;
    thumbnailUrl: string;
    manual: boolean;
    appOnFocus: string;
    alertWords: string[];
    comments?: number;
}

export function readAPIScreenshotResponse(
    data: APIPhotoResponse
): PhotoData
{
    const {
        aiScanStatus,
        fileId,
        fileUrl,
        thumbnailId,
        thumbnailUrl,
        manual,
        appOnFocus,
        alertWords,
        comments,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        aiScanStatus,
        fileId,
        fileUrl,
        thumbnailId,
        thumbnailUrl,
        manual,
        appOnFocus,
        alertWords,
        comments,
    };
}
