import { CaptureParams } from 'src/models/captures/capturedData.model';
import { FilesTrackingData } from 'src/models/captures/filesTrackingData.models';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPIFilesTrackingResponse } from '../data-transfer-objects/capture/filesTracking';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import instance from '../instance';

export async function getFilesTracking(
    captureParams: CaptureParams
): Promise<PaginatedContent<FilesTrackingData>>
{
    const response = await instance.get(
        'files-tracking',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIFilesTrackingResponse);
}

export async function getFileTracking (id: string): Promise<FilesTrackingData>
{
    const response = await instance.get(`files-tracking/${id}`).then(x => x.data);
    return readAPIFilesTrackingResponse(response);
}
