import React, { useState } from 'react';
import { Box, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flag, flagStringKey } from 'src/models/flag.enum';
import {
    selectAlertsFilter,
    selectFilters,
    selectscreenshotModalData,
    updateFlagToAll,
} from 'src/state/captures/capturesSlice';
import { FlagIcon } from 'src/components/flag-icon/FlagIcon';
import {
    DropdownOption,
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { Modal } from 'src/components/modal/Modal';
import { UpdateFlagAllModel } from './UpdateFlagAllModel';
import { DataType } from 'src/models/dataType.enum';
import { transformCaptureParams } from 'src/services/data-transfer-objects/capture/captures';
import { FlagParams } from 'src/models/captures/flagData.model';

export interface DataTypeUpdateFlagAllProps {
    dataType: DataType,
    count?: number;
}

export function DataTypeUpdateFlagAll({ dataType, count }
    : DataTypeUpdateFlagAllProps): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const detailsFilter = useSelector(selectscreenshotModalData)
    const [selectedFlag, setSelectedFlag] = useState<Flag | string>();
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [confirmPopup, setConfirmPopup] = useState<boolean>(false);

    const flags: DropdownOption[] = Object.values(Flag).map((value) =>
    {
        return {
            name: t(flagStringKey(value)),
            value,
            icon: <FlagIcon flag={value} />,
        };
    }).filter((flag) => flag?.value !== Flag.SOFTWARE) as DropdownOption[];

    const handleFlagUpdateConfirmation = (value: boolean): void =>
    {
        if(value === false)
        {
            setSelectedFlag(undefined);
            setConfirmModalOpen(false);
        }
        else if (value === true && selectedFlag)
        {
            const updateFlagFilter = {
                ...(isAlertsPage ? alertsFilter : filters),
                pageNumber: 1,
                entriesPerPage: count ? count : 0,
            };
            const flagAllRequest: FlagParams = {
                dataType,
                flag: selectedFlag as Flag,
                filters: dataType === DataType.SCREENSHOTS ? detailsFilter :transformCaptureParams(updateFlagFilter),
            };
            if (isAlertsPage) {
                flagAllRequest.alertsDataType = dataType;
            }
            dispatch(updateFlagToAll(flagAllRequest));
            setConfirmModalOpen(false);
            setConfirmPopup(true);
            setTimeout(() =>
            {
                setSelectedFlag(undefined);
                setConfirmPopup(false);
            }, 3000);
        }
    };

    function handleChangeOption(value: string): void
    {
        setSelectedFlag(value);
        setConfirmModalOpen(true);
    }

    return (
        <>
            <Box >
                <DropdownSelector
                    options={flags}
                    fullWidth
                    disabled={false}
                    label={`Flag All ${dataType}`}
                    value={selectedFlag}
                    onChange={(value) =>
                    {
                        handleChangeOption(value as string);
                    }}
                    inputProps={{
                        sx: {
                            backgroundColor:
                                (theme: Theme) => theme.palette.common.white,
                        },
                    }}
                />
            </Box>
            <Modal
                title={t('data-type.filters.confirmation-alert')}
                open={confirmModalOpen}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <>
                    <UpdateFlagAllModel
                        flag={selectedFlag as Flag}
                        onSubmit={handleFlagUpdateConfirmation}
                        count={count}
                    />
                </>
            </Modal>
            <Modal
                title={t('data-type.filters.confirmation-alert')}
                open={confirmPopup}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <span style={{ fontSize: '20px' }}>
                    {t('data-type.filters.confirmation-message')}
                    {' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {selectedFlag === Flag.NO_FLAG ? 'No Flag' : selectedFlag}
                    </span>
                    {' '}
                    {t('data-type.filters.confirmation-message-2')}
                </span>
            </Modal>
        </>
    );
}

export default DataTypeUpdateFlagAll;
