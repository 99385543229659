import { ErrorData } from 'src/models/errors/errorData.model';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
    CommentData,
    CommentListParams,
    CommentParams,
} from 'src/models/comments/commentData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getComments as getCommentsRequest,
    getCommentsfilter as getCommentsFilterRequest,
    createComment as createCommentRequest,
    deleteComments as deleteCommentsRequest,
    editComments as editCommentsRequest,
} from 'src/services/comments/comments';
import { RootState } from '../store';
import axios from 'axios';
interface CommentsState {
    comment?: CommentData,
    comments?: CommentData[]
    commentsStatus: RequestStatus,
    commentsfilter?: CommentData[]
    commentsfilterStatus: RequestStatus,
    saveCommentStatus: RequestStatus,
    deleteCommentStatus: RequestStatus,
    editCommentStatus: RequestStatus,
    commentError?: ErrorData,
}
const initialState: CommentsState = {
    commentsStatus: RequestStatus.NotStarted,
    commentsfilterStatus: RequestStatus.NotStarted,
    saveCommentStatus: RequestStatus.NotStarted,
    deleteCommentStatus: RequestStatus.NotStarted,
    editCommentStatus: RequestStatus.NotStarted,
};
export const getComments = createAsyncThunk(
    'comments/getComments',
    async (params: CommentListParams) => getCommentsRequest(params)
);
export const getCommentsfilter = createAsyncThunk(
    'comments/getCommentsfilter',
    async (
        params: CommentListParams
    ) => getCommentsFilterRequest(params)
);
export const createComment = createAsyncThunk(
    'comments/createComments',
    async (user: CommentParams, { rejectWithValue }) =>
    {
        try
        {
            return await createCommentRequest(user);
        }
        catch(error)
        {
            if (axios.isAxiosError( error ))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const deleteComments = createAsyncThunk(
    'comments/deleteComments',
    async (params: string | undefined) => deleteCommentsRequest(params)
);
export const editComments = createAsyncThunk(
    'comments/editComments',
    async (params: CommentData) => editCommentsRequest(params)
);
export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        clearComment: (state) =>
        {
            state.comment = undefined;
            state.saveCommentStatus = RequestStatus.NotStarted;
            state.commentError = undefined;
        },
        resetEditCommentStatus: (state) =>
        {
            state.editCommentStatus = RequestStatus.NotStarted;
        },
        resetDeleteCommentStatus: (state) =>
        {
            state.deleteCommentStatus = RequestStatus.NotStarted;
        },
    },
    extraReducers: (builder) =>
    {
        builder
            .addCase(getComments.pending, (state) =>
            {
                state.commentsStatus = RequestStatus.InProgress;
            })
            .addCase(getComments.fulfilled, (state, action) =>
            {
                state.commentsStatus = RequestStatus.Success;
                state.comments = action.payload;
            })
            .addCase(getComments.rejected, (state) =>
            {
                state.commentsStatus = RequestStatus.Failure;
                state.comments = undefined;
            })
            .addCase(getCommentsfilter.pending, (state) =>
            {
                state.commentsfilterStatus = RequestStatus.InProgress;
            })
            .addCase(getCommentsfilter.fulfilled, (state, action) =>
            {
                state.commentsfilterStatus = RequestStatus.Success;
                state.commentsfilter = action.payload;
            })
            .addCase(getCommentsfilter.rejected, (state) =>
            {
                state.commentsfilterStatus = RequestStatus.Failure;
                state.commentsfilter = undefined;
            })
            .addCase(editComments.pending, (state) =>
            {
                state.editCommentStatus = RequestStatus.InProgress;
            })
            .addCase(editComments.fulfilled, (state, action) =>
            {
                state.editCommentStatus = RequestStatus.Success;
            })
            .addCase(editComments.rejected, (state) =>
            {
                state.editCommentStatus = RequestStatus.Failure;
            })
            .addCase(deleteComments.pending, (state) =>
            {
                state.deleteCommentStatus = RequestStatus.InProgress;
            })
            .addCase(deleteComments.fulfilled, (state, action) =>
            {
                state.deleteCommentStatus = RequestStatus.Success;
            })
            .addCase(deleteComments.rejected, (state) =>
            {
                state.deleteCommentStatus = RequestStatus.Failure;
            })
            .addCase(createComment.pending, (state) =>
            {
                state.saveCommentStatus = RequestStatus.InProgress;
            })
            .addCase(createComment.fulfilled, (state, action) =>
            {
                state.saveCommentStatus = RequestStatus.Success;
                state.comment = action.payload;
            })
            .addCase(createComment.rejected, (state, action) =>
            {
                state.comment = undefined;
                state.saveCommentStatus = RequestStatus.Failure;
                if(Array.isArray(action.payload))
                {
                    state.commentError = { messages: action.payload as string[] };
                }
                else
                {
                    state.commentError = action.payload as ErrorData;
                }
            });
    },
});

function selectState(state: RootState): CommentsState
{
    return state.comments;
}
export const selectComment = createSelector(selectState, state => state.comment);
export const selectComments = createSelector(selectState, state => state.comments);
export const selectCommentsStatus = createSelector(selectState, state => state.commentsStatus);
export const selectCommentsfilter = createSelector(selectState, state => state.commentsfilter);
export const selectCommentsfilterStatus = createSelector(
    selectState,
    state => state.commentsfilterStatus
);
export const selectSaveCommentStatus = createSelector(
    selectState,
    state => state.saveCommentStatus
);
export const selectDeleteCommentStatus = createSelector(
    selectState,
    state => state.deleteCommentStatus
);
export const selectEditCommentStatus = createSelector(
    selectState,
    state => state.editCommentStatus
);
export const selectCommentError = createSelector(selectState, state => state.commentError);
export const {
    clearComment,
    resetEditCommentStatus,
    resetDeleteCommentStatus,
} = commentsSlice.actions;

export default commentsSlice.reducer;
