import {
    CommentData,
    CommentListParams,
    CommentParams,
} from './../../models/comments/commentData.model';
import instance from '../instance';
import {
    readAPICommentResponse,
    readAPIfilterCommentResponse,
    transformCommentAPIRequest,
    transformCommentsParams,
    transformeditCommentAPIRequest,
} from '../data-transfer-objects/comments/comments';

export async function getComments(
    commentsParams: CommentListParams
): Promise<CommentData[]>
{
    const response = await instance.get(
        'comments',
        { params: transformCommentsParams(commentsParams) }
    );
    return response.data.map(readAPICommentResponse);
}

export async function getCommentsfilter(
    commentsParams: CommentListParams
): Promise<CommentData[]>
{
    const response = await instance.get(
        'comments/filter',
        { params: transformCommentsParams(commentsParams) }
    );
    return response.data.map(readAPIfilterCommentResponse);
}

export async function createComment(data: CommentParams): Promise<CommentData>
{
    const response = await instance.post(
        'comments',
        transformCommentAPIRequest(data)
    );

    return readAPICommentResponse(response.data);
}

export async function editComments(
    commentsParams: CommentData
): Promise<object>
{
    const response = await instance.patch(
        `comments/edit/${commentsParams.id}`,
        transformeditCommentAPIRequest(commentsParams)
    );
    return readAPICommentResponse(response.data);
}


export async function deleteComments(
    commentsParams: string | undefined
): Promise<object>
{
    const response = await instance.delete(
        `comments/delete/${commentsParams}`
    );
    return response;
}
