import { PaginatedContent, PaginationParams } from 'src/models/paginatedResult.model';
import {
    readAPIInstructionResponse,
    readAPIInstructionTypeResponse,
} from '../data-transfer-objects/administration/instruction';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import {
    FileId,
    InstructionType,
    InstructionTypeData,
    deleteId,
    platformType,
} from 'src/models/administration/instructionData.model';

export async function addInstruction(data: InstructionTypeData[]): Promise<InstructionType>
{
    const response = await instance.post(
        '/instruction/file/instruction',
        data
    );

    return readAPIInstructionTypeResponse(response.data);
}

export async function updateInstruction(data: InstructionTypeData[]): Promise<InstructionType>
{
    const response = await instance.patch(
        '/instruction/update',
        data
    );

    return readAPIInstructionTypeResponse(response.data);
}
// eslint-disable-next-line
export async function uploadInstructionImage(data: any): Promise<FileId>
{
    const response = await instance.post(
        '/instruction/upload',
        data
    );
    return response.data;
}

export async function getInstruction(
    params: PaginationParams
): Promise<PaginatedContent<InstructionType>>
{
    const response = await instance.get(
        '/instruction',
        { params }
    );

    return readAPIPaginatedResponse(response.data, readAPIInstructionResponse);
}

export async function deleteInstruction(id: string): Promise<InstructionType>
{
    const response = await instance.delete(
        `instruction/${id}`
    );

    return response.data;
}
export async function deletePlatformInstruction(data:deleteId): Promise<string[]>
{
    const response = await instance.delete(
        '/instruction/platform/instructions',
        {
            data: data
        }
    );

    return response.data;
}

export async function getTypeInstruction(id: string): Promise<InstructionTypeData[]>
{
    const response = await instance.get(`instruction/${id}`);

    return response.data;
}
export async function getPlatformTypeInstruction(params: platformType): Promise<InstructionTypeData[]>
{
    const response = await instance.get(
        '/instruction/instructionsplatform/type',
        { params }
    );
    return response.data;
}


