import { CaptureParams, GetAlertsId } from 'src/models/captures/capturedData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';
import {
    AlertsDetailsParams,
    DeviceRecordListParams,
    RecordIdsListParams,
    UpdateSeenStatusParams,
} from 'src/models/common/filterOptionData.model';
import {
    transformAlertsDetailsParams,
    transformDeviceRecordListParams,
    transformUpdateSeenStatusParams,
} from '../data-transfer-objects/common/filterOptions';
import {
    readAPIActiveResponse,
    readAPIAlertsResponse,
    readAPICountResponse,
    readAPIInActiveResponse,
    readUserSeenResponse,
}
    from '../data-transfer-objects/capture/alerts';
import {
    ActiveData,
    AlertsData,
    AlertsDetailsData,
    AllActiveData,
    CountData,
    InActiveData,
    UpdateSeenData,
} from 'src/models/captures/alertsData.model';

export async function getAlertsRequest(
    captureParams: CaptureParams
) : Promise<PaginatedContent<AlertsData>>
{
    const response = await instance.get(
        'alerts',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIAlertsResponse);
}

export async function getAlertsDetails(
    params : AlertsDetailsParams
): Promise<AlertsDetailsData>
{
    const response = await instance.get('alerts/:id', {
        params: transformAlertsDetailsParams(params),
    });
    return readAPIAlertsResponse(response.data);
}

export async function updateSeenStatusRequest(
    params : UpdateSeenStatusParams
): Promise<UpdateSeenData>
{
    const response = await instance.patch(
        `alerts/${params.alertsId}`,
        transformUpdateSeenStatusParams(params)
    );
    return readUserSeenResponse(response.data);
}

export async function getActiveDevicesCountRequest(
    params: DeviceRecordListParams
) : Promise<PaginatedContent<ActiveData>>
{

    const response = await instance.get('alerts/activeinfo', {
        params: transformDeviceRecordListParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIActiveResponse);
}
export async function getAllActiveDevicesCountRequest(
    params: DeviceRecordListParams
) : Promise<PaginatedContent<AllActiveData>>
{

    const response = await instance.get('alerts/allactiveinfo', {
        params: transformDeviceRecordListParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIActiveResponse);
}

export async function getActiveDevicesIdRequest(
    params: RecordIdsListParams
) : Promise<string[]>
{

    const response = await instance.get('alerts/activeinfoIds', {
        params,
    });
    return response.data;
}

export async function getInActiveDevicesCountRequest(
    params: DeviceRecordListParams
): Promise<PaginatedContent<InActiveData>>
{
    const response = await instance.get('alerts/inactiveinfo', {
        params: transformDeviceRecordListParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIInActiveResponse);
}


export async function getallInActiveDevicesCountRequest(
    params: DeviceRecordListParams
): Promise<PaginatedContent<InActiveData>>
{
    const response = await instance.get('alerts/allinactiveinfo', {
        params: transformDeviceRecordListParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIInActiveResponse);
}

export async function getInActiveDevicesIdRequest(
    params: RecordIdsListParams
) : Promise<string[]>
{

    const response = await instance.get('alerts/inactiveinfoIds', {
        params,
    });
    return response.data;
}

export async function getRedFlaggedCountRequest( params: CaptureParams)
: Promise<CountData>
{
    const response = await
    instance.get('alerts/redflagged', {
        params: transformCaptureParams(params),
    });
    return readAPICountResponse(response.data);
}

export async function getRecordCountRequest()
: Promise<CountData>
{
    const response = await instance.get(
        'alerts/record'
    );
    return readAPICountResponse(response.data);
}

export async function getalertsIds(
    captureParams: GetAlertsId
): Promise<string[]>
{
    const response = await instance.get(
        'alerts/getIds',
        { params: captureParams }
    );
    return response.data;
}

