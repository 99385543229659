import React, { useEffect, useState } from 'react';
import GridView from 'src/components/grid-image-view';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAlertsFilter,
    getalertsJPEGDetails,
    selectAlertsFilter,
    selectAlertsIdStatus,
    selectAlertsJPEG,
    selectAlertsJPEGStatus,
    selectGridAlerts,
} from 'src/state/captures/capturesSlice';
import { AlertsData } from 'src/models/captures/alertsData.model';
import { format } from 'date-fns';
import {
    TableExportFormat,
    usePDFExport,
    usePDFExportSetter,
    useTableExporter,
} from 'src/components/table-exporter/TableExporterProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, CircularProgress, Grid } from '@mui/material';
import { downloadImages } from 'src/utils/common';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { PDFExport } from '../common-export-form/PDFExport';
import { JPEGExport } from '../common-export-form/JPEGExport';
import DataTypeUpdateFlagAll from '../data-type-update-flag-all/DatatypeUpdateFlagAll';
import { DataType } from 'src/models/dataType.enum';

export default function LandingPageAlertsGrid({
    selectedValue,
    handleOpen,
} : {
    readonly selectedValue : string,
    readonly handleOpen: (alertsData: AlertsData) => void
}): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertsGridData = useSelector(selectGridAlerts);
    const alertsFilter = useSelector(selectAlertsFilter);
    const alertsIdStatus = useSelector(selectAlertsIdStatus);
    const alertsJPEGStatus = useSelector(selectAlertsJPEGStatus);
    const alertsJPEG = useSelector(selectAlertsJPEG);

    const pdfExportSetter = usePDFExportSetter();
    const pdfExporter = usePDFExport();
    const { setExportFormat } = useTableExporter();
    const [selectedImages, setSelectedImages] = React.useState<Array<AlertsData>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [formats, setFormats] = useState<TableExportFormat | undefined>(undefined);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfType, setPdfType] = React.useState('');
    const [alltoPDFIds,setAlltoPDFIds]=useState<string[]>([]);
    
    const handleSetSelectedImages = (
        alertsData: AlertsData,
        checked: boolean
    ): void =>
    {
        if (alertsData)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: AlertsData[]) => [
                    ...prevSelectedImages,
                    alertsData,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    alertsData.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: AlertsData[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== alertsData.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== alertsData.fileId
                    ));
            }
        }
    };

    const handleSelectPDF = (): void =>
    {
        if (selectedImages.length > 0)
        {
            pdfExportSetter({
                type: selectedValue === 'MMS' ? 'SMS' : selectedValue,
                recordIds: selectedImages.map((item) => item.id),
            });
            setPdfType('selected');
        }
    };

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormats(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormats(undefined);
    }

    React.useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        setAlltoPDFIds([...alertsGridData.contents.map(doc=>doc.id)])
    }

    const handleALLJPEGExport = (): void =>
    {
        setIsLoading(true);
        dispatch(getalertsJPEGDetails({
            ...alertsFilter,
            alertsdatatype: selectedValue,
        }));
    };

    return (
        <>
            <Grid container mb="1rem" mt="rem">
                <Grid item md={6} xs={12}>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={alltoPDFIds}
                        idStatus={alertsIdStatus}
                        data={selectedValue === 'MMS' ? 'SMS' : selectedValue}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                        islandingPage ={true}
                    />
                    <JPEGExport
                        selectedValue={handleALLJPEGExport}
                        ids={alertsJPEG}
                        idStatus={alertsJPEGStatus}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    {selectedImages.length > 0 ? (
                        <>
                            <Button
                                variant="text"
                                startIcon={
                                    TableExporter.loading
                                        && formats === TableExportFormat.PDF
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={(e) => {
                                    handleSelectPDF();
                                }}
                            >
                                {t('common.button.selected-to-pdf')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={
                                    selectLoading
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={() => {
                                    downloadImages(
                                        selectedImages,
                                        null,
                                        setSelectLoading
                                    );
                                }}
                            >
                                {t('common.button.selected-to-jpeg')}
                            </Button>
                        </>
                    )
                        : null}
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {alertsFilter.device && (
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                            >
                                <DataTypeUpdateFlagAll
                                    dataType={selectedValue as DataType}
                                    count={alertsGridData?.numberOfEntries}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <Button
                                variant="contained"
                                sx={{ float: 'right', top: '-4px' }}
                                onClick={(e) => {
                                    const afterChangeFilter = sessionStorage.getItem('AlertsFilter');
                                    let updatedFilters =
                                        alertsFilter.alertsdatatype && afterChangeFilter
                                            ? JSON.parse(afterChangeFilter)
                                            : alertsFilter;
                                    updatedFilters = {
                                        ...updatedFilters,
                                        alertwordsOnly: !alertsFilter.alertwordsOnly,
                                    };
                                    dispatch(changeAlertsFilter(updatedFilters));
                                }}
                            >
                                {!alertsFilter.alertwordsOnly
                                    ? t('form.View-alert-words')
                                    : t('form.No-alert-words')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <GridView
                images={
                    alertsGridData.contents
                        .map((alerts, index) => ({
                            fileId: alerts.fileId,
                            date: format(new Date(alerts.receivedDate), 'yyyy-MM-dd HH:mm:ss'),
                            fileUrl: alerts.fileUrl,
                            count: index + 1,
                            totalCount: alertsGridData.numberOfEntries,
                            onClick: () => handleOpen(alerts),
                            onCheck: handleSetSelectedImages,
                            aiScanStatus: alerts.aiScanStatus,
                            alertWords: alerts.alertWords || [],
                            device: alerts.device,
                            deviceId: alerts.deviceId,
                            platform: alerts.platform,
                            username: alerts.username,
                            flag: alerts.flag,
                            dataType: alerts.dataType,
                            id: alerts.id,
                            captureStartDate: alerts.captureStartDate,
                            captureEndDate: alerts.captureEndDate,
                            capturedDate: alerts.capturedDate,
                            receivedDate: alerts.receivedDate,
                            comments: alerts.comments,
                        }))
                }
                selectedFileIds = {selectedFileIds}
            />
        </>
    );
}
