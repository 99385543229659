import { ErrorData } from './../../models/errors/errorData.model';
import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {
    SessionlogParams,
    SessionParams,
} from 'src/models/administration/assignedOfficerData.model';

import { User } from 'src/models/userModels';
import {
    PaginatedContent,
    PaginationParams,
} from 'src/models/paginatedResult.model';
import { RootState } from '../store';
import {
    UserFilters,
    UserParams,
} from 'src/models/administration/usersData.model';
import {
    getUser as getUserRequest,
    getUsers as getUsersRequest,
    getUsersNoPageable as getUsersNoPageableRequest,
    createUser as createUserRequest,
    updateUser as updateUserRequest,
    getSupervisor as getSupervisorRequest,
    getOffUser as getOffUsersRequest,
    deleteUser as deleteUserRequest,

} from 'src/services/administration/users';
import {
    addInstruction as addInstructionRequest,
    updateInstruction as updateInstructionRequest,
    deleteInstruction as deleteInstructionRequest,
    deletePlatformInstruction as deletePlatformInstructionRequest,
    getInstruction as getInstructionRequest,
    getTypeInstruction as getTypeInstructionRequest,
    getPlatformTypeInstruction as getPlatformTypeInstructionRequest,
    uploadInstructionImage as uploadInstructionImageRequest,
}from 'src/services/administration/instruction';
import {
    getAgency as getAgencyRequest,
    getAgencies as getAgenciesRequest,
    getAllAgencies as getAllAgenciesRequest,
    createAgency as createAgencyRequest,
    updateAgency as updateAgencyRequest,
    deleteAgency as deleteAgencyRequest,
    getAgencyMonitorList as getAgencyMonitorListRequest,
    getAssignedAgencyMonitor as getAssignedAgencyMonitorRequest,
    getAssignedAgencyOfficer as getAgencyOfficerListRequest,
    getAssignedAgencyClient as getAgencyClientListRequest,
    getAssignedAgencyActiveDevice as getAgencyActiveDeviceListRequest,
    getnoncompliantAgencyDetails as getnoncompliantAgencyDetailsRequest,
} from 'src/services/administration/agencies';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    Device,
    DeviceCreationParams,
    DeviceFilters,
    DeviceLogParams,
    DeviceLogs,
    DeviceParams,
    DevicePermissions,
    DevicePermissionsParams,
    DeviceUpdateParams,
    PlatformVersionUpateResponse,
} from 'src/models/administration/deviceData.model';
import {
    getDevice,
    getDevices as getDevicesRequest,
    createDevice as createDeviceRequest,
    updateDevice as updateDeviceRequest,
    updatePlatformVersion as updatePlatformRequest,
    getLogs as getLogsRequest,
    getAgencyCompareOptions as getAgencyCompareOptionsRequest,
    deleteDevice as sendDeleteDeviceRequest,
    getPermissions,
    updateDownloadURL,
} from 'src/services/administration/devices';
import {
    Agency,
    AgencyFilters,
    AgencyParams,
    DeleteAgencyParams,
    MonitorAgency,
} from 'src/models/administration/agencyData.model';
import {
    Client,
    ClientBasic,
    ClientConfig,
    ClientFilters,
    ClientParams,
    DeleteClientParams,
} from 'src/models/administration/clientData.model';
import {
    Report,
    ReportFileParams,
    ReportFilters,
    ReportParams,
    ReportsListFileResponse,
} from 'src/models/administration/reportData.model';
import {
    getAllClients as getAllClientsRequest,
    getClient as getClientRequest,
    getClients as getClientsRequest,
    createClient as createClientRequest,
    updateClient as updateClientRequest,
    deleteClient as deleteClientRequest,
    getAssignedClientActiveDevice as getClientActiveDeviceListRequest,
} from 'src/services/administration/clients';
import {
    getReportFiles as getReportFilesRequest,
    getReport as getReportRequest,
    getReports as getReportsRequest,
    createReport as createReportRequest,
    updateReport as updateReportRequest,
    deleteReport as deleteReportRequest,
} from 'src/services/administration/reports';
import {
    Configuration,
    ConfigurationOwner,
    ConfigurationParams,
    ParentConfigurationParams,
} from 'src/models/administration/configurationData.model';
import {
    getPartialConfiguration as getPartialConfigurationRequest,
    getConfiguration as getConfigurationRequest,
    getDefaultConfiguration,
    updateConfiguration as updateConfigurationRequest,
    createConfiguration as createConfigurationRequest,
} from 'src/services/administration/configuration';
import axios from 'axios';
import {
    createProfile as createProfileRequest,
    getProfiles as getProfilesRequest,
    getProfile as getProfileRequest,
    updateProfile as updateProfileRequest,
    deleteProfile as deleteProfileRequest,
    getUserAgents as getUserAgentRequest,
    getPlatformVersion as getPlatformVersionRequest,
    updateUserAgent as updateUserAgentRequest,
} from 'src/services/administration/profiles';
import {
    createExtension as createExtensionRequest,
    getExtensions as getExtensionsRequest,
    updateExtension as updateExtensionRequest,
    deleteExtension as sendDeleteExtensionRequest,
} from 'src/services/administration/extensions';
import {
    getUserSession as getUserSessionRequest,
    getAssignedOfficerSession as getAssignedOfficerSessionRequest,
    getUserlogDetails as getUserlogDetailsRequest,
} from 'src/services/administration/session';
import {
    createSuggestedAWL as createSuggestedAWLRequest,
    getClientAWLs as getClientAWLsRequest,
    getClientToggleAWLs as getClientToggleAWLsRequest,
    createClientAWL as createClientAWLRequest,
    createCustomAWL as createCustomAWLRequest,
    getCustomAWLs as getCustomAWLsRequest,
    getCustomAWLById as getCustomAWLByIdRequest,
    getClientAWLById as getClientAWLByIdRequest,
    updateCustomAWL as updateCustomAWLRequest,
    updateClientAWL as updateClientAWLRequest,
    updateClientAWLToggle as updateClientAWLToggleRequest,
    deleteClientAWL as deleteClientAWLRequest,
    getSuggestedAWLs as getSuggestedAWLsRequest,
    updateSuggestedAWL as updateSuggestedAWLRequest,
    getSuggestedAWLById as getSuggestedAWLByIdRequest,
    getAgencyAWLConfiguration as getAgencyAWLConfigurationRequest,
    updateAgencyAWLConfiguration as updateAgencyAWLConfigurationRequest,
    getSuggestedAWLsNoPageable as getSuggestedAWLsNoPageableRequest,
    getOfficerAWLs as getOfficerAWLsRequest,
    getOfficerToggleAWLs as getOfficerToggleAWLsRequest,
    createOfficerAWL as createOfficerAWLRequest,
    getOfficerAWLById as getOfficerAWLByIdRequest,
    updateOfficerAWL as updateOfficerAWLRequest,
    updateOfficerAWLToggle as updateOfficerAWLToggleRequest,
} from 'src/services/administration/alertWordList';
import {
    sendActivationCode as sendActivationCodeRequest,
} from 'src/services/administration/activation';
import {
    Profile,
    ProfileParams,
    UpdateAgentParams,
} from 'src/models/administration/profileData.model';
import {
    Extension,
    ExtensionManagement,
    ExtensionParams,
} from 'src/models/administration/extensionData.model';
import { BlockedUserAgent } from 'src/models/administration/blockedUserAgentData.model';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';

import {
    UserLogResponse,
    UserSessionResponse,
} from 'src/models/administration/userSessionData.model';
import {
    SuggestedAlertWordsList,
    AWLCreationParams,
    AWLUpdateParams,
    ClientAWLCreationParams,
    AWLRetrieveParams,
    CustomAWLCreationParams,
    AgencyAWLConfiguration,
    AgencyAWLConfigurationUpdateParams,
    CustomAlertWordsList,
    CustomAWLsRetrieveParams,
    CustomAWLUpdateParams,
    ClientAWLUpdateParams,
    GenericAWL,
    AWLClientParams,
    ClientAWLToggleUpdateParams,
    ClientAlertWordsToggleList,
    AWLOfficerParams,
    OfficerAWLToggleUpdateParams,
    OfficerAWLCreationParams,
    OfficerAWLUpdateParams,
    OfficerAlertWordsList,
    OfficerAlertWordsToggleList,
} from 'src/models/administration/alertWordListData.model';
import { AgencyFormValues } from 'src/templates/agency-form/AgencyForm';
import { ClientFormValues } from 'src/templates/client-form/ClientForm';
import { SendActivationCodeParams } from 'src/models/administration/activationData.model';
import { FilterOption } from 'src/models/common/filterOptionData.model';
import { AdminSettings } from 'src/models/adminSettings.enum';
import {
    Notification,
    NotificationConfiguration,
    NotificationConfigurationFilters,
    NotificationConfigurationParams,
    NotificationParams,
    PushNotificationConfiguration,
    PushnotificationParams,
    UpdateNotificationParams,
} from 'src/models/administration/notificationData.model';
import {
    getNotifications as getNotificationsRequest,
    getNotificationConfiguration as getNotificationConfigurationRequest,
    getNotificationConfigurations as getNotificationConfigurationsRequest,
    createNotificationConfiguration as createNotificationConfigurationRequest,
    updateNotificationConfiguration as updateNotificationConfigurationRequest,
    deleteNotificationConfiguration as deleteNotificationConfigurationRequest,
    getNotificationConfigurationNotifications as getNotificationConfigurationNotificationsRequest,
    sendpushnotification,
    DeleteNotification,
    getNotification,
} from 'src/services/administration/notifications';
import { SessionLogs } from 'src/models/sessionLogs.enum';
import {
    AdminSearchParams, AdminSettingsSearchRequest,
} from 'src/models/administration/adminSearchData.model';
import { VersionsParams } from 'src/services/data-transfer-objects/administration/agencies';
import {
    deleteId,
    FileId,
    InstructionType,
    InstructionTypeData,
    platformType,
} from 'src/models/administration/instructionData.model';

interface AdministrationState {
    adminFilters: AdminSettingsSearchRequest,

    user?: User,
    users?: PaginatedContent<User>,
    instructionList?: PaginatedContent<InstructionType>
    instructionData?: InstructionTypeData[]
    // eslint-disable-next-line
    instructionPlatFormData?: any
    uploadInstructionImageId?: FileId
    userFilters?: UserFilters
    usersNoPageable?: User[]
    userError?: ErrorData

    userStatus: RequestStatus,
    instructionStatus: RequestStatus
    usersStatus: RequestStatus,
    userCreateStatus: RequestStatus,
    instructionCreateStatus: RequestStatus
    instructiondeleteStatus: RequestStatus
    uploadInstructionImageStatus: RequestStatus
    userUpdateStatus: RequestStatus
    userDeleteStatus: RequestStatus

    version?: PlatformVersionUpateResponse,
    versionStatus: RequestStatus,
    versionError?: ErrorData,
    createVersionStatus: RequestStatus,
    updateVersionStatus: RequestStatus,

    devices?: PaginatedContent<Device>,
    devicePermissions?: PaginatedContent<DevicePermissions>
    devicePermissionStatus?: RequestStatus,
    deviceLogs?: PaginatedContent<DeviceLogs>,
    deviceFilters?: DeviceFilters,
    deviceStatus: RequestStatus,
    deviceSaveStatus: RequestStatus,
    deviceError?: ErrorData,

    allAgencies?: FilterOption[],
    allAgenciesRetrieveStatus: RequestStatus,
    agencies?: PaginatedContent<Agency>,
    agency?: Agency,
    agencyFilters?: AgencyFilters,
    agencyStatus: RequestStatus,
    agencyError?: ErrorData,
    agencySaveStatus: RequestStatus,
    agencyDeleteStatus: RequestStatus,
    agencyOfficer?: PaginatedContent<User>,
    agencyClient?: PaginatedContent<Client>,
    agencyActiveDevices?: PaginatedContent<Device>,

    supervisorList?: [],
    supervisorListStatus: RequestStatus,

    client?: Client,
    clients?: PaginatedContent<Client>,
    clientFilters?: ClientFilters,
    clientStatus: RequestStatus,
    clientSaveStatus: RequestStatus,
    clientRemoteNoError?: ErrorData,
    clientRetrieveStatus: RequestStatus,
    allClients?: ClientBasic[],
    allClientsRetrieveStatus: RequestStatus,
    clientActiveDevices?: PaginatedContent<Device>,

    extensionSaveStatus: RequestStatus,

    report?: Report,
    reportStatus: RequestStatus,
    reportError?: ErrorData,
    reports?: PaginatedContent<Report>,
    reportsStatus: RequestStatus,
    reportFilters?: ReportFilters,
    reportCreateStatus: RequestStatus,
    reportUpdateStatus: RequestStatus,
    reportDeleteStatus: RequestStatus,
    reportFiles?: PaginatedContent<ReportsListFileResponse>,
    reportFilesStatus: RequestStatus,

    notificationConfiguration?: NotificationConfiguration,
    notificationConfigurationStatus: RequestStatus,
    notificationConfigurationError?: ErrorData,
    notificationConfigurations?: PaginatedContent<NotificationConfiguration>,
    notificationConfigurationsStatus: RequestStatus,
    notificationConfigurationFilters?: NotificationConfigurationFilters,
    notificationConfigurationCreateStatus: RequestStatus,
    notificationConfigurationUpdateStatus: RequestStatus,
    notificationConfigurationDeleteStatus: RequestStatus,
    notificationConfigurationNotifications?: PaginatedContent<Notification>,
    notificationConfigurationNotificationsStatus: RequestStatus,
    notificationConfigurationNotificationsError?: ErrorData,
    newNotifications?: Notification[],
    notifications?: PaginatedContent<Notification>,
    notificationsStatus: RequestStatus,
    pushNotificationConfigurationStatus: RequestStatus,
    pushNotificationConfiguration?: PushNotificationConfiguration,

    configuration?: Configuration,
    partialConfiguration?: Configuration,
    parentConfiguration?: Configuration,
    configurationStatus: RequestStatus,
    partialConfigurationStatus: RequestStatus,
    parentConfigurationStatus: RequestStatus,
    saveConfigurationStatus: RequestStatus,
    itemInConfiguration?: Agency | Client | Device | User | ClientConfig,
    itemInConfigurationStatus: RequestStatus,

    profileCreationStatus: RequestStatus,
    profileRetrievingStatus: RequestStatus,
    profileUpdateStatus: RequestStatus,
    profiledeleteStatus: RequestStatus,
    profile?: Profile,
    profiles?: PaginatedContent<Profile>,
    profilesStatus: RequestStatus,
    profileError?: ErrorData,
    extensionCreationStatus: RequestStatus,
    extensionUpdateStatus: RequestStatus,
    extensions?: PaginatedContent<ExtensionManagement>,
    extension?: Extension,
    extensionsStatus: RequestStatus,
    extensionManagementStatus: RequestStatus,
    allExtensions?: PaginatedContent<ExtensionManagement>,
    allExtensionsRetrieveStatus: RequestStatus,

    blockedUserAgents?: PaginatedContent<BlockedUserAgent>,
    blockedUserAgentsStatus: RequestStatus,
    updateUserAgentStatus: RequestStatus,
    platformVersion?: PaginatedContent<PlatformVersion>,
    // eslint-disable-next-line
    noncompliantAgency: any,
    noncompliantAgencyStatus: RequestStatus,
    platformVersionStatus: RequestStatus,
    allProfiles?: PaginatedContent<Profile>,
    allProfilesRetrieveStatus: RequestStatus,

    compareVersion?: VersionsParams,
    compareVersionStatus: RequestStatus,

    userSession?: PaginatedContent<UserSessionResponse>,
    userSessionStatus?: RequestStatus,

    assignedOfficerSession?: PaginatedContent<UserSessionResponse>,
    assignedOfficerSessionStatus?: RequestStatus,
    userlogStatus?: RequestStatus,
    userLogDetails?: PaginatedContent<UserLogResponse>,

    // NOTE: here we create a save status for each AWL type.
    // This because they are displayed on tabs so an AWL of each type
    // could be saved at the same time.


    alertWordsList?: GenericAWL,
    awlCreationStatus: RequestStatus,
    clientAWLCreationStatus: RequestStatus,
    customAWLCreationStatus: RequestStatus,
    customAWLRetrieveStatus: RequestStatus,
    customAWLs?: PaginatedContent<CustomAlertWordsList>,
    agencyAWLConfiguration?: AgencyAWLConfiguration,
    agencyAWLConfigurationRetrieveStatus: RequestStatus,
    agencyAWLConfigurationSaveStatus: RequestStatus,
    awlUpdateStatus: RequestStatus,

    awlToggleUpdateStatus: RequestStatus,
    clientDisableAWLRetrieveStatus: RequestStatus,
    clientDisableAWLs?: ClientAlertWordsToggleList,

    awlRetrieveStatus: RequestStatus,
    suggestedAWL?: PaginatedContent<SuggestedAlertWordsList>,
    suggestedAWLNoPageable?: SuggestedAlertWordsList[],
    suggestedAWLRetrieveStatus: RequestStatus,
    agencyMonitorList?: MonitorAgency[],
    agencyMonitorStatus: RequestStatus,
    agencyassignedMonitor?: MonitorAgency[],
    agencyassignedMonitorStatus: RequestStatus,
    clientAWLRetrieveStatus: RequestStatus,
    clientAWLs?: PaginatedContent<OfficerAlertWordsList>,
    officerAWLCreationStatus: RequestStatus,
    officerDisableAWLRetrieveStatus: RequestStatus,
    officerDisableAWLs?: OfficerAlertWordsToggleList,
    officerAWLRetrieveStatus: RequestStatus,
    officerAWLs?: PaginatedContent<OfficerAlertWordsList>,

    sendActivationCodeStatus: RequestStatus,
    sendDeleteDeviceStatus: RequestStatus,

    sendDeleteExtensionStatus: RequestStatus,

    adminSettingsMenu?: AdminSettings,

    sessionLogsMenu?: SessionLogs,
    backNavigation?: boolean,
    backNavigationValues?: number,
}

const initialState: AdministrationState = {

    adminFilters: { searchValue: '' },
    userFilters: {},
    userStatus: RequestStatus.NotStarted,
    usersStatus: RequestStatus.NotStarted,
    userCreateStatus: RequestStatus.NotStarted,
    instructionCreateStatus: RequestStatus.NotStarted,
    instructiondeleteStatus: RequestStatus.NotStarted,
    uploadInstructionImageStatus: RequestStatus.NotStarted,
    instructionStatus: RequestStatus.NotStarted,
    instructionList: undefined,
    instructionData: [],
    uploadInstructionImageId: {},
    userUpdateStatus: RequestStatus.NotStarted,
    userDeleteStatus: RequestStatus.NotStarted,
    createVersionStatus: RequestStatus.NotStarted,
    updateVersionStatus: RequestStatus.NotStarted,
    versionStatus: RequestStatus.NotStarted,
    compareVersionStatus: RequestStatus.NotStarted,
    noncompliantAgency: [],
    deviceFilters: {},
    deviceStatus: RequestStatus.NotStarted,
    deviceSaveStatus: RequestStatus.NotStarted,

    agencyFilters: {},
    allAgenciesRetrieveStatus: RequestStatus.NotStarted,
    agencyStatus: RequestStatus.NotStarted,
    agencySaveStatus: RequestStatus.NotStarted,
    agencyDeleteStatus: RequestStatus.NotStarted,

    clientFilters: {},
    clientStatus: RequestStatus.NotStarted,
    clientSaveStatus: RequestStatus.NotStarted,
    clientRetrieveStatus: RequestStatus.NotStarted,
    allClientsRetrieveStatus: RequestStatus.NotStarted,

    extensionSaveStatus: RequestStatus.NotStarted,

    reportFilters: {},
    reportStatus: RequestStatus.NotStarted,
    reportsStatus: RequestStatus.NotStarted,
    reportCreateStatus: RequestStatus.NotStarted,
    reportUpdateStatus: RequestStatus.NotStarted,
    reportDeleteStatus: RequestStatus.NotStarted,
    reportFilesStatus: RequestStatus.NotStarted,

    supervisorList: [],
    supervisorListStatus: RequestStatus.NotStarted,
    agencyMonitorStatus: RequestStatus.NotStarted,
    agencyassignedMonitorStatus: RequestStatus.NotStarted,
    notificationConfigurationFilters: {},
    notificationConfigurationStatus: RequestStatus.NotStarted,
    notificationConfigurationsStatus: RequestStatus.NotStarted,
    notificationConfigurationCreateStatus: RequestStatus.NotStarted,
    notificationConfigurationUpdateStatus: RequestStatus.NotStarted,
    notificationConfigurationDeleteStatus: RequestStatus.NotStarted,
    notificationConfigurationNotificationsStatus: RequestStatus.NotStarted,
    notificationsStatus: RequestStatus.NotStarted,
    pushNotificationConfigurationStatus: RequestStatus.NotStarted,
    newNotifications: [],

    configurationStatus: RequestStatus.NotStarted,
    partialConfigurationStatus: RequestStatus.NotStarted,
    parentConfigurationStatus: RequestStatus.NotStarted,
    saveConfigurationStatus: RequestStatus.NotStarted,
    itemInConfigurationStatus: RequestStatus.NotStarted,

    profileCreationStatus: RequestStatus.NotStarted,
    profilesStatus: RequestStatus.NotStarted,
    profileRetrievingStatus: RequestStatus.NotStarted,
    profileUpdateStatus: RequestStatus.NotStarted,
    profiledeleteStatus: RequestStatus.NotStarted,
    allProfilesRetrieveStatus: RequestStatus.NotStarted,
    
    extensionCreationStatus: RequestStatus.NotStarted,
    extensionsStatus: RequestStatus.NotStarted,
    extensionUpdateStatus: RequestStatus.NotStarted,
    allExtensionsRetrieveStatus: RequestStatus.NotStarted,
    extensionManagementStatus: RequestStatus.NotStarted,

    blockedUserAgentsStatus: RequestStatus.NotStarted,
    updateUserAgentStatus: RequestStatus.NotStarted,
    platformVersionStatus: RequestStatus.NotStarted,
    noncompliantAgencyStatus: RequestStatus.NotStarted,

    userSessionStatus: RequestStatus.NotStarted,
    assignedOfficerSessionStatus: RequestStatus.NotStarted,
    userlogStatus: RequestStatus.NotStarted,
    awlCreationStatus: RequestStatus.NotStarted,
    clientAWLCreationStatus: RequestStatus.NotStarted,
    customAWLCreationStatus: RequestStatus.NotStarted,
    customAWLRetrieveStatus: RequestStatus.NotStarted,
    agencyAWLConfigurationRetrieveStatus: RequestStatus.NotStarted,
    agencyAWLConfigurationSaveStatus: RequestStatus.NotStarted,
    awlUpdateStatus: RequestStatus.NotStarted,

    awlToggleUpdateStatus: RequestStatus.NotStarted,
    clientDisableAWLRetrieveStatus: RequestStatus.NotStarted,

    officerAWLCreationStatus: RequestStatus.NotStarted,
    officerDisableAWLRetrieveStatus: RequestStatus.NotStarted,
    awlRetrieveStatus: RequestStatus.NotStarted,

    sendActivationCodeStatus: RequestStatus.NotStarted,
    sendDeleteDeviceStatus: RequestStatus.NotStarted,
    sendDeleteExtensionStatus: RequestStatus.NotStarted,

    suggestedAWLRetrieveStatus: RequestStatus.NotStarted,
    clientAWLRetrieveStatus: RequestStatus.NotStarted,
    officerAWLRetrieveStatus: RequestStatus.NotStarted,
    backNavigation: false,
    backNavigationValues: 10,
};

export const getUser = createAsyncThunk(
    'administration/user',
    async (userId: string) => getUserRequest(userId)
);
export const getTypeInstruction = createAsyncThunk(
    'administration/type/instruction',
    async (id: string) => getTypeInstructionRequest(id)
);
export const getPlatformTypeInstruction = createAsyncThunk(
    'administration/platformType/instruction',
    async (params: platformType) => getPlatformTypeInstructionRequest(params)
);
export const uploadInstructionImage = createAsyncThunk(
    'administration/instruction/uploadImage',
    // eslint-disable-next-line
    async (user: any, { rejectWithValue }) => {
        try
        {
            return await uploadInstructionImageRequest(user);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const getUsers = createAsyncThunk(
    'administration/users',
    async (params: UserParams) => getUsersRequest(params)
);

export const getUsersNoPageable = createAsyncThunk(
    'administration/usersNoPageable',
    async (params: UserFilters) => getUsersNoPageableRequest(params)
);
export const getOffUser = createAsyncThunk(
    'administration/offuser',
    async (params: UserParams) => getOffUsersRequest(params)
);
export const getInstruction = createAsyncThunk(
    'administration/instructionList',
    async (params: PaginationParams) => getInstructionRequest(params)
);

export const createUser = createAsyncThunk(
    'administration/userCreate',
    async (user: User, { rejectWithValue }) =>
    {
        try
        {
            return await createUserRequest(user);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateUser = createAsyncThunk(
    'administration/userUpdate',
    async (user: User, { rejectWithValue }) =>
    {
        try
        {
            return await updateUserRequest(user);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);



export const deleteUser = createAsyncThunk(
    'administration/userDelete',
    async (user: string, { rejectWithValue }) =>
    {
        try
        {
            return await deleteUserRequest(user);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const getSupervisor = createAsyncThunk(
    'administration/getSupervisor',
    async (id: string | null, { rejectWithValue }) =>
    {
        try
        {
            return await getSupervisorRequest(id);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const addInstructions = createAsyncThunk(
    'administration/instructionAdd',
    async (data: InstructionTypeData[], { rejectWithValue }) =>
    {
        try
        {
            return await addInstructionRequest(data);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const updateInstructions = createAsyncThunk(
    'administration/instructionUpdate',

    async (data: InstructionTypeData[], { rejectWithValue }) =>
    {
        try
        {
            return await updateInstructionRequest(data);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const deleteInstructions = createAsyncThunk(
    'administration/instruction/delete',
    async (id: string) => deleteInstructionRequest(id)
);
export const deletePlatformInstructions = createAsyncThunk(
    'administration/instruction/platform/delete',
    async (data: deleteId, { rejectWithValue }) =>
    {
        try
        {
            return await deletePlatformInstructionRequest(data);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const createVersion = createAsyncThunk(
    'administration/versionCreate',
    async (platform: PlatformVersion, { rejectWithValue }) =>
    {
        try
        {
            return await updatePlatformRequest(platform);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);

export const updateVersion = createAsyncThunk(
    'administration/versionUpdate',
    async (platform: PlatformVersion, { rejectWithValue }) =>
    {
        try
        {
            return await updatePlatformRequest(platform);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);

export const updatedownloadurl = createAsyncThunk(
    'administration/versionUpdate',
    async (platform: PlatformVersion, { rejectWithValue }) =>
    {
        try
        {
            return await updateDownloadURL(platform);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);

export const getAgencyCompareOptions = createAsyncThunk(
    'common/agencyCompares',
    async ({ platformId }: { platformId?: string }) =>
    {
        return getAgencyCompareOptionsRequest({ platformId });
    }
);

export const updateExtension = createAsyncThunk(
    'administration/extensionUpdate',
    async (extension: ExtensionManagement, { rejectWithValue }) =>
    {
        try
        {
            return await updateExtensionRequest(extension);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);

export const getAllAgencies = createAsyncThunk(
    'administration/getAllDevices',
    async () => getAllAgenciesRequest()
);

export const createAgency = createAsyncThunk(
    'administration/agencyCreate',
    async ({ agency, logo }: { agency: AgencyFormValues, logo?: File }, { rejectWithValue }) =>
    {
        try
        {
            return await createAgencyRequest(agency, logo);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateAgency = createAsyncThunk(
    'administration/agencyUpdate',
    async ({ agency, logo }: { agency: Agency, logo?: File }, { rejectWithValue }) =>
    {
        try
        {
            return await updateAgencyRequest(agency, logo);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const deleteAgency = createAsyncThunk(
    'administration/agencyDelete',
    async ({ agency }: { agency: Agency }, { rejectWithValue }) =>
    {
        try
        {
            return await deleteAgencyRequest(agency);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const getDevices = createAsyncThunk(
    'administration/devices',
    async (params: DeviceParams) => getDevicesRequest(params)
);

export const getDeviceLogs = createAsyncThunk(
    'administration/deviceLogs',
    async (params: DeviceLogParams) => getLogsRequest(params)
);

export const getDevicePermissions = createAsyncThunk(
    'administration/devicePermissions',
    async (params: DevicePermissionsParams) => getPermissions(params)
);

export const createDevice = createAsyncThunk(
    'administration/createDevice',
    async (params: DeviceCreationParams, { rejectWithValue }) =>
    {
        try
        {
            return await createDeviceRequest(params);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateDevice = createAsyncThunk(
    'administration/updateDevice',
    async (params: DeviceUpdateParams, { rejectWithValue }) =>
    {
        try
        {
            return await updateDeviceRequest(params);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const getAgencies = createAsyncThunk(
    'administration/agencies',
    async (params: AgencyParams) => getAgenciesRequest(params)
);

export const getClient = createAsyncThunk(
    'administration/getClient',
    async (id: string) => getClientRequest(id)
);

export const getClients = createAsyncThunk(
    'administration/clients',
    async (params: ClientParams) => getClientsRequest(params)
);

export const getAllClients = createAsyncThunk(
    'administration/getAllClients',
    async () => getAllClientsRequest()
);

export const createClient = createAsyncThunk(
    'administration/createClient',
    async (params: ClientFormValues, { rejectWithValue }) =>
    {
        try
        {
            return await createClientRequest(params);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateClient = createAsyncThunk(
    'administration/updateClient',
    async (params: Client, { rejectWithValue }) =>
    {
        try
        {
            return await updateClientRequest(params);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const deleteClient = createAsyncThunk(
    'administration/deleteClient',
    async (params: Client) => deleteClientRequest(params)
);
export const getClientActiveDevice = createAsyncThunk(
    'clients/activeDevice',
    async (params: DeleteClientParams) => getClientActiveDeviceListRequest(params)
);

export const getReport = createAsyncThunk(
    'administration/report',
    async (reportId: string) => getReportRequest(reportId)
);

export const getReports = createAsyncThunk(
    'administration/reports',
    async (params: ReportParams) => getReportsRequest(params)
);

export const getReportFiles = createAsyncThunk(
    'administration/report-files',
    async (params: ReportFileParams) => getReportFilesRequest(params)
);

export const getConfiguration = createAsyncThunk(
    'administration/configuration',
    async (params: ConfigurationParams) => getConfigurationRequest(params)
);

export const getPartialConfiguration = createAsyncThunk(
    'administration/partialConfiguration',
    async (configId: string) => getPartialConfigurationRequest(configId)
);

export const setItemInConfiguration = createAsyncThunk(
    'administration/setItemInConfiguration',
    async ({ ownerId, ownerType }: ConfigurationParams) =>
    {
        switch (ownerType)
        {
        case ConfigurationOwner.Agency:
            return await getAgencyRequest(ownerId);
        case ConfigurationOwner.Client:
            return await getClientRequest(ownerId);
        case ConfigurationOwner.Device:
            return await getDevice(ownerId);
        case ConfigurationOwner.Officer:
            return await getUserRequest(ownerId);
        default:
            throw new Error('Invalid config type');
        }
    }
);

export const getParentConfiguration = createAsyncThunk(
    'administration/parentConfiguration',
    async ({ child, ownerType }: ParentConfigurationParams) =>
    {
        let parentType: ConfigurationOwner;
        let parentId: string;

        switch (ownerType)
        {
        case ConfigurationOwner.Agency:
            return await getDefaultConfiguration();
        case ConfigurationOwner.Client:
            parentType = (child as ClientConfig)?.officers.length > 0 ?  ConfigurationOwner.Officer:ConfigurationOwner.Agency;
            parentId = (child as ClientConfig)?.officers.length > 0 ? (child as ClientConfig)?.officers[0]:(child as ClientConfig)?.agencyId
            break;
        case ConfigurationOwner.Officer:
            parentType = ConfigurationOwner.Agency;
            parentId = (child as User)?.agency as string ?? "";
            break;
        case ConfigurationOwner.Device:
            parentType = ConfigurationOwner.Client;
            parentId = (child as Device).clientId;
            break;
        default:
            throw new Error('Invalid config type.');
        }
        if (parentId) {
            return getConfigurationRequest({ ownerId: parentId, ownerType: parentType });
        }
        return;
    }
);

export const updateConfiguration = createAsyncThunk(
    'administration/update-configuration',
    async (configuration: Configuration) => updateConfigurationRequest(configuration)
);

export const createConfiguration = createAsyncThunk(
    'administration/create-configuration',
    async (configuration: Configuration) => createConfigurationRequest(configuration)
);

export const createReport = createAsyncThunk(
    'administration/createReport',
    async (report: Report, { rejectWithValue }) =>
    {
        try
        {
            return await createReportRequest(report);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateReport = createAsyncThunk(
    'administration/updateReport',
    async (report: Report, { rejectWithValue }) =>
    {
        try
        {
            return await updateReportRequest(report);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const deleteReport = createAsyncThunk(
    'administration/deleteReport',
    async (params: string) => deleteReportRequest(params)
);

export const createProfile = createAsyncThunk(
    'administration/createProfile',
    async (params: ProfileParams, { rejectWithValue }) =>
    {
        try
        {
            return await createProfileRequest(params);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const getProfiles = createAsyncThunk(
    'administration/getProfiles',
    async (params: PaginationParams) => getProfilesRequest(params)
);

export const createExtension = createAsyncThunk(
    'administration/createExtension',
    async (params: ExtensionParams) => createExtensionRequest(params)
);

export const getExtensions = createAsyncThunk(
    'administration/getExtensions',
    async (params: AdminSearchParams) => getExtensionsRequest(params)
);

export const getuserSessions = createAsyncThunk(
    'administration/getUserSession',
    async (params: SessionParams) => getUserSessionRequest(params)
);

export const getAssignedOfficerSessions = createAsyncThunk(
    'administration/getAssignedOfficerSession',
    async (params: SessionParams) => getAssignedOfficerSessionRequest(params)
);
export const getUserlogDetails = createAsyncThunk(
    'administration/getUserlogDetails',
    async (params: SessionlogParams) => getUserlogDetailsRequest(params)
);
export const getUserAgents = createAsyncThunk(
    'administration/getUserAgents',
    async (params: PaginationParams) => getUserAgentRequest(params)
);

export const updateUserAgents = createAsyncThunk(
    'administration/updateUserAgents',
    async (params: UpdateAgentParams) => updateUserAgentRequest(params)
);

export const getPlatformVersion = createAsyncThunk(
    'administration/getPlatformVersion',
    async (params: PaginationParams) => getPlatformVersionRequest(params)
);

export const getnoncompliantAgencyDetails = createAsyncThunk(
    'administration/getnoncompliantAgencyDetails',
    // eslint-disable-next-line
    async (params: any, { rejectWithValue }) => {
        try
        {
            return await getnoncompliantAgencyDetailsRequest(params);
        }
        catch (error)
        {


            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const getProfile = createAsyncThunk(
    'administration/getProfile',
    async (id: string) => getProfileRequest(id)
);

export const updateProfile = createAsyncThunk(
    'administration/updateProfile',
    async (profile: Profile, { rejectWithValue }) =>
    {
        try
        {
            return await updateProfileRequest(profile);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);
export const deleteProfile = createAsyncThunk(
    'administration/deleteProfile',
    async (id: string) => deleteProfileRequest(id)
);
export const getAllProfiles = createAsyncThunk(
    'administration/allProfiles',
    async () => getProfilesRequest()
);
export const createSuggestedAWL = createAsyncThunk(
    'administration/createSuggestedAWL',
    async (params: AWLCreationParams) => createSuggestedAWLRequest(params)
);

export const getClientAWLs = createAsyncThunk(
    'administration/getClientAWLs',
    async (params: AWLClientParams) => getClientAWLsRequest(params)
);


export const getClientDisbaleAWLs = createAsyncThunk(
    'administration/getClientDisbaleAWLs',
    async (params: ClientAWLToggleUpdateParams) => getClientToggleAWLsRequest(params)
);

export const createClientAWL = createAsyncThunk(
    'administration/createClientAWL',
    async (params: ClientAWLCreationParams) => createClientAWLRequest(params)
);
export const getOfficerAWLs = createAsyncThunk(
    'administration/getOfficerAWLs',
    async (params: AWLOfficerParams) => getOfficerAWLsRequest(params)
);


export const getOfficerDisbaleAWLs = createAsyncThunk(
    'administration/getOfficerDisbaleAWLs',
    async (params: OfficerAWLToggleUpdateParams) => getOfficerToggleAWLsRequest(params)
);

export const createOfficerAWL = createAsyncThunk(
    'administration/createOfficerAWL',
    async (params: OfficerAWLCreationParams) => createOfficerAWLRequest(params)
);

export const createCustomsAWL = createAsyncThunk(
    'administration/createCustomAWL',
    async (params: CustomAWLCreationParams) => createCustomAWLRequest(params)
);

export const getCustomAWLs = createAsyncThunk(
    'administration/getCustomAWLs',
    async (params: CustomAWLsRetrieveParams) => getCustomAWLsRequest(params)
);

export const getCustomAWLById = createAsyncThunk(
    'administration/getCustomAWLById',
    async (id: string) => getCustomAWLByIdRequest(id)
);

export const getClientAWLById = createAsyncThunk(
    'administration/getClientAWLById',
    async (id: string) => getClientAWLByIdRequest(id)
);

export const updateCustomAWL = createAsyncThunk(
    'administration/updateCustomAWL',
    async (params: CustomAWLUpdateParams) => updateCustomAWLRequest(params)
);

export const updateClientAWL = createAsyncThunk(
    'administration/updateClientAWL',
    async (params: ClientAWLUpdateParams) => updateClientAWLRequest(params)
);

export const deleteClientAWL = createAsyncThunk(
    'administration/deleteClientAWL',
    async (id: string) =>
    {
        await deleteClientAWLRequest(id);
        return id;
    }
);

export const updateClientAWLToggle = createAsyncThunk(
    'administration/updateClientAWLToggle',
    async (params: ClientAWLToggleUpdateParams) => updateClientAWLToggleRequest(params)
);

export const getOfficerAWLById = createAsyncThunk(
    'administration/getOfficerAWLById',
    async (id: string) => getOfficerAWLByIdRequest(id)
);
export const updateOfficerAWL = createAsyncThunk(
    'administration/updateOfficerAWL',
    async (params: OfficerAWLUpdateParams) =>
    {
        return updateOfficerAWLRequest(params);
    }
);
export const updateOfficerAWLToggle = createAsyncThunk(
    'administration/updateOfficerAWLToggle',
    async (params: OfficerAWLToggleUpdateParams) => updateOfficerAWLToggleRequest(params)
);


export const getAgencyAWLConfiguration = createAsyncThunk(
    'administration/getAgencyAWLConfiguration',
    async (agencyId?: string) => getAgencyAWLConfigurationRequest(agencyId)
);

export const updateAgencyAWLConfiguration = createAsyncThunk(
    'administration/updateAgencyAWLConfiguration',
    async (
        params: AgencyAWLConfigurationUpdateParams
    ) => updateAgencyAWLConfigurationRequest(params)
);

export const sendActivationCode = createAsyncThunk(
    'administration/sendActivationCode',
    async (params: SendActivationCodeParams) => sendActivationCodeRequest(params)
);

export const sendDeleteDevice = createAsyncThunk(
    'administration/sendDeleteDevice',
    async (params: string) => sendDeleteDeviceRequest(params)
);

export const sendDeleteExtension = createAsyncThunk(
    'administration/sendExtensionDevice',
    async (params: string) => sendDeleteExtensionRequest(params)
);

export const getSuggestedAWLs = createAsyncThunk(
    'administration/getSuggestedAWL',
    async (params: AWLRetrieveParams) => getSuggestedAWLsRequest(params)
);

export const getSuggestedAWLsNoPageable = createAsyncThunk(
    'administration/getSuggestedAWLNoPageable',
    async () => getSuggestedAWLsNoPageableRequest()
);
export const getAgencyMonitorList = createAsyncThunk(
    'agency/monitor',
    async () => getAgencyMonitorListRequest()
);
export const getAssignedAgencyMonitor = createAsyncThunk(
    'agency/monitorbyId',
    async (params: string) => getAssignedAgencyMonitorRequest(params)
);
export const getAgencyOfficers = createAsyncThunk(
    'agency/officer',
    async (params: DeleteAgencyParams) => getAgencyOfficerListRequest(params)
);
export const getAgencyClients = createAsyncThunk(
    'agency/clients',
    async (params: DeleteAgencyParams) => getAgencyClientListRequest(params)
);
export const getAgencyActiveDevice = createAsyncThunk(
    'agency/activeDevice',
    async (params: DeleteAgencyParams) => getAgencyActiveDeviceListRequest(params)
);
export const updateSuggestedAWL = createAsyncThunk(
    'administration/updateSuggestedAWL',
    async (params: AWLUpdateParams) => updateSuggestedAWLRequest(params)
);

export const getSuggestedAWLById = createAsyncThunk(
    'administration/getSuggestedAWLById',
    async (id: string) => getSuggestedAWLByIdRequest(id)
);

export const getNotificationConfiguration = createAsyncThunk(
    'administration/notificationConfiguration',
    async (notificationConfigurationId: string) =>
        getNotificationConfigurationRequest(notificationConfigurationId)
);

export const getNotificationConfigurations = createAsyncThunk(
    'administration/notificationConfigurations',
    async (params: NotificationConfigurationParams) => getNotificationConfigurationsRequest(params)
);

export const getNotificationConfigurationNotifications = createAsyncThunk(
    'administration/notificationConfigurationNotifications',
    async (params: NotificationParams) => getNotificationConfigurationNotificationsRequest(params)
);

export const getNotifications = createAsyncThunk(
    'administration/notifications',
    async () => getNotificationsRequest()
);

export const createNotificationConfiguration = createAsyncThunk(
    'administration/createNotificationConfiguration',
    async (notificationConfiguration: NotificationConfiguration, { rejectWithValue }) =>
    {
        try
        {
            return await createNotificationConfigurationRequest(notificationConfiguration);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const updateNotificationConfiguration = createAsyncThunk(
    'administration/updateNotificationConfiguration',
    async (notificationConfiguration: NotificationConfiguration, { rejectWithValue }) =>
    {
        try
        {
            return await updateNotificationConfigurationRequest(notificationConfiguration);
        }
        catch (error)
        {
            if (axios.isAxiosError(error))
            {
                return rejectWithValue(error.response?.data);
            }

            throw error;
        }
    }
);

export const sendpushnotificationConfiguration = createAsyncThunk(
    'administration/sendpushnotificationConfiguration',
    async (data: PushnotificationParams) =>
    {
        return await sendpushnotification(data);
    }
);

export const getpushNotificationConfiguration = createAsyncThunk(
    'administration/getpushNotificationConfiguration',
    async () => getNotification()
);

export const updateNotification = createAsyncThunk(
    'administration/updateNotification',
    async (params: UpdateNotificationParams) =>
    {
        DeleteNotification(params);
    }
);
export const deleteNotificationConfiguration = createAsyncThunk(
    'administration/deleteNotificationConfiguration',
    async (params: string) =>
    {
        deleteNotificationConfigurationRequest(params);
    }
);

export const administrationSlice = createSlice({
    name: 'administration',
    initialState,
    reducers: {
        changeAdminFilters: (state, action: PayloadAction<AdminSettingsSearchRequest>) =>
        {
            state.adminFilters = { ...state.adminFilters, ...action.payload };

        },
        clearSearchValue: (state) =>
        {
            state.adminFilters.searchValue = '';
        },
        setUserFilters: (state, action: PayloadAction<UserFilters>) =>
        {
            state.userFilters = { ...state.userFilters, ...action.payload };
        },
        setDeviceFilters: (state, action: PayloadAction<DeviceFilters>) =>
        {
            state.deviceFilters = { ...state.deviceFilters, ...action.payload };
        },
        setClientFilters: (state, action: PayloadAction<ClientFilters>) =>
        {
            state.clientFilters = { ...state.clientFilters, ...action.payload };
        },
        setReportFilters: (state, action: PayloadAction<ReportFilters>) =>
        {
            state.reportFilters = { ...state.reportFilters, ...action.payload };
        },
        setNotificationConfigurationFilters: (
            state,
            action: PayloadAction<NotificationConfigurationFilters>
        ) =>
        {
            state.notificationConfigurationFilters = {
                ...state.notificationConfigurationFilters,
                ...action.payload,
            };
        },
        setUser: (state, action: PayloadAction<User>) =>
        {
            state.user = action.payload;
            state.userStatus = RequestStatus.Success;
        },
        clearUser: (state) =>
        {
            state.user = undefined;
            state.userError = undefined;
            state.userStatus = RequestStatus.NotStarted;
            state.userCreateStatus = RequestStatus.NotStarted;
            state.userUpdateStatus = RequestStatus.NotStarted;
            state.userDeleteStatus = RequestStatus.NotStarted;
        },

        setVersion: (state, action: PayloadAction<PlatformVersionUpateResponse>) =>
        {
            state.version = action.payload;
            state.versionStatus = RequestStatus.Success;

        },
        clearVersion: (state) =>
        {
            state.version = undefined;
            state.versionError = undefined;
            state.versionStatus = RequestStatus.NotStarted;
            state.createVersionStatus = RequestStatus.NotStarted;
            state.updateVersionStatus = RequestStatus.NotStarted;
        },


        setAgency: (state, action: PayloadAction<Agency>) =>
        {
            state.agency = action.payload;
            state.agencyStatus = RequestStatus.Success;
        },
        clearAgency: (state) =>
        {
            state.agency = undefined;
            state.agencyError = undefined;
            state.agencyStatus = RequestStatus.NotStarted;
            state.agencySaveStatus = RequestStatus.NotStarted;
            state.agencyDeleteStatus = RequestStatus.NotStarted;
        },
        setClient: (state, action: PayloadAction<Client>) =>
        {
            state.client = action.payload;
            state.clientStatus = RequestStatus.Success;
        },
        setReport: (state, action: PayloadAction<Report>) =>
        {
            state.report = action.payload;
            state.reportStatus = RequestStatus.Success;
        },
        clearReports: (state) =>
        {
            state.report = undefined;
            state.reportError = undefined;
            state.reportsStatus = RequestStatus.NotStarted;
            state.reportCreateStatus = RequestStatus.NotStarted;
            state.reportUpdateStatus = RequestStatus.NotStarted;
        },
        clearRemoteComError: (state) =>
        {
            state.clientRemoteNoError = undefined;
        },
        setNotificationConfiguration: (state, action: PayloadAction<NotificationConfiguration>) =>
        {
            state.notificationConfiguration = action.payload;
            state.notificationConfigurationStatus = RequestStatus.Success;
        },
        clearNotificationConfigurations: (state) =>
        {
            state.notificationConfiguration = undefined;
            state.notificationConfigurationError = undefined;
            state.notificationConfigurationsStatus = RequestStatus.NotStarted;
            state.notificationConfigurationCreateStatus = RequestStatus.NotStarted;
            state.notificationConfigurationUpdateStatus = RequestStatus.NotStarted;
            state.notificationConfigurationDeleteStatus = RequestStatus.NotStarted;
        },
        addNewNotification: (state, action: PayloadAction<Notification>) =>
        {
            state.newNotifications = state.newNotifications?.concat(action.payload);
        },
        clearConfigs: (state) =>
        {
            state.configuration = undefined;
            state.parentConfiguration = undefined;
            state.partialConfiguration = undefined;
            state.itemInConfiguration = undefined;
        },
        resetItemInConfigStatus: (state) =>
        {
            state.itemInConfigurationStatus = RequestStatus.NotStarted;
        },
        resetAllClientsRetrieveStatus: (state) =>
        {
            state.allClientsRetrieveStatus = RequestStatus.NotStarted;
        },
        resetAWLCreationStatus: (state) =>
        {
            state.awlCreationStatus = RequestStatus.NotStarted;
        },
        resetProfileStatus: (state) =>
        {
            state.profileUpdateStatus = RequestStatus.NotStarted;

        },
        resetProfileErrorValue: (state) =>
        {
            state.profileError = undefined;
        },
        resetDeleteProfileStatus: (state) =>
        {
            state.profiledeleteStatus = RequestStatus.NotStarted;
        },
        resetDeleteInstructionStatus: (state) =>
        {
            state.instructiondeleteStatus = RequestStatus.NotStarted;
        },
        resetAddInstructionStatus: (state) =>
        {
            state.instructionCreateStatus = RequestStatus.NotStarted;
        },
        resetClientAWLCreationStatus: (state) =>
        {
            state.clientAWLCreationStatus = RequestStatus.NotStarted;
        },
        resetOfficerAWLCreationStatus: (state) =>
        {
            state.officerAWLCreationStatus = RequestStatus.NotStarted;
        },
        resetCustomAWLCreationStatus: (state) =>
        {
            state.customAWLCreationStatus = RequestStatus.NotStarted;
        },
        resetCustomAWLRetrieveStatus: (state) =>
        {
            state.customAWLRetrieveStatus = RequestStatus.NotStarted;
        },
        clearAgencyAWLConfiguration: (state) =>
        {
            state.agencyAWLConfiguration = undefined;
        },
        resetAgencyAWLConfigurationRetrieveStatus: (state) =>
        {
            state.agencyAWLConfigurationRetrieveStatus = RequestStatus.NotStarted;
        },
        resetAgencyAWLConfigurationSaveStatus: (state) =>
        {
            state.agencyAWLConfigurationSaveStatus = RequestStatus.NotStarted;
        },
        resetAWLUpdateStatus: (state) =>
        {
            state.awlUpdateStatus = RequestStatus.NotStarted;
        },
        resetClientSaveStatus: (state) =>
        {
            state.clientSaveStatus = RequestStatus.NotStarted;
        },
        resetExtensionSaveStatus: (state) =>
        {
            state.extensionCreationStatus = RequestStatus.NotStarted;
        },
        resetExtensionUpdateStatus: (state) =>
        {
            state.extensionUpdateStatus = RequestStatus.NotStarted;
        },
        resetAgencySaveStatus: (state) =>
        {
            state.agencySaveStatus = RequestStatus.NotStarted;
        },
        resetAgencyDeleteStatus: (state) =>
        {
            state.agencyDeleteStatus = RequestStatus.NotStarted;
        },
        resetDeviceSaveStatus: (state) =>
        {
            state.deviceSaveStatus = RequestStatus.NotStarted;
        },
        resetDeviceDeleteStatus: (state) =>
        {
            state.sendDeleteDeviceStatus = RequestStatus.NotStarted;
        },
        resetSendCodeActivationStatus: (state) =>
        {
            state.sendActivationCodeStatus = RequestStatus.NotStarted;
        },
        resetSendDeleteDeviceStatus: (state) =>
        {
            state.sendDeleteDeviceStatus = RequestStatus.NotStarted;
        },
        resetSendDeleteExtensionStatus: (state) =>
        {
            state.sendDeleteExtensionStatus = RequestStatus.NotStarted;
        },

        resetSuggestedAWLRetrieveStatus: (state) =>
        {
            state.suggestedAWLRetrieveStatus = RequestStatus.NotStarted;
        },
        resetClientAWLRetrieveStatus: (state) =>
        {
            state.clientAWLRetrieveStatus = RequestStatus.NotStarted;
        },
        resetOfficerAWLRetrieveStatus: (state) =>
        {
            state.officerAWLRetrieveStatus = RequestStatus.NotStarted;
        },
        resetSaveConfigurationStatus: (state) =>
        {
            state.saveConfigurationStatus = RequestStatus.NotStarted;
        },
        setAdminSettingsMenu: (state, action: PayloadAction<AdminSettings>) =>
        {
            state.adminSettingsMenu = action.payload;
        },
        setSessionSettingsMenu: (state, action: PayloadAction<SessionLogs>) =>
        {
            state.sessionLogsMenu = action.payload;
        },
        resetUserFormStatus: (state) =>
        {
            state.userCreateStatus = RequestStatus.NotStarted;
            state.userUpdateStatus = RequestStatus.NotStarted;
            state.userDeleteStatus = RequestStatus.NotStarted;
            state.userError = undefined;
        },
        resetUserAgentstatus: (state) =>
        {
            state.updateUserAgentStatus = RequestStatus.NotStarted;
        },
        resetVersionFormStatus: (state) =>
        {
            state.createVersionStatus = RequestStatus.NotStarted;
            state.updateVersionStatus = RequestStatus.NotStarted;
            state.versionError = undefined;
        },
        BackNavigation: (state, action: PayloadAction<boolean>) =>
        {
            state.backNavigation = action.payload;
        },
        BackNavigationValues: (state, action: PayloadAction<number>) =>
        {
            state.backNavigationValues = action.payload;
        },
    },
    extraReducers: (builder) =>
    {
        builder
            .addCase(getUser.pending, (state) =>
            {
                state.userStatus = RequestStatus.InProgress;
            })
            .addCase(getUser.fulfilled, (state, action) =>
            {
                state.userStatus = RequestStatus.Success;
                state.user = action.payload;
            })
            .addCase(getUser.rejected, (state) =>
            {
                state.userStatus = RequestStatus.Failure;
                state.user = undefined;
            })

            .addCase(getTypeInstruction.pending, (state) =>
            {
                state.instructionData = undefined;
            })
            .addCase(
                getTypeInstruction.fulfilled,
                (state, action: PayloadAction<InstructionTypeData[]>) =>
                {
                    state.instructionData = action.payload;
                }
            )
            .addCase(getTypeInstruction.rejected, (state) =>
            {
                state.instructionData = undefined;
            })
            .addCase(getPlatformTypeInstruction.pending, (state) =>
            {
                state.instructionPlatFormData = undefined;
            })
            .addCase(
                getPlatformTypeInstruction.fulfilled,
                (state, action) =>
                {
                    state.instructionPlatFormData = action.payload;
                }
            )
            .addCase(getPlatformTypeInstruction.rejected, (state) =>
            {
                state.instructionPlatFormData = undefined;
            })
            .addCase(uploadInstructionImage.pending, (state) =>
            {
                state.uploadInstructionImageStatus = RequestStatus.InProgress;
            })
            .addCase(uploadInstructionImage.fulfilled, (state, action) =>
            {
                state.uploadInstructionImageStatus = RequestStatus.Success;
                state.uploadInstructionImageId = action.payload;
            })
            .addCase(uploadInstructionImage.rejected, (state) =>
            {
                state.uploadInstructionImageStatus = RequestStatus.Failure;
                state.uploadInstructionImageId = undefined;
            })
            .addCase(getAgencyOfficers.pending, (state) =>
            {
                state.instructionCreateStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyOfficers.fulfilled, (state, action) =>
            {
                state.userStatus = RequestStatus.Success;
                state.agencyOfficer = action.payload;
            })
            .addCase(getAgencyOfficers.rejected, (state) =>
            {
                state.userStatus = RequestStatus.Failure;
                state.agencyOfficer = undefined;
            })
            .addCase(getAgencyClients.pending, (state) =>
            {
                state.clientStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyClients.fulfilled, (state, action) =>
            {
                state.clientStatus = RequestStatus.Success;
                state.agencyClient = action.payload;
            })
            .addCase(getAgencyClients.rejected, (state) =>
            {
                state.clientStatus = RequestStatus.Failure;
                state.agencyClient = undefined;
            })
            .addCase(getAgencyActiveDevice.pending, (state) =>
            {
                state.deviceStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyActiveDevice.fulfilled, (state, action) =>
            {
                state.deviceStatus = RequestStatus.Success;
                state.agencyActiveDevices = action.payload;
            })
            .addCase(getAgencyActiveDevice.rejected, (state) =>
            {
                state.deviceStatus = RequestStatus.Failure;
                state.agencyActiveDevices = undefined;
            })
            .addCase(getClientActiveDevice.pending, (state) =>
            {
                state.clientStatus = RequestStatus.InProgress;
            })
            .addCase(getClientActiveDevice.fulfilled, (state, action) =>
            {
                state.clientStatus = RequestStatus.Success;
                state.clientActiveDevices = action.payload;
            })
            .addCase(getClientActiveDevice.rejected, (state) =>
            {
                state.clientStatus = RequestStatus.Failure;
                state.clientActiveDevices = undefined;
            })
            .addCase(getUsers.pending, (state) =>
            {
                state.usersStatus = RequestStatus.InProgress;
            })
            .addCase(getUsers.fulfilled, (state, action) =>
            {
                state.usersStatus = RequestStatus.Success;
                state.users = action.payload;
            })
            .addCase(getUsers.rejected, (state) =>
            {
                state.users = undefined;
                state.usersStatus = RequestStatus.Failure;
            })
            .addCase(getOffUser.pending, (state) =>
            {
                state.usersStatus = RequestStatus.InProgress;
            })
            .addCase(getOffUser.fulfilled, (state, action) =>
            {
                state.usersStatus = RequestStatus.Success;
                state.users = action.payload;
            })
            .addCase(getOffUser.rejected, (state) =>
            {
                state.users = undefined;
                state.usersStatus = RequestStatus.Failure;
            })
            .addCase(getInstruction.pending, (state) =>
            {
                state.instructionStatus = RequestStatus.InProgress;
            })
            .addCase(getInstruction.fulfilled, (state, action) =>
            {
                state.instructionStatus = RequestStatus.Success;
                state.instructionList = action.payload;
            })
            .addCase(getInstruction.rejected, (state) =>
            {
                state.instructionList = undefined;
                state.instructionStatus = RequestStatus.Failure;
            })
            .addCase(getUsersNoPageable.fulfilled, (state, action) =>
            {
                state.usersNoPageable = action.payload;
            })
            .addCase(getUsersNoPageable.rejected, (state) =>
            {
                state.usersNoPageable = undefined;
            })
            .addCase(createUser.pending, (state) =>
            {
                state.userCreateStatus = RequestStatus.InProgress;
                state.userError = undefined;
            })
            .addCase(createUser.fulfilled, (state, action) =>
            {
                state.userCreateStatus = RequestStatus.Success;
                state.user = action.payload;
            })
            .addCase(createUser.rejected, (state, action) =>
            {
                state.userCreateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.userError = { messages: action.payload as string[] };
                }
                else
                {
                    state.userError = action.payload as ErrorData;
                }
            })
            .addCase(updateUser.pending, (state) =>
            {
                state.userUpdateStatus = RequestStatus.InProgress;
                state.userError = undefined;
            })
            .addCase(updateUser.fulfilled, (state, action) =>
            {
                state.userUpdateStatus = RequestStatus.Success;
                state.user = action.payload;
            })
            .addCase(updateUser.rejected, (state, action) =>
            {
                state.userUpdateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.userError = { messages: action.payload as string[] };
                }
                else
                {
                    state.userError = action.payload as ErrorData;
                }
            })
            .addCase(deleteUser.pending, (state) =>
            {
                state.userDeleteStatus = RequestStatus.InProgress;
                state.userError = undefined;
            })
            .addCase(deleteUser.fulfilled, (state, action) =>
            {
                state.userDeleteStatus = RequestStatus.Success;
                state.user = action.payload;
            })
            .addCase(deleteUser.rejected, (state, action) =>
            {
                state.userDeleteStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.userError = { messages: action.payload as string[] };
                }
            })
            .addCase(addInstructions.pending, (state) =>
            {
                state.instructionCreateStatus = RequestStatus.InProgress;
                state.userError = undefined;
            })
            .addCase(addInstructions.fulfilled, (state, action) =>
            {
                state.instructionCreateStatus = RequestStatus.Success;
                state.user = action.payload;
            })
            .addCase(addInstructions.rejected, (state, action) =>
            {
                state.instructionCreateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.userError = { messages: action.payload as string[] };
                }
                else
                {
                    state.userError = action.payload as ErrorData;
                }
            })
            .addCase(updateInstructions.pending, (state) =>
            {
                state.instructionCreateStatus = RequestStatus.InProgress;
            })
            .addCase(updateInstructions.fulfilled, (state, action) =>
            {
                state.instructionCreateStatus = RequestStatus.Success;
            })
            .addCase(updateInstructions.rejected, (state, action) =>
            {
                state.instructionCreateStatus = RequestStatus.Failure;
            })
            .addCase(deleteInstructions.pending, (state) =>
            {
                state.instructiondeleteStatus = RequestStatus.InProgress;
            })
            .addCase(deleteInstructions.fulfilled, (state, action) =>
            {
                state.instructiondeleteStatus = RequestStatus.Success;
            })
            .addCase(deleteInstructions.rejected, (state, action) =>
            {
                state.instructiondeleteStatus = RequestStatus.Failure;
            })

            .addCase(createAgency.pending, (state) =>
            {
                state.agencySaveStatus = RequestStatus.InProgress;
                state.agencyError = undefined;
            })
            .addCase(createAgency.fulfilled, (state, action) =>
            {
                state.agencySaveStatus = RequestStatus.Success;
                state.agency = action.payload;
            })
            .addCase(createAgency.rejected, (state, action) =>
            {
                state.agencySaveStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.agencyError = { messages: action.payload as string[] };
                }
                else
                {
                    state.agencyError = action.payload as ErrorData;
                }
            })
            .addCase(updateAgency.pending, (state) =>
            {
                state.agencySaveStatus = RequestStatus.InProgress;
                state.agencyError = undefined;
            })
            .addCase(updateAgency.fulfilled, (state, action) =>
            {
                state.agencySaveStatus = RequestStatus.Success;
                state.agency = action.payload;
            })
            .addCase(updateAgency.rejected, (state, action) =>
            {
                state.agencySaveStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.agencyError = { messages: action.payload as string[] };
                }
                else
                {
                    state.agencyError = action.payload as ErrorData;
                }
            })
            .addCase(deleteAgency.pending, (state) =>
            {
                state.agencyDeleteStatus = RequestStatus.InProgress;
                state.agencyError = undefined;
            })
            .addCase(deleteAgency.fulfilled, (state, action) =>
            {
                state.agencyDeleteStatus = RequestStatus.Success;
            })
            .addCase(deleteAgency.rejected, (state, action) =>
            {
                state.agencyDeleteStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.agencyError = { messages: action.payload as string[] };
                }
                else
                {
                    state.agencyError = action.payload as ErrorData;
                }
            })
            .addCase(getSupervisor.pending, (state) =>
            {
                state.supervisorList = undefined;
                state.supervisorListStatus = RequestStatus.InProgress;
            })
            .addCase(getSupervisor.fulfilled, (state, action) =>
            {
                state.supervisorList = action.payload;
                state.supervisorListStatus = RequestStatus.Success;
            })
            .addCase(getAllAgencies.pending, (state, action) =>
            {
                state.allAgenciesRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getAllAgencies.fulfilled, (state, action) =>
            {
                state.allAgenciesRetrieveStatus = RequestStatus.Success;
                state.allAgencies = action.payload;
            })
            .addCase(getAllAgencies.rejected, (state, action) =>
            {
                state.allAgenciesRetrieveStatus = RequestStatus.Failure;
                state.allAgencies = undefined;
            })
            .addCase(getDevices.pending, (state) =>
            {
                state.deviceStatus = RequestStatus.InProgress;
            })
            .addCase(getDevices.fulfilled, (state, action) =>
            {
                state.deviceStatus = RequestStatus.Success;
                state.devices = action.payload;
            })
            .addCase(getDevices.rejected, (state) =>
            {
                state.deviceStatus = RequestStatus.Failure;
                state.devices = undefined;
            })
            .addCase(getDeviceLogs.fulfilled, (state, action) =>
            {
                state.deviceLogs = action.payload;
            })
            .addCase(getDevicePermissions.pending, (state) =>
            {
                state.devicePermissionStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicePermissions.fulfilled, (state, action) =>
            {
                state.devicePermissionStatus = RequestStatus.Success;
                state.devicePermissions = action.payload;
            })
            .addCase(getDevicePermissions.rejected, (state, action) =>
            {
                state.devicePermissionStatus = RequestStatus.Failure;
            })
            .addCase(updateDevice.pending, (state) =>
            {
                state.deviceSaveStatus = RequestStatus.InProgress;
                state.deviceError = undefined;
            })
            .addCase(updateDevice.fulfilled, (state, action) =>
            {
                state.deviceSaveStatus = RequestStatus.Success;
            })
            .addCase(updateDevice.rejected, (state, action) =>
            {
                state.deviceSaveStatus = RequestStatus.Failure;
                state.deviceError = action.payload as ErrorData;
            })
            .addCase(createDevice.pending, (state) =>
            {
                state.deviceError = undefined;
                state.deviceSaveStatus = RequestStatus.InProgress;
            })
            .addCase(createDevice.fulfilled, (state, action) =>
            {
                state.deviceSaveStatus = RequestStatus.Success;
            })
            .addCase(createDevice.rejected, (state, action) =>
            {
                state.deviceSaveStatus = RequestStatus.Failure;
                state.deviceError = action.payload as ErrorData;
            })
            .addCase(getAgencies.pending, (state) =>
            {
                state.agencyStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencies.fulfilled, (state, action) =>
            {
                state.agencies = action.payload;
                state.agencyStatus = RequestStatus.Success;
            })
            .addCase(getAgencies.rejected, (state) =>
            {
                state.agencies = undefined;
                state.agencyStatus = RequestStatus.Failure;
            })
            .addCase(getClient.pending, (state) =>
            {
                state.clientRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getClient.fulfilled, (state, action) =>
            {
                state.clientRetrieveStatus = RequestStatus.Success;
                state.client = action.payload;
            })
            .addCase(getClient.rejected, (state) =>
            {
                state.clientRetrieveStatus = RequestStatus.Failure;
                state.client = undefined;
            })
            .addCase(getClients.pending, (state) =>
            {
                state.clientStatus = RequestStatus.InProgress;
            })
            .addCase(getClients.fulfilled, (state, action) =>
            {
                state.clientStatus = RequestStatus.Success;
                state.clients = action.payload;
            })
            .addCase(getClients.rejected, (state) =>
            {
                state.clientStatus = RequestStatus.Failure;
                state.clients = undefined;
            })
            .addCase(getUserAgents.pending, (state) =>
            {
                state.blockedUserAgentsStatus = RequestStatus.InProgress;
            })
            .addCase(getUserAgents.fulfilled, (state, action) =>
            {
                state.blockedUserAgentsStatus = RequestStatus.Success;
                state.blockedUserAgents = action.payload;
            })
            .addCase(getUserAgents.rejected, (state) =>
            {
                state.blockedUserAgentsStatus = RequestStatus.Failure;
                state.blockedUserAgents = undefined;
            })
            .addCase(updateUserAgents.pending, (state) =>
            {
                state.blockedUserAgentsStatus = RequestStatus.InProgress;
                state.updateUserAgentStatus = RequestStatus.InProgress;
            })
            .addCase(updateUserAgents.fulfilled, (state, action) =>
            {
                state.updateUserAgentStatus = RequestStatus.Success;
            })
            .addCase(createVersion.pending, (state) =>
            {
                state.createVersionStatus = RequestStatus.InProgress;
                state.versionError = undefined;
            })
            .addCase(createVersion.fulfilled, (state, action) =>
            {
                state.createVersionStatus = RequestStatus.Success;
                state.version = action.payload;
            })
            .addCase(createVersion.rejected, (state, action) =>
            {
                state.createVersionStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.versionError = { messages: action.payload as string[] };
                }
                else
                {
                    state.versionError = action.payload as ErrorData;
                }
            })
            .addCase(updateVersion.pending, (state) =>
            {
                state.updateVersionStatus = RequestStatus.InProgress;
                state.versionError = undefined;
            })
            .addCase(updateVersion.fulfilled, (state, action) =>
            {
                state.updateVersionStatus = RequestStatus.Success;
                state.version = action.payload;
            })
            .addCase(updateVersion.rejected, (state, action) =>
            {
                state.updateVersionStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.versionError = { messages: action.payload as string[] };
                }
                else
                {
                    state.versionError = action.payload as ErrorData;
                }
            })
            .addCase(getPlatformVersion.pending, (state) =>
            {
                state.platformVersionStatus = RequestStatus.InProgress;
            })
            .addCase(getPlatformVersion.fulfilled, (state, action) =>
            {
                state.platformVersionStatus = RequestStatus.Success;
                state.platformVersion = action.payload;
            })
            .addCase(getPlatformVersion.rejected, (state) =>
            {
                state.platformVersionStatus = RequestStatus.Failure;
                state.platformVersion = undefined;
            })
            .addCase(getAgencyCompareOptions.pending, (state) =>
            {
                state.compareVersionStatus = RequestStatus.InProgress;
            })

            .addCase(getnoncompliantAgencyDetails.pending, (state) =>
            {
                state.noncompliantAgencyStatus = RequestStatus.InProgress;
                state.noncompliantAgency = undefined;
            })
            .addCase(getnoncompliantAgencyDetails.fulfilled, (state, action) =>
            {
                state.noncompliantAgencyStatus = RequestStatus.Success;
                state.noncompliantAgency = action.payload;
            })
            .addCase(getnoncompliantAgencyDetails.rejected, (state) =>
            {
                state.noncompliantAgencyStatus = RequestStatus.Failure;
                state.noncompliantAgency = undefined;
            })

            .addCase(getAgencyCompareOptions.fulfilled, (state, action) =>
            {
                state.compareVersionStatus = RequestStatus.Success;
                state.compareVersion = action.payload;
            })
            .addCase(getAgencyCompareOptions.rejected, (state) =>
            {
                state.compareVersionStatus = RequestStatus.Failure;
                state.compareVersion = undefined;
            })

            .addCase(getuserSessions.pending, (state) =>
            {
                state.userSessionStatus = RequestStatus.InProgress;
            })
            .addCase(getuserSessions.fulfilled, (state, action) =>
            {
                state.userSessionStatus = RequestStatus.Success;
                state.userSession = action.payload;
            })
            .addCase(getuserSessions.rejected, (state) =>
            {
                state.userSessionStatus = RequestStatus.Failure;
                state.userSession = undefined;
            })

            .addCase(getAssignedOfficerSessions.pending, (state) =>
            {
                state.assignedOfficerSessionStatus = RequestStatus.InProgress;
            })
            .addCase(getAssignedOfficerSessions.fulfilled, (state, action) =>
            {
                state.assignedOfficerSessionStatus = RequestStatus.Success;
                state.assignedOfficerSession = action.payload;
            })
            .addCase(getAssignedOfficerSessions.rejected, (state) =>
            {
                state.assignedOfficerSessionStatus = RequestStatus.Failure;
                state.assignedOfficerSession = undefined;
            })

            .addCase(getUserlogDetails.pending, (state) =>
            {
                state.userlogStatus = RequestStatus.InProgress;
            })
            .addCase(getUserlogDetails.fulfilled, (state, action) =>
            {
                state.userlogStatus = RequestStatus.Success;
                state.userLogDetails = action.payload;
            })
            .addCase(getUserlogDetails.rejected, (state) =>
            {
                state.userlogStatus = RequestStatus.Failure;
                state.userLogDetails = undefined;
            })

            .addCase(getAllClients.pending, (state) =>
            {
                state.allClientsRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getAllClients.fulfilled, (state, action) =>
            {
                state.allClientsRetrieveStatus = RequestStatus.Success;
                state.allClients = action.payload;
            })
            .addCase(getAllClients.rejected, (state) =>
            {
                state.allClientsRetrieveStatus = RequestStatus.Failure;
                state.allClients = undefined;
            })
            .addCase(createClient.pending, (state) =>
            {
                state.clientSaveStatus = RequestStatus.InProgress;
                state.clientRemoteNoError = undefined;
            })
            .addCase(createClient.fulfilled, (state, action) =>
            {
                state.clientSaveStatus = RequestStatus.Success;
                state.clientRemoteNoError = undefined;
            })
            .addCase(createClient.rejected, (state, action) =>
            {
                state.clientSaveStatus = RequestStatus.Failure;
                state.clientRemoteNoError = action.payload as ErrorData;
            })
            .addCase(updateClient.pending, (state) =>
            {
                state.clientSaveStatus = RequestStatus.InProgress;
                state.clientRemoteNoError = undefined;
            })
            .addCase(updateClient.fulfilled, (state, action) =>
            {
                state.clientSaveStatus = RequestStatus.Success;
                state.clientRemoteNoError = undefined;
            })
            .addCase(updateClient.rejected, (state, action) =>
            {
                state.clientSaveStatus = RequestStatus.Failure;
                state.clientRemoteNoError = action.payload as ErrorData;
            })
            .addCase(deleteClient.pending, (state) =>
            {
                state.clientSaveStatus = RequestStatus.InProgress;
            })
            .addCase(deleteClient.fulfilled, (state) =>
            {
                state.clientSaveStatus = RequestStatus.Deleted;
            })
            .addCase(deleteClient.rejected, (state) =>
            {
                state.clientSaveStatus = RequestStatus.Failure;
            })
            .addCase(getReport.pending, (state) =>
            {
                state.reportStatus = RequestStatus.InProgress;
            })
            .addCase(getReport.fulfilled, (state, action) =>
            {
                state.reportStatus = RequestStatus.Success;
                state.report = action.payload;
            })
            .addCase(getReport.rejected, (state) =>
            {
                state.reportStatus = RequestStatus.Failure;
                state.report = undefined;
            })
            .addCase(getReports.pending, (state) =>
            {
                state.reportsStatus = RequestStatus.InProgress;
            })
            .addCase(getReports.fulfilled, (state, action) =>
            {
                state.reportsStatus = RequestStatus.Success;
                state.reports = action.payload;
            })
            .addCase(getReports.rejected, (state) =>
            {
                state.reportsStatus = RequestStatus.Failure;
                state.reports = undefined;
            })
            .addCase(getReportFiles.pending, (state) =>
            {
                state.reportFilesStatus = RequestStatus.InProgress;
            })
            .addCase(getReportFiles.fulfilled, (state, action) =>
            {
                state.reportFilesStatus = RequestStatus.Success;
                state.reportFiles = action.payload;
            })
            .addCase(getReportFiles.rejected, (state) =>
            {
                state.reportFilesStatus = RequestStatus.Failure;
                state.reportFiles = undefined;
            })
            .addCase(createReport.pending, (state) =>
            {
                state.reportCreateStatus = RequestStatus.InProgress;
                state.reportError = undefined;
            })
            .addCase(createReport.fulfilled, (state, action) =>
            {
                state.reportCreateStatus = RequestStatus.Success;
                state.report = action.payload;
            })
            .addCase(createReport.rejected, (state, action) =>
            {
                state.reportCreateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.reportError = { messages: action.payload as string[] };
                }
                else
                {
                    state.reportError = action.payload as ErrorData;
                }
            })
            .addCase(updateReport.pending, (state) =>
            {
                state.reportUpdateStatus = RequestStatus.InProgress;
                state.reportError = undefined;
            })
            .addCase(updateReport.fulfilled, (state, action) =>
            {
                state.reportUpdateStatus = RequestStatus.Success;
                state.report = action.payload;
            })
            .addCase(updateReport.rejected, (state, action) =>
            {
                state.reportUpdateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.reportError = { messages: action.payload as string[] };
                }
                else
                {
                    state.reportError = action.payload as ErrorData;
                }
            })

            .addCase(deleteReport.pending, (state) =>
            {
                state.reportDeleteStatus = RequestStatus.InProgress;
            })
            .addCase(deleteReport.fulfilled, (state, action) =>
            {
                state.reportDeleteStatus = RequestStatus.Success;
            })
            .addCase(deleteReport.rejected, (state, action) =>
            {
                state.reportDeleteStatus = RequestStatus.Failure;
            })

            .addCase(getConfiguration.pending, (state) =>
            {
                state.configurationStatus = RequestStatus.InProgress;
            })
            .addCase(getConfiguration.fulfilled, (state, action) =>
            {
                state.configurationStatus = RequestStatus.Success;
                state.configuration = action.payload;
            })
            .addCase(getConfiguration.rejected, (state) =>
            {
                state.configurationStatus = RequestStatus.Failure;
                state.configuration = undefined;
            })
            .addCase(getPartialConfiguration.pending, (state) =>
            {
                state.partialConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(getPartialConfiguration.fulfilled, (state, action) =>
            {
                state.partialConfigurationStatus = RequestStatus.Success;
                state.partialConfiguration = action.payload;
            })
            .addCase(getPartialConfiguration.rejected, (state) =>
            {
                state.partialConfigurationStatus = RequestStatus.Failure;
                state.partialConfiguration = undefined;
            })
            .addCase(getParentConfiguration.pending, (state) =>
            {
                state.parentConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(getParentConfiguration.fulfilled, (state, action) =>
            {
                state.parentConfigurationStatus = RequestStatus.Success;
                state.parentConfiguration = action.payload;
            })
            .addCase(getParentConfiguration.rejected, (state) =>
            {
                state.parentConfigurationStatus = RequestStatus.Failure;
                state.parentConfiguration = undefined;
            })
            .addCase(setItemInConfiguration.pending, (state) =>
            {
                state.itemInConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(setItemInConfiguration.fulfilled, (state, action) =>
            {
                state.itemInConfigurationStatus = RequestStatus.Success;
                state.itemInConfiguration = action.payload;
            })
            .addCase(setItemInConfiguration.rejected, (state) =>
            {
                state.itemInConfigurationStatus = RequestStatus.Failure;
                state.itemInConfiguration = undefined;
            })
            .addCase(updateConfiguration.pending, (state) =>
            {
                state.saveConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(updateConfiguration.fulfilled, (state, action) =>
            {
                state.saveConfigurationStatus = RequestStatus.Success;
                state.partialConfiguration = action.payload;
            })
            .addCase(updateConfiguration.rejected, (state) =>
            {
                state.saveConfigurationStatus = RequestStatus.Failure;
            })
            .addCase(createConfiguration.pending, (state) =>
            {
                state.saveConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(createConfiguration.fulfilled, (state, action) =>
            {
                state.saveConfigurationStatus = RequestStatus.Success;
                state.partialConfiguration = action.payload;
            })
            .addCase(createConfiguration.rejected, (state) =>
            {
                state.saveConfigurationStatus = RequestStatus.Failure;
            })

            .addCase(createExtension.pending, (state) =>
            {
                state.extensionCreationStatus = RequestStatus.InProgress;
            })
            .addCase(createExtension.fulfilled, (state, action) =>
            {
                state.extensionCreationStatus = RequestStatus.Success;
                state.extension = action.payload;
            })
            .addCase(createExtension.rejected, (state) =>
            {
                state.extensionCreationStatus = RequestStatus.Failure;
                state.extension = undefined;
            })
            .addCase(getExtensions.pending, (state) =>
            {
                state.extensionsStatus = RequestStatus.InProgress;
            })
            .addCase(getExtensions.fulfilled, (state, action) =>
            {
                state.extensionsStatus = RequestStatus.Success;
                state.extensions = action.payload;
            })
            .addCase(getExtensions.rejected, (state) =>
            {
                state.extensionsStatus = RequestStatus.Failure;
                state.extensions = undefined;
            })
            .addCase(updateExtension.pending, (state) =>
            {
                state.extensionUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateExtension.fulfilled, (state, action) =>
            {
                state.extensionUpdateStatus = RequestStatus.Success;
                state.extension = action.payload;
            })
            .addCase(updateExtension.rejected, (state) =>
            {
                state.extensionUpdateStatus = RequestStatus.Failure;
            })
            .addCase(createProfile.pending, (state) =>
            {
                state.profileCreationStatus = RequestStatus.InProgress;
                state.profileError = undefined;
            })
            .addCase(createProfile.fulfilled, (state, action) =>
            {
                state.profileCreationStatus = RequestStatus.Success;
                state.profile = action.payload;
                state.profileError = undefined;
            })
            .addCase(createProfile.rejected, (state, action) =>
            {
                state.profileCreationStatus = RequestStatus.Failure;
                state.profile = undefined;
                if (Array.isArray(action.payload))
                {
                    state.profileError = { messages: action.payload as string[] };
                }
                else
                {
                    state.profileError = action.payload as ErrorData;
                }
            })
            .addCase(getProfiles.pending, (state) =>
            {
                state.profilesStatus = RequestStatus.InProgress;
            })
            .addCase(getProfiles.fulfilled, (state, action) =>
            {
                state.profilesStatus = RequestStatus.Success;
                state.profiles = action.payload;
            })
            .addCase(getProfiles.rejected, (state) =>
            {
                state.profilesStatus = RequestStatus.Failure;
                state.profiles = undefined;
            })
            .addCase(getProfile.pending, (state) =>
            {
                state.profileRetrievingStatus = RequestStatus.InProgress;
            })
            .addCase(getProfile.fulfilled, (state, action) =>
            {
                state.profileRetrievingStatus = RequestStatus.Success;
                state.profile = action.payload;
            })
            .addCase(getProfile.rejected, (state) =>
            {
                state.profileRetrievingStatus = RequestStatus.Failure;
                state.profile = undefined;
            })
            .addCase(updateProfile.pending, (state) =>
            {
                state.profileUpdateStatus = RequestStatus.InProgress;
                state.profileError = undefined;
            })
            .addCase(updateProfile.fulfilled, (state, action) =>
            {
                state.profileUpdateStatus = RequestStatus.Success;
                state.profile = action.payload;
                state.profileError = undefined;
            })
            .addCase(updateProfile.rejected, (state, action) =>
            {
                state.profileUpdateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.profileError = { messages: action.payload as string[] };
                }
                else
                {
                    state.profileError = action.payload as ErrorData;
                }
            })
            .addCase(deleteProfile.pending, (state) =>
            {
                state.profiledeleteStatus = RequestStatus.InProgress;
            })
            .addCase(deleteProfile.fulfilled, (state, action) =>
            {
                state.profiledeleteStatus = RequestStatus.Success;
            })
            .addCase(deleteProfile.rejected, (state) =>
            {
                state.profiledeleteStatus = RequestStatus.Failure;
            })

            .addCase(getAllProfiles.pending, (state) =>
            {
                state.allProfilesRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getAllProfiles.fulfilled, (state, action) =>
            {
                state.allProfilesRetrieveStatus = RequestStatus.Success;
                state.allProfiles = action.payload;
            })
            .addCase(getAllProfiles.rejected, (state) =>
            {
                state.allProfilesRetrieveStatus = RequestStatus.Failure;
                state.allProfiles = undefined;
            })
            .addCase(createSuggestedAWL.pending, (state) =>
            {
                state.awlCreationStatus = RequestStatus.InProgress;
            })
            .addCase(createSuggestedAWL.fulfilled, (state, action) =>
            {
                state.awlCreationStatus = RequestStatus.Success;
            })
            .addCase(createSuggestedAWL.rejected, (state) =>
            {
                state.awlCreationStatus = RequestStatus.Failure;
            })
            .addCase(getClientAWLs.pending, (state) =>
            {
                state.clientAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getClientAWLs.fulfilled, (state, action) =>
            {
                state.clientAWLRetrieveStatus = RequestStatus.Success;
                state.clientAWLs = action.payload;
            })
            .addCase(getClientAWLs.rejected, (state) =>
            {
                state.clientAWLRetrieveStatus = RequestStatus.Failure;
                state.clientAWLs = undefined;
            })
            .addCase(getClientDisbaleAWLs.pending, (state) =>
            {
                state.clientDisableAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getClientDisbaleAWLs.fulfilled, (state, action) =>
            {
                state.clientDisableAWLRetrieveStatus = RequestStatus.Success;
                state.clientDisableAWLs = action.payload;
            })
            .addCase(getClientDisbaleAWLs.rejected, (state) =>
            {
                state.clientDisableAWLRetrieveStatus = RequestStatus.Failure;
                state.clientDisableAWLs = undefined;
            })
            .addCase(createClientAWL.pending, (state) =>
            {
                state.clientAWLCreationStatus = RequestStatus.InProgress;
            })
            .addCase(createClientAWL.fulfilled, (state, action) =>
            {
                state.clientAWLCreationStatus = RequestStatus.Success;
            })
            .addCase(createClientAWL.rejected, (state) =>
            {
                state.clientAWLCreationStatus = RequestStatus.Failure;
            })
            .addCase(getOfficerAWLs.pending, (state) =>
            {
                state.officerAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getOfficerAWLs.fulfilled, (state, action) =>
            {
                state.officerAWLRetrieveStatus = RequestStatus.Success;
                state.officerAWLs = action.payload;
            })
            .addCase(getOfficerAWLs.rejected, (state) =>
            {
                state.officerAWLRetrieveStatus = RequestStatus.Failure;
                state.officerAWLs = undefined;
            })
            .addCase(getOfficerDisbaleAWLs.pending, (state) =>
            {
                state.officerDisableAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getOfficerDisbaleAWLs.fulfilled, (state, action) =>
            {
                state.officerDisableAWLRetrieveStatus = RequestStatus.Success;
                state.officerDisableAWLs = action.payload;
            })
            .addCase(getOfficerDisbaleAWLs.rejected, (state) =>
            {
                state.officerDisableAWLRetrieveStatus = RequestStatus.Failure;
                state.officerDisableAWLs = undefined;
            })
            .addCase(createOfficerAWL.pending, (state) =>
            {
                state.officerAWLCreationStatus = RequestStatus.InProgress;
            })
            .addCase(createOfficerAWL.fulfilled, (state, action) =>
            {
                state.officerAWLCreationStatus = RequestStatus.Success;
            })
            .addCase(createOfficerAWL.rejected, (state) =>
            {
                state.officerAWLCreationStatus = RequestStatus.Failure;
            })
            .addCase(createCustomsAWL.pending, (state) =>
            {
                state.customAWLCreationStatus = RequestStatus.InProgress;
            })
            .addCase(createCustomsAWL.fulfilled, (state) =>
            {
                state.customAWLCreationStatus = RequestStatus.Success;
            })
            .addCase(createCustomsAWL.rejected, (state) =>
            {
                state.customAWLCreationStatus = RequestStatus.Failure;
            })
            .addCase(getCustomAWLs.pending, (state) =>
            {
                state.customAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getCustomAWLs.fulfilled, (state, action) =>
            {
                state.customAWLRetrieveStatus = RequestStatus.Success;
                state.customAWLs = action.payload;
            })
            .addCase(getCustomAWLs.rejected, (state) =>
            {
                state.customAWLRetrieveStatus = RequestStatus.Failure;
                state.customAWLs = undefined;
            })
            .addCase(getCustomAWLById.pending, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getCustomAWLById.fulfilled, (state, action) =>
            {
                state.awlRetrieveStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(getCustomAWLById.rejected, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.Failure;
                state.alertWordsList = undefined;
            })
            .addCase(getClientAWLById.pending, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getClientAWLById.fulfilled, (state, action) =>
            {
                state.awlRetrieveStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(getClientAWLById.rejected, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.Failure;
                state.alertWordsList = undefined;
            })
            .addCase(getOfficerAWLById.pending, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getOfficerAWLById.fulfilled, (state, action) =>
            {
                state.awlRetrieveStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(getOfficerAWLById.rejected, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.Failure;
                state.alertWordsList = undefined;
            })
            .addCase(updateCustomAWL.pending, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateCustomAWL.fulfilled, (state, action) =>
            {
                state.awlUpdateStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(updateCustomAWL.rejected, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.Failure;
            })
            .addCase(updateClientAWL.pending, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateClientAWL.fulfilled, (state, action) =>
            {
                state.awlUpdateStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(updateClientAWL.rejected, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.Failure;
            })
            .addCase(updateClientAWLToggle.pending, (state) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateClientAWLToggle.fulfilled, (state, action) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.Success;
            })
            .addCase(updateClientAWLToggle.rejected, (state) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.Failure;
            })
            .addCase(updateOfficerAWL.pending, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateOfficerAWL.fulfilled, (state, action) =>
            {
                state.awlUpdateStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(updateOfficerAWL.rejected, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.Failure;
            })
            .addCase(updateOfficerAWLToggle.pending, (state) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateOfficerAWLToggle.fulfilled, (state, action) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.Success;
            })
            .addCase(updateOfficerAWLToggle.rejected, (state) =>
            {
                state.awlToggleUpdateStatus = RequestStatus.Failure;
            })
            .addCase(getAgencyAWLConfiguration.pending, (state) =>
            {
                state.agencyAWLConfigurationRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyAWLConfiguration.fulfilled, (state, action) =>
            {
                state.agencyAWLConfigurationRetrieveStatus = RequestStatus.Success;
                state.agencyAWLConfiguration = action.payload;
            })
            .addCase(getAgencyAWLConfiguration.rejected, (state) =>
            {
                state.agencyAWLConfigurationRetrieveStatus = RequestStatus.Failure;
                state.agencyAWLConfiguration = undefined;
            })
            .addCase(updateAgencyAWLConfiguration.pending, (state) =>
            {
                state.agencyAWLConfigurationSaveStatus = RequestStatus.InProgress;
            })
            .addCase(updateAgencyAWLConfiguration.fulfilled, (state, action) =>
            {
                state.agencyAWLConfigurationSaveStatus = RequestStatus.Success;
                state.agencyAWLConfiguration = action.payload;
            })
            .addCase(updateAgencyAWLConfiguration.rejected, (state) =>
            {
                state.agencyAWLConfigurationSaveStatus = RequestStatus.Failure;
            })
            .addCase(updateSuggestedAWL.pending, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.InProgress;
            })
            .addCase(updateSuggestedAWL.fulfilled, (state, action) =>
            {
                state.awlUpdateStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(updateSuggestedAWL.rejected, (state) =>
            {
                state.awlUpdateStatus = RequestStatus.Failure;
            })
            .addCase(getSuggestedAWLById.pending, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getSuggestedAWLById.fulfilled, (state, action) =>
            {
                state.awlRetrieveStatus = RequestStatus.Success;
                state.alertWordsList = action.payload;
            })
            .addCase(getSuggestedAWLById.rejected, (state) =>
            {
                state.awlRetrieveStatus = RequestStatus.Failure;
                state.alertWordsList = undefined;
            })
            .addCase(sendActivationCode.pending, (state) =>
            {
                state.sendActivationCodeStatus = RequestStatus.InProgress;
            })
            .addCase(sendActivationCode.fulfilled, (state, action) =>
            {
                state.sendActivationCodeStatus = RequestStatus.Success;
            })
            .addCase(sendActivationCode.rejected, (state) =>
            {
                state.sendActivationCodeStatus = RequestStatus.Failure;
            })
            .addCase(sendDeleteDevice.pending, (state) =>
            {
                state.sendDeleteDeviceStatus = RequestStatus.InProgress;
            })
            .addCase(sendDeleteDevice.fulfilled, (state, action) =>
            {
                state.sendDeleteDeviceStatus = RequestStatus.Success;
            })
            .addCase(sendDeleteDevice.rejected, (state) =>
            {
                state.sendDeleteDeviceStatus = RequestStatus.Failure;
            })
            .addCase(sendDeleteExtension.pending, (state) =>
            {
                state.sendDeleteExtensionStatus = RequestStatus.InProgress;
            })
            .addCase(sendDeleteExtension.fulfilled, (state, action) =>
            {
                state.sendDeleteExtensionStatus = RequestStatus.Success;
            })
            .addCase(sendDeleteExtension.rejected, (state) =>
            {
                state.sendDeleteExtensionStatus = RequestStatus.Failure;
            })

            .addCase(getSuggestedAWLs.pending, (state) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getSuggestedAWLs.fulfilled, (state, action) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.Success;
                state.suggestedAWL = action.payload;
            })
            .addCase(getSuggestedAWLs.rejected, (state) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.Failure;
                state.suggestedAWL = undefined;
            })
            .addCase(getSuggestedAWLsNoPageable.pending, (state) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.InProgress;
            })
            .addCase(getSuggestedAWLsNoPageable.fulfilled, (state, action) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.Success;
                state.suggestedAWLNoPageable = action.payload;
            })
            .addCase(getSuggestedAWLsNoPageable.rejected, (state) =>
            {
                state.suggestedAWLRetrieveStatus = RequestStatus.Failure;
                state.suggestedAWLNoPageable = undefined;
            })
            .addCase(getAgencyMonitorList.pending, (state) =>
            {
                state.agencyMonitorStatus = RequestStatus.InProgress;
            })
            .addCase(getAgencyMonitorList.fulfilled, (state, action) =>
            {
                state.agencyMonitorStatus = RequestStatus.Success;
                state.agencyMonitorList = action.payload;
            })
            .addCase(getAgencyMonitorList.rejected, (state) =>
            {
                state.agencyMonitorStatus = RequestStatus.Failure;
                state.agencyMonitorList = undefined;
            })
            .addCase(getAssignedAgencyMonitor.pending, (state) =>
            {
                state.agencyassignedMonitorStatus = RequestStatus.InProgress;
            })
            .addCase(getAssignedAgencyMonitor.fulfilled, (state, action) =>
            {
                state.agencyassignedMonitorStatus = RequestStatus.Success;
                state.agencyassignedMonitor = action.payload;
            })
            .addCase(getAssignedAgencyMonitor.rejected, (state) =>
            {
                state.agencyassignedMonitorStatus = RequestStatus.Failure;
                state.agencyassignedMonitor = undefined;
            })
            .addCase(getNotificationConfiguration.pending, (state) =>
            {
                state.notificationConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(getNotificationConfiguration.fulfilled, (state, action) =>
            {
                state.notificationConfigurationStatus = RequestStatus.Success;
                state.notificationConfiguration = action.payload;
            })
            .addCase(getNotificationConfiguration.rejected, (state) =>
            {
                state.notificationConfigurationStatus = RequestStatus.Failure;
                state.notificationConfiguration = undefined;
            })
            .addCase(getNotificationConfigurations.pending, (state) =>
            {
                state.notificationConfigurationsStatus = RequestStatus.InProgress;
            })
            .addCase(getNotificationConfigurations.fulfilled, (state, action) =>
            {
                state.notificationConfigurationsStatus = RequestStatus.Success;
                state.notificationConfigurations = action.payload;
            })
            .addCase(getNotificationConfigurations.rejected, (state) =>
            {
                state.notificationConfigurationsStatus = RequestStatus.Failure;
                state.notificationConfigurations = undefined;
            })
            .addCase(getNotificationConfigurationNotifications.pending, (state) =>
            {
                state.notificationConfigurationNotificationsStatus = RequestStatus.InProgress;
            })
            .addCase(getNotificationConfigurationNotifications.fulfilled, (state, action) =>
            {
                state.notificationConfigurationNotificationsStatus = RequestStatus.Success;
                state.notificationConfigurationNotifications = action.payload;
            })
            .addCase(getNotificationConfigurationNotifications.rejected, (state) =>
            {
                state.notificationConfigurationNotificationsStatus = RequestStatus.Failure;
                state.notificationConfigurationNotifications = undefined;
            })
            .addCase(createNotificationConfiguration.pending, (state) =>
            {
                state.notificationConfigurationCreateStatus = RequestStatus.InProgress;
                state.notificationConfigurationError = undefined;
            })
            .addCase(createNotificationConfiguration.fulfilled, (state, action) =>
            {
                state.notificationConfigurationCreateStatus = RequestStatus.Success;
                state.notificationConfiguration = action.payload;
            })
            .addCase(createNotificationConfiguration.rejected, (state, action) =>
            {
                state.notificationConfigurationCreateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.notificationConfigurationError = { messages: action.payload as string[] };
                }
                else
                {
                    state.notificationConfigurationError = action.payload as ErrorData;
                }
            })
            .addCase(updateNotificationConfiguration.pending, (state) =>
            {
                state.notificationConfigurationUpdateStatus = RequestStatus.InProgress;
                state.notificationConfigurationError = undefined;
            })
            .addCase(updateNotificationConfiguration.fulfilled, (state, action) =>
            {
                state.notificationConfigurationUpdateStatus = RequestStatus.Success;
                state.notificationConfiguration = action.payload;
            })
            .addCase(updateNotificationConfiguration.rejected, (state, action) =>
            {
                state.notificationConfigurationUpdateStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload))
                {
                    state.notificationConfigurationError = { messages: action.payload as string[] };
                }
                else
                {
                    state.notificationConfigurationError = action.payload as ErrorData;
                }
            })
            .addCase(getpushNotificationConfiguration.pending, (state) =>
            {
                state.pushNotificationConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(getpushNotificationConfiguration.fulfilled, (state, action) =>
            {
                state.pushNotificationConfigurationStatus = RequestStatus.Success;
                state.pushNotificationConfiguration = action.payload;
            })
            .addCase(getpushNotificationConfiguration.rejected, (state) =>
            {
                state.pushNotificationConfigurationStatus = RequestStatus.Failure;
                state.pushNotificationConfiguration = undefined;
            })
            .addCase(deleteNotificationConfiguration.pending, (state) =>
            {
                state.notificationConfigurationDeleteStatus = RequestStatus.InProgress;
            })
            .addCase(deleteNotificationConfiguration.fulfilled, (state, action) =>
            {
                state.notificationConfigurationDeleteStatus = RequestStatus.Success;
            })
            .addCase(deleteNotificationConfiguration.rejected, (state) =>
            {
                state.notificationConfigurationDeleteStatus = RequestStatus.Failure;
            })
            .addCase(getNotifications.pending, (state) =>
            {
                state.notificationsStatus = RequestStatus.InProgress;
            })
            .addCase(getNotifications.fulfilled, (state, action) =>
            {
                state.notificationsStatus = RequestStatus.Success;
                state.notifications = action.payload;
            })
            .addCase(getNotifications.rejected, (state) =>
            {
                state.notificationsStatus = RequestStatus.Failure;
                state.notifications = undefined;
            });
    },
});

function selectState(state: RootState): AdministrationState
{
    return state.administration;
}

export const selectUser = createSelector(selectState, (state) => state.user);
export const selectUserStatus = createSelector(
    selectState,
    (state) => state.userStatus
);
export const selectUsers = createSelector(selectState, (state) => state.users);
export const selectInstruction = createSelector(
    selectState,
    (state) => state.instructionList
);
export const selectUserFilters = createSelector(
    selectState,
    (state) => state.userFilters
);
export const selectUsersStatus = createSelector(
    selectState,
    (state) => state.usersStatus
);
export const selectUsersNoPageable = createSelector(
    selectState,
    (state) => state.usersNoPageable
);
export const selectUserError = createSelector(
    selectState,
    (state) => state.userError
);
export const selectVersionUpdateError = createSelector(
    selectState,
    (state) => state.versionError
);
export const selectProfileError = createSelector(
    selectState,
    (state) => state.profileError
);
export const selectAgencyOfficers = createSelector(
    selectState,
    (state) => state.agencyOfficer
);
export const selectAgencyClients = createSelector(
    selectState,
    (state) => state.agencyClient
);
export const selectInstructionData = createSelector(
    selectState,
    (state) => state.instructionData
);
export const selectInstructionPlatFormType= createSelector(
    selectState,
    (state) => state.instructionPlatFormData
);
export const selectInstructionFileId = createSelector(
    selectState,
    (state) => state.uploadInstructionImageId
);
export const selectAgencyActiveDevices = createSelector(
    selectState,
    state => state.agencyActiveDevices
);
export const selectClientActiveDevices = createSelector(
    selectState,
    state => state.clientActiveDevices
);
export const selectUserCreateStatus = createSelector(
    selectState,
    (state) => state.userCreateStatus
);
export const selectInstructionCreateStatus = createSelector(
    selectState,
    (state) => state.instructionCreateStatus
);
export const selectInstructiondeleteStatus = createSelector(
    selectState,
    (state) => state.instructiondeleteStatus
);

export const selectuploadImageStatus = createSelector(
    selectState,
    (state) => state.uploadInstructionImageStatus
);
export const selectUserUpdateStatus = createSelector(
    selectState,
    state => state.userUpdateStatus
);
export const selectUserDeleteStatus = createSelector(
    selectState,
    state => state.userDeleteStatus
);
export const selectCreateVersionStatus = createSelector(
    selectState,
    state => state.createVersionStatus
);
export const selectUpdateVersionStatus = createSelector(
    selectState,
    state => state.updateVersionStatus
);

export const selectDevices = createSelector(selectState, state => state.devices);
export const selectDeviceLogs = createSelector(selectState, state => state.deviceLogs);
export const selectDeviceFilters = createSelector(selectState, state => state.deviceFilters);
export const selectDeviceStatus = createSelector(selectState, state => state.deviceStatus);
export const selectDeviceSaveStatus = createSelector(
    selectState,
    state => state.deviceSaveStatus
);
export const selectDeviceError = createSelector(selectState, state => state.deviceError);
export const selectDevicePermissions = createSelector(
    selectState,
    state => state.devicePermissions
);
export const selectDevicePermissionsStatus = createSelector(
    selectState,
    state => state.devicePermissionStatus
);
export const selectAllAgenciesRetrieveStatus = createSelector(
    selectState,
    state => state.allAgenciesRetrieveStatus
);
export const selectSuperVisorStatus = createSelector(
    selectState,
    state => state.supervisorListStatus
);
export const selectSupervisorList = createSelector(
    selectState,
    state => state.supervisorList
);

export const selectAllAgencies = createSelector(selectState, state => state.allAgencies);
export const selectAgencies = createSelector(selectState, state => state.agencies);
export const selectAgencyFilters = createSelector(selectState, state => state.agencyFilters);
export const selectAgencyStatus = createSelector(selectState, state => state.agencyStatus);
export const selectAgencySaveStatus = createSelector(selectState, state => state.agencySaveStatus);
export const selectAgencyDeleteStatus = createSelector(
    selectState,
    state => state.agencyDeleteStatus
);
export const selectAgencyError = createSelector(selectState, state => state.agencyError);

export const selectClient = createSelector(selectState, state => state.client);
export const selectClients = createSelector(selectState, state => state.clients);
export const selectAllClients = createSelector(selectState, state => state.allClients);
export const selectClientFilters = createSelector(selectState, state => state.clientFilters);
export const selectClientsStatus = createSelector(selectState, state => state.clientStatus);
export const selectClientSaveStatus = createSelector(
    selectState,
    state => state.clientSaveStatus
);
export const clientRemoteNoError = createSelector(selectState, state => state.clientRemoteNoError);

export const selectExtensionSaveStatus = createSelector(
    selectState,
    state => state.extensionSaveStatus
);

export const selectClientRetrieveStatus = createSelector(
    selectState,
    state => state.clientRetrieveStatus
);

export const selectReport = createSelector(selectState, state => state.report);
export const selectReportStatus = createSelector(selectState, state => state.reportStatus);
export const selectReports = createSelector(selectState, state => state.reports);
export const selectReportFilters = createSelector(selectState, state => state.reportFilters);
export const selectReportsStatus = createSelector(selectState, state => state.reportsStatus);
export const selectReportFiles = createSelector(selectState, state => state.reportFiles);
export const selectReportFilesStatus = createSelector(
    selectState,
    state => state.reportFilesStatus
);
export const selectReportCreateStatus = createSelector(
    selectState,
    state => state.reportCreateStatus
);
export const selectReportUpdateStatus = createSelector(
    selectState,
    state => state.reportUpdateStatus
);
export const selectReportDeleteStatus = createSelector(
    selectState,
    state => state.reportDeleteStatus
);
export const selectReportError = createSelector(selectState, state => state.reportError);

export const selectNotificationConfiguration = createSelector(
    selectState,
    state => state.notificationConfiguration
);
export const selectNotificationConfigurationStatus = createSelector(
    selectState,
    state => state.notificationConfigurationStatus
);
export const selectNotificationConfigurations = createSelector(
    selectState,
    state => state.notificationConfigurations
);
export const selectNotificationConfigurationFilters = createSelector(
    selectState,
    state => state.notificationConfigurationFilters
);
export const selectNotificationConfigurationsStatus = createSelector(
    selectState,
    state => state.notificationConfigurationsStatus
);
export const selectNotificationConfigurationNotifications = createSelector(
    selectState,
    state => state.notificationConfigurationNotifications
);
export const selectNotificationConfigurationNotificationsStatus = createSelector(
    selectState,
    state => state.notificationConfigurationNotificationsStatus
);
export const selectNotificationConfigurationCreateStatus = createSelector(
    selectState,
    state => state.notificationConfigurationCreateStatus
);
export const selectNotificationConfigurationUpdateStatus = createSelector(
    selectState,
    state => state.notificationConfigurationUpdateStatus
);
export const selectNotificationConfigurationDeleteStatus = createSelector(
    selectState,
    state => state.notificationConfigurationDeleteStatus
);
export const selectNotificationConfigurationError = createSelector(
    selectState,
    state => state.notificationConfigurationError
);
export const selectNotifications = createSelector(
    selectState,
    state => state.notifications
);
export const selectpushNotification = createSelector(
    selectState,
    state => state.pushNotificationConfiguration
);
export const selectpushNotificationStatus = createSelector(
    selectState,
    state => state.pushNotificationConfigurationStatus
);

export const selectNotificationsStatus = createSelector(
    selectState,
    state => state.notificationsStatus
);

export const selectConfiguration = createSelector(
    selectState,
    state => state.configuration
);
export const selectConfigurationStatus = createSelector(
    selectState,
    state => state.configurationStatus
);
export const selectPartialConfiguration = createSelector(
    selectState,
    state => state.partialConfiguration
);
export const selectPartialConfigurationStatus = createSelector(
    selectState,
    state => state.partialConfigurationStatus
);
export const selectParentConfiguration = createSelector(
    selectState,
    state => state.parentConfiguration
);
export const selectParentConfigurationStatus = createSelector(
    selectState,
    state => state.parentConfigurationStatus
);
export const selectSaveConfigurationStatus = createSelector(
    selectState,
    state => state.saveConfigurationStatus
);
export const selectItemInConfiguration = createSelector(
    selectState,
    state => state.itemInConfiguration
);
export const selectItemInConfigurationStatus = createSelector(
    selectState,
    state => state.itemInConfigurationStatus
);

export const selectProfile = createSelector(selectState, state => state.profile);
export const selectProfileCreationStatus = createSelector(
    selectState,
    state => state.profileCreationStatus
);
export const selectProfiles = createSelector(selectState, state => state.profiles);
export const selectProfilesStatus = createSelector(selectState, state => state.profilesStatus);

export const selectProfileRetrievingStatus = createSelector(
    selectState,
    state => state.profileRetrievingStatus
);

export const selectExtension = createSelector(selectState, state => state.extension);
export const selectExtensionCreationStatus = createSelector(
    selectState,
    state => state.extensionCreationStatus
);
export const selectExtensions = createSelector(selectState, state => state.extensions);
export const selectExtensionsStatus = createSelector(selectState, state => state.extensionsStatus);

export const selectUserAgents = createSelector(selectState, state => state.blockedUserAgents);
export const selectUserAgentsStatus = createSelector(
    selectState,
    state => state.blockedUserAgentsStatus
);

export const selectUserAgentUpdateStatus = createSelector(
    selectState,
    state => state.updateUserAgentStatus
);

export const selectUserSessions = createSelector(selectState, state => state.userSession);
export const selectUserSessionStatus = createSelector(
    selectState,
    state => state.userSessionStatus
);

export const selectAssignedOfficerSession =
    createSelector(selectState, state => state.assignedOfficerSession);
export const selectUserLogDetails = createSelector(
    selectState,
    state => state.userLogDetails
);
export const selectuserlogStatus = createSelector(
    selectState,
    state => state.userlogStatus
);

export const selectAssignedOfficerSessionStatus = createSelector(
    selectState,
    state => state.assignedOfficerSessionStatus
);

export const selectAdminFilters = createSelector(
    selectState,
    state => state.adminFilters
);

export const selectDisableAWL = createSelector(
    selectState,
    state => state.clientDisableAWLs
);
export const selectOffDisableAWL = createSelector(
    selectState,
    state => state.officerDisableAWLs
);
export const selectPlatformVersions = createSelector(
    selectState,
    state => state.platformVersion
);
export const selectNonCompliantAgency = createSelector(
    selectState,
    state => state.noncompliantAgency
);
export const selectNonCompliantAgencyStatus = createSelector(
    selectState,
    state => state.noncompliantAgencyStatus
);
export const selectPlatformVersionsStatus = createSelector(
    selectState,
    state => state.platformVersionStatus
);

export const selectCompareVersion = createSelector(
    selectState,
    state => state.compareVersion
);
export const selectCompareVersionStatus = createSelector(
    selectState,
    state => state.compareVersionStatus
);

export const selectExtensiontypes = createSelector(selectState, state => state.extensions);
export const selectExtensiontypesStatus = createSelector(
    selectState,
    state => state.extensionManagementStatus
);
export const selectExtensionUpdateStatus = createSelector(
    selectState,
    status => status.extensionUpdateStatus
);

export const selectProfileUpdateStatus = createSelector(
    selectState,
    status => status.profileUpdateStatus
);
export const selectProfiledeleteStatus = createSelector(
    selectState,
    status => status.profiledeleteStatus
);
export const selectAllProfiles = createSelector(selectState, state => state.allProfiles);
export const selectAllProfilesRetrieveStatus = createSelector(
    selectState,
    state => state.allProfilesRetrieveStatus
);
export const selectAlertWordsList = createSelector(
    selectState,
    state => state.alertWordsList
);
export const selectAgencyAWLConfiguration = createSelector(
    selectState,
    state => state.agencyAWLConfiguration
);
export const selectAWLCreationStatus = createSelector(
    selectState,
    state => state.awlCreationStatus
);
export const selectClientAWLCreationStatus = createSelector(
    selectState,
    state => state.clientAWLCreationStatus
);
export const selectOfficerAWLCreationStatus = createSelector(
    selectState,
    state => state.officerAWLCreationStatus
);
export const selectCustomAWLCreationStatus = createSelector(
    selectState,
    state => state.customAWLCreationStatus
);
export const selectCustomAWLs = createSelector(
    selectState,
    state => state.customAWLs
);
export const selectCustomAWLRetrieveStatus = createSelector(
    selectState,
    state => state.customAWLRetrieveStatus
);
export const selectAgencyAWLConfigurationRetrieveStatus = createSelector(
    selectState,
    state => state.agencyAWLConfigurationRetrieveStatus
);
export const selectAgencyAWLConfigurationSaveStatus = createSelector(
    selectState,
    state => state.agencyAWLConfigurationSaveStatus
);
export const selectSuggestedAWL = createSelector(
    selectState,
    state => state.suggestedAWL
);
export const selectSuggestedAWLNoPageable = createSelector(
    selectState,
    state => state.suggestedAWLNoPageable
);
export const agencyMonitorList = createSelector(
    selectState,
    state => state.agencyMonitorList
);
export const agencyassignedMonitor = createSelector(
    selectState,
    state => state.agencyassignedMonitor
);
export const agencyassignedMonitorStatus = createSelector(
    selectState,
    state => state.agencyassignedMonitorStatus
);
export const selectSuggestedAWLRetrieveStatus = createSelector(
    selectState,
    state => state.suggestedAWLRetrieveStatus
);
export const selectClientAWLs = createSelector(
    selectState,
    state => state.clientAWLs
);
export const selectAWLUpdateStatus = createSelector(
    selectState,
    state => state.awlUpdateStatus
);
export const selectAWLRetrieveStatus = createSelector(
    selectState,
    state => state.awlRetrieveStatus
);
export const selectClientAWLRetrieveStatus = createSelector(
    selectState,
    state => state.clientAWLRetrieveStatus
);
export const selectOfficerAWLs = createSelector(
    selectState,
    state => state.officerAWLs
);

export const selectOfficerAWLRetrieveStatus = createSelector(
    selectState,
    state => state.officerAWLRetrieveStatus
);

export const selectSendActivationCodeStatus = createSelector(
    selectState,
    state => state.sendActivationCodeStatus
);
export const selectDeleteDeviceStatus = createSelector(
    selectState,
    state => state.sendDeleteDeviceStatus
);
export const selectDeleteExtensionStatus = createSelector(
    selectState,
    state => state.sendDeleteExtensionStatus
);
export const selectAdminSettingsMenu = createSelector(
    selectState,
    state => state.adminSettingsMenu
);
export const selectSessionLogsMenu = createSelector(
    selectState,
    state => state.sessionLogsMenu
);
export const selectBackNavigation = createSelector(
    selectState,
    state => state.backNavigation
);
export const selectBackNavigationValues = createSelector(
    selectState,
    state => state.backNavigationValues
);

export const {
    setUserFilters,
    setClientFilters,
    setDeviceFilters,
    setUser,
    clearUser,
    setVersion,
    clearVersion,
    setAgency,
    clearAgency,
    setClient,
    setReport,
    setNotificationConfigurationFilters,
    clearReports,
    clearRemoteComError,
    setReportFilters,
    clearConfigs,
    resetItemInConfigStatus,
    resetAWLCreationStatus,
    resetProfileStatus,
    resetProfileErrorValue,
    resetDeleteProfileStatus,
    resetClientAWLCreationStatus,
    resetOfficerAWLCreationStatus,
    resetCustomAWLCreationStatus,
    resetCustomAWLRetrieveStatus,
    resetAgencyAWLConfigurationRetrieveStatus,
    resetAgencyAWLConfigurationSaveStatus,
    clearAgencyAWLConfiguration,
    resetAllClientsRetrieveStatus,
    resetAWLUpdateStatus,
    resetClientSaveStatus,
    resetExtensionSaveStatus,
    resetExtensionUpdateStatus,
    resetAgencySaveStatus,
    resetAgencyDeleteStatus,
    resetDeviceSaveStatus,
    resetDeviceDeleteStatus,
    resetSendCodeActivationStatus,
    resetSuggestedAWLRetrieveStatus,
    resetClientAWLRetrieveStatus,
    resetAddInstructionStatus,
    resetDeleteInstructionStatus,
    resetOfficerAWLRetrieveStatus,
    resetSaveConfigurationStatus,
    setAdminSettingsMenu,
    setSessionSettingsMenu,
    setNotificationConfiguration,
    clearNotificationConfigurations,
    addNewNotification,
    resetUserFormStatus,
    resetUserAgentstatus,
    resetVersionFormStatus,
    changeAdminFilters,
    clearSearchValue,
    BackNavigation,
    BackNavigationValues,
} = administrationSlice.actions;

export default administrationSlice.reducer;
