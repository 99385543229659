import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import { UsersForm } from 'src/templates/users-form/UsersForm';

export interface OfficerCreationFormModalProps {
    open?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function OfficerCreationFormModal({
    open = true,
    onClose = noop,
}: Readonly<OfficerCreationFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();

    function closeModal(status: boolean):void
    {
        onClose();
    }

    return (
        <Modal
            title={t('form.add-officer')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <UsersForm closeModal={closeModal} />
            </Box>
        </Modal>
    );
}
