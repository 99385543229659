import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { ScreenshotAlertsTable } from 'src/templates/screenshot-alerts-table/ScreenshotAlertsTable';
import {
    ScreenshotmetaAlertsTable,
} from 'src/templates/screenshotmeta-alerts-table/ScreenshotmetaAlertsTable';

export function DataTypeScreenshots(): React.ReactElement
{
    return (
        <DataTypePage>
            <ScreenshotAlertsTable />
        </DataTypePage>
    );
}

export function DataTypeScreenshotsmeta(): React.ReactElement
{
    return (
        <DataTypePage>
            <ScreenshotmetaAlertsTable />
        </DataTypePage>
    );
}
