import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    KEYWORDS,
    SCREENSHOTS,
    PHOTOS,
    TEXTSMS,
    TEXTMMS,
    EXTERNALDEVICES,
    FILESTRACKING,
    SCREENCAPTURES,
    INSTALLEDPROGRAMS,
    CALLHISTORY,
    GEOLOCATIONS,
    LOGONEVENTS,
    EMAILS,
    PRINTEDDOCUMENTS,
    PROCESS,
    WEBSITES,
    SEARCHES,
    SCREENSHOTSMETA,
}
    from 'src/utils/routes';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import {
    changeAlertsFilter,
    changeFilters,
    changeViewDataIdStatus,
    changeViewDataJPEGStatus,
    changescreenshotGridViewFilters,
    resetPhotosData,
    resetaiFlagFilterData,
    resetscreenCapturesData,
    resettextMMSsData,
    selectFilters,
} from 'src/state/captures/capturesSlice';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { Flag, FlagWithAI } from 'src/models/flag.enum';
import { CaptureFilters } from 'src/models/captures/capturedData.model';

export function DataTypeSelect(): React.ReactElement
{
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);

    function handleTabChange(newValue: unknown): void
    {
        dispatch(resetPhotosData());
        dispatch(resettextMMSsData());
        dispatch(resetscreenCapturesData());
        dispatch(
            changeFilters({
                searchValue: '' as string,
            })
        );

            let updatedFilters: CaptureFilters;

            if(newValue as string === TEXTMMS ||
                newValue as string === PHOTOS ||
                newValue as string === SCREENCAPTURES||
                newValue as string === SCREENSHOTS
            )
            {
                updatedFilters = {
                    ...filters,
                    isGridView: false,
                    alertwordsOnly: false,
                    flags: Object.values(FlagWithAI).map((flag) => flag),
                };
            }
            else
            {
                updatedFilters = {
                    ...filters,
                    isGridView: false,
                    alertwordsOnly: false,
                    flags: Object.values(Flag).map((flag) => flag),
                };
            }
            dispatch(changeFilters(updatedFilters));
            dispatch(changescreenshotGridViewFilters(true));
            dispatch(resetaiFlagFilterData('viewData'));
            dispatch(changeViewDataIdStatus());
            dispatch(changeViewDataJPEGStatus());
            navigate(newValue as string);
    }

    const menuItems = [
        { value: KEYWORDS, name: t('data-type.tabs.keystrokes') },
        { value: SCREENSHOTS, name: t('data-type.tabs.Screenshots') },
        // { value: SCREENSHOTSMETA, name: 'SCREENSHOT-COPY' },
        { value: SCREENCAPTURES, name: t('data-type.tabs.ScreenCaptures') },
        { value: PHOTOS, name: t('data-type.tabs.Photos') },
        { value: TEXTMMS, name: t('MMS') },
        { value: TEXTSMS, name: t('data-type.tabs.SMS') },
        { value: EXTERNALDEVICES, name: t('data-type.tabs.ExternalDevices') },
        { value: FILESTRACKING, name: t('data-type.tabs.FilesTracking') },
        { value: INSTALLEDPROGRAMS, name: t('data-type.tabs.InstalledPrograms') },
        { value: GEOLOCATIONS, name: t('data-type.tabs.Geolocation') },
        { value: EMAILS, name: t('data-type.tabs.Emails') },
        { value: LOGONEVENTS, name: t('data-type.tabs.LogonEvents') },
        { value: CALLHISTORY, name: t('data-type.tabs.CallHistory') },
        { value: PRINTEDDOCUMENTS, name: t('data-type.tabs.PrintedDocuments') },
        { value: PROCESS, name: t('data-type.tabs.ExecutionTime') },
        { value: WEBSITES, name: t('data-type.tabs.websites') },
        { value: SEARCHES, name: t('data-type.tabs.searches') },
    ];

    return (
        <DropdownSelector
            options={menuItems}
            fullWidth
            label={t('data-type.filters.data-types')}
            onChange={handleTabChange}
            value={location.pathname}
        />
    );
}
