import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { ProcessAlertsTable }
    from 'src/templates/process-alerts-table/ProcessAlertsTable';

export function DataTypeProcesses(): React.ReactElement
{
    return (
        <DataTypePage>
            <ProcessAlertsTable />
        </DataTypePage>
    );
}
