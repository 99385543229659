import { Report, ReportFile, ReportParams, ReportsListFileResponse } from 'src/models/administration/reportData.model';
import { DataType } from 'src/models/dataType.enum';
import { Frequency } from 'src/models/frequency.enum';
import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { ReportsFormat } from 'src/models/reportsFormat.enum';

export interface APIReportParams extends APIPaginationParams {
    startDate?: string;
    endDate?: string;
}

export interface InActiveValue{
    deviceId: string;
    deviceName:string;
}

export interface APIReportRequest {
    id?: string;
    devices?: string[];
    name?: string;
    frequency?: Frequency;
    startDate?: Date;
    endDate?: Date;
    dataType?: DataType;
    users?: string[];
    comment?: string;
    format?: string;
    isActive?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    inActiveDevices?: InActiveValue[];
    flags?: string[];
    reportFormat?: ReportsFormat;
}

export interface APIReportFileResponse {
    reportDate: Date;
    files: ReportFile[];
}

export interface APIReportResponse extends APIReportRequest {
    id: string;
}

export function transformReportParams(params: ReportParams): APIReportParams
{
    const {
        startDate,
        endDate,
        ...paginatedParams
    } = params;

    return {
        startDate,
        endDate,
        ...transformPaginationParams(paginatedParams),
    };
}

export function readAPIReportResponse(data: APIReportResponse): Report
{
    return {
        id: data.id,
        name: data.name,
        dataType: data.dataType,
        devices: data.devices,
        endDate: data.endDate && new Date(data.endDate?.toLocaleString()),
        startDate: data.startDate && new Date(data.startDate?.toLocaleString()),
        frequency: data.frequency,
        users: data.users,
        isActive: data.isActive,
        format: data.format,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        inActiveDevices: data.inActiveDevices,
        flags: data.flags,
        reportFormat: data.reportFormat,
    };
}

export function transformAPIRequest(data: Report): APIReportRequest
{
    return {
        id: data.id,
        name: data.name,
        dataType: data.dataType,
        devices: data.devices,
        endDate: data.endDate && formatEndDate(data.endDate),
        startDate: data.startDate && formatStartDate(data.startDate),
        frequency: data.frequency,
        users: data.users,
        comment: data.comment,
        isActive: data.isActive,
        format: data.format,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        flags: data.flags,
        reportFormat: data.reportFormat,
    };
}

export function readAPIReportFilesResponse(data: APIReportFileResponse): ReportsListFileResponse
{
    return {
        reportDate: data.reportDate,
        files: data.files.map(file => ({
            fileId: file.fileId,
            fileName: file.fileName,
            fileUrl: file.fileUrl,
            createdAt: file.createdAt,
        })),
    };
}

function formatStartDate(startDate: Date): Date
{
    return new Date(
        Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            0,
            0,
            0,
            0
        )
    );
}

function formatEndDate(endDate: Date): Date
{
    return new Date(
        Date.UTC(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59,
            59,
            999
        )
    );
}
