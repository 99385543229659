import {
    Profile,
    ProfileParams,
    UpdateAgentParamsResponse,
    ConfigurationBot,
} from 'src/models/administration/profileData.model';
import { BlockedUserAgent } from 'src/models/administration/blockedUserAgentData.model';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';


export interface APIProfileParams {
    profileName: string;
    bots: ConfigurationBot[];
    description: string;
}

export interface APIUpdateProfileParams extends APIProfileParams {
    id: string;
}

export interface APIProfileResponse {
    id: string;
    profileName: string;
    bots: ConfigurationBot[];
    description: string;
}

export interface APIUserBlockedAgentResponse {
    id: string;
    platform: string;
    userAgent: string;
    isBlocked: boolean;
}

export interface APIPlatformVersionResponse {
    id: string;
    platform: string;
    version: string;
    downloadUrl: string;
    downgrade:boolean;
    createdAt: Date;
    agencyIds?: Array<{ agency: string; activeDevice: number, platforms: object }>;
}

export function transformProfileParams({ name, bots, description }: ProfileParams): APIProfileParams
{
    return {
        profileName: name,
        bots,
        description,
    };
}

export function transformUpdateProfileParams(
    { id, name, bots, description }: Profile
): APIUpdateProfileParams
{
    return {
        id,
        profileName: name,
        bots,
        description,
    };
}

export function readAPIProfileResponse({
    id,
    profileName,
    bots,
    description,
}: APIProfileResponse): Profile
{
    return {
        id,
        name: profileName,
        bots,
        description,
    };
}

export function readBlockedUserAgentResponse({
    id,
    platform,
    userAgent,
    isBlocked,
}: APIUserBlockedAgentResponse): BlockedUserAgent
{
    return {
        id,
        platform,
        userAgent,
        isBlocked,
    };
}

export function readPlatformVersionResponse({
    id,
    platform,
    version,
    downloadUrl,
    downgrade,
    createdAt,
    agencyIds,
}: APIPlatformVersionResponse): PlatformVersion
{
    return {
        id,
        platform,
        version,
        downloadUrl,
        downgrade,
        createdAt,
        agencyIds,
    };
}

export function readAPIAgentUpdateStatus({
    status,
    message,
}: UpdateAgentParamsResponse): UpdateAgentParamsResponse
{
    return {
        status,
        message,
    };
}
