import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    AlertProps,
    Box,
    Button,
    Grid,
    Snackbar,
    SnackbarProps,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import { ProgramSelector } from '../program-selector/ProgramSelector';
import {
    ConfigurationBlockedApp,
    ConfigurationDetail,
    ConfigurationOwner,
    ServiceConfiguration,
} from 'src/models/administration/configurationData.model';
import { URLSelector } from '../url-selector/URLSelector';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { noop, sortByKey } from 'src/utils/common';
import {
    getPartialConfiguration,
    resetSaveConfigurationStatus,
    selectParentConfiguration,
    selectPartialConfiguration,
    updateConfiguration,
} from 'src/state/administration/administrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    DeviceServiceConfigurator,
} from '../device-service-configurator/DeviceServiceConfigurator';
import { INITIALCONFIGURATION_ID } from 'src/utils/environment';
import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { DataTypeConfig } from 'src/models/dataType.enum';

const rootStyle = {
    width: '100%',
    height: '1.75em',
    fontSize: '2rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '30px',
    color: (theme: Theme) => theme.palette.common.lightGrey,
};

export interface ServiceConfigurationItem extends ServiceConfiguration {
    id?:'62ba59ca3b52833cc0cad2dc'
    label: string
    override: boolean
}
const initialConfiguration: ConfigurationDetail = {
    services: [],
    blockedApps: [],
    blockedUrls: [],
    blockUninstall: true,
    blockAccessSetting: true,
    disableConfig: false,
    isLogCapture: false,
    isUninstallNotification: true,
    permissionScreenAccess: true,
};
export interface ServiceConfiguratorProps {
    configuration: ServiceConfigurationItem
    disableConfig: boolean
    onClose?: () => void
    onChange?: (services: ServiceConfiguration[], isValid: boolean) => void;
}
interface ServiceItemsMap {
    [key: string]: ServiceConfigurationItem
}
function serviceConfigurationToConfigurationItem(
    conf: ServiceConfiguration,
    override: boolean
): ServiceConfigurationItem
{
    return {
        id: INITIALCONFIGURATION_ID,
        app: conf.app,
        label: conf.app,
        captureInterval: conf.captureInterval,
        syncInterval: conf.syncInterval,
        enabled: conf.enabled,
        override,
    };
}
function configurationItemToServiceConfiguration({
    app,
    captureInterval,
    syncInterval,
    enabled,
}: ServiceConfigurationItem): ServiceConfiguration
{
    return {
        app,
        captureInterval,
        syncInterval,
        enabled,
    };
}
function appConfigurerPropsMapToServiceConfiguration(map: ServiceItemsMap): ServiceConfiguration[]
{
    return Object.values(map)
        .filter((configurationItem) => configurationItem.override)
        .reduce<ServiceConfiguration[]>((acc, configurationItem) => ([
            ...acc,
            configurationItemToServiceConfiguration(configurationItem),
        ]), []);
}

function getConfigurerProps(
    ownedConfiguration: ServiceConfiguration[],
    inheritedConfiguration: ServiceConfiguration[]
): ServiceItemsMap
{
    const propsMap = inheritedConfiguration.reduce<ServiceItemsMap>(
        (acc, conf) =>
        {
            return {
                ...acc,
                [conf.app]: serviceConfigurationToConfigurationItem(conf, false),
            };
        },
        {}
    );

    return ownedConfiguration.reduce<ServiceItemsMap>((acc, conf) =>
    {
        return {
            ...acc,
            [conf.app]: serviceConfigurationToConfigurationItem(conf, true),
        };
    }, propsMap);
}

export function DeviceConfigurationManagement({
    configuration: initialConfigurations,
    disableConfig = false,
    onClose = noop,
}: Readonly<ServiceConfiguratorProps>): React.ReactElement
{
    const { t } = useTranslation();
    const [invalidConfs, setInvalidConfs] = useState<string[]>([]);
    const [notificationState, setNotificationState] =
    useState<SnackbarProps & AlertProps>();
    const dispatch = useDispatch();
    const partialConfiguration = useSelector(selectPartialConfiguration);
    const [configurationId, setConfigurationId] = useState<string>('');
    const parentConfiguration = useSelector(selectParentConfiguration);
    const [appConfiguration, setAppConfiguration] =
    useState<ConfigurationDetail>(initialConfiguration);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [changed, setChanged] = useState<boolean>(false);
    const [ownServices, setOwnServices] = useState<ServiceConfiguration[]>([]);
    const [fallbackServices, setFallbackServices] = useState<ServiceConfiguration[]>([]);
    const filteredFallbackServices = fallbackServices
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            return config.app !== DataTypeConfig.WEBSITES
            && config.app !== DataTypeConfig.SEARCHES;
        });
    const filteredOwedServices = ownServices
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            return config.app !== DataTypeConfig.WEBSITES
            && config.app !== DataTypeConfig.SEARCHES;
        });
    const [appConfigurerItemsMap, setAppConfigurerItemsMap] = useState<ServiceItemsMap>(
        getConfigurerProps(filteredOwedServices, filteredFallbackServices)
    );

    useEffect(() =>
    {
        if (
            partialConfiguration &&
            partialConfiguration.id === initialConfigurations.id
        )
        {
            setConfigurationId(initialConfigurations.id);

        }
    }, [initialConfigurations, partialConfiguration]);

    useEffect(() =>
    {
        if (
            partialConfiguration &&
            partialConfiguration.ownerId === undefined
        )
        {
            setOwnServices(partialConfiguration?.services ?? []);
            setFallbackServices(partialConfiguration?.services ?? []);
            setAppConfiguration(partialConfiguration ??partialConfiguration);
        }
        else
        {
            setOwnServices([]);
            setFallbackServices([]);
        }
    }, [partialConfiguration, parentConfiguration]);

    useEffect(() =>
    {
        setAppConfigurerItemsMap(
            getConfigurerProps(filteredOwedServices, filteredFallbackServices)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownServices, fallbackServices]);

    useEffect(() =>
    {
        if (
            partialConfiguration &&
            partialConfiguration.id === initialConfigurations.id
        )
        {
            setConfigurationId(initialConfigurations.id);

        }
    }, [initialConfigurations, partialConfiguration]);

    useEffect(() =>
    {
        if (initialConfigurations.id)
        {
            dispatch(getPartialConfiguration(initialConfigurations.id));
        }
    }, [initialConfigurations, parentConfiguration, dispatch]);

    const sortedConfigs = sortByKey(Object.values(appConfigurerItemsMap), 'app');
    const filteredConfigs = sortedConfigs
        .map((config) =>
        {
            if (config.app === DataTypeConfig.SEARCHES)
            {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                    label: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) =>
        {
            return config.app !== DataTypeConfig.WEBSITES
            && config.app !== DataTypeConfig.SEARCHES;
        });
    function onConfigurationChange(item: ServiceConfigurationItem): void
    {
        const { app, override, captureInterval, syncInterval } = item;
        let newAppItemsMap = { ...appConfigurerItemsMap };
        if (override)
        {
            newAppItemsMap = {
                ...newAppItemsMap,
                [app]: item,
            };
        }
        else
        {
            const fallbackFound = filteredFallbackServices.find((service) => service.app === app);
            if (fallbackFound)
            {
                newAppItemsMap = {
                    ...newAppItemsMap,
                    [app]: serviceConfigurationToConfigurationItem(fallbackFound, false),
                };
            }
        }
        setAppConfigurerItemsMap(newAppItemsMap);
        if ((captureInterval && syncInterval) || !override)
        {
            setInvalidConfs((configs) => configs.filter((config) => config !== app));
        }
        else
        {
            setInvalidConfs((configs) => [...configs, app]);
        }
        const services = appConfigurerPropsMapToServiceConfiguration(newAppItemsMap);
        const isValid = invalidConfs.length === 0;
        onServicesChange(services, isValid);
    }
    function onServicesChange(services: ServiceConfiguration[], isValid: boolean): void
    {
        setAppConfiguration((current) => ({
            ...current,
            services,
        }));
        setIsValid(isValid);

        setChanged(true);
    }

    function onProgramSelectionChange(programs: ConfigurationBlockedApp[]): void
    {
        setAppConfiguration((current) => ({
            ...current,
            blockedApps: programs,
        }));

        setChanged(true);
    }

    function onURLSelectionChange(urls: string[]): void
    {
        setAppConfiguration((current) => ({
            ...current,
            blockedUrls: urls,
        }));
        setChanged(true);
    }

    function onToggleChange(fieldname: keyof ConfigurationDetail):
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    {
        return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
        {
            const newConf = { ...appConfiguration, [fieldname]: checked };
            setAppConfiguration(newConf);
            setChanged(true);
        };
    }
    const onCloseNotification = ():void =>
    {
        setNotificationState({ ...notificationState, open: false });
        dispatch(resetSaveConfigurationStatus());
    };
    function onSaveHandler(): void
    {
        onSave(appConfiguration);
        setNotificationState({
            open: true,
            severity: 'success',
            message: 'Device configuration saved successfully',
        });
        setTimeout(onCloseNotification, 6000);
    }
    function onSave(configurationDetail: ConfigurationDetail): void
    {
        if (configurationId)
        {
            partialConfiguration && dispatch(
                updateConfiguration({
                    ...partialConfiguration,
                    ...configurationDetail,
                })
            );
        }
    }
    return (
        <Box>
            <Grid container rowSpacing={5} direction="column">
                <Grid item md={12}>
                    <Grid container rowSpacing={1} columnSpacing={'5%'}>
                        {filteredConfigs.map(
                            (
                                selectedConfiguration: ServiceConfigurationItem
                            ) => (
                                <React.Fragment
                                    key={selectedConfiguration.label}
                                >
                                    <Stack
                                        direction="row"
                                        sx={rootStyle}
                                    >
                                        <Grid
                                            container
                                            rowSpacing={1}
                                            columnSpacing={'1%'}
                                        >
                                            <DeviceServiceConfigurator
                                                selectedConfiguration={selectedConfiguration}
                                                onChange={onConfigurationChange}
                                                disableConfig={disableConfig}
                                            />
                                        </Grid>
                                    </Stack>
                                </React.Fragment>
                            )
                        )}
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container rowSpacing={5} columnSpacing={'5%'}>
                        <Grid item xs={12} lg={6}>
                            <ProgramSelector
                                deviceId=""
                                agencyId=""
                                clientId=""
                                officerId=""
                                ownBlockedApps={appConfiguration.blockedApps}
                                isConfigurationExists={true}
                                onChange={onProgramSelectionChange}
                                context={ConfigurationOwner.Device}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <URLSelector
                                ownBlockedUrls={appConfiguration.blockedUrls}
                                isConfigurationExists={true}
                                onChange={onURLSelectionChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.lightGrey,
                                    fontSize: '1.25rem',
                                }}
                            >
                                {t('settings.block-uninstall')}
                            </Typography>
                            <ToggleSwitch
                                sx={{
                                    m: 1,
                                }}
                                checked={appConfiguration.blockUninstall}
                                onChange={onToggleChange('blockUninstall')}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.lightGrey,
                                    fontSize: '1.25rem',
                                }}
                            >
                                {t('settings.block-access-settings')}
                            </Typography>
                            <ToggleSwitch
                                sx={{ m: 1 }}
                                checked={
                                    appConfiguration.blockAccessSetting
                                }
                                onChange={onToggleChange(
                                    'blockAccessSetting'
                                )}
                                disabled={false}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}

                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) => theme.palette.common.lightGrey,
                                    fontSize: '1.25rem',
                                }}
                            >
                                {t('devices.disabled-configuration')}
                            </Typography>
                            <ToggleSwitch
                                sx={{ m: 1 }}
                                disabled={false}
                                checked={
                                    appConfiguration.disableConfig
                                }
                                onChange={onToggleChange(
                                    'disableConfig'
                                )}
                            />

                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}

                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.common.lightGrey,
                                    fontSize: '1.25rem',
                                }}
                            >
                                {t('settings.permission-screen')}
                            </Typography>
                            <ToggleSwitch
                                sx={{ m: 1 }}
                                checked={
                                    appConfiguration.permissionScreenAccess
                                }
                                onChange={onToggleChange(
                                    'permissionScreenAccess'
                                )}
                                disabled={false}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap="1em"
                sx={{ padding: '1em' }}
            >
                <>
                    <Button
                        variant="contained"
                        disabled={!(changed && isValid) ||
                            (
                                userIsInRole([Role.Officer, Role.OfficerSupervisor]))}
                        onClick={onSaveHandler}
                    >

                        {t('common.actions.save')}
                    </Button>
                    <Snackbar
                        autoHideDuration={6000}
                        open={notificationState?.open}
                        onClose={onCloseNotification}
                    >
                        <Alert severity={notificationState?.severity}>
                            {notificationState?.message}
                        </Alert>
                    </Snackbar>
                </>

            </Stack>
        </Box>
    );
}


