import {
    Activation,
    SendActivationCodeParams,
} from 'src/models/administration/activationData.model';
import {
    readSendActivationResponse,
    transformSendActivationParams,
} from '../data-transfer-objects/administration/activation';
import instance from '../instance';

export async function sendActivationCode(params: SendActivationCodeParams): Promise<Activation>
{
    const response = await instance.post(
        'device/request-activation',
        transformSendActivationParams(params)
    );

    return readSendActivationResponse(response.data);
}
