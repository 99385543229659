import { CaptureParams } from 'src/models/captures/capturedData.model';
import { GeolocationData } from 'src/models/captures/geolocationData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { APIGeolocationResponse, readAPIGeolocationResponse }
    from '../data-transfer-objects/capture/geolocation';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import instance from '../instance';

export async function getGeolocations(
    captureParams: CaptureParams
): Promise<PaginatedContent<GeolocationData>>
{
    const response = await instance.get('geolocation', {
        params: transformCaptureParams(captureParams),
    });
    return readAPIPaginatedResponse(response.data, readAPIGeolocationResponse);
}

export async function getGeolocationslistDevice(
    captureParams: CaptureParams
): Promise<PaginatedContent<GeolocationData>>
{
    const response = await instance.get('geolocation/list-device', {
        params: transformCaptureParams(captureParams),
    });

    return readAPIPaginatedResponse(response.data, readAPIGeolocationResponse);
}

export async function getGeolocationsByDate(params: {
    date: Date,
    deviceId: string
}): Promise<GeolocationData[]>
{
    const response = await instance.get(
        'geolocation/by-date',
        { params }
    );
    return response.data.map((geolocation: APIGeolocationResponse) =>
        readAPIGeolocationResponse(geolocation));
}
