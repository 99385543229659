import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { PrintedDocumentAlertsTable }
    from 'src/templates/printed-document-alerts-table/PrintedDocumentAlertsTable';

export function DataTypePrintedDocuments(): React.ReactElement
{
    return (
        <DataTypePage>
            <PrintedDocumentAlertsTable />
        </DataTypePage>
    );
}
