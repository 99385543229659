import instance from '../instance';

export async function downloadFile(
    fileUrl: string
)
: Promise<Blob>
{
    const response = await instance.get(
        fileUrl + '/download',
        { responseType: 'arraybuffer' }
    );

    const blob = new Blob([response.data], { type: 'blob' });
    return blob;
}
