import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SMSType, TextSMSData } from 'src/models/captures/textSMSData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getTextSMSs,
    selectFilters,
    changeTextSMS,
    selectTextSMS,
    selectTextSMSs,
    selectTextSMSsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import Chip from '@mui/material/Chip';
import { TextSMSDetails } from './TextSMSDetails';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';


export function TextSMSAlertsTable(): React.ReactElement
{

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const textSMSsStatus = useSelector(selectTextSMSsStatus);
    const textSMSsData = useSelector(selectTextSMSs);
    const textSMSData = useSelector(selectTextSMS);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [open, setOpen] = React.useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage]=useState(0);
    const prevPage = useRef(1);
    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (data: TextSMSData): void =>
    {
        openRecordData(data.id);

        dispatch(changeTextSMS(data));
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({ detailsId: data.id, dataType: DataType.SMS }));
        }
        setOpen(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (textSMSsData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(textSMSsData?.contents[textSMSsData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }


    useEffect(() =>
    {
        if(filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if(filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getTextSMSs({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: TextSMSData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: TextSMSData) => timeZoneFormat(
                new Date(data.date),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: TextSMSData) => data.device,
        },
        {
            label: t('data-type.table.contact-name'),
            text: (data: TextSMSData) => data.contactName,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: TextSMSData) => data.username,
        },
        {
            label: t('data-type.table.text-message-type'),
            text: (data: TextSMSData) => data.smsType.toString(),
        },
        {
            label: t('data-type.table.device-number'),
            text: (data: TextSMSData) => data.deviceNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.external-number'),
            text: (data: TextSMSData) => data.externalNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.sender'),
            text: (data: TextSMSData) =>
            {
                return data.smsType === SMSType.INCOMING
                    ? t('data-type.table.received')
                    : t('data-type.table.sender');
            },
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: TextSMSData) => data.alertWords?.join(', '),
        },
    ];

    const getAiScanStatusColumn = (data: TextSMSData): React.ReactNode =>
    {
        if(data.datatype === 'MMS')
        {
            if (data.aiScanStatus === 2) return <ScoutRed />;
            else if (data.aiScanStatus === 0)
                return (
                    <HourglassEmptyOutlinedIcon
                        sx={{ fill: 'lightGrey' }}
                    />
                );
        }
        else return '';
    };
    const getFlagColumn = (data: TextSMSData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.SMS}
            onChange={()=>
            {
                onPageChange(textSMSsData?.currentPage ?? 1);
            }}
        />
    );
    const getSenderColumn = (data: TextSMSData): React.ReactNode => (
        data.smsType === SMSType.INCOMING ? (
            <Chip
                label={t('data-type.table.received')}
                color="error"
                size="small"
            />
        ) : (
            <Chip
                label={t('data-type.table.sender')}
                color="success"
                size="small"
            />
        )
    );
    const getMessageColumn = (data: TextSMSData): React.ReactNode => (
        <HighlightText
            textToHighlight={data?.message || ''}
            highlightWords={data?.alertWords || ['']}
        />
    );
    const getImageColumn = (data: TextSMSData): React.ReactNode => (
        <img height="50" alt="" src={data.fileUrl} />
    );
    const openDetailColumn = (data: TextSMSData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: TextSMSData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: TextSMSData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: TextSMSData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: TextSMSData) =>
                data.date ? new Date(data.date).toLocaleString() : '',
            sortByType: 'date',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: TextSMSData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 2,
            value: (data: TextSMSData) => data.device,
            sortByType: 'device',
        },

        {
            label: t('data-type.table.device-number'),
            size: 2,
            value: (data: TextSMSData) => data.deviceNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.external-number'),
            size: 2,
            value: (data: TextSMSData) => data.externalNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.contact-name'),
            size: 2,
            value: (data: TextSMSData) => data.contactName,
        },
        {
            label: t('data-type.table.sender'),
            size: 1,
            align: 'center' as const,
            value: (data: TextSMSData) =>
            {
                return data.smsType === SMSType.INCOMING
                    ? t('data-type.table.received')
                    : t('data-type.table.sender');
            },
            format: (data: TextSMSData) => getSenderColumn(data),
        },
        {
            label: t('data-type.table.message'),
            size: 4,
            value: (data: TextSMSData) => data?.message,
            sortByType: 'value',
            format: (data: TextSMSData) => getMessageColumn(data),
        },
        {
            label: t('data-type.table.image'),
            size: 2,
            align: 'center' as const,
            format: (data: TextSMSData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: TextSMSData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: TextSMSData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (textSMSsData)
        {
            setLastPage(Math.floor(textSMSsData.numberOfEntries/entriesPerPage));
            pdfExportSetter({
                type: DataType.SMS,
                recordIds: textSMSsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(textSMSsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.SMS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSMSsData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<TextSMSData>
                title={t('data-type.details.text-sms-title')}
                open={open}
                pdfEnabled={false}
                data={textSMSData}
                detailItems={detailItems}
                onClose={() => setOpen(false)}
                comments={{
                    contentId: textSMSData?.id,
                    type: CommentType.SMS,
                }}
            >
                <TextSMSDetails {...(textSMSData as TextSMSData)} />
            </DataDetailsModal>
            {useDesktopScreen() ? (
                <DataTable<TextSMSData>
                    isLoading={textSMSsStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...textSMSsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={textSMSsStatus === RequestStatus.InProgress}
                    contents={textSMSsData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={textSMSsData?.numberOfEntries}
                    currentPage={textSMSsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
