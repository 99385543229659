import {
    KeystrokeData,
    KeystrokeDetailsData,
    KeystrokeMetadata,
} from 'src/models/captures/keystrokeData.model';
import { readAPICaptureData, APICaptureData } from './captures';

export interface APIKeystrokeMetadata
{
    keyValue: string;
    keyName: string;
    shift: boolean;
    control: boolean;
    meta: boolean;
    alt: boolean;
}

export interface APIKeystrokeResponse extends APICaptureData
{
    value: string;
    alertWords: string[];
    appOnFocus: string;
    dataCollected:string;
    isSeen:boolean;
}

export interface APIKeystrokeDetailsResponse extends APIKeystrokeResponse
{
    keystrokes: APIKeystrokeMetadata[];
}

export function readAPIKeystrokeResponse(
    data: APIKeystrokeResponse
): KeystrokeData
{
    const { value, alertWords, appOnFocus, dataCollected, isSeen, ...captureData } = data;
    return {
        ...readAPICaptureData(captureData),
        value,
        alertWords,
        appOnFocus,
        dataCollected,
        isSeen,
    };
}

export function readAPIKeystrokeMetadata(
    data: APIKeystrokeMetadata
): KeystrokeMetadata
{
    return data;
}

export function readAPIKeystrokeDetailsResponse(
    data: APIKeystrokeDetailsResponse
): KeystrokeDetailsData
{
    const { keystrokes, ...keystrokeData } = data;
    return {
        ...readAPIKeystrokeResponse(keystrokeData),
        keystrokes: keystrokes.map(readAPIKeystrokeMetadata),
    };
}
