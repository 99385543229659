import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    TextField,
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { noop } from 'src/utils/common';
import DownloadIcon from '@mui/icons-material/Download';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import { DataTable } from 'src/components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActiveData,
    selectActiveDataStatus,
} from 'src/state/captures/capturesSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
    getClientActiveDevice,
    selectClientActiveDevices,
} from 'src/state/administration/administrationSlice';
import { User } from 'src/models/userModels';
import { Device } from 'src/models/administration/deviceData.model';
import { DataType } from 'src/models/dataType.enum';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { USER_TIME_ZONE } from 'src/utils/environment';

interface ConfirmationPanelProps {
    // eslint-disable-next-line
    data: any,
    DeleteModal?: () => void;
    DeleteSubmit?: (data?: string) => void;
    type: string;
}

function ClientDetailsModel({
    data,
    DeleteModal = noop,
    DeleteSubmit = noop,
    type,
}: Readonly<ConfirmationPanelProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [deleteText, setDeleteText] = useState<string>();
    const [deviceClicked, setDeviceClicked] = useState(false);
    const activedata = useSelector(selectActiveData);
    const activedataStatus = useSelector(selectActiveDataStatus);
    // eslint-disable-next-line

    const [deviceEntriesPerPage, setDeviceEntriesPerPage] = useState(10);
    const activeDevices = useSelector(selectClientActiveDevices);

    const [activeDevicesCount, setActiveDevicesCount] = useState(0);
    const [inActiveDevicesCount, setInActiveDevicesCount] = useState(0);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    function onDevicePageChange(pageNumber: number): void
    {
        dispatch(getClientActiveDevice({
            clientId: data.id,
            pageNumber,
            entriesPerPage: deviceEntriesPerPage,
        }));
    }
    const handleDeviceEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setDeviceEntriesPerPage(newEntriesPerPage);
        dispatch(getClientActiveDevice({
            clientId: data.id,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
        }));
    };

    useEffect(() =>
    {
        dispatch(getClientActiveDevice({
            clientId: data.id,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() =>
    {
        setActiveDevicesCount(activeDevices?.totalActive ? activeDevices.totalActive : 0);
        setInActiveDevicesCount(activeDevices?.totalInActive ? activeDevices.totalInActive : 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDevices]);

    const handleCardClick = (cardType: string): void =>
    {
        if (cardType === 'device')
        {
            setDeviceClicked(true);
        }
        else
        {
            setDeviceClicked(false);
        }
    };

    const officerAndDeviceStatus = (data: User | Device): JSX.Element =>
    {
        const textStatus: string = data.activated
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: theme => (data.activated
                        ? theme.palette.success.main
                        : theme.palette.error.main),
                }}
            >
                {textStatus}
            </Box>
        );
    };

    const devicecolumns = [
        {
            label: t('common.nouns.id'),
            size: 5,
            value: (data: Device) => data.id,
        },
        {
            label: t('data-type.filters.client'),
            size: 4,
            value: (data: Device) => data.clientName,
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: Device) => data.alias,
        },
        {
            label: t('data-type.table.device-type'),
            size: 4,
            value: (data: Device) => data.platform,
        },
        {
            label: t('common.nouns.status'),
            size: 2,
            value: (data: Device) =>
            {
                if (!data.enabled)
                {
                    return t('common.status.disabled');
                }
                if (!data.activated)
                {
                    return t('common.status.inactive');
                }
                return t('common.status.active');
            },
            format: (data: Device) => officerAndDeviceStatus(data),
        },
        {
            label: t('data-type.table.last-connect-date'),
            size: 3,
            dateFormat: (device: Device) =>
            {
                if (device.lastConnectAt)
                {
                    return timeZoneFormat(new Date(device.lastConnectAt), USER_TIME_ZONE);
                }
                else
                {
                    return ('-');
                }
            },
        },
    ];

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        const clientIds = data.id;
        if (tableExportFormat === TableExportFormat.PDF)
        {
            await TableExporter.asPDF({
                type: DataType.CLIENT_DEVICES,
                recordIds: [clientIds],
            });
        }
        setFormat(undefined);
    }

    return (
        <>
            <span style={{ maxWidth: '10px' }}>
                <Alert severity="warning" style={{ marginBottom: '10px' }}>
                    {t('reports.delete-client-alert')}
                </Alert>
            </span>
            {deviceClicked && (
                <Button
                    variant="text"
                    sx={{
                        marginLeft: '86%',
                        '@media (max-width: 768px)': {
                            marginLeft: '80%',
                        },
                    }}
                    startIcon={
                        TableExporter.loading &&
                            format === TableExportFormat.PDF
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    onClick={() =>
                        handlerPDFExport(TableExportFormat.PDF)
                    }
                >
                    {t('common.button.all-to-pdf')}
                </Button>
            )}
            {!deviceClicked && (
                <>
                    <section
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginRight: '10px',
                            marginTop: '-2%',
                        }}
                    >
                        <Card
                            sx={{
                                width: 250,
                                height: 130,
                                mt: 4,
                                boxShadow: 5,
                                mb: 2,
                                position: 'relative',
                                color: '#9e9e9e',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleCardClick('device')}

                        >
                            <CardContent>
                                <Typography variant="h4" component="div">
                                    {t('data-type.details.devices')}
                                </Typography>
                                <Typography component="div" style={{ fontSize: '0.9rem' }}>
                                    {t('data-type.details.active-inactive')}
                                </Typography>
                                <Typography variant="h2" >
                                    {(activeDevicesCount)}
                                    /
                                    {(inActiveDevicesCount)}
                                </Typography>
                                <Box >
                                    <PhonelinkOutlinedIcon
                                        style={{
                                            position: 'absolute',
                                            top: 3,
                                            right: 4,
                                            fontSize: '3rem',
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </section>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        style={{ marginTop: '70px' }}
                    >
                        <WarningIcon fontSize="small" />
                        <span style={{ position: 'relative', marginLeft: '5px' }}>
                            {t('clients.delete-modal.type-the')}
                            {' '}
                            {type}
                            {' '}
                            name
                            {' '}
                            <b>
                                {data.firstName + ' ' + data.lastName}
                            </b>
                            {' '}
                            to delete
                        </span>

                        <TextField
                            fullWidth
                            autoComplete="off"
                            value={deleteText}
                            onChange={(e) => setDeleteText(e.target.value)}
                            required
                            autoFocus
                        />
                    </Grid>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap={1}
                        padding="1rem 0"
                    >
                        <Button
                            type="button"
                            variant="contained"
                            onClick={DeleteModal}
                        >
                            {t('common.actions.cancel')}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            disabled={data.firstName +' '+ data.lastName !== deleteText}
                            color="error"
                            onClick={() => DeleteSubmit(deleteText)}
                        >
                            {t('common.actions.delete')}
                        </Button>
                    </Stack>
                </>
            )}
            {deviceClicked && (
                <Button
                    type="button"
                    variant="contained"
                    sx={{ marginBottom: '1%' }}
                    onClick={() =>
                    {
                        setDeviceClicked(false);
                    }}
                >
                    <KeyboardDoubleArrowLeftIcon />
                    {t('common.actions.back')}
                </Button>
            )}
            {deviceClicked ? (
                <DataTable<Device>
                    isLoading={activedataStatus === RequestStatus.InProgress}
                    columns={devicecolumns}
                    onPageChange={onDevicePageChange}
                    entriesPerPage={deviceEntriesPerPage}
                    onChangeEntriesPerPage={handleDeviceEntriesPerPageChange}
                    datas={activedata}
                    {...activeDevices}
                />
            ) : null}
        </>
    );
}


export default ClientDetailsModel;
