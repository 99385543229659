import { SCREENSHOTS } from 'src/models/alertsType.enum';
import { FlagWithAI } from 'src/models/flag.enum';

// eslint-disable-next-line
export const checkFilterChange = (currentFilter: any, filter: any, datatype?: string): boolean =>
{
    const mismatchedKeys = [];
    const oddKeys = ['rowsPerPage', datatype === SCREENSHOTS ? 'isGridView' : null];

    for (const key in currentFilter)
    {
        if (!oddKeys.includes(key) && currentFilter[key] !== filter[key])
        {
            mismatchedKeys.push(key);
        }
    }

    return mismatchedKeys.length === 0;
};

// eslint-disable-next-line
export const isFilterApplied = (filter: any): boolean => {
    let status = false;
    const { searchValue, flags } = filter;

    if (searchValue || flags.length !== Object.values(FlagWithAI).length)
    {
        status = true;
    }

    return status;
};
