import React, { FocusEvent, useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    SnackbarProps,
    Stack,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileParams, ConfigurationBot } from 'src/models/administration/profileData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    createProfile,
    resetProfileStatus,
    selectProfileCreationStatus,
    selectProfileError,
} from 'src/state/administration/administrationSlice';
import { BotSelector } from 'src/templates/bot-selector/BotSelector';
import { noop } from 'src/utils/common';
import { handleError, handleErrorText } from 'src/utils/errorUtils';

const initialValues: ProfileParams = {
    name: '',
    bots: [],
    description: '',
};

export interface ProfileManagementModalProps {
    onCloseModal?: () => void;
}

export function NewProfile({
    onCloseModal = noop,
}: Readonly<ProfileManagementModalProps>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const creationStatus = useSelector(selectProfileCreationStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [formValues, setFormValues] = useState<ProfileParams>(initialValues);
    const [saveProfile,setSaveProfile]=useState(false);
    const profileErrorValue = useSelector(selectProfileError);
    function onSubmit(event: React.FormEvent): void
    {
        event.preventDefault();
        dispatch(createProfile(formValues));
        setSaveProfile(true);

    }

    function updateValues(
        { name, value }: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
        trim = false
    ): void
    {
        const finalValue = trim ? value.trim() : value;
        setFormValues((current) => ({
            ...current,
            [name]: finalValue,
        }));
    }

    function updateBots(newbots : ConfigurationBot[]): void
    {
        setFormValues((current) => ({
            ...current,
            bots: newbots,
        }));
    }

    function onInputChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void
    {
        updateValues(event.target);

    }

    function onBlur(event: FocusEvent<HTMLInputElement>): void
    {
        updateValues(event.target, true);
    }

    function onClose(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    useEffect(() =>
    {
        let message = '';
        if ((creationStatus === RequestStatus.Success) && (saveProfile===true))
        {
            const profileString = t('profiles.profile');
            message = t('common.messages.saved-successfully', { subject: profileString });
            setFormValues(initialValues);
        }
        else if ((creationStatus === RequestStatus.Failure)&&(formValues.name))
        {
            message = t('common.messages.profile-already-exist');
        }

        if (message)
        {
            setNotificationState({ open: true, message });
            if(creationStatus === RequestStatus.Success){
                setTimeout(() =>
                    {
                        if(!profileErrorValue){
                        onCloseModal();
                        }
                    }, 3000);
            }
          
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creationStatus, setNotificationState, t, dispatch]);

    return (
        <>
            <Box>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={5}>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                name="name"
                                label={t('common.nouns.name')}
                                variant="outlined"
                                value={formValues.name}
                                onChange={onInputChange}
                                onBlur={onBlur}
                                disabled={creationStatus === RequestStatus.InProgress}
                                fullWidth
                                error={handleError(profileErrorValue, 'profileName',formValues.name)}
                                helperText={handleErrorText(profileErrorValue, 'profileName',formValues.name)}
                              

                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <BotSelector
                                onInputChange={updateBots}
                                bots={formValues.bots}
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                name="description"
                                label={t('common.nouns.description')}
                                variant="outlined"
                                value={formValues.description}
                                onChange={onInputChange}
                                disabled={creationStatus === RequestStatus.InProgress}
                                multiline
                                rows={4}
                                fullWidth
                                error={handleError(profileErrorValue, 'description')}
                                helperText={handleErrorText(profileErrorValue, 'description')}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap="1em"
                        sx={{ padding: '1em' }}
                    >
                        {
                            creationStatus === RequestStatus.InProgress
                                ? <CircularProgress />
                                :(
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        sx={{ mt: 0 }}
                                    >
                                        <Button
                                            sx={{
                                                mr: 1,
                                                backgroundColor: 'transparent',
                                                color: theme => theme.palette.common.goldYellow,
                                                '&.Mui-disabled': {
                                                    backgroundColor: 'inherit',
                                                    // eslint-disable-next-line
                                                    color: theme => theme.palette.common.goldYellow,
                                                },
                                            }}
                                            onClick={ ()=> onCloseModal() }
                                        >
                                            {t('form.cancel')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"

                                        >
                                            {t('common.actions.save')}
                                        </Button>
                                    </Box>
                                )
                        }
                    </Stack>
                </form>
            </Box>
            <Snackbar
                open={notificationState?.open}
                autoHideDuration={6000}
                onClose={onClose}
            >
                <Alert
                    severity={creationStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
