import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import {
    ConfigurationBlockedApp,
    ConfigurationDetail,
    ConfigurationOwner,
    ServiceConfiguration,
} from 'src/models/administration/configurationData.model';
import { noop } from 'src/utils/common';
import {
    ServiceConfigurationPanel,
} from '../service-configuration-panel/ServiceConfigurationPanel';
import { ProgramSelector } from '../program-selector/ProgramSelector';
import { URLSelector } from '../url-selector/URLSelector';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';


import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { Platform } from 'src/models/platforms.enum';

export interface ConfigurationPanelProps {
    context?: ConfigurationOwner;
    platform?: Platform;
    ownerId: string;
    configuration: ConfigurationDetail;
    fallbackConfiguration: ConfigurationDetail;
    onSave?: (value: ConfigurationDetail) => void;
    onCancel?: () => void;
    isLoading?: boolean;
    disableConfig: boolean;
    isLogCapture: boolean;
    isUninstallNotification?: boolean;
}

export function ConfigurationPanel({
    context,
    platform,
    ownerId,
    configuration: initialConfiguration,
    fallbackConfiguration,
    onSave = noop,
    onCancel = noop,
    isLoading = false,
    disableConfig = false,
    isLogCapture = false,
    isUninstallNotification = false,
}: Readonly<ConfigurationPanelProps>): JSX.Element
{
    const { t } = useTranslation();
    const [
        appConfiguration,
        setAppConfiguration,
    ] = useState<ConfigurationDetail>(initialConfiguration);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [changed, setChanged] = useState<boolean>(false);

    function onSaveHandler(): void
    {
        onSave(appConfiguration);
    }

    function onServicesChange(
        services: ServiceConfiguration[],
        isValid: boolean
    ): void
    {
        setAppConfiguration((current) => ({
            ...current,
            services,
        }));
        setIsValid(isValid);

        setChanged(true);
    }

    function onProgramSelectionChange(programs: ConfigurationBlockedApp[])
        : void
    {
        setAppConfiguration((current) => ({
            ...current,
            blockedApps: programs,
        }));

        setChanged(true);
    }

    function onURLSelectionChange(urls: string[]): void
    {
        setAppConfiguration((current) => ({
            ...current,
            blockedUrls: urls,
        }));

        setChanged(true);
    }

    function onToggleChange(fieldname: keyof ConfigurationDetail):
        (event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean) => void
    {
        return (
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
        ): void =>
        {
            const newConf = { ...appConfiguration, [fieldname]: checked };
            setAppConfiguration(newConf);
            setChanged(true);
        };
    }

    const isSaveButtonDisabled =!(changed && isValid) ||
    (appConfiguration.disableConfig &&
        userIsInRole([Role.Officer, Role.OfficerSupervisor]));

    useEffect(() =>
    {
        setAppConfiguration(() =>
        {
            let newConfig = { ...initialConfiguration };
            if (!initialConfiguration.id)
            {
                newConfig = {
                    ...newConfig,
                    blockedApps: fallbackConfiguration.blockedApps,
                    blockedUrls: fallbackConfiguration.blockedUrls,
                    blockUninstall: fallbackConfiguration.blockUninstall,
                    blockAccessSetting: fallbackConfiguration.blockAccessSetting,
                    disableConfig: fallbackConfiguration.disableConfig,
                };
            }
            newConfig = {
                ...newConfig,
                permissionScreenAccess: appConfiguration?.permissionScreenAccess ?? 
                fallbackConfiguration?.permissionScreenAccess
            };
            return newConfig;
        });
        setChanged(false);
    }, [initialConfiguration]);

    return (
        <Box>
            <Grid container rowSpacing={5} direction="column">
                <Grid item md={12}>
                    <ServiceConfigurationPanel
                        context={context}
                        platform={platform}
                        ownServices={appConfiguration.services}
                        fallbackServices={fallbackConfiguration.services}
                        onChange={onServicesChange}
                        disableConfig={appConfiguration.disableConfig}
                    />
                </Grid>
                {context === ConfigurationOwner.Device ? (
                    <Grid item md={12}>
                        <Grid container rowSpacing={5} columnSpacing={'5%'}>
                            <Grid item xs={12} lg={6}>
                                <ProgramSelector
                                    deviceId={ownerId}
                                    agencyId=""
                                    clientId=""
                                    officerId=""
                                    ownBlockedApps={appConfiguration.blockedApps}
                                    fallbackBlockedApps={fallbackConfiguration.blockedApps}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onProgramSelectionChange}
                                    context={ConfigurationOwner.Device}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <URLSelector
                                    ownBlockedUrls={appConfiguration.blockedUrls}
                                    fallbackBlockedUrls={fallbackConfiguration.blockedUrls}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onURLSelectionChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-uninstall')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={appConfiguration.blockUninstall}
                                    onChange={onToggleChange('blockUninstall')}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-access-settings')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                        appConfiguration.blockAccessSetting
                                    }
                                    onChange={onToggleChange(
                                        'blockAccessSetting'
                                    )}
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {context === ConfigurationOwner.Agency ? (
                    <Grid item md={12}>
                        <Grid container rowSpacing={5} columnSpacing={'5%'}>
                            <Grid item xs={12} lg={6}>
                                <ProgramSelector
                                    deviceId=""
                                    clientId=""
                                    officerId=""
                                    agencyId={ownerId}
                                    ownBlockedApps={appConfiguration.blockedApps}
                                    fallbackBlockedApps={fallbackConfiguration.blockedApps}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onProgramSelectionChange}
                                    context={ConfigurationOwner.Agency}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <URLSelector
                                    ownBlockedUrls={appConfiguration.blockedUrls}
                                    fallbackBlockedUrls={fallbackConfiguration.blockedUrls}
                                    onChange={onURLSelectionChange}
                                    isConfigurationExists={!!initialConfiguration.id}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-uninstall')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={appConfiguration.blockUninstall}
                                    onChange={onToggleChange('blockUninstall')}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-access-settings')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                        appConfiguration.blockAccessSetting
                                    }
                                    onChange={onToggleChange(
                                        'blockAccessSetting'
                                    )}
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {context === ConfigurationOwner.Officer ? (
                    <Grid item md={12}>
                        <Grid container rowSpacing={5} columnSpacing={'5%'}>
                            <Grid item xs={12} lg={6}>
                                <ProgramSelector
                                    deviceId=""
                                    agencyId=""
                                    clientId=""
                                    officerId={ownerId}
                                    ownBlockedApps={appConfiguration.blockedApps}
                                    fallbackBlockedApps={fallbackConfiguration.blockedApps}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onProgramSelectionChange}
                                    context={ConfigurationOwner.Officer}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <URLSelector
                                    ownBlockedUrls={appConfiguration.blockedUrls}
                                    fallbackBlockedUrls={fallbackConfiguration.blockedUrls}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onURLSelectionChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-uninstall')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={appConfiguration.blockUninstall}
                                    onChange={onToggleChange('blockUninstall')}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-access-settings')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                        appConfiguration.blockAccessSetting
                                    }
                                    onChange={onToggleChange(
                                        'blockAccessSetting'
                                    )}
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {context === ConfigurationOwner.Client ? (
                    <Grid item md={12}>
                        <Grid container rowSpacing={5} columnSpacing={'5%'}>
                            <Grid item xs={12} lg={6}>
                                <ProgramSelector
                                    deviceId=""
                                    agencyId=""
                                    officerId=""
                                    clientId={ownerId}
                                    ownBlockedApps={appConfiguration.blockedApps}
                                    fallbackBlockedApps={fallbackConfiguration.blockedApps}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onProgramSelectionChange}
                                    context={ConfigurationOwner.Client}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <URLSelector
                                    ownBlockedUrls={appConfiguration.blockedUrls}
                                    fallbackBlockedUrls={fallbackConfiguration.blockedUrls}
                                    isConfigurationExists={!!initialConfiguration.id}
                                    onChange={onURLSelectionChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-uninstall')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={appConfiguration.blockUninstall}
                                    onChange={onToggleChange('blockUninstall')}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.block-access-settings')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                        appConfiguration.blockAccessSetting
                                    }
                                    onChange={onToggleChange(
                                        'blockAccessSetting'
                                    )}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('settings.permission-screen')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                                appConfiguration.permissionScreenAccess
                                            }
                                            onChange={onToggleChange(
                                                'permissionScreenAccess'
                                            )}
                                            disabled={false}
                                        />
                                    </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>


            <Grid item md={12}>
                <Grid container rowSpacing={5} columnSpacing={'5%'}>
                    {context === ConfigurationOwner.Agency ||
                        context === ConfigurationOwner.Officer ? (
                            <>
                                {userIsInRole(
                                    [Role.Administrator,
                                        Role.Employee,
                                        Role.AgencyAdministrator]
                                ) ?
                                    (
                                        <>
                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                        >
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    color:
                                                theme => theme.palette.common.lightGrey,
                                                    fontSize: '1.25rem',
                                                }}
                                            >
                                                {t('devices.disabled-configuration')}
                                            </Typography>
                                            <ToggleSwitch
                                                sx={{ m: 1 }}
                                                checked={appConfiguration.disableConfig}
                                                onChange={onToggleChange('disableConfig')}
                                                disabled={disableConfig}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('settings.permission-screen')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                                appConfiguration.permissionScreenAccess
                                            }
                                            onChange={onToggleChange(
                                                'permissionScreenAccess'
                                            )}
                                            disabled={false}
                                        />
                                    </Grid>
                                    </>
                                    ) : null}
                            </>
                        ) : null}

                    {context === ConfigurationOwner.Device &&
                        userIsInRole([Role.Administrator]) ? (

                            <Grid
                                item
                                xs={12}
                                lg={6}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color:
                                        (theme) => theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('settings.sync-logs')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    disabled={false}
                                    checked={
                                        appConfiguration.isLogCapture
                                    }
                                    onChange={onToggleChange(
                                        'isLogCapture'
                                    )}
                                />
                            </Grid>
                        )
                        : null}


                    {context === ConfigurationOwner.Device && (platform===Platform.Android || platform===Platform.ChromeOS) &&
                        userIsInRole([
                            Role.Administrator,
                            Role.Employee,
                        ]) ? (
                            <>
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.common.lightGrey,
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    {t('Uninstall Notification')}
                                </Typography>
                                <ToggleSwitch
                                    sx={{ m: 1 }}
                                    disabled={isUninstallNotification}
                                    checked={
                                        appConfiguration.isUninstallNotification
                                    }
                                    onChange={onToggleChange(
                                        'isUninstallNotification'
                                    )}
                                />
                            </Grid>
                             <Grid item xs={12} lg={6}>
                             <Typography
                                 variant="h3"
                                 sx={{
                                     color: (theme) =>
                                         theme.palette.common.lightGrey,
                                     fontSize: '1.25rem',
                                 }}
                             >
                                 {t('settings.permission-screen')}
                             </Typography>
                             <ToggleSwitch
                                 sx={{ m: 1 }}
                                 checked={
                                     appConfiguration.permissionScreenAccess
                                 }
                                 onChange={onToggleChange(
                                     'permissionScreenAccess'
                                 )}
                                 disabled={false}
                             />
                         </Grid>
                         </>
                        )
                        : null}
                </Grid>
            </Grid>

            <Stack
                direction="row"
                justifyContent="flex-end"
                gap="1em"
                sx={{ padding: '1em' }}
            >
                {
                    isLoading
                        ? (
                            <CircularProgress />
                        )
                        : (
                            <>
                                <Button
                                    onClick={onCancel}
                                >
                                    {t('common.actions.cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={isSaveButtonDisabled}
                                    onClick={onSaveHandler}
                                >
                                    {t('common.actions.save')}
                                </Button>
                            </>
                        )
                }
            </Stack>
        </Box>
    );
}
