import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { UsersForm } from 'src/templates/users-form/UsersForm';
import { User } from 'src/models/userModels';
import { noop } from 'src/utils/common';

export interface OfficerEditionFormModalProps {
    user?: User;
    open?: boolean;
    onClose?: () => void;
}

export function OfficerEditionFormModal({
    user,
    open = true,
    onClose = noop,
}: Readonly<OfficerEditionFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();

    function closeModal(status: boolean):void
    {
        onClose();
    }

    return (
        <Modal
            title={t('form.edit-officer')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <UsersForm {...user} closeModal={closeModal} />
            </Box>
        </Modal>
    );
}
