import { CapturedData } from './capturedData.model';

export enum MMSType {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
}

export interface TextMMSData extends CapturedData {
    deviceNumber: string,
    externalNumber: string,
    contactName: string,
    message: string,
    mmsType: MMSType,
    aiScanStatus?: number,
    alertWords: string[],
    fileId: string;
    fileUrl: string;
    thumbnailId: string,
    thumbnailUrl: string,
    comments?: number,
}

