import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    NotificationsForm,
} from 'src/templates/notifications/notifications-form/NotificationsForm';

export function NotificationCreate(): JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <Box m={4}>
                <TopBar
                    labels={[
                        t('notifications.my-notifications'),
                        t('notifications.create-notification'),
                    ]}
                    icon={<NotificationsIcon />}
                />
                <NotificationsForm />
            </Box>
        </>
    );
}
