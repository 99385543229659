import React, { useState } from 'react';
import { Button, Menu, MenuItem, Theme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const menuListStyle = {
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    padding: 0,
    borderColor: (theme: Theme) => theme.palette.common.goldYellow,
    background: (theme: Theme) => theme.palette.common.darkGrey,
};

const menuListItemStyle = {
    width: '100%',
    maxWidth: 360,
    color: (theme: Theme) => theme.palette.common.white,
    padding: '1rem',
    justifyContent: 'center',
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: (theme: Theme) => theme.palette.common.goldYellow,
    '&:hover': {
        color: (theme: Theme) => theme.palette.common.goldYellow,
    },
};

export interface MenuOptionItem<DataType> {
    label?: string;
    onClick?: (data: DataType) => void;
}
export interface MenuOptionProps<DataType> extends MenuOptionItem<DataType>{
    options?: MenuOptionItem<DataType>[];
    data:DataType;
}

export function ContextMenu<DataType>({
    options = [],
    data,
}: Readonly<MenuOptionProps<DataType>>): JSX.Element
{
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void =>
    {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: MenuOptionItem<DataType>): void =>
    {
        option?.onClick?.(data);
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </Button>
            {
                options.length > 0 && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            sx: menuListStyle,
                        }}
                    >
                        {
                            options.map((option) => (
                                <MenuItem
                                    key={option.label}
                                    onClick={() => handleOptionClick(option)}
                                    sx={menuListItemStyle}
                                >
                                    {option.label}
                                </MenuItem>
                            ))
                        }
                    </Menu>
                )
            }
        </div>
    );
}
