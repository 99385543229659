import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { EmailData } from 'src/models/captures/emailData.model';
import { CommentType } from 'src/models/commentType.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { selectEmail, selectEmailAttachments } from 'src/state/captures/capturesSlice';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import AttachmentsTable from './AttachmentsTable';

export default function EmailAttachments(
    {
        open,
        onClose,
    }: Readonly<{open: boolean, onClose: () => void}>
): React.ReactElement
{
    const emailData = useSelector(selectEmail);
    const attachments = useSelector(selectEmailAttachments);

    const details = [
        {
            label: t('data-type.details.received-date'),
            text: (data:EmailData) => new Date(data.receivedDate).toLocaleString(),
        },
        {
            label: t('data-type.details.captured-date'),
            text: (data:EmailData) => new Date(data.capturedDate).toLocaleString(),
        },
        {
            label: t('data-type.details.device-alias'),
            text: (data:EmailData) => data.device,
        },
        {
            label: t('data-type.details.user'),
            text: (data:EmailData) => data.username,
        },
        {
            label: t('data-type.details.to'),
            text: (data:EmailData) => data.recipients.join(', '),
        },
        {
            label: t('data-type.details.subject'),
            text: (data:EmailData) => data.subject,
        },
        {
            label: t('data-type.details.has-attachments'),
            text: (data:EmailData) => data.attachments.length
                ? t('common.toggle.yes')
                : t('common.toggle.no'),
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data:EmailData) => data.alertWords?.join(', '),
        },
    ];

    return (
        <DataDetailsModal<EmailData>
            title={t('data-type.details.email-attachments-title')}
            detailsTitle={t('data-type.details.email-attachments-details-title')}
            data={emailData}
            pdfEnabled={false}
            detailItems={details}
            open={open}
            onClose={onClose}
            comments={{
                contentId: emailData?.id,
                type: CommentType.EMAILS,
            }}
        >
            <AttachmentsTable
                attachments={attachments.attachments}
                isLoading={attachments.status===RequestStatus.InProgress}
            />
        </DataDetailsModal>
    );
}
