import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'src/templates/header/Header';
import {
    Box, CircularProgress, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { UsersForm } from 'src/templates/users-form/UsersForm';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useParams } from 'react-router-dom';
import {
    getUser,
    selectUser,
    selectUserStatus,
} from 'src/state/administration/administrationSlice';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';

type UserEditParams = {
    id: string;
}

export function UserManagementEdit(): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams<UserEditParams>();

    const user = useSelector(selectUser);
    const userStatus = useSelector(selectUserStatus);

    useEffect(() =>
    {
        if(!user?.id && params?.id)
        {
            dispatch(getUser(params.id));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <Header />
            <Box m={4}>
                <AdministrationHeader
                    labels={[t('menu.user-management'), t('user.edit-user')]}
                    icon={<SettingsRoundedIcon fontSize="large"/>}
                />
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                        margin: '20px 0',
                    }}
                >
                    {t('user.user-id')}
                    {' '}
                    {user?.id}
                    <br />
                </Typography>
                {userStatus === RequestStatus.Success ? (
                    <UsersForm { ...user } />
                )
                    : (
                        <Box textAlign={'center'}>
                            <CircularProgress />
                        </Box>
                    )}
            </Box>
        </>
    );
}
