import { Alert, AlertProps, Box, CircularProgress, Snackbar, SnackbarProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getClientAWLById,
    getCustomAWLById,
    getOfficerAWLById,
    getSuggestedAWLById,
    resetAWLUpdateStatus,
    selectAlertWordsList,
    selectAWLRetrieveStatus,
    selectAWLUpdateStatus,
    updateClientAWL,
    updateOfficerAWL,
    updateSuggestedAWL,
} from 'src/state/administration/administrationSlice';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { AWLForm } from 'src/templates/awl-form/AWLForm';
import { Header } from 'src/templates/header/Header';
import { ADMIN_SETTINGS, AWL_MANAGEMENT } from 'src/utils/routes';

export function AWLEditPage(): React.ReactElement
{
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const alertWordList = useSelector(selectAlertWordsList);
    const retrieveStatus = useSelector(selectAWLRetrieveStatus);
    const saveStatus = useSelector(selectAWLUpdateStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const isLoading = retrieveStatus === RequestStatus.InProgress;
    const isSaving = saveStatus === RequestStatus.InProgress;
    const [titles, setTitles] = useState<string[]>([]);

    function onSubmit({ profileId: profile, clientId, officerId, ...restValues }: GenericAWL): void
    {
        if (params.id)
        {
            const basePayload = {
                ...restValues,
                id: params.id,
            };

            switch (params.context)
            {
            case AWLContext.Suggested:
            case 'custom':
                if (profile)
                {
                    dispatch(updateSuggestedAWL({
                        ...basePayload,
                        profile,
                    }));
                    setTimeout(() =>
                    {
                        navigate(AWL_MANAGEMENT as string);
                    }, 2000);
                }
                break;
            case AWLContext.Client:
                if (clientId)
                {
                    dispatch(updateClientAWL({
                        ...basePayload,
                        client: clientId,
                    }));
                }
                break;
            case AWLContext.Officer:
                if (officerId)
                {
                    dispatch(updateOfficerAWL({
                        ...basePayload,
                        user: officerId,
                    }));
                }
            }
        }
    }

    function onCloseNotification(): void
    {
        dispatch(resetAWLUpdateStatus());
    }

    useEffect(() =>
    {
        let message = '';
        const subject = t('alert-word.alert-words');

        if (saveStatus === RequestStatus.Success)
        {
            message = t('common.messages.saved-successfully', { subject });
        }
        else if (saveStatus === RequestStatus.Failure)
        {
            message = t('common.messages.something-wrong');
        }

        setNotificationState({
            open: !!message,
            message,
            severity: saveStatus === RequestStatus.Success ? 'success' : 'error',
        });
    }, [saveStatus, t]);

    useEffect(() =>
    {
        if (!params.id)
        {
            return;
        }

        switch (params.context)
        {
        case AWLContext.Suggested:
            dispatch(getSuggestedAWLById(params.id));
            break;
        case 'custom':
            dispatch(getCustomAWLById(params.id));
            break;
        case AWLContext.Client:
            dispatch(getClientAWLById(params.id));
            break;
        case AWLContext.Officer:
            dispatch(getOfficerAWLById(params.id));
        }
    }, [params, dispatch]);

    useEffect(() =>
    {
        const titles = [
            alertWordList?.name ?? '',
            t('common.actions.edit'),
        ];

        switch (params.context)
        {
        case AWLContext.Suggested:
            titles.splice(0, 0, t('alert-word.suggested'));
            break;
        case 'custom':
            titles.splice(0, 0, t('alert-word.custom'));
            break;
        case AWLContext.Client:
            titles.splice(0, 0, t('alert-word.client'));
            break;
        case AWLContext.Officer:
            titles.splice(0, 0, t('alert-word.officer'));
        }

        setTitles(titles);
    }, [params, alertWordList, t]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(resetAWLUpdateStatus());
        };
    }, [dispatch]);

    function onBack(): void
    {
        if(params.context === AWLContext.Suggested)
        {
            navigate(ADMIN_SETTINGS);
        }
    }

    function renderBody(): React.ReactElement
    {
        if (retrieveStatus === RequestStatus.Failure)
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }
        else if (alertWordList && !isLoading)
        {
            return (
                <>
                    <AdministrationHeader
                        labels={titles}
                    />
                    <Box>
                        <AWLForm
                            isLoading={isSaving}
                            onBackToList={onBack}
                            actionLabel={t('common.actions.save')}
                            initialValues={alertWordList}
                            onSubmit={onSubmit}
                            clientField={params.context === AWLContext.Client}
                            officerField={params.context === AWLContext.Officer}
                            profileField={
                                [AWLContext.Suggested, 'custom'].includes(
                                    params.context as AWLContext
                                )
                            }
                        />
                    </Box>
                    <Snackbar
                        autoHideDuration={6000}
                        open={notificationState?.open}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            severity={notificationState?.severity}
                        >
                            {notificationState?.message}
                        </Alert>
                    </Snackbar>
                </>
            );
        }
        else
        {
            return <CircularProgress />;
        }
    }

    return (
        <>
            <Header />
            <Box m={4}>
                {renderBody()}
            </Box>
        </>
    );
}
