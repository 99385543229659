import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProcessData } from 'src/models/captures/processData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getProcesses,
    selectFilters,
    selectProcesses,
    selectProcessesStatus,
    getProcessDetails,
    selectProcessDetails,
    selectProcessDetailsStatus,
    updateSeenListStatus,
    changeFilters,
    selectallPageData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { secondsToHHMMSS, timeZoneFormat } from 'src/utils/dateUtils';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
export function ProcessAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();

    const filters = useSelector(selectFilters);

    const processesStatus = useSelector(selectProcessesStatus);
    const processesData = useSelector(selectProcesses);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const [detailData, setDetailData] = useState<ProcessData>();
    const processDetailsStatus = useSelector(selectProcessDetailsStatus);
    const processDetailsData = useSelector(selectProcessDetails);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    // eslint-disable-next-line
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);
    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    function onPageChange(pageNumber: number): void
    {
        if (processesData && pageNumber!==lastPage &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(processesData?.contents[processesData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    const handleOpen = (processId: ProcessData): void =>
    {
        setDetailData(processId);
        openRecordData(processId.id);

        dispatch(getProcessDetails(processId.id));
        if (!processId.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: processId.id,
                dataType: DataType.PROCESSES,
            }));
        }
        setShowModal(true);
    };


    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getProcesses({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: ProcessData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.PROCESSES}
            onChange={() =>
            {
                onPageChange(processesData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: ProcessData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            sortByType: 'flag',
            format: (data: ProcessData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: ProcessData) =>
                data.capturedDate ? new Date(data.capturedDate)?.toLocaleString() : '',
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: ProcessData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: ProcessData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.program'),
            size: 8,
            value: (data: ProcessData) => data.name,
            sortByType: 'name',
        },
        {
            label: t('data-type.table.total-usage-time'),
            size: 2,
            align: 'right' as const,
            value: (data: ProcessData) => secondsToHHMMSS(data.totalIntervalTime),
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: ProcessData) => openDetailColumn(data),
        },
    ];

    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: ProcessData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.details.captured-date'),
            text: (data: ProcessData) => timeZoneFormat(
                new Date(data.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.details.device-alias'),
            text: (data: ProcessData) => data?.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ProcessData) => data?.username,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: ProcessData) => data?.platform,
        },
        {
            label: t('data-type.table.local-id'),
            text: (data: ProcessData) => data?.localId,
        },
        {
            label: t('data-type.table.program'),
            text: (data: ProcessData) => data?.name,
        },
        {
            label: t('data-type.table.total-usage-time'),
            text: (data: ProcessData) =>
            {
                return secondsToHHMMSS(data?.totalIntervalTime);
            },
        },
    ];

    useEffect(() =>
    {
        if (processesData)
        {
            pdfExportSetter({
                type: DataType.PROCESSES,
                recordIds: processesData.contents,

            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(processesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.PROCESSES)
            );
        }
    }, [processesData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<ProcessData>
                title={t('data-type.details.processes-title')}
                open={showModal}
                data={detailData}
                detailItems={detailItems}
                isLoading={processDetailsStatus === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: processDetailsData?.id,
                    content: detailData,
                    type: CommentType.PROCESSES,
                }}
            />

            {useDesktopScreen() ? (
                <DataTable<ProcessData>
                    isLoading={processesStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...processesData}
                />
            ) : (
                <DataTableMobile
                    isLoading={processesStatus === RequestStatus.InProgress}
                    contents={processesData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={processesData?.numberOfEntries}
                    currentPage={processesData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
