import React from 'react';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { DevicesTable } from 'src/templates/devices-table/DevicesTable';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { useTranslation } from 'react-i18next';

export function Devices(): JSX.Element
{
    const { t } = useTranslation();

    return (
        <AdministrationPage>
            <AdministrationHeader
                labels={[t('menu.device-management')]}
            />
            <DevicesTable />
        </AdministrationPage>
    );
}
