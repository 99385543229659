export enum Platform {
    Windows = 'PC',
    MacOS = 'MAC',
    Android = 'ANDROID',
    iOS = 'IOS',
    ChromeOS = 'CHROMEOS',
    WindowsIOS = 'WINDOWS-IOS',
    ChromeOsExtension = 'CHROME OS EXTENSION'
}

export function platformStringKey(platform: Platform): string
{
    switch (platform)
    {
    case Platform.Windows:
        return 'common.platform.windows';
    case Platform.MacOS:
        return 'common.platform.macos';
    case Platform.Android:
        return 'common.platform.android';
    case Platform.iOS:
        return 'common.platform.ios';
    case Platform.ChromeOS:
        return 'common.platform.chromeos';
    case Platform.WindowsIOS:
        return 'common.platform.windowsios';
    case Platform.ChromeOsExtension:
        return 'common.platform.chromeosextension';
    default:
        return '';
    }
}
