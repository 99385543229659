import { DataType } from '../dataType.enum';
import { CapturedData } from './capturedData.model';

export enum SMSType {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
}

export interface TextSMSData extends CapturedData {
    deviceNumber: string
    externalNumber: string
    contactName: string
    date: Date
    message: string
    smsType: SMSType
    aiScanStatus?: number,
    alertWords: string[]
    fileId: string
    fileUrl: string
    thumbnailId: string
    thumbnailUrl: string
    datatype?: DataType
}

export interface TextSMSForDetails {
    id?: string;
    page?: number;
    sizePerPage: number;
    deviceNumber: string,
    externalNumber: string,
    startDate?:string;
    endDate?:string;
    type: string;
    isDateFilterChanged?:boolean;
}

