/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce, { Cancelable } from '@mui/utils/debounce';
import { useEffect, useState } from 'react';

export default function useDebounce<T extends (
...args: any[]) => any>(
    func: T,
    delay: number
): T
{
    const [debounced, setDebounced] = useState<T & Cancelable>();

    useEffect(() =>
    {
        let timer: T & Cancelable;

        setDebounced(() =>
        {
            timer = debounce(func, delay);

            return timer;
        });

        return () => timer.clear();
    }, [func, delay]);

    return debounced ?? func;
}
