import React, {
    useState,
    useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PhotoData } from 'src/models/captures/photoData.model';
import 'src/styles/svg.css';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changePhoto,
    getPhotos,
    selectFilters,
    selectPhotos,
    selectPhotosStatus,
    changeFilters,
    updateSeenListStatus,
    resetPhotosData,
    selectallPageData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { PhotoAlertsDetails } from './PhotoAlertsDetails';
import {
    Button,
    CircularProgress,
    IconButton,
    Typography,
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import PhotosAlertsGrid from './PhotosAlertsGrid';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { checkFilterChange } from 'src/utils/checkFilterChange';

export function PhotoAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();

    const filters = useSelector(selectFilters);
    const photosStatus = useSelector(selectPhotosStatus);
    const photosData = useSelector(selectPhotos);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showDetails, setShowDetails] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const desktopScreen = useDesktopScreen();
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    // eslint-disable-next-line
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);


    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    function showPhotoDetails(photo: PhotoData): void
    {
        openRecordData(photo.id);

        dispatch(changePhoto(photo));
        if (!photo.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: photo.id,
                dataType: DataType.PHOTOS,
            }));
        }
        setShowDetails(true);
    }

    function onPageChange(pageNumber: number): void
    {
        if (photosData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(photosData?.contents[
                    photosData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        dispatch(resetPhotosData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.isGridView]);

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
        dispatch(getPhotos({
            skipRecordId,
            isGridView: filters.isGridView,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: filters.isGridView === true ? 100 : rowsPerPage,
            ...filters,
            alertwordsOnly: filters.isGridView === true ? filters.alertwordsOnly : false,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getAiScanStatusColumn = (data: PhotoData): React.ReactNode =>
    {
        if (data.aiScanStatus === 2) return <ScoutRed />;
        else if (data.aiScanStatus === 0) return (
            <HourglassEmptyOutlinedIcon
                sx={{ fill: 'lightGrey' }}
            />
        );
        else return ('');
    };
    const getFlagColumn = (data: PhotoData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.PHOTOS}
            onChange={()=>
            {
                onPageChange(photosData?.currentPage ?? 1);
            }}
        />
    );
    const getImageColumn = (data: PhotoData): React.ReactNode => (
        <img height="50" src={data.fileUrl} alt={data.id} />
    );
    const openDetailColumn = (data: PhotoData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => showPhotoDetails(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: PhotoData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: PhotoData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: PhotoData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 4,
            value: (data: PhotoData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 4,
            value: (data: PhotoData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 2,
            value: (data: PhotoData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.image'),
            size: 3,
            format: (data: PhotoData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: PhotoData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: PhotoData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (photosData)
        {
            setLastPage(Math.floor(
                photosData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.PHOTOS,
                recordIds: photosData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(photosData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.PHOTOS)
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photosData, pdfExportSetter]);

    const screenLayoutComp =
        filters.isGridView === false ? (
            desktopScreen ? (
                <DataTable<PhotoData>
                    isLoading={photosStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...photosData}
                />
            ) : (
                <DataTableMobile
                    isLoading={photosStatus === RequestStatus.InProgress}
                    contents={photosData?.contents || []}
                    setOpenModal={showPhotoDetails}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={photosData?.numberOfEntries}
                    currentPage={photosData?.currentPage}
                    enablePagination
                />
            )
        ) : filters.isGridView === true &&
            photosData && photosData?.contents?.length > 0 ? (
                <>
                    <PhotosAlertsGrid showDetails={showPhotoDetails} />
                    {photosStatus === RequestStatus.InProgress && (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress
                                style={{
                                    width: '4rem',
                                    height: '4rem',
                                    display: 'inline-block',
                                    marginTop: '-4%',
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                filters.isGridView === true &&
            photosData && photosData?.contents?.length === 0 &&
            photosStatus === RequestStatus.Success && (
                    // eslint-disable-next-line
                    <>
                        {filters.alertwordsOnly && (
                            <Button
                                variant="contained"
                                sx={{ float: 'right', top: '-4px' }}
                                onClick={(e) =>
                                {
                                    dispatch(changeFilters({
                                        alertwordsOnly: !filters.alertwordsOnly,
                                    }));
                                }}
                            >
                                {!filters.alertwordsOnly
                                    ? t('form.View-alert-words')
                                    : t('form.No-alert-words')}
                            </Button>
                        )}
                        <Typography
                            variant="h3"
                            sx={{
                                color: (theme) => theme.palette.common.lightGrey,
                                margin: '4rem auto',
                                textAlign: 'center',
                            }}
                        >
                            {t('data-type.table.no-content')}
                        </Typography>
                    </>
                )
            );

    return (
        <>
            <PhotoAlertsDetails
                open={showDetails}
                onClose={() => setShowDetails(false)}
            />
            <>
                {screenLayoutComp}
                {filters.isGridView === true &&
                    photosData && photosData?.contents?.length === 0 &&
                    photosStatus === RequestStatus.InProgress && (
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <CircularProgress
                            style={{
                                width: '4rem',
                                height: '4rem',
                                display: 'inline-block',
                            }}
                        />
                    </div>
                )}
            </>
        </>
    );
}
