export enum AlertTriggerType {
    FLAG = 'FLAG',
    AISCAN = 'AISCAN',
    ALERTWORD = 'ALERTWORD',
  }

export const ALL_DATA_TYPE = 'All Data Types';
export const KEYWORDS = 'Keywords';
export const SCREENSHOTS = 'Screenshots';
export const SCREENCAPTURES = 'Screen Captures';
export const PHOTOS = 'Photos';
export const TEXTSMS = 'SMS';
export const TEXTMMS = 'MMS';
export const EXTERNALDEVICES = 'External Media';
export const FILESTRACKING = 'Files Tracking';
export const INSTALLEDPROGRAMS = 'Programs';
export const GEOLOCATIONS = 'Geolocation';
export const EMAILS = 'Emails';
export const LOGONEVENTS = 'Logon Events';
export const CALLHISTORY = 'Calls';
export const PRINTEDDOCUMENTS = 'Printed Docs';
export const PROCESS = 'Processes';
export const WEBSITES = 'Websites';
export const SEARCHES = 'Websites Search';

export const ALERTSPAGE = '/landing-page';
export const AGENCYPAGE = '/administration/agencies';

export const SCREENSHOTPAGE='/data-type/screenshots';
export const SCREENSHOTMETAPAGE = '/data-type/screenshotsmeta';
export const OFFICERPAGE='/administration/officer';
