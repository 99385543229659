import { Extension, ExtensionParams } from 'src/models/administration/extensionData.model';

export interface APIExtensionParams {
    platform?: string;
    extension?: string;
}

export interface APIExtensionResponse {
    id: string;
    platform: string;
    extension: string;
}

export type APIUpdateExtensionParams = APIExtensionParams

export function transformUpdateExtensionParams(
    { platform, extension }: Extension
): APIUpdateExtensionParams
{
    return {
        extension,
        platform,
    };
}

export function readAPIExtensionResponse({
    id,
    platform,
    extension,
}: APIExtensionResponse): Extension
{
    return {
        id,
        extension,
        platform,
    };
}

export function transformExtensionParams({
    extension,
    platform,
}: ExtensionParams): APIExtensionParams
{
    return {
        platform,
        extension,
    };
}
