import React from 'react';
import { LogonEventData }
    from 'src/models/captures/logonEventData.model';
import { useTranslation } from 'react-i18next';

export function LogonEventDetails(
    { receivedDate, type }: Readonly<LogonEventData>
): React.ReactElement
{
    const { t } = useTranslation();
    return (
        <div>
            <div style={{ display: 'inline-block', paddingRight: '30px' }}>
                <div>
                    <b>
                        {t('data-type.details.date')}
                    </b>
                </div>
                <div>
                    {
                        (new Date(receivedDate))
                            .toLocaleDateString()
                    }
                </div>
            </div>
            <div style={{ display: 'inline-block', paddingRight: '30px' }}>
                <div>
                    <b>
                        {t('data-type.details.time')}
                    </b>
                </div>
                <div>
                    {
                        (new Date(receivedDate))
                            .toLocaleTimeString()
                    }
                </div>
            </div>
            <div style={{ display: 'inline-block', paddingRight: '30px' }}>
                <div>
                    <b>
                        {t('data-type.details.event-type')}
                    </b>
                </div>
                <div>
                    {type?.toString()}
                </div>
            </div>
        </div>
    );
}
