
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { KeystrokeDetailsData, KeystrokeMetadata } from 'src/models/captures/keystrokeData.model';
import { HighlightText } from 'src/components/highlight-text/HighlightText';

const keyBoxStyle = {
    display: 'inline',
    border: '1px solid #0004',
    borderStyle: 'dotted',
    margin: '0 1px',
};

export function KeystrokeDetails(
    { keystrokes, value, dataCollected, alertWords }: Readonly<KeystrokeDetailsData>
): React.ReactElement
{

    const { t } = useTranslation();

    function getTextFromKeystrokes(
        inputKeystrokes: KeystrokeMetadata[] | undefined
    ): React.ReactElement
    {
        return (
            <>
                {inputKeystrokes?.map(
                    ({ keyValue, keyName }) => (
                        <Box sx={keyBoxStyle} key={keyName}>
                            {keyValue ?? keyName}
                        </Box>
                    )
                )}
            </>
        );
    }

    return (
        <Box>
            <Box sx={{ mb: 6 /* margin-bottom: 4rem */ }}>

                <Box>
                    <Box>
                        <Box sx={{ color: theme => theme.palette.error.main }}>
                            {t('data-type.details.content')}
                        </Box>
                    </Box>
                    <Box
                        style={{
                            wordBreak: 'break-word',
                        }}
                    >
                        {dataCollected? (
                            <HighlightText
                                textToHighlight={dataCollected || ''}
                                highlightWords={alertWords || ['']}
                            />
                        )
                            : (
                                <HighlightText
                                    textToHighlight={value || ''}
                                    highlightWords={alertWords || ['']}
                                />
                            )}
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ color: theme => theme.palette.error.main }}>
                        {t('data-type.details.data-collected')}
                    </Box>
                </Box>
                <Box
                    style={{
                        wordBreak: 'break-word',
                    }}
                >
                    {getTextFromKeystrokes(keystrokes)}
                </Box>
            </Box>
        </Box>
    );
}
