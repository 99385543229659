import {
    Configuration,
    ConfigurationOwner,
    ConfigurationParams,
} from 'src/models/administration/configurationData.model';
import {
    parseConfiguration,
    readAPIConfigurationResponse,
} from '../data-transfer-objects/administration/configuration';
import { configInstance } from '../instance';
import * as storage from 'src/utils/storage';
import { Token } from 'src/models/authorizationModels';

function getEndpointPath({ ownerType, ownerId }: ConfigurationParams): string
{
    switch (ownerType)
    {
    case ConfigurationOwner.Agency:
    case ConfigurationOwner.Client:
    case ConfigurationOwner.Officer:
        return `monitoring-configuration/${ownerType.toLowerCase()}/${ownerId}`;
    case ConfigurationOwner.Device:
        // eslint-disable-next-line
        return `monitoring-configuration/v2/${ownerType.toLowerCase()}/${ownerId}?connectionType=Browser`;
    case ConfigurationOwner.Default:
    default:
        return 'monitoring-configuration';
    }
}

export async function getDefaultConfiguration(): Promise<Configuration>
{
    const headers = getToken();
    const response = await configInstance.get('monitoring-configuration', { headers });

    return readAPIConfigurationResponse(response.data);
}

export async function getConfiguration(params: ConfigurationParams): Promise<Configuration>
{
    const headers = getToken();
    if (params.ownerType === ConfigurationOwner.Default)
    {
        return await getDefaultConfiguration();
    }

    const response = await configInstance.get(getEndpointPath(params), { headers });

    return readAPIConfigurationResponse(response.data);
}

export async function getPartialConfiguration(configId: string): Promise<Configuration>
{
    const headers = getToken();
    const endpoint = `monitoring-configuration/${configId}`;
    const response = await configInstance.get(endpoint, { headers });
    return readAPIConfigurationResponse(response.data);
}

export async function updateConfiguration(configuration: Configuration): Promise<Configuration>
{
    const headers = getToken();
    const response = await configInstance.patch(
        `monitoring-configuration/${configuration.id}`,
        parseConfiguration(configuration),
        { headers }
    );

    return readAPIConfigurationResponse(response.data);
}

export async function createConfiguration(configuration: Configuration): Promise<Configuration>
{
    const headers = getToken();
    const response = await configInstance.post(
        'monitoring-configuration',
        parseConfiguration(configuration),
        { headers }
    );

    return readAPIConfigurationResponse(response.data);
}
export function getToken(): { Authorization: string }
{
    const token = storage.read<Token>(storage.Keys.Token);
    if (!token)
    {
        throw new Error('Token not found');
    }
    const headers = {
        Authorization: token ? `Bearer ${token.accessToken}` : '',
    };
    return headers;
}
// getToken()
