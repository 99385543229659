import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'src/components/protected-route/ProtectedRoute';
import { ConfigurationOwner } from 'src/models/administration/configurationData.model';
import {
    ACTIVATE_USER,
    AGENCY_MANAGEMENT,
    AGENCY_SETTINGS_MANAGEMENT,
    AWL_NEW,
    AWL_EDIT,
    CALLHISTORY,
    CHANGE_PASSWORD,
    CLIENT_DEVICES_MANAGEMENT,
    CLIENT_MANAGEMENT,
    CLIENT_SETTINGS_MANAGEMENT,
    DATA_TYPE,
    DEVICES_MANAGEMENT,
    DEVICE_SETTINGS_MANAGEMENT,
    EMAILS,
    EXTERNALDEVICES,
    FILESTRACKING,
    FORGOT_PASSWORD,
    GEOLOCATIONS, HOME,
    INSTALLEDPROGRAMS,
    KEYWORDS,
    LOGIN,
    LOGONEVENTS,
    PHOTOS,
    PRINTEDDOCUMENTS,
    PROCESS,
    REPORTS_CREATE,
    REPORTS_EDIT,
    USER_MANAGEMENT_CREATE,
    USER_MANAGEMENT_EDIT,
    REPORTS_MANAGMENT,
    RESET_PASSWORD,
    SCREENCAPTURES,
    SCREENSHOTS,
    TEXTSMS,
    TEXTMMS,
    USER_MANAGEMENT,
    WEBSITES,
    SEARCHES,
    OFFICER_MANAGEMENT,
    ADMIN_SETTINGS,
    SESSION_LOGS,
    AWL_SUGGESTED_NEW,
    NOTIFICATIONS_MANAGMENT,
    NOTIFICATIONS_CREATE,
    NOTIFICATIONS_EDIT,
    ALERT_WORDS_PAGE,
    LANDING_PAGE,
    AWL_MANAGEMENT,
    SCREENSHOTSMETA,
    INSTRUCTION,
    INSTRUCTION_CREATE,
    INSTRUCTION_EDIT,
} from 'src/utils/routes';
import { ActivateUser } from './activate-user/ActivateUser';
import { AgenciesManagement } from './administration/agencies/AgenciesManagement';
import {
    AWLCreationPage,
} from './administration/alert-words-list/awl-creation-page/AWLCreationPage';
import {
    ApplicationSettingsManagement,
} from './administration/application-settings-management/ApplicationSettingsManagement';
import { ClientDeviceManagement } from './administration/client-management/ClientDeviceManagement';
import { ClientManagement } from './administration/client-management/ClientManagement';
import { Devices } from './administration/devices/Devices';
import { UserManagementCreate } from './administration/user-management/UserManagementCreate';
import { UserManagementEdit } from './administration/user-management/UserManagementEdit';
import { ChangePassword } from './change-password/ChangePassword';
import { DataTypeCallHistory } from './data-type/call-history/DataTypeCallHistory';
import { DataTypeEmails } from './data-type/emails/DataTypeEmails';
import { DataTypeExternalDevices } from './data-type/external-devices/DataTypeExternalDevices';
import { DataTypeGeolocations } from './data-type/geolocations/DataTypeGeolocations';
import {
    DataTypeInstalledPrograms,
} from './data-type/installed-programs/DataTypeInstalledPrograms';
import { DataTypeKeystrokes } from './data-type/keystrokes/DataTypeKeystrokes';
import { DataTypeLogonEvents } from './data-type/logon-events/DataTypeLogonEvents';
import { DataTypePhotos } from './data-type/photos/DataTypePhotos';
import { DataTypePrintedDocuments } from './data-type/printed-documents/DataTypePrintedDocuments';
import { DataTypeProcesses } from './data-type/processes/DataTypeProcesses';
import { DataTypeScreenCaptures } from './data-type/screen-captures/DataTypeScreenCaptures';
import {
    DataTypeScreenshots,
    DataTypeScreenshotsmeta,
} from './data-type/screenshots/DataTypeScreenshots';
import { DataTypeTextSMS } from './data-type/text-sms/DataTypeTextSMS';
import { DataTypeTextMMS } from './data-type/text-mms/DataTypeTextMMS';
import { DataTypeWebsites } from './data-type/websites/DataTypeWebsites';
import { DataTypeSearches } from './data-type/searches/DataTypeSearches';
import { ForgotPassword } from './forgot-password/ForgotPassword';
import { Login } from './login/Login';
import { ReportCreate } from './reports/ReportCreate';
import { ReportEdit } from './reports/ReportEdit';
import { Reports } from './reports/Reports';
import { ResetPassword } from './reset-password/ResetPassword';
import { AWLEditPage } from './administration/alert-words-list/awl-edit-page/AWLEditPage';
import { OfficerList } from './administration/officers/officer-list/OfficerList';
import { OfficerAccountList } from './administration/officer-account/OfficerAccountList';
import { AdminSettingsManagement } from './administration/admin-settings/AdminSettingsManagement';
import {
    AWLSuggestedWordsCreate,
} from './administration/alert-words-list/awl-creation-page/AWLSuggestedWordsCreate';
import { Notifications } from './notifications/Notifications';
import { NotificationCreate } from './notifications/NotificationCreate';
import { NotificationEdit } from './notifications/NotificationsEdit';
import { DataTypeFilesTracking } from './data-type/files-tracking/DataTypeFilesTracking';
import { Role } from 'src/models/roles.enum';
import { SessionLog } from './session-logs/SessionLogs';
import { LandingPage } from './landing-page/LandingPage';
import { SuggestedAWLTable } from 'src/templates/suggested-awl-table/SuggestedAWLTable';
import { InstructionAdd } from './instruction-management/InstructionAdd';

export function Router(): JSX.Element
{
    return (
        <Routes>
            <Route index element={<Navigate to={LOGIN} replace />} />
            <Route path={LOGIN} element={<Login />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ACTIVATE_USER} element={<ActivateUser />} />
            <Route
                path={HOME}
                element={<Navigate to={LANDING_PAGE} replace />}
            />
            <Route
                path={CHANGE_PASSWORD}
                element={(
                    <ProtectedRoute>
                        <ChangePassword />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={USER_MANAGEMENT}
            >
                <Route
                    path={USER_MANAGEMENT_CREATE}
                    element={(
                        <ProtectedRoute requiredRoles={[Role.Administrator]}>
                            <UserManagementCreate />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={USER_MANAGEMENT_EDIT}
                    element={(
                        <ProtectedRoute requiredRoles={[Role.Administrator]}>
                            <UserManagementEdit />
                        </ProtectedRoute>
                    )}
                />
            </Route>
            <Route
                path={INSTRUCTION}

            >
                <Route
                    path={INSTRUCTION_CREATE}
                    element={(
                        <ProtectedRoute requiredRoles={[Role.Administrator]}>
                            <InstructionAdd />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={INSTRUCTION_EDIT}
                    element={(
                        <ProtectedRoute requiredRoles={[Role.Administrator]}>
                            <InstructionAdd />
                        </ProtectedRoute>
                    )}
                />
            </Route>
            <Route
                path={ADMIN_SETTINGS}
                element={(
                    <ProtectedRoute requiredRoles={[Role.Administrator]}>
                        <AdminSettingsManagement />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={SESSION_LOGS}
                element={(
                    <ProtectedRoute>
                        <SessionLog />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={LANDING_PAGE}
                element={(
                    <ProtectedRoute>
                        <LandingPage />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={DEVICES_MANAGEMENT}
                element={(
                    <ProtectedRoute>
                        <Devices />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={AGENCY_MANAGEMENT}
            >
                <Route
                    index
                    element={(
                        <ProtectedRoute
                            requiredRoles={[
                                Role.Administrator,
                                Role.Employee,
                                Role.Monitor,
                                Role.AgencyAdministrator,
                            ]}
                        >
                            <AgenciesManagement />
                        </ProtectedRoute>
                    )}
                />
            </Route>
            <Route
                path={CLIENT_MANAGEMENT}
            >
                <Route
                    index
                    element={(
                        <ProtectedRoute>
                            <ClientManagement />
                        </ProtectedRoute>
                    )}
                />
            </Route>
            <Route
                path={CLIENT_DEVICES_MANAGEMENT}
                element={(
                    <ProtectedRoute>
                        <ClientDeviceManagement />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={AGENCY_SETTINGS_MANAGEMENT}
                element={(
                    <ProtectedRoute>
                        <ApplicationSettingsManagement
                            context={ConfigurationOwner.Agency}
                        />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={CLIENT_SETTINGS_MANAGEMENT}
                element={(
                    <ProtectedRoute>
                        <ApplicationSettingsManagement
                            context={ConfigurationOwner.Client}
                        />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={DEVICE_SETTINGS_MANAGEMENT}
                element={(
                    <ProtectedRoute>
                        <ApplicationSettingsManagement
                            context={ConfigurationOwner.Device}
                        />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={REPORTS_MANAGMENT}
            >
                <Route
                    index
                    element={(
                        <ProtectedRoute>
                            <Reports />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={REPORTS_CREATE}
                    element={(
                        <ProtectedRoute>
                            <ReportCreate />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={REPORTS_EDIT}
                    element={(
                        <ProtectedRoute>
                            <ReportEdit />
                        </ProtectedRoute>
                    )}
                />
            </Route>

            <Route
                path={NOTIFICATIONS_MANAGMENT}
            >
                <Route
                    index
                    element={(
                        <ProtectedRoute>
                            <Notifications />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={NOTIFICATIONS_CREATE}
                    element={(
                        <ProtectedRoute>
                            <NotificationCreate />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={NOTIFICATIONS_EDIT}
                    element={(
                        <ProtectedRoute>
                            <NotificationEdit />
                        </ProtectedRoute>
                    )}
                />
            </Route>
            <Route
                path={AWL_MANAGEMENT}
                element={(
                    <ProtectedRoute requiredRoles={[Role.Administrator]}>
                        <SuggestedAWLTable />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={OFFICER_MANAGEMENT}
                element={(
                    <ProtectedRoute
                        requiredRoles={[
                            Role.Administrator,
                            Role.Employee,
                            Role.Monitor,
                            Role.OfficerSupervisor,
                            Role.AgencyAdministrator,
                        ]}
                    >
                        <OfficerList />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={ALERT_WORDS_PAGE}
                element={(
                    <ProtectedRoute>
                        <OfficerAccountList />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={AWL_NEW}
                element={(
                    <ProtectedRoute requiredRoles={[Role.Administrator]}>
                        <AWLCreationPage />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={AWL_EDIT}
                element={(
                    <ProtectedRoute requiredRoles={[Role.Administrator]}>
                        <AWLEditPage />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={AWL_SUGGESTED_NEW}
                element={(
                    <ProtectedRoute requiredRoles={[Role.Administrator]}>
                        <AWLSuggestedWordsCreate />
                    </ProtectedRoute>
                )}
            />
            <Route
                path={DATA_TYPE}
            >
                <Route index element={<Navigate to={KEYWORDS} replace />} />
                <Route
                    path={KEYWORDS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeKeystrokes />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={SCREENSHOTS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeScreenshots />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={SCREENSHOTSMETA}
                    element={(
                        <ProtectedRoute>
                            <DataTypeScreenshotsmeta />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={SCREENCAPTURES}
                    element={(
                        <ProtectedRoute>
                            <DataTypeScreenCaptures />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={PHOTOS}
                    element={(
                        <ProtectedRoute>
                            <DataTypePhotos />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={TEXTSMS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeTextSMS />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={TEXTMMS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeTextMMS />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={EXTERNALDEVICES}
                    element={(
                        <ProtectedRoute>
                            <DataTypeExternalDevices />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={FILESTRACKING}
                    element={(
                        <ProtectedRoute>
                            <DataTypeFilesTracking />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={INSTALLEDPROGRAMS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeInstalledPrograms />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={GEOLOCATIONS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeGeolocations />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={EMAILS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeEmails />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={LOGONEVENTS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeLogonEvents />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={CALLHISTORY}
                    element={(
                        <ProtectedRoute>
                            <DataTypeCallHistory />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={PRINTEDDOCUMENTS}
                    element={(
                        <ProtectedRoute>
                            <DataTypePrintedDocuments />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={PROCESS}
                    element={(
                        <ProtectedRoute>
                            <DataTypeProcesses />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={WEBSITES}
                    element={(
                        <ProtectedRoute>
                            <DataTypeWebsites />
                        </ProtectedRoute>
                    )}
                />
                <Route
                    path={SEARCHES}
                    element={(
                        <ProtectedRoute>
                            <DataTypeSearches />
                        </ProtectedRoute>
                    )}
                />
            </Route>
        </Routes>
    );
}
