import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { noop } from 'src/utils/common';
import {
    AWLForm,
    AWLFormProps,
    DEFAULT_VALUES,
} from '../awl-form/AWLForm';

export interface AlertWordNewFormProps extends Pick<
    AWLFormProps,
    'onSubmit'| 'agencyField' | 'clientField' | 'officerField' | 'profileField'>
{
    onNotificationClose: () => void,
    status?: RequestStatus,
    onBackToList?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function AWLCreationForm({
    status,
    onNotificationClose,
    onSubmit = noop,
    onBackToList = noop,
    onSuccess = noop,
    onFailure = noop,
    agencyField = false,
    clientField = false,
    officerField = false,
    profileField = false,
}: Readonly<AlertWordNewFormProps>): React.ReactElement
{
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const [initialValues, setInitialValues] = useState<GenericAWL>(
        DEFAULT_VALUES
    );

    useEffect(() =>
    {
        setIsLoading(status === RequestStatus.InProgress);

        let message = '';
        const subject = t('alert-word.alert-words');

        if (status === RequestStatus.Success)
        {
            onSuccess();
            message = t('common.messages.saved-successfully', { subject });
            setInitialValues({ ...DEFAULT_VALUES });
        }
        else if (status === RequestStatus.Failure)
        {
            onFailure();
            message = t('common.messages.something-wrong');
        }

        if (message)
        {
            setNotificationState({
                open: true,
                message,
                severity: status === RequestStatus.Failure ? 'error' : 'success',
            });
        }
        else
        {
            setNotificationState({ open: false });
        }
    }, [status, setNotificationState, setInitialValues, onFailure, onSuccess, t]);

    return (
        <>
            <AWLForm
                initialValues={initialValues}
                actionLabel={t('common.actions.create')}
                onSubmit={onSubmit}
                onBackToList={onBackToList}
                agencyField={agencyField}
                clientField={clientField}
                officerField={officerField}
                profileField={profileField}
                isLoading={isLoading}
            />
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onNotificationClose}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
