import { AlertTriggerType } from 'src/models/alertsType.enum';
import {
    ActiveData,
    AlertsData,
    CountData,
    InActiveData,
    UpdateSeenData,
} from 'src/models/captures/alertsData.model';
import { DataType } from 'src/models/dataType.enum';
import { APICaptureData, readAPICaptureData } from './captures';

export interface APIAlertsResponse extends APICaptureData{
    updatedAt: Date;
    dataType: DataType;
    trigger_for: AlertTriggerType[];
    alertWords: string[];
    detailsId: string;
    deviceId : string,
    details :string;
    fileUrl: string;
    seen_by: string[];
    aiScanStatus: number;
    fileId: string;
    comments?: number;
}

export function readAPIAlertsResponse(data: APIAlertsResponse): AlertsData
{
    const {
        updatedAt,
        dataType,
        trigger_for,
        alertWords,
        detailsId,
        deviceId,
        details,
        fileUrl,
        seen_by,
        aiScanStatus,
        fileId,
        comments,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        updatedAt,
        dataType,
        trigger_for,
        alertWords,
        detailsId,
        deviceId,
        details,
        fileUrl,
        seen_by,
        aiScanStatus,
        fileId,
        comments,
    };
}

export interface APICountResponse{
  redflagCount : number;
  recordCount : number;
}

export function readAPICountResponse(data:APICountResponse) : CountData
{
    const {
        redflagCount,
        recordCount,
    } = data ;

    return {
        redflagCount,
        recordCount,
    };
}

export interface UserSeenResponse{
    userIds : string[]
    detailsId : string
}

export interface UserGetSeenResponse{
    detailsIds : string[]
    userId : string
}

export function readUserSeenResponse(data: UserSeenResponse ) : UpdateSeenData
{
    const { userIds, detailsId } = data;
    return {
        userIds,
        detailsId,
    };
}

export interface ActiveResponse {
    id:string;
    deviceAlias: string;
    client: string;
    remoteCOMAccount:string;
    softwareVersion: string;
    lastReported: number| string;
}


export interface InActiveResponse {
    id:string;
    deviceAlias: string;
    client: string;
    clientEmail: string;
    remoteCOMAccount:string;
    softwareVersion: string;
    nonReportDays: number| string;
    lastScreenshot: number | string;
    lastProcesses: number | string;
    lastWebsite: number | string;
    lastText: number | string;
    lastPhoto: number | string;
    lastKeystroke: number | string;
    lastCall: number | string;
    lastInstalledprograms: number | string;
}
export function readAPIActiveResponse(data:ActiveResponse):ActiveData
{
    const {
        id,
        deviceAlias,
        client,
        remoteCOMAccount,
        softwareVersion,
        lastReported,
    } = data;

    return {
        id,
        deviceAlias,
        client,
        remoteCOMAccount,
        softwareVersion,
        lastReported,
    };
}
export function readAPIInActiveResponse(data:InActiveResponse):InActiveData
{
    const {
        id,
        deviceAlias,
        client,
        clientEmail,
        remoteCOMAccount,
        softwareVersion,
        nonReportDays,
        lastScreenshot,
        lastProcesses,
        lastWebsite,
        lastText,
        lastPhoto,
        lastKeystroke,
        lastCall,
        lastInstalledprograms,
    } = data;

    return {
        id,
        deviceAlias,
        client,
        clientEmail,
        remoteCOMAccount,
        softwareVersion,
        nonReportDays,
        lastScreenshot,
        lastProcesses,
        lastWebsite,
        lastText,
        lastPhoto,
        lastKeystroke,
        lastCall,
        lastProgramsApps: lastInstalledprograms,
    };
}
