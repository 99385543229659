import { CaptureParams } from 'src/models/captures/capturedData.model';
import { LogonEventData } from 'src/models/captures/logonEventData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPILogonEventResponse } from '../data-transfer-objects/capture/logonEvents';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getLogonEvents(
    captureParams: CaptureParams
): Promise<PaginatedContent<LogonEventData>>
{
    const response = await instance.get(
        'logon-events',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPILogonEventResponse);
}
