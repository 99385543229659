import { CaptureParams } from 'src/models/captures/capturedData.model';
import { SearchData } from 'src/models/captures/searchData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPISearchesResponse } from '../data-transfer-objects/capture/searches';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getSearches(
    captureParams: CaptureParams
): Promise<PaginatedContent<SearchData>>
{
    const response = await instance.get(
        'searches',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPISearchesResponse);
}

export async function getSearch (id: string): Promise<SearchData>
{
    const response = await instance.get(`searches/${id}`).then(x => x.data);
    return readAPISearchesResponse(response);
}
