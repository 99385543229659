import React, { FocusEvent, useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { removeDuplicates } from 'src/utils/common';
import { isURL } from 'src/utils/stringUtils';

const containerStyle = {
    textAlign: 'left',
};

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export interface URLSelectorProps {
    ownBlockedUrls: string[];
    fallbackBlockedUrls?: string[];
    isConfigurationExists: boolean;
    onChange: (urls: string[]) => void;
}

export function URLSelector({
    ownBlockedUrls = [],
    fallbackBlockedUrls = [],
    isConfigurationExists,
    onChange,
}: Readonly<URLSelectorProps>): React.ReactElement
{
    const { t } = useTranslation();

    const blockUrls = isConfigurationExists ? ownBlockedUrls : fallbackBlockedUrls;

    const [urls, setUrls] = useState<string[]>(blockUrls);
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        setUrls(blockUrls);
    }, [blockUrls])

    function onChangeHandler(value: (string | string[])[]): void
    {
        const urls = value.map((word) =>
        {
            if (Array.isArray(word))
            {
                return word.join('');
            }

            return word.split(' ')
                .map((subword) => subword.trim())
                .filter((subword) => subword);
        }).flat().filter(isURL);

        const uniqueURLs = removeDuplicates(urls);

        setUrls(uniqueURLs);
        onChange(uniqueURLs);
        setInputValue('');
    }

    function onWordsBlur(event: FocusEvent<HTMLInputElement>): void
    {
        let newURLs = event.target.value.split(' ')
            .map((item) => item.trim())
            .filter(isURL);

        newURLs = removeDuplicates([...urls, ...newURLs]);

        setUrls(newURLs);
        onChange(newURLs);
        setInputValue('');
    }

    return (
        <Stack direction="column" sx={containerStyle}>
            <Typography
                variant="h3"
                sx={{
                    color: theme => theme.palette.common.lightGrey,
                    fontSize: '1.25rem',
                }}
            >
                {t('settings.blocked-urls')}
            </Typography>
            <Autocomplete
                multiple
                disablePortal
                id="program"
                value={urls}
                options={[]}
                onChange={(_, data) => onChangeHandler(data)}
                freeSolo
                fullWidth
                inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onBlur={onWordsBlur}
                        onChange={(event) =>
                        {
                            setInputValue(event.target.value);
                        }}
                    />
                )}
                renderOption={(props, option) =>
                {
                    return (
                        <li {...props}>
                            {option}
                        </li>
                    );
                }}
                ChipProps={{ sx: chipStyle }}
            />
        </Stack>
    );
}
