import React from 'react';
import Box from '@mui/material/Box';

import { Header } from 'src/templates/header/Header';

import { NotificationsFilters } from '../notifications-filters/NotificationsFilters';

export interface NotificationsPageProps {
    children?: React.ReactNode;
}

export function NotificationsPage({ children = null }: Readonly<NotificationsPageProps>)
    : JSX.Element
{
    return (
        <>
            <Header />
            <NotificationsFilters />
            <Box m={4}>
                {children}
            </Box>
        </>
    );
}
