import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { Device, DeviceLogs } from 'src/models/administration/deviceData.model';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceLogs, selectDeviceLogs } from 'src/state/administration/administrationSlice';
import { formatSize } from 'src/utils/common';

const downloadLink = (fileUrl: string):string =>
    fileUrl + '/download';

export default function DeviceAttachments(
    {
        open,
        onClose,
        data,
    }: Readonly<{ open: boolean; onClose: () => void; data: Device | undefined }>
): React.ReactElement
{
    const dispatch = useDispatch();
    const deviceLogs = useSelector(selectDeviceLogs);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    useEffect(() =>
    {
        if(data)
        {
            dispatch(getDeviceLogs({ pageNumber: 1, entriesPerPage, deviceId: data?.id }));
        }
    }, [dispatch, entriesPerPage, data]);

    const details = [
        {
            label: t('data-type.table.created-date'),
            text: (data:Device) => new Date(data.createdAt).toLocaleString(),
        },
        {
            label: t('data-type.details.device-alias'),
            text: (data:Device) => data.alias,
        },
        {
            label: t('data-type.table.agency-name'),
            text: (data:Device) => data.agencyName,
        },
        {
            label: t('data-type.table.client-name'),
            text: (data:Device) => data.clientName,
        },
        {
            label: t('data-type.table.platform'),
            text: (data:Device) => data.platform,
        },
        {
            label: t('data-type.table.last-connect'),
            text: (data:Device) => data.lastConnectAt ? new Date(
                data.lastConnectAt
            ).toLocaleString() : '-',
        },
    ];

    const getActionColumn = (data: DeviceLogs): React.ReactNode => (
        <a
            href={`${downloadLink(data.fileUrl)}`}
            download={data.fileName}
        >
            <FileDownloadIcon sx={{ color: 'black' }} />
        </a>
    );

    const columns: ColumnData<DeviceLogs>[] = [
        {
            label: t('data-type.table.file-name'),
            size: 8,
            value: (data: DeviceLogs) => data.fileName,
        },
        {
            label: t('common.properties.created-at'),
            size: 4,
            value: (data: DeviceLogs) =>
            {
                if (data.clientCreatedAt)
                {
                    const dateObject = new Date(data.clientCreatedAt);
                    return dateObject.toLocaleString();
                }
                else
                {
                    return ('-');
                }
            },
        },
        {
            label: t('data-type.table.size'),
            size: 4,
            value: (data: DeviceLogs) =>
            {
                return formatSize(parseInt(data?.fileSizeBytes));
            },
        },
        {
            label: t('data-type.table.format'),
            size: 4,
            value: (data: DeviceLogs) =>
            {
                const parts = data.fileName.split('.');
                return parts[parts.length - 1];
            },
        },
        {
            label: t('data-type.table.action'),
            size: 1,
            align: 'center' as const,
            format: (data: DeviceLogs) => getActionColumn(data),
        },
    ];

    function onPageChange(pageNumber: number): void
    {
        dispatch(getDeviceLogs({ pageNumber, entriesPerPage, deviceId: data?.id }));
    }

    return (

        <DataDetailsModal<Device>
            title="Sync Logs"
            detailsTitle="Logs"
            data={data}
            pdfEnabled={false}
            detailItems={details}
            open={open}
            onClose={onClose}
        >
            <DataTable<DeviceLogs>
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...deviceLogs}
            />
        </DataDetailsModal>
    );
}
