import { Platform } from 'src/models/platforms.enum';

export enum APIPlatform {
    Windows = 'PC',
    MacOS = 'MAC',
    Android = 'ANDROID',
    iOS = 'IOS',
    ChromeOS = 'CHROMEOS',
    WindowsIOS = 'WINDOWS-IOS',
    ChromeOsExtension = 'CHROME OS EXTENSION'
}

export function readPlatform(platform: APIPlatform): Platform
{
    switch (platform)
    {
    case APIPlatform.Windows:
        return Platform.Windows;
    case APIPlatform.MacOS:
        return Platform.MacOS;
    case APIPlatform.Android:
        return Platform.Android;
    case APIPlatform.iOS:
        return Platform.iOS;
    case APIPlatform.ChromeOS:
        return Platform.ChromeOS;
    case APIPlatform.WindowsIOS:
        return Platform.WindowsIOS;
    case APIPlatform.ChromeOsExtension:
        return Platform.ChromeOsExtension;
    }
}

export function transformPlatform(platform: Platform): APIPlatform
{
    switch (platform)
    {
    case Platform.Android:
        return APIPlatform.Android;
    case Platform.MacOS:
        return APIPlatform.MacOS;
    case Platform.Windows:
        return APIPlatform.Windows;
    case Platform.iOS:
        return APIPlatform.iOS;
    case Platform.ChromeOS:
        return APIPlatform.ChromeOS;
    case Platform.WindowsIOS:
        return APIPlatform.WindowsIOS;
    case Platform.ChromeOsExtension:
        return APIPlatform.ChromeOsExtension;
    }
}
