import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsPage } from 'src/templates/reports-page/ReportsPage';
import { ReactComponent as ReportImage } from 'src/assets/images/report.svg';
import { ReportsTable } from 'src/templates/reports-table/ReportsTable';
import { TopBar } from 'src/components/top-bar/TopBar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { REPORTS_CREATE } from 'src/utils/routes';

export function Reports(): JSX.Element
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <ReportsPage>
            <TopBar
                labels={[t('reports.my-reports')]}
                icon={<ReportImage />}
                buttons={[
                    {
                        text: t('reports.create-new-report'),
                        icon: <AddCircleIcon />,
                        onClick: () => navigate(REPORTS_CREATE),
                    },
                ]}
            />
            <ReportsTable />
        </ReportsPage>
    );
}
