import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HOME } from 'src/utils/routes';
import { LoginHeader } from 'src/templates/login-header/LoginHeader';
import { PasswordChangeForm } from 'src/templates/password-change-form/PasswordChangeForm';
import { AuthenticationPage } from 'src/templates/authentication-page/AuthenticationPage';
import { ReactComponent as PadlockImage } from 'src/assets/images/padlock.svg';
import { changePassword, selectChangePasswordStatus } from 'src/state/user/userSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';

export function ChangePassword(): JSX.Element
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const changePasswordStatus = useSelector(selectChangePasswordStatus);

    function handleCancel(): void
    {
        navigate(HOME);
    }

    function handleChangePassword(password: string, confirmPassword: string): void
    {
        dispatch(changePassword({ password, confirmPassword }));
    }

    if (changePasswordStatus === RequestStatus.Success)
    {
        navigate(HOME);
    }

    return (
        <>
            <LoginHeader />
            <AuthenticationPage image={<PadlockImage />}>
                <PasswordChangeForm
                    title={t('password-change.reset-password-title')}
                    handleCancel={handleCancel}
                    handleChangePassword={handleChangePassword}
                    isChangingPassword={changePasswordStatus === RequestStatus.InProgress}
                    hasError={changePasswordStatus === RequestStatus.Failure}
                />
            </AuthenticationPage>
        </>
    );
}
