import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Role } from 'src/models/roles.enum';
import { selectIsLoggedIn, selectRole } from 'src/state/authorization/authorizationSlice';
import { LOGIN, HOME } from 'src/utils/routes';

export interface ProtectedRouteProps
{
    requiredRoles?: Role[];
    children: React.ReactNode;
}

export function ProtectedRoute({ requiredRoles, children }: Readonly<ProtectedRouteProps>)
    : React.ReactElement
{
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userRole = useSelector(selectRole);

    if (!isLoggedIn)
    {
        return <Navigate to={LOGIN} replace />;
    }

    if (requiredRoles?.length && (!userRole || !requiredRoles.includes(userRole)))
    {
        return <Navigate to={HOME} replace />;
    }

    return (
        <>
            {children}
        </>
    );
}
