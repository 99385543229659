import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    LogonEventAlertsTable,
} from 'src/templates/logon-event-alerts-table/LogonEventAlertsTable';

export function DataTypeLogonEvents(): React.ReactElement
{
    return (
        <DataTypePage>
            <LogonEventAlertsTable />
        </DataTypePage>
    );
}
