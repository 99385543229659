import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from 'src/assets/images/logo.svg';
import { UserMenu } from 'src/components/user-menu/UserMenu';
import { NotificationsMenu } from 'src/components/notifications-menu/NotificationsMenu';
import HeaderMenu from '../header-menu/HeaderMenu';

export function Header(): React.ReactElement
{
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <AppBar
            sx={{
                position: 'sticky',
                borderColor: theme => theme.palette.common.goldYellow,
                borderStyle: 'solid',
                borderWidth: '0 0 0.25rem',
                [theme.breakpoints.up('md')]: {
                    padding: '0.625rem 2.1875rem',
                },
                [theme.breakpoints.down('md')]: {
                    padding: '0.625rem 1.0625rem',
                },
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between', gap: '1rem' }} disableGutters>
                <img
                    style={{ height: 100 }}
                    src={logo}
                    alt="Logo"
                />
                {isDesktopScreen && <HeaderMenu />}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NotificationsMenu />
                    <UserMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
