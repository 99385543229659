import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { Client } from 'src/models/administration/clientData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    updateClient,
    resetClientSaveStatus,
    selectClientSaveStatus,
    clearRemoteComError,
    deleteClient,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { ClientForm, ClientFormValues, DEFAULT_VALUES } from '../client-form/ClientForm';

export interface ClientEditionFormModalProps
{
    client: Client;
    open?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
    onDeleted?: () => void;
}

export function ClientEditionFormModal({
    client,
    open = false,
    onClose = noop,
    onSuccess = noop,
    onFailure = noop,
    onDeleted = noop,
}: Readonly<ClientEditionFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectClientSaveStatus);
    const [initialValues, setInitialValues] = useState<ClientFormValues>(DEFAULT_VALUES);

    function onSubmit(data: ClientFormValues): void
    {
        dispatch(updateClient({
            ...data,
            id: client.id,
        }));
    }
    function onDelete(data: ClientFormValues): void
    {
        dispatch(deleteClient({
            ...data,
            id: client.id,
        }));
    }

    function onCancel(): void
    {
        onClose();
    }

    useEffect(
        () =>
        {
            dispatch(clearRemoteComError());
            if (status === RequestStatus.Success)
            {
                onSuccess();
                dispatch(resetClientSaveStatus());
            }
            else if (status === RequestStatus.Deleted)
            {
                onDeleted();
                dispatch(resetClientSaveStatus());
            }
            else if (status === RequestStatus.Failure)
            {
                onFailure();
                dispatch(resetClientSaveStatus());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [status, setInitialValues, onSuccess, onDeleted, onFailure, dispatch]
    );

    useEffect(() =>
    {
        return () =>
        {
            dispatch(resetClientSaveStatus());
        };
    }, [dispatch]);

    useEffect(() =>
    {
        setInitialValues(client);
    }, [client]);

    return (
        <Modal
            title={t('clients.edit-client')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <ClientForm
                    buttonLabel={t('form.save-changes')}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onCancel={onCancel}
                    loading={status === RequestStatus.InProgress}
                />
            </Box>
        </Modal>
    );
}
