export enum Keys {
    Token = 'Token',
    User = 'User',
    Filters = 'Filters',
    SessionFilters = 'SessionFilters',
    AlertsFilter = 'AlertsFilter',
    screenshotGridView = 'screenshotGridView'
}

export function write(key: string, value: unknown): void
{
    if(key === Keys.Token || key === Keys.User){
      localStorage.setItem(key, JSON.stringify(value));
    }
    else{
      sessionStorage.setItem(key, JSON.stringify(value));
    }
}

export function read<ValueType>(
    key: string,
    defaultValue: ValueType | null = null
): ValueType | null
{
    const value = key === Keys.Token || key === Keys.User ? 
    localStorage.getItem(key): 
    sessionStorage.getItem(key);
    if (!value)
    {
        return defaultValue;
    }
    return JSON.parse(value);
}

export function remove(key: string): void
{
    if(key === Keys.Token || key === Keys.User){
    localStorage.removeItem(key);
    }
    else{
    sessionStorage.removeItem(key);
    }
}

export function clear(): void
{
    for(const key in Keys)
    {
        remove(key);
    }
}
