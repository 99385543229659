/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import { ReactComponent as ReportImage } from 'src/assets/images/report.svg';
import {
    Box, CircularProgress, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportForm } from 'src/templates/report-form/ReportForm';
import {
    getReport,
    selectReport,
    selectReportStatus,
} from 'src/state/administration/administrationSlice';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'src/models/requestStatus.enum';

type ReportEditParams = {
    id: string;
}

export function ReportEdit(): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams<ReportEditParams>();

    const report = useSelector(selectReport);
    const reportStatus = useSelector(selectReportStatus);


    useEffect(() =>
    {
        if(!report?.id && params?.id)
        {
            dispatch(getReport(params.id));
        }
    }, []);

    return (
        <>

            <Header />
            <Box m={4}>
                <TopBar
                    labels={[t('reports.my-reports'), t('reports.edit-report')]}
                    icon={<ReportImage />}
                />
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                        margin: '20px 0',
                    }}
                >
                    {t('reports.report-id')}
                    {' '}
                    {report?.id}
                    <br />
                </Typography>
                {reportStatus === RequestStatus.Success ?
                    <ReportForm { ...report } />
                    : (
                        <Box textAlign={'center'}>
                            <CircularProgress />
                        </Box>
                    )}
            </Box>
        </>
    );
}
