import {
    Agency,
    AgencyParams,
    DeleteAgencyParams,
    MonitorAgency,
    NonCompliantAgency,
    NonCompliantAgencyResponse,
} from 'src/models/administration/agencyData.model';
import { FilterOption } from 'src/models/common/filterOptionData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    APIAgencyRequest,
    readAPIAgencyResponse,
    transformAgency,
    transformAgencyParams,
    readAPIMonitorResponse,
    transformDeleteAgencyParams,
    readAPINonCompliantAgency,
} from '../data-transfer-objects/administration/agencies';
import { readAPIAgencyFilterResponse } from '../data-transfer-objects/common/filterOptions';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import { User } from 'src/models/userModels';
import { readAPIUserResponse } from '../data-transfer-objects/administration/users';
import { Client } from 'src/models/administration/clientData.model';
import { readAPIClientResponse } from '../data-transfer-objects/administration/clients';
import { Device } from 'src/models/administration/deviceData.model';
import { readAPIDeviceResponse } from '../data-transfer-objects/administration/devices';

export async function getAgency(agencyId: string): Promise<Agency>
{
    const response = await instance.get(`agency/${agencyId}`);

    return readAPIAgencyResponse(response.data);
}

export async function getAgencies(params: AgencyParams): Promise<PaginatedContent<Agency>>
{
    const response = await instance.get(
        'agency',
        { params: transformAgencyParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIAgencyResponse);
}

export async function getAllAgencies(): Promise<FilterOption[]>
{
    const response = await instance.get('agency/no-pageable');

    return readAPIAgencyFilterResponse(response.data);
}

export async function createAgency(data: Agency, logo?: File): Promise<Agency>
{
    const response = await instance.post(
        'agency',
        toFormData(transformAgency(data), logo)
    );

    return readAPIAgencyResponse(response.data);
}

export async function updateAgency(data: Agency, logo?: File): Promise<Agency>
{
    const response = await instance.patch(
        `agency/${data.id}`,
        toFormData(transformAgency(data), logo)
    );

    return readAPIAgencyResponse(response.data);
}

export async function getAgencyMonitorList(): Promise<MonitorAgency[]>
{
    const response = await instance.get(
        'users/monitors/list'
    );

    return readAPIMonitorResponse(response.data.results);
}
export async function getAssignedAgencyMonitor(id: string): Promise<MonitorAgency[]>
{
    const response = await instance.get(
        `agency/monitors/${id}`
    );

    return readAPIMonitorResponse(response.data.results);
}
export async function getAssignedAgencyOfficer(
    params: DeleteAgencyParams
): Promise<PaginatedContent<User>>
{
    const response = await instance.get(
        'agency/officer',
        { params: transformDeleteAgencyParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIUserResponse);
}
export async function getAssignedAgencyClient(
    params: DeleteAgencyParams
): Promise<PaginatedContent<Client>>
{
    const response = await instance.get(
        'agency/client',
        { params: transformDeleteAgencyParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIClientResponse);
}
export async function getAssignedAgencyActiveDevice(
    params: DeleteAgencyParams
): Promise<PaginatedContent<Device>>
{
    const response = await instance.get(
        'agency/active-device',
        { params: transformDeleteAgencyParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIDeviceResponse);
}
export async function deleteAgency(data: Agency): Promise<Agency>
{
    const response = await instance.patch(
        `agency/delete/${data.id}`,
        data
    );

    return readAPIAgencyResponse(response.data);
}

function toFormData(agency: APIAgencyRequest, logo?: File): FormData
{
    const formData = new FormData();

    if(logo)
    {
        formData.append(
            'logo',
            logo,
            logo.name
        );
    }

    formData.append('agencyName', agency.agencyName);
    formData.append('description', agency.description);
    formData.append('enabled', (agency.enabled ?? false).toString());
    formData.append('activated', (agency.activated ?? false).toString());
    agency.suggestedAlertWords?.forEach(suggestedAlertWord =>
    {
        formData.append('suggestedAlertWords[]', suggestedAlertWord);
    });
    agency.monitoruser?.forEach(monitoruser =>
    {
        formData.append('monitoruser[]', monitoruser);
    });
    agency.ccContacts?.forEach(ccContacts =>
    {
        formData.append('ccContacts[]', ccContacts);
    });
    return formData;
}
export async function getnoncompliantAgencyDetails(
    params: NonCompliantAgency
): Promise<NonCompliantAgencyResponse []>
{
    const response = await instance.get(
        'agency/noncompliant/activedevice',
        { params }
    );


    return readAPINonCompliantAgency(response.data);
}
