import React from 'react';
import { Header } from 'src/templates/header/Header';
import {
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {
    SuggestedAWLCreationForm,
} from 'src/templates/suggested-awl-creation-form/SuggestedAWLCreationForm';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';

export function AWLSuggestedWordsCreate(): JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <Box m={4}>
                <AdministrationHeader
                    labels={[
                        t('menu.admin-settings'),
                        t('settings.suggested-alert-words'),
                        t('common.actions.create'),
                    ]}
                    icon={<SettingsRoundedIcon fontSize="large"/>}
                />
                <SuggestedAWLCreationForm />
            </Box>
        </>
    );
}

