import React from 'react';
import Box from '@mui/material/Box';
import { Header } from '../header/Header';

export interface AdministrationPageProps {
    children?: React.ReactNode;
}

export function AdministrationPage({ children = null }: Readonly<AdministrationPageProps>)
    : JSX.Element
{
    return (
        <>
            <Header />
            <Box m={4}>
                {children}
            </Box>
        </>
    );
}
