import React from 'react';
import { useLocation } from 'react-router-dom';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LANDING_PAGE } from 'src/utils/routes';

interface IProps {
    // eslint-disable-next-line
    contents: Array<any>;setOpenModal: (data: any) => void;
    onPageChange?: (newPage: number) => void;
    entriesPerPage?: number;
    onChangeEntriesPerPage?: (newEntriesPerPage: number) => void;
    numberOfEntries?: number;
    currentPage?: number;
    enablePagination?: boolean;
    isLoading?: boolean;
}

enum RoutesPathnames {
    'keywords',
    'screenshots',
    'screen-captures',
    'photos',
    'text-sms',
    'external-devices',
    'installed-programs',
    'geolocations',
    'emails',
    'logon-events',
    'call-history',
    'printed-documents',
    'process',
    'searches',
    'websites',
    'files-tracking',
    'text-mms'
}

export function DataTableMobile({
    contents,
    setOpenModal,
    onChangeEntriesPerPage,
    onPageChange,
    entriesPerPage = 0,
    numberOfEntries = 0,
    currentPage = 0,
    enablePagination = false,
    isLoading = true,
}: Readonly<IProps>): React.ReactElement
{
    const location = useLocation();
    const { t } = useTranslation();

    const optionName = location.pathname.replace(
        '/data-type/',
        ''
    ) as keyof typeof RoutesPathnames;

    const landingPage = location.pathname === LANDING_PAGE;

    interface CommonProperties {
        titleLeft: string;
        subtitleLeft: string;
        titleRight: string;
        bottonList: string;
        bottonItemLeft: string;
        bottonItemRight: string;
    }

    const commonProperties: CommonProperties = {
        titleLeft: 'platform',
        subtitleLeft: 'capturedDate',
        titleRight: 'device',
        bottonList: 'program',
        bottonItemLeft: 'latitude',
        bottonItemRight: 'longitude',
    };
    const landingPageOptions: Record<string, CommonProperties> = {};

    const optionNames = [
        'Geolocation',
        'Calls',
        'Emails',
        'Processes',
        'External Media',
        'Files Tracking',
        'Keywords',
        'Logon Events',
        'MMS',
        'Photos',
        'Printed Docs',
        'Programs',
        'Screen Captures',
        'Screenshots',
        'Websites Search',
        'SMS',
        'Websites',
    ];

    for (const optionName of optionNames)
    {
        landingPageOptions[optionName] = { ...commonProperties };
    }

    const listOptions: Record<string, CommonProperties> = {};

    const options = [
        'geolocations',
        'call-history',
        'emails',
        'process',
        'external-devices',
        'files-tracking',
        'keywords',
        'logon-events',
        'text-mms',
        'photos',
        'printed-documents',
        'installed-programs',
        'screen-captures',
        'screenshots',
        'searches',
        'text-sms',
        'websites',
    ];

    for (const optionName of options)
    {
        listOptions[optionName] = { ...commonProperties };
    }

    function handleChangeEntriesPerPage(
        event: React.ChangeEvent<HTMLInputElement>
    ): void
    {
        const newEntriesPerPage = parseInt(event.target.value, 10);
        onChangeEntriesPerPage?.(newEntriesPerPage);
    }

    function handlePageChange(_event: unknown, newPage: number): void
    {
        onPageChange?.(newPage + 1);
    }

    return (
        <>
            {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress
                        style={{
                            width: '4rem',
                            height: '4rem',
                            display: 'inline-block',
                            marginTop: '4%',
                        }}
                    />
                </div>
            ) : (
                contents.map((content, index) => (
                    <Card sx={{ minWidth: 275, mb: 2 }} key={content.id || index}>
                        <CardContent
                            sx={{
                                '& .MuiCardContent-root': {
                                    paddingBottom: 1,
                                    backgroundColor: 'red',
                                },
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                        >
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column' }}
                                minWidth={40}
                            >
                                <DataTypeFlagItem
                                    capturedData={content}
                                    dataType={DataType.KEYWORDS}
                                    onChange={() => onPageChange?.(currentPage ?? 1)}
                                />
                            </Box>
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column' }}
                                onClick={() => setOpenModal(content)}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        ml: '0.625rem',
                                        flexGrow: 1,
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    {
                                        content[
                                            landingPage
                                                ? landingPageOptions[
                                                    (content.dataType) as
                                                    keyof typeof landingPageOptions
                                                ]?.titleLeft
                                                : listOptions[optionName]?.titleLeft
                                        ]
                                    }
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        ml: '0.625rem',
                                        flexGrow: 1,
                                        fontWeight: 400,
                                        fontSize: 16,
                                    }}
                                >
                                    {new Date(
                                        content[
                                            landingPage
                                                ? landingPageOptions[
                                                (content.dataType) as
                                                keyof typeof landingPageOptions]
                                                    ?.subtitleLeft
                                                : listOptions[optionName]?.subtitleLeft
                                        ]
                                    ).toLocaleDateString('en-US')}
                                </Typography>
                                {content[
                                    landingPage
                                        ? landingPageOptions[
                                            (content.dataType) as keyof typeof landingPageOptions
                                        ]?.bottonList
                                        : listOptions[optionName]?.bottonList
                                // eslint-disable-next-line
                                ]?.map((item: any, key: number) => (
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        key={`${key}_${item}`}
                                        color="#D94444"
                                        sx={{
                                            ml: '0.625rem',
                                            flexGrow: 1,
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                ))}
                                {content[
                                    landingPage
                                        ? landingPageOptions[
                                            (content.dataType) as
                                            keyof typeof landingPageOptions]
                                            ?.bottonItemLeft
                                        : listOptions[optionName]?.bottonItemLeft
                                ] && (
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            ml: '0.625rem',
                                            flexGrow: 1,
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                    >
                                        {content[
                                            landingPage
                                                ? landingPageOptions[
                                                        (content.dataType) as
                                                        keyof typeof landingPageOptions
                                                ]?.bottonItemLeft
                                                : listOptions[optionName]?.bottonItemLeft
                                        ]}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                                onClick={() => setOpenModal(content)}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        ml: '0.625rem',
                                        flexGrow: 1,
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    {content[
                                        landingPage
                                            ? landingPageOptions[
                                                (content.dataType) as
                                                keyof typeof landingPageOptions
                                            ]?.titleRight
                                            : listOptions[optionName]?.titleRight
                                    ]}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        ml: '0.625rem',
                                        flexGrow: 1,
                                        fontWeight: 400,
                                        fontSize: 16,
                                    }}
                                >
                                    {content[
                                        landingPage
                                            ? landingPageOptions[
                                                (content.dataType) as
                                                keyof typeof landingPageOptions
                                            ]?.bottonItemRight
                                            : listOptions[optionName]?.bottonItemRight
                                    ]}
                                </Typography>
                                <ArrowForward sx={{ marginLeft: '90%', mt: '1rem' }} />
                            </Box>
                        </CardContent>
                    </Card>
                ))
            )}
            {contents.length === 0 && (
                <Typography
                    variant="h3"
                    sx={{
                        color: (theme) => theme.palette.common.lightGrey,
                        margin: '4rem auto',
                        textAlign: 'center',
                    }}
                >
                    {t('data-type.table.no-content')}
                </Typography>
            )}

            {enablePagination && (
                <TablePagination
                    component="div"
                    labelRowsPerPage="Rows #"
                    count={numberOfEntries}
                    rowsPerPage={entriesPerPage}
                    page={currentPage - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeEntriesPerPage}
                />
            )}
        </>
    );
}
