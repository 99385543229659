import React from 'react';
import { useDispatch } from 'react-redux';
import { changeAdminFilters, clearSearchValue } from 'src/state/administration/administrationSlice';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';


export function AdminSettingSearchFilter(): React.ReactElement
{
    const dispatch = useDispatch();
    dispatch(clearSearchValue());
    const { t } = useTranslation();
    function onChangeAdminFilter(value: string): void
    {
        dispatch(
            changeAdminFilters({
                searchValue: value,
            })
        );
    }

    return (
        <FormControl>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                autoComplete="on"
                onChange={event => onChangeAdminFilter(event.target.value)}
            />
        </FormControl>
    );
}
