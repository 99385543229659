import { VAPID_PUBLIC_KEY } from './utils/environment';
import instance from './services/instance';

class BrowserNotificationServices
{
    private isSubscribed = false;
    private swRegistration!: ServiceWorkerRegistration;
    // eslint-disable-next-line
    public async init(): Promise<any>
    {
        if ('serviceWorker' in navigator && 'PushManager' in window)
        {
            navigator.serviceWorker.register('/service-worker.js')
                .then((swReg) =>
                {
                    this.swRegistration = swReg;
                    this.swRegistration.pushManager.getSubscription()
                        .then(async (subscription) =>
                        {
                            this.isSubscribed = (subscription !== null);
                            if (this.isSubscribed)
                            {
                                if (Notification && Notification.permission === 'granted')
                                {
                                    this.subscribeUser();
                                }
                            }
                            else if (Notification && Notification.permission === 'granted')
                            {
                                this.isSubscribed = true;
                                this.subscribeUser();
                            }
                            else
                            {
                                this.initializeUI();
                            }
                        });
                })
                .catch(err =>
                {
                    // eslint-disable-next-line
                    console.error('Service Worker Error', err);
                });
        }
    }
    private urlB64ToUint8Array(base64String: string): Uint8Array
    {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        // eslint-disable-next-line
            .replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i)
        {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    // eslint-disable-next-line
    private async initializeUI(): Promise<any>
    {

        Notification.requestPermission(status =>
        {
            if (status === 'granted')
            {
                this.isSubscribed = true;
                this.subscribeUser();
            }
            else if (status === 'denied')
            {
                alert(' Your Browser Notification Access permission is blocked, kindly check  ');
            }
        });
    }


    // eslint-disable-next-line
    private async updateSubscriptionOnServer(subscription: PushSubscription | null): Promise<any>
    {

        if (subscription)
        {
            this.sendSubscriptionToBackEnd(subscription);
        }
    }

    private applicationServerPublicKey = VAPID_PUBLIC_KEY;
    // eslint-disable-next-line
    public async subscribeUser() : Promise<any>
    {
        const applicationServerKey = this.urlB64ToUint8Array(this.applicationServerPublicKey);
        this.swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey,
        })
            .then(subscription =>
            {
                this.updateSubscriptionOnServer(subscription);
                this.isSubscribed = true;
            })
            .catch(() =>
            {
                throw new Error('Failed to subscribe the user');
            });
    }

    // eslint-disable-next-line
    private async sendSubscriptionToBackEnd(subscription : PushSubscription): Promise<any>
    {
        try
        {
            const response =
            await instance.post('/notifications/Subscribe', subscription);

            if (response.data.message === 'success')
            {
                throw new Error('Subscriber saved successfully');
            }
            return response.data;
        }
        catch (error)
        {
            throw new Error('Bad status code from server.');
        }

    }
}

window.addEventListener('load', () =>
{
    navigator.serviceWorker?.register('/service-worker.js');
});

const browserNotificationInstance = new BrowserNotificationServices();

export default browserNotificationInstance;
