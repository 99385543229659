import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WebsiteData } from 'src/models/captures/websiteData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getWebsites,
    selectFilters,
    selectWebsites,
    selectWebsitesStatus,
    getWebsiteDetails,
    selectWebsiteDetails,
    selectWebsiteDetailsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
export function WebsitesTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();

    const filters = useSelector(selectFilters);

    const websitesStatus = useSelector(selectWebsitesStatus);
    const websitesData = useSelector(selectWebsites);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});

    const websiteDetailsStatus = useSelector(selectWebsiteDetailsStatus);
    const websiteDetailsData = useSelector(selectWebsiteDetails);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };

    const handleOpen = (data: WebsiteData): void =>
    {
        openRecordData(data.id);

        dispatch(getWebsiteDetails(data.id));
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({ detailsId: data.id, dataType: DataType.WEBSITES }));
        }
        setShowModal(true);
    };

    function onPageChange(pageNumber: number): void
    {
        if (
            websitesData &&
            pageNumber !== lastPage &&
            (
                pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1
            )
        )
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(
                    websitesData.contents?.[
                        websitesData.contents.length - 1
                    ]?.id ?? ''
                );
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(
                    Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                        lastRecordDatas[lastRecordDatas.length - 1]?.id :
                        ''
                );
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getWebsites({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: WebsiteData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.WEBSITES}
            onChange={() =>
            {
                onPageChange(websitesData?.currentPage ?? 1);
            }}
        />
    );
    const getTitleColumn = (data: WebsiteData): React.ReactNode => (
        <HighlightText
            textToHighlight={data?.title || ''}
            highlightWords={data?.alertWords || ['']}
        />
    );
    // eslint-disable-next-line
    const getUrlColumn = (data: WebsiteData): React.ReactNode => (
        <a
            href={(data.url?.includes('http')) ? data.url : ('http://' + data.url)}
            title={data.url}
            target="_blank"
            rel="noreferrer"
        >
            {data?.url?.length > 250 ? (
                <HighlightText
                    textToHighlight={data.url?.substring(0, 250) + '...'}
                    highlightWords={data?.alertWords || ['']}
                />
            ) : (
                <HighlightText
                    textToHighlight={data?.url || ''}
                    highlightWords={data?.alertWords || ['']}
                />
            )}
        </a>
    );
    const openDetailColumn = (data: WebsiteData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: WebsiteData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: WebsiteData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: WebsiteData) => new Date(data.capturedDate)?.toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: WebsiteData) => new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: WebsiteData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.user-agent'),
            size: 4,
            value: (data: WebsiteData) => data.userAgent,
            sortByType: 'userAgent',
        },
        {
            label: t('data-type.table.title'),
            size: 4,
            value: (data: WebsiteData) => data?.title,
            sortByType: 'title',
            format: (data: WebsiteData) => getTitleColumn(data),
        },
        {
            label: t('data-type.table.url'),
            size: 4,
            value: (data: WebsiteData) => data.url,
            sortByType: 'url',
            format: (data: WebsiteData) => getUrlColumn(data),
        },
        {
            label: t('data-type.details.alert-words'),
            value: (data: WebsiteData) => data?.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: WebsiteData) => openDetailColumn(data),
        },
    ];

    const detailItems = [
        {
            label: t('data-type.table.captured-date'),
            text: (data: WebsiteData) => timeZoneFormat(
                new Date(data.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.received-date'),
            text: (data: WebsiteData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: WebsiteData) => data.device,
        },

        {
            label: t('data-type.table.title'),
            text: (data: WebsiteData) => data?.title.substring(0, 35) + '...',
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data: WebsiteData) => data?.alertWords?.join(', '),
        },
        {
            label: t('data-type.table.platform'),
            text: (data: WebsiteData) => data?.platform,
        },
        {
            label: t('data-type.table.url'),
            text: (data: WebsiteData) => data.url,
        },
        {
            label: t('data-type.table.user-agent'),
            text: (data: WebsiteData) => data.userAgent,
        },
    ];

    useEffect(() =>
    {
        if (websitesData)
        {
            setLastPage(
                Math.floor(websitesData.numberOfEntries / entriesPerPage)
            );
            pdfExportSetter({
                type: DataType.WEBSITES,
                recordIds: websitesData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(websitesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.WEBSITES)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [websitesData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<WebsiteData>
                title={t('data-type.details.websites-title')}
                open={showModal}
                data={websiteDetailsData}
                detailItems={detailItems}
                isLoading={websiteDetailsStatus === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: websiteDetailsData?.id,
                    type: CommentType.WEBSITES,
                }}
            />


            {useDesktopScreen() ? (
                <DataTable<WebsiteData>
                    isLoading={websitesStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                        onPageChange(pageNumber)
                    }
                    onChangeEntriesPerPage={(entriesPerPage) =>
                        updateEntriesPerPage(entriesPerPage)
                    }
                    datas={datas}
                    {...websitesData}
                />
            ) : (
                <DataTableMobile
                    isLoading={websitesStatus === RequestStatus.InProgress}
                    contents={websitesData?.contents || []}
                    setOpenModal={handleOpen}
                    onPageChange={(pageNumber) =>
                        onPageChange(pageNumber)
                    }
                    onChangeEntriesPerPage={(entriesPerPage) =>
                        updateEntriesPerPage(entriesPerPage)
                    }
                    entriesPerPage={entriesPerPage}
                    numberOfEntries={websitesData?.numberOfEntries}
                    currentPage={websitesData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
