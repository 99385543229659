import { Role } from 'src/models/roles.enum';
import { User } from 'src/models/userModels';
import { Keys, read } from './storage';

export function userIsInRole(allowedRoles: Role[] | undefined): boolean
{
    const user = read<User>(Keys.User);
    if (!allowedRoles?.length) return true;
    return allowedRoles.some(role => role === user?.role);
}
