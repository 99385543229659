import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import { UserSessionResponse } from 'src/models/administration/userSessionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    selectAssignedOfficerSession,
    selectAssignedOfficerSessionStatus,
    getAssignedOfficerSessions,
    getUserlogDetails,
} from 'src/state/administration/administrationSlice';
import { selectSessionFilters } from 'src/state/captures/capturesSlice';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { IconButton } from '@mui/material';
import { MilliSECONDS } from 'src/utils/environment';
import { UserLogActivityModal } from 'src/pages/session-logs/UserLogActivityModal';

export function AssignedOfficerLogs(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const status = useSelector(selectAssignedOfficerSessionStatus);
    const userSessions = useSelector(selectAssignedOfficerSession);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [modelOpen, setModelOpen]=useState(false);
    const [userData, setUserData]=useState();
    const filters = useSelector(selectSessionFilters);

    function onPageChange(pageNumber: number): void
    {
        dispatch(
            getAssignedOfficerSessions({
                pageNumber,
                entriesPerPage,
                ...filters,
            })
        );
    }

    useEffect(() =>
    {
        dispatch(
            getAssignedOfficerSessions({
                pageNumber: 1,
                entriesPerPage,
                ...filters,
            })
        );
    }, [dispatch, entriesPerPage, filters]);

    // eslint-disable-next-line
    function handleOnchange(data:any): void
    {
        setModelOpen(true);

        setUserData(data);
        dispatch(
            getUserlogDetails({
                pageNumber: 1,
                entriesPerPage,
                userId: data.userId,
                fromDate: data.loggedInAt,
                toDate: data.trackedAt,
            })
        );
    }
    function closeModal(): void
    {
        setModelOpen(false);
    }

    const getActivityColumn = (data: UserSessionResponse): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={()=>
            {
                handleOnchange(data);
            }}
        >
            <ListAltIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.user-name'),
            size: 1,
            value: (data: UserSessionResponse) => data.userName,
        },
        {
            label: t('data-type.table.user-id'),
            size: 1,
            value: (data: UserSessionResponse) => data.userId,
        },
        {
            label: t('data-type.table.session-start'),
            size: 1,
            value: (data: UserSessionResponse) =>
                data.loggedInAt
                    ? new Date(data.loggedInAt)?.toLocaleString()
                    : '-',
        },
        {
            label: t('data-type.table.session-end'),
            size: 1,
            value: (data: UserSessionResponse) =>
            {
                if (data.loggedOutAt)
                {
                    return new Date(data.loggedOutAt)?.toLocaleString();
                }
                else if (data.trackedAt)
                {
                    const trackedTime = new Date(data.trackedAt);
                    const currentTime = new Date().getTime();
                    const timeDifferenceMs = currentTime - trackedTime.getTime();

                    if (timeDifferenceMs >= MilliSECONDS)
                    {
                        return trackedTime.toLocaleString();
                    }
                    else
                    {
                        return '-';
                    }
                }
                else
                {
                    return '-';
                }
            },
        },
        {
            label: t('data-type.table.total-session-time'),
            size: 1,
            value: (data: UserSessionResponse) =>
            {
                const startTime = new Date(data.loggedInAt).getTime();
                let endTime: number | undefined;

                if (data.loggedOutAt)
                {
                    endTime = new Date(data.loggedOutAt).getTime();
                }
                else if (data.trackedAt)
                {
                    const trackedAtTime = new Date(data.trackedAt).getTime();
                    const twelveHoursAgo = Date.now() - MilliSECONDS;
                    if (trackedAtTime <= twelveHoursAgo)
                    {
                        endTime = trackedAtTime;
                    }
                }

                if (startTime && endTime)
                {
                    const durationMs = endTime - startTime;
                    const hours = Math.floor(durationMs / (1000 * 60 * 60));
                    const minutes = Math.floor(
                        (durationMs % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    const seconds = Math.floor(
                        (durationMs % (1000 * 60)) / 1000
                    );
                    return `${hours}h ${minutes}m ${seconds}s`;
                }
                else
                {
                    return '-';
                }
            },
        },
        {
            label: t('data-type.table.activity'),
            size: 1,
            format: (data: UserSessionResponse) => getActivityColumn(data),
        },
    ];

    return (
        <>
            <DataTable<UserSessionResponse>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...userSessions}
            />

            {
                modelOpen && (
                    <UserLogActivityModal
                        data={userData}
                        open={modelOpen}
                        onClose={closeModal}
                    // onActionComplete={onComplete}
                    />
                )
            }
        </>
    );
}
