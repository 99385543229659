import { APICaptureData, readAPICaptureData } from './captures';
import { EmailData } from 'src/models/captures/emailData.model';
import { EmailApps } from 'src/models/emailApps.enum';


export interface APIEmailResponse extends APICaptureData {
    mailApplication?: EmailApps;
    alreadyExisted?: string[];
    emailId: string;
    sender: string;
    cc?: string[];
    bcc?: string[];
    recipients: string[];
    subject: string;
    emailBody: string;
    emailDate: Date;
    desktop: boolean;
    attachments: string[];
    alertWords: string[];
    fileSizeExceed?: boolean;
}

export function readAPIEmailResponse(
    data: APIEmailResponse
): EmailData
{
    const {
        mailApplication,
        alreadyExisted,
        emailId,
        sender,
        cc,
        bcc,
        recipients,
        subject,
        emailBody,
        emailDate,
        desktop,
        attachments,
        alertWords,
        fileSizeExceed,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        mailApplication,
        alreadyExisted,
        emailId,
        sender,
        cc,
        bcc,
        recipients,
        subject,
        emailBody,
        emailDate,
        desktop,
        attachments,
        alertWords,
        fileSizeExceed,
    };
}
