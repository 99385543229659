import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CancelIcon from '@mui/icons-material/Cancel';
import {
    SelectProps,
    FormControlProps,
    IconButton,
    CircularProgress,
    FormHelperText,
    Box,
    Checkbox,
    ListItemIcon,
    ListItemText,
    Typography,
    SxProps,
    Theme,
} from '@mui/material';
import { noop } from 'src/utils/common';
export type { SelectChangeEvent } from '@mui/material/Select';

export interface DropdownOption {
    // eslint-disable-next-line
    value?: any;
    name?: string;
    icon?: React.ReactElement;
    divider?: boolean;
}

export interface DropdownSelectorProps extends SelectProps {
    checkBoxSelection?: boolean
    options?: DropdownOption[]
    parentProps?: FormControlProps
    clearable?: boolean
    isLoading?: boolean
    selectAll?: boolean
    helperText?: string
    fullWidth?: boolean
    displayEmpty?: boolean
    disabled?: boolean
    required?: boolean
    multiple?: boolean
    error?: boolean
    labelId?: string
    name?: string
    label?: string
    // eslint-disable-next-line
    value?: any
    sx?: SxProps
    inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
        sx?: SxProps<Theme>
    }
    onBlur?: (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    onChange?: (value: unknown) => void
}

export function DropdownSelector(
    {
        options,
        parentProps,
        clearable = false,
        value = '',
        isLoading = false,
        selectAll = false,
        checkBoxSelection = false,
        helperText = '',
        onChange = noop,
        ...props
    }: Readonly<DropdownSelectorProps>
): React.ReactElement
{
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<unknown>(value);
    const selectAllValue = t('data-type.filters.show-all');
    const sortedOptions = [...(options ?? [])].sort(
        (a, b) => (a?.name?.toLowerCase() || '') > (b?.name?.toLowerCase() || '') ? 1 : -1
    );
    const isAllSelected = Array.isArray(selectedValue)
        ? (options?.length && selectedValue?.length === options?.length)
        : false;

    function onChangeHandler(event: SelectChangeEvent<unknown>, child: React.ReactNode): void
    {
        const { value } = event.target;
        if(Array.isArray(selectedValue)
        && checkBoxSelection
        && Array.isArray(value)
        && value[value.length - 1] === selectAllValue)
        {
            const newValue = selectedValue.length === options?.length
                ? []
                : options?.map(item => item.value);

            setSelectedValue(
                newValue
            );
            if(newValue?.length)
            {
                onChange(newValue);
            }

        }
        else
        {
            setSelectedValue(value);
            onChange(value);
        }


    }

    function onSelectAll(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) : void
    {
        const { value } = event.target;
        if(Array.isArray(selectedValue)
        && checkBoxSelection
        && Array.isArray(value))

        {
            setSelectedValue(
                selectedValue.length === options?.length
                    ? []
                    : options?.map(item => item.value)
            );
        }
    }

    function onClear(): void
    {
        setSelectedValue('');
        onChange('');
    }

    useEffect(() =>
    {
        setSelectedValue(value);
    }, [value]);

    return (
        <FormControl
            fullWidth={props.fullWidth}
            error={props.error}
            {...parentProps}
        >
            <Box
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <InputLabel id={props.labelId} shrink={props.displayEmpty}>
                    {props.label}
                </InputLabel>
                <Select
                    displayEmpty={!options?.length}
                    {...props}
                    value={selectedValue}
                    onChange={onChangeHandler}
                    renderValue={Array.isArray(selectedValue)
                        ? (selected) => Array.isArray(selected) && selected.join(', ')
                        : undefined}
                    disabled={isLoading || props.disabled}
                >
                    {(Array.isArray(selectedValue) && checkBoxSelection && options) && (
                        <MenuItem
                            value={selectAllValue}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={isAllSelected as boolean}
                                    onChange={onSelectAll}
                                    indeterminate={
                                        selectedValue.length > 0
                                        && selectedValue.length < options?.length
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={selectAllValue}
                            />
                        </MenuItem>
                    )}
                    {sortedOptions?.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.divider}
                        >
                            { (checkBoxSelection || option.icon) && (
                                <ListItemIcon>
                                    {checkBoxSelection && (
                                        <Checkbox
                                            checked={
                                                Array.isArray(selectedValue)
                                                    && selectedValue.indexOf(
                                                        option?.value?.toString()
                                                    ) > -1}
                                        />
                                    )}
                                    {option.icon && (
                                        <Box
                                            marginRight={1}
                                            marginTop={checkBoxSelection ? 1.8 :.30}
                                        >
                                            {option.icon}
                                        </Box>
                                    )}
                                </ListItemIcon>
                            )}
                            {option.divider ? (
                                <Typography variant="h5">
                                    {option.name}
                                </Typography>
                            ) : option.name}
                        </MenuItem>
                    ))}
                </Select>
                {
                    clearable
                        ? (
                            <IconButton
                                onClick={onClear}
                                disabled={selectedValue === '' || isLoading}
                            >
                                {
                                    isLoading
                                        ? <CircularProgress size={'0.7em'} />
                                        : <CancelIcon fontSize="small" />
                                }
                            </IconButton>
                        )
                        : null
                }
            </Box>
            {
                helperText && (
                    <FormHelperText>
                        {helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}
