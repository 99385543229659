import { APICaptureData, readAPICaptureData } from './captures';
import { ProcessData } from 'src/models/captures/processData.model';


export interface APIProcessResponse extends APICaptureData {
    totalIntervalTime: number;
    localId: string;
    name: string;
    captureTime: Date;
    createdAt: string,
    clientCreatedAt: string,
    intervalTime: number
}

export function readAPIProcessesResponse(
    data: APIProcessResponse
): ProcessData
{
    const {
        totalIntervalTime,
        intervalTime,
        localId,
        name,
        captureTime,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        totalIntervalTime,
        intervalTime,
        localId,
        name,
        captureTime,
    };
}
