import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PasswordInput } from 'src/components/password-input/PasswordInput';
import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';
import { checkPassword } from 'src/services/user';
import { PasswordRule } from 'src/models/userModels';
import { FORGOT_PASSWORD } from 'src/utils/routes';
import { verifyToken, selectVerifyTokenStatus } from 'src/state/user/userSlice';

import { PasswordRules } from './PasswordRules';
import { RequestStatus } from 'src/models/requestStatus.enum';

export interface PasswordChangeFormProps
{
    title: string,
    handleCancel: () => void;
    handleChangePassword: (password: string, confirmPassword: string) => void;
    isChangingPassword?: boolean;
    hasError: boolean;
    expiredError?: boolean;
}

export function PasswordChangeForm(
    {
        title,
        handleCancel,
        handleChangePassword,
        isChangingPassword = false,
        hasError = false,
        expiredError= false,
    }: Readonly<PasswordChangeFormProps>
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [displayExpiredError, setDisplayExpiredError] = useState(expiredError);
    const verifyTokenStatus = useSelector(selectVerifyTokenStatus);
    const { forgotPasswordToken = '' } = useParams();

    const [passwordInputValue, setPasswordInputValue] = useState('');
    function onChangePasswordInputValue(event: React.ChangeEvent<HTMLInputElement>): void
    {
        setPasswordInputValue(event.currentTarget.value);
    }
    const [confirmPasswordInputValue, setConfirmPasswordInputValue] = useState('');
    function onChangeConfirmPasswordInputValue(event: React.ChangeEvent<HTMLInputElement>): void
    {
        setConfirmPasswordInputValue(event.currentTarget.value);
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        handleChangePassword(
            passwordInputValue,
            confirmPasswordInputValue
        );
    }

    const [displayError, setDisplayError] = useState(hasError);
    function clearError(): void
    {
        setDisplayError(false);
    }

    const [passwordRules, setPasswordRules] = useState<PasswordRule[]>([]);
    useEffect(() =>
    {
        clearError();
        checkPassword(passwordInputValue, confirmPasswordInputValue).then(setPasswordRules);
    }, [confirmPasswordInputValue, passwordInputValue]);

    useEffect(() =>
    {
        setDisplayError(hasError);
    }, [hasError]);

    useEffect(() =>
    {
        if(forgotPasswordToken)
        {
            dispatch(verifyToken({ forgotPasswordToken }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPasswordToken]);

    useEffect(() =>
    {
        setDisplayExpiredError(expiredError);
    }, [expiredError]);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            if(verifyTokenStatus === RequestStatus.Failure)
            {
                navigate(FORGOT_PASSWORD);
            }
        }, 3700);
    }, [verifyTokenStatus, navigate]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                height: '25rem' /* 400px */,
                mt: { xs: 4 },
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: theme => theme.palette.common.lightGrey,
                        fontSize: '1.25rem', // 20px
                        mb: 2, // margin-bottom 16px
                    }}
                >
                    {title}
                </Typography>

                <Grid container>
                    <Grid item md={9} xs={12}>
                        <PasswordInput
                            fullWidth
                            name="password"
                            id="password-field"
                            label={t('password-change.password-input-label')}
                            value={passwordInputValue}
                            onChange={onChangePasswordInputValue}
                            parentSx={{
                                '& input, & label': {
                                    color: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                '& svg': {
                                    fill: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                opacity: isChangingPassword ? 0.5 : 1,
                            }}
                            disabled={isChangingPassword}
                        />

                        <Box sx={{ mt: 2, mb: 4 }}>
                            <PasswordRules passwordRules={passwordRules} hasError={displayError} />
                        </Box>

                        <PasswordInput
                            fullWidth
                            name="confirm-password"
                            id="password-field"
                            label={t('password-change.confirm-password-input-label')}
                            value={confirmPasswordInputValue}
                            onChange={onChangeConfirmPasswordInputValue}
                            parentSx={{
                                '& input, & label': {
                                    color: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                '& svg': {
                                    fill: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                opacity: isChangingPassword ? 0.5 : 1,
                            }}
                            disabled={isChangingPassword}
                        />
                    </Grid>

                    {displayError && (
                        <Grid item xs={11}>
                            <Box
                                sx={{
                                    mt: 4, // margin-top 32px
                                    fontSize: '0.75rem', // 12px
                                    color: theme => theme.palette.error.main,
                                }}
                            >
                                {t('generic.failed-message')}
                            </Box>
                        </Grid>
                    )}

                    {displayExpiredError && (
                        <Grid item xs={11}>
                            <Box
                                sx={{
                                    mt: 4, // margin-top 32px
                                    fontSize: '0.75rem', // 12px
                                    color: theme => theme.palette.error.main,
                                }}
                            >
                                {t('expired.expired-message')}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button
                    type="reset"
                    sx={{
                        mr: 1, // margin-right: 8px
                        backgroundColor: 'transparent',
                        color: theme => theme.palette.common.goldYellow,
                        opacity: isChangingPassword ? 0.5 : 1,
                        '&.Mui-disabled': {
                            backgroundColor: 'inherit',
                            color: theme => theme.palette.common.goldYellow,
                        },
                    }}
                    disabled={isChangingPassword}
                    onClick={handleCancel}
                >
                    {t('password-change.cancel-button')}
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: '7.5rem', // 120px
                        '&.Mui-disabled': {
                            backgroundColor: theme => theme.palette.primary.main,
                            color: theme => theme.palette.primary.contrastText,
                        },
                    }}
                    disabled={isChangingPassword}
                >
                    {t(isChangingPassword
                        ? 'password-change.save-button-loading'
                        : 'password-change.save-button')}
                    {isChangingPassword && <AnimatedEllipsis />}
                </Button>
            </Box>
        </Box>
    );
}
