import React, { FocusEvent, useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    SnackbarProps,
    Stack,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getProfile,
    resetProfileStatus,
    selectProfile,
    selectProfileUpdateStatus,
    updateProfile,
    selectProfileError,
} from 'src/state/administration/administrationSlice';
import { BotSelector } from 'src/templates/bot-selector/BotSelector';
import {
    ConfigurationBot,
    ProfileParams,
} from 'src/models/administration/profileData.model';
import { noop } from 'src/utils/common';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
const initialValues: ProfileParams = {
    name: '',
    bots: [],
    description: '',
};

export interface EditProfileManagementModalProps {
    onCloseModal?: () => void;
    id?: string;
}

export function EditProfile({
    onCloseModal = noop,
    id,
} : Readonly<EditProfileManagementModalProps>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updateStatus = useSelector(selectProfileUpdateStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [formValues, setFormValues] = useState<ProfileParams>(initialValues);
    const profile = useSelector(selectProfile);
    const profileErrorValue = useSelector(selectProfileError);
    function onSubmit(event: React.FormEvent): void
    {
        event.preventDefault();

        if (profile)
        {
            dispatch(updateProfile({
                ...formValues,
                id: profile.id,
            }));
          
        }
    }

    function updateValues(
        { name, value }: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
        trim = false
    ): void
    {
        const finalValue = trim ? value.trim() : value;

        setFormValues((current) => ({
            ...current,
            [name]: finalValue,
        }));
    }

    function updateBots(newbots : ConfigurationBot[]): void
    {
        setFormValues((current) => ({
            ...current,
            bots: newbots,
        }));
    }

    function onInputChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void
    {
        updateValues(event.target);
    }

    function onBlur(event: FocusEvent<HTMLInputElement>): void
    {
        updateValues(event.target, true);
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    useEffect(() =>
    {
        let message = '';
        if (updateStatus === RequestStatus.Success)
        {
            const profileString = t('profiles.profile');
            message = t('common.messages.saved-successfully', { subject: profileString });
            setFormValues(initialValues);
        }
        else if (updateStatus === RequestStatus.Failure
            && formValues.name)
        {
            message = t('common.messages.profile-already-exist');
        }

        if (message)
        {
            setNotificationState({ open: true, message });
            setTimeout(() =>
                {
                    if(!profileErrorValue){
                    onCloseModal();
                    }
                }, 3000);
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus, setNotificationState, t, dispatch]);

    useEffect(() =>
    {
        if (id)
        {
            dispatch(getProfile(id));
        }
    }, [id, dispatch]);

    useEffect(() =>
    {
        if (profile && profile.id === id)
        {
            setFormValues(profile);
        }
    }, [profile, setFormValues, id]);

    return (
        <>
            <Box>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={5} maxWidth="1200px">
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                name="name"
                                label={t('common.nouns.name')}
                                variant="outlined"
                                value={formValues.name}
                                onChange={onInputChange}
                                onBlur={onBlur}
                                // eslint-disable-next-line max-len
                                disabled={updateStatus === RequestStatus.InProgress}
                                fullWidth
                                error={handleError(profileErrorValue, 'profileName',formValues.name)}
                                helperText={handleErrorText(profileErrorValue, 'profileName',formValues.name)}

                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <BotSelector
                                onInputChange={updateBots}
                                bots={formValues.bots}
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                name="description"
                                label={t('common.nouns.description')}
                                variant="outlined"
                                value={formValues.description}
                                onChange={onInputChange}
                                // eslint-disable-next-line max-len
                                disabled={updateStatus === RequestStatus.InProgress}
                                multiline
                                rows={4}
                                error={handleError(profileErrorValue, 'description')}
                                helperText={handleErrorText(profileErrorValue, 'description')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap="1em"
                        sx={{ padding: '1em' }}
                    >
                        {
                            updateStatus === RequestStatus.InProgress
                                ? <CircularProgress />
                                : (
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        sx={{ mt: 0 }}
                                    >
                                        <Button
                                            sx={{
                                                mr: 1,
                                                backgroundColor: 'transparent',
                                                color: theme => theme.palette.common.goldYellow,
                                                '&.Mui-disabled': {
                                                    backgroundColor: 'inherit',
                                                    // eslint-disable-next-line
                                                    color: theme => theme.palette.common.goldYellow,
                                                },
                                            }}
                                            onClick={() => onCloseModal()}
                                        >
                                            {t('form.cancel')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                        >
                                            {t('common.actions.save')}
                                        </Button>
                                    </Box>
                                )
                        }
                    </Stack>
                </form>
            </Box>
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={updateStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
