import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import { readAPIScreenCaptureResponse } from '../data-transfer-objects/capture/screenCaptures';

import instance from '../instance';
import { CaptureParams, GetAlertsId } from 'src/models/captures/capturedData.model';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import { ScreenCaptureData } from 'src/models/captures/screenCaptureData.model';

export async function getScreenCaptures(
    params: CaptureParams
): Promise<PaginatedContent<ScreenCaptureData>>
{
    const response = await instance.get(
        'images/screenshot/manual',
        { params: transformCaptureParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIScreenCaptureResponse);
}

export async function getScreenCapturesIds(
    params: GetAlertsId
): Promise<string[]>
{
    const response = await instance.get(
        'images/screenshot/manual/getIds',
        { params }
    );

    return response.data;
}
