import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { HOME, FORGOT_PASSWORD } from 'src/utils/routes';
import { LoginHeader } from 'src/templates/login-header/LoginHeader';
import { LoginForm } from 'src/templates/login-form/LoginForm';
import { AuthenticationPage } from 'src/templates/authentication-page/AuthenticationPage';
import { ReactComponent as PadlockImage } from 'src/assets/images/padlock.svg';
import { selectLoginStatus, login } from 'src/state/authorization/authorizationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import BrowserNotificationServices from '../../browsernotification';

export function Login(): JSX.Element
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginStatus = useSelector(selectLoginStatus);

    function handleLogin(
        email: string,
        password: string
    ): void
    {
        dispatch(login({
            email,
            password,
        }));
    }

    if (loginStatus === RequestStatus.Success)
    {
        navigate(HOME);
        Notification.requestPermission((status) =>
        {
            if (status === 'granted')
            {
                BrowserNotificationServices.init();
            }
        });
    }

    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.common.darkGrey,
            }}
        >
            <LoginHeader />
            <AuthenticationPage image={<PadlockImage />}>
                <LoginForm
                    handleLogin={handleLogin}
                    forgotPasswordRoute={FORGOT_PASSWORD}
                    isLoggingIn={loginStatus === RequestStatus.InProgress}
                    hasLoginError={loginStatus === RequestStatus.Failure}
                />
            </AuthenticationPage>
        </Box>
    );
}
