import React, { createContext, useContext, useState } from 'react';
import { GenerateReportParams } from 'src/models/captures/report.model';
import { noop } from 'src/utils/common';

export enum TableExportFormat {
    PDF = 'PDF',
    CSV = 'CSV',
    XLSX = 'XLSX',
    ALLXLSX='All XLSX'
}

export interface TableExporterContextInterface {
    format: TableExportFormat | undefined;
    setExportFormat: (format: TableExportFormat | undefined) => void;
}

export interface PDFExportParams extends GenerateReportParams {
    pdfEnabled?: boolean;
}

const TableExporterContext = createContext<TableExporterContextInterface>({
    format: undefined,
    setExportFormat: noop,
});

const TablePDFExportContext = createContext<PDFExportParams | undefined>(undefined);
const TablePDFExportSetterContext = createContext<
    (params: PDFExportParams | undefined) => void
        >(noop);

export interface TableExporterProviderProps {
    children: React.ReactNode
}

export function useTableExporter(): TableExporterContextInterface
{
    return useContext(TableExporterContext);
}

export function usePDFExport(): PDFExportParams | undefined
{
    return useContext(TablePDFExportContext);
}

export function usePDFExportSetter(): (params: PDFExportParams | undefined) => void
{
    return useContext(TablePDFExportSetterContext);
}

function TableExporterProvider({ children }: Readonly<TableExporterProviderProps>): JSX.Element
{
    const defaultValue: TableExporterContextInterface = {
        format: undefined,
        setExportFormat,
    };
    const [value, setValue] = useState<TableExporterContextInterface>(defaultValue);
    const [pdfExporter, setPDFExporter] = useState<PDFExportParams | undefined>(undefined);

    function setExportFormat(format: TableExportFormat | undefined): void
    {
        setValue({
            ...value,
            format,
        });
    }

    return (
        <TableExporterContext.Provider value={value}>
            <TablePDFExportContext.Provider value={pdfExporter}>
                <TablePDFExportSetterContext.Provider value={setPDFExporter}>
                    {children}
                </TablePDFExportSetterContext.Provider>
            </TablePDFExportContext.Provider>
        </TableExporterContext.Provider>
    );
}

export default TableExporterProvider;
