import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useDesktopScreen = (): boolean =>
{
    const theme = useTheme();
    const checkDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    return checkDesktopScreen;
};
