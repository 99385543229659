import { ActionCreatorWithoutPayload, AsyncThunk } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import {
    AgencyAWLConfiguration,
    AgencyAWLConfigurationUpdateParams,
} from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    clearAgencyAWLConfiguration,
    getAgencyAWLConfiguration,
    resetAgencyAWLConfigurationRetrieveStatus,
    resetAgencyAWLConfigurationSaveStatus,
    selectAgencyAWLConfiguration,
    selectAgencyAWLConfigurationRetrieveStatus,
    selectAgencyAWLConfigurationSaveStatus,
    updateAgencyAWLConfiguration,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';

type AWLSetter = (ids: string[]) => void;

const AgencyAWLConfigurationContext = React.createContext<
    AgencyAWLConfiguration | undefined
>(undefined);
const SuggestedAWLsContext = React.createContext<AWLSetter>(noop);
const CustomAWLsContext = React.createContext<AWLSetter>(noop);

const onAWLsChange = (
    selection: AgencyAWLConfigurationUpdateParams,
    fn: (selection: AgencyAWLConfigurationUpdateParams) => void
): void =>
{
    fn(selection);
};

export function useConfiguration(): AgencyAWLConfiguration | undefined
{
    return useContext(AgencyAWLConfigurationContext);
}

export function useGetConfiguration(): AsyncThunk<
    AgencyAWLConfiguration,
    string | undefined,
    Record<string, unknown>
    >
{
    return getAgencyAWLConfiguration;
}

export function useConfigurationRetrieveStatus(): RequestStatus
{
    return useSelector(selectAgencyAWLConfigurationRetrieveStatus);
}

export function useResetConfigurationRetrieveStatus(): ActionCreatorWithoutPayload<string>
{
    return resetAgencyAWLConfigurationRetrieveStatus;
}

export function useConfigurationSaveStatus(): RequestStatus
{
    return useSelector(selectAgencyAWLConfigurationSaveStatus);
}

export function useResetConfigurationSaveStatus(): ActionCreatorWithoutPayload<string>
{
    return resetAgencyAWLConfigurationSaveStatus;
}

export function useSetSuggestedAWLs(): AWLSetter
{
    return useContext(SuggestedAWLsContext);
}

export function useSetCustomAWLs(): AWLSetter
{
    return useContext(CustomAWLsContext);
}

export function AgencyAWLConfigurationContextProvider({
    children,
}: PropsWithChildren<{ children?: React.ReactNode }>): React.ReactElement
{
    const dispatch = useDispatch();
    const agencyAWLConfiguration = useSelector(selectAgencyAWLConfiguration);
    const [suggestedAWLs, setSuggestedAWLs] = useState<string[]>([]);
    const [customAWLs, setCustomAWLs] = useState<string[]>([]);
    const debouncedOnChange = useDebounce(onAWLsChange, 2000);
    const initialSet = useRef<boolean>(true);
    const currentId = useRef<string>();

    function saveAWLs(selection: AgencyAWLConfigurationUpdateParams): void
    {
        if (agencyAWLConfiguration)
        {
            dispatch(updateAgencyAWLConfiguration({
                ...agencyAWLConfiguration,
                ...selection,
            }));
        }
    }

    useEffect(() =>
    {
        if (!initialSet.current)
        {
            debouncedOnChange({
                suggested: suggestedAWLs,
                custom: customAWLs,
            }, saveAWLs);
        }
        else if (agencyAWLConfiguration)
        {
            initialSet.current = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestedAWLs, customAWLs, debouncedOnChange]);

    useEffect(() =>
    {
        initialSet.current = agencyAWLConfiguration?.id !== currentId.current;
        currentId.current = agencyAWLConfiguration?.id;

        if (agencyAWLConfiguration)
        {
            setSuggestedAWLs(agencyAWLConfiguration?.suggested.map(({ id }) => id));
            setCustomAWLs(agencyAWLConfiguration?.custom.map(({ id }) => id));
        }
    }, [agencyAWLConfiguration]);

    useEffect(() =>
    {
        return (): void =>
        {
            dispatch(clearAgencyAWLConfiguration());
        };
    }, [dispatch]);

    return (
        <AgencyAWLConfigurationContext.Provider value={agencyAWLConfiguration}>
            <SuggestedAWLsContext.Provider value={setSuggestedAWLs}>
                <CustomAWLsContext.Provider value={setCustomAWLs}>
                    {children}
                </CustomAWLsContext.Provider>
            </SuggestedAWLsContext.Provider>
        </AgencyAWLConfigurationContext.Provider>
    );
}
