export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:forgotPasswordToken';
export const CHANGE_PASSWORD = '/change-password';
export const ACTIVATE_USER = '/users/activate/:activateUserToken';

export const HOME = '/home';

export const DATA_TYPE = '/data-type';
export const KEYWORDS = `${DATA_TYPE}/keywords`;
export const SCREENSHOTS = `${DATA_TYPE}/screenshots`;
export const SCREENSHOTSMETA = `${DATA_TYPE}/screenshotsmeta`;
export const SCREENCAPTURES = `${DATA_TYPE}/screen-captures`;
export const PHOTOS = `${DATA_TYPE}/photos`;
export const TEXTSMS = `${DATA_TYPE}/text-sms`;
export const TEXTMMS = `${DATA_TYPE}/text-mms`;
export const EXTERNALDEVICES = `${DATA_TYPE}/external-devices`;
export const FILESTRACKING = `${DATA_TYPE}/files-tracking`;
export const INSTALLEDPROGRAMS = `${DATA_TYPE}/installed-programs`;
export const GEOLOCATIONS = `${DATA_TYPE}/geolocations`;
export const EMAILS = `${DATA_TYPE}/emails`;
export const LOGONEVENTS = `${DATA_TYPE}/logon-events`;
export const CALLHISTORY = `${DATA_TYPE}/call-history`;
export const PRINTEDDOCUMENTS = `${DATA_TYPE}/printed-documents`;
export const PROCESS = `${DATA_TYPE}/process`;
export const WEBSITES = `${DATA_TYPE}/websites`;
export const SEARCHES = `${DATA_TYPE}/searches`;

export const USER_MANAGEMENT = '/administration/users';
export const USER_MANAGEMENT_CREATE = '/administration/users/create';
export const USER_MANAGEMENT_EDIT = '/administration/users/:id/edit';
export const INSTRUCTION = '/adminstration/instructions';
export const INSTRUCTION_CREATE = '/adminstration/instructions/create';
export const INSTRUCTION_EDIT = '/adminstration/instructions/:id/edit';
export const DEVICES_MANAGEMENT = '/administration/devices';

export const AGENCY_MANAGEMENT = '/administration/agencies';
export const CLIENT_MANAGEMENT = '/administration/clients';

export const REPORTS_MANAGMENT = '/administration/reports';
export const REPORTS_CREATE = '/administration/reports/create';
export const REPORTS_EDIT = '/administration/reports/edit/:id';

export const NOTIFICATIONS_MANAGMENT = '/administration/notifications';
export const NOTIFICATIONS_CREATE = '/administration/notifications/create';
export const NOTIFICATIONS_EDIT = '/administration/notifications/edit/:id';

export const AGENCY_SETTINGS_MANAGEMENT ='/administration/agency/:id/settings';

export const CLIENT_SETTINGS_MANAGEMENT = '/administration/client/:id/settings';
export const CLIENT_DEVICES_MANAGEMENT = '/administration/client/:id/devices';

export const DEVICE_SETTINGS_MANAGEMENT = '/administration/device/:id/settings';

export const OFFICER_MANAGEMENT = '/administration/officer';

export const AWL_NEW = '/administration/alert-words/new';
export const AWL_EDIT = '/administration/alert-words/:context/:id/edit';
export const AWL_SUGGESTED_NEW = '/administration/alert-words/suggested/new';
export const AWL_MANAGEMENT = '/administration/settings';

export const ADMIN_SETTINGS = '/administration/settings';

export const SESSION_LOGS = '/sessionLogs';
export const ALERT_WORDS_PAGE = '/administration/account-information';

export const LANDING_PAGE = '/landing-page';

