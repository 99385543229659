import {
    createAsyncThunk,
    createSlice,
    createSelector,
} from '@reduxjs/toolkit';

import {
    forgotPassword as forgotPasswordRequest,
    resetPassword as resetPasswordRequest,
    changePassword as changePasswordRequest,
    activateUser as activateUserRequest,
    verifyUser as verifyUserRequest,
} from 'src/services/user';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    ForgotPasswordRequest,
    ResetPasswordRequest,
    ChangePasswordRequest,
    ActivateUserRequest,
    ResetPasswordLinkRequest,
} from 'src/models/userModels';

import { RootState } from '../store';

export interface UserState {
    forgotPasswordStatus: RequestStatus;
    resetPasswordStatus: RequestStatus;
    changePasswordStatus: RequestStatus;
    activateUserStatus: RequestStatus;
    verifyTokenStatus: RequestStatus;
}

const initialState: UserState = {
    forgotPasswordStatus: RequestStatus.NotStarted,
    resetPasswordStatus: RequestStatus.NotStarted,
    changePasswordStatus: RequestStatus.NotStarted,
    activateUserStatus: RequestStatus.NotStarted,
    verifyTokenStatus: RequestStatus.NotStarted,
};

export const forgotPassword = createAsyncThunk(
    'user/forgot-password',
    async ({ email }: ForgotPasswordRequest) => forgotPasswordRequest(email)
);

export const resetPassword = createAsyncThunk(
    'user/reset-password',
    async ({ forgotPasswordToken, password, confirmPassword }: ResetPasswordRequest) =>
        resetPasswordRequest(forgotPasswordToken, password, confirmPassword)
);

export const changePassword = createAsyncThunk(
    'user/change-password',
    async ({ password, confirmPassword }: ChangePasswordRequest) =>
        changePasswordRequest(password, confirmPassword)
);

export const activateUser = createAsyncThunk(
    'user/activate',
    async ({ activateUserToken, password, confirmPassword }: ActivateUserRequest) =>
        activateUserRequest(activateUserToken, password, confirmPassword)
);

export const verifyToken = createAsyncThunk(
    'user/verify-token',
    async ({ forgotPasswordToken }: ResetPasswordLinkRequest) =>
        verifyUserRequest(forgotPasswordToken)
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
    {
        builder
            .addCase(forgotPassword.pending, (state) =>
            {
                state.forgotPasswordStatus = RequestStatus.InProgress;
            })
            .addCase(forgotPassword.fulfilled, (state) =>
            {
                state.forgotPasswordStatus = RequestStatus.Success;
            })
            .addCase(forgotPassword.rejected, (state) =>
            {
                state.forgotPasswordStatus = RequestStatus.Failure;
            })
            .addCase(resetPassword.pending, (state) =>
            {
                state.resetPasswordStatus = RequestStatus.InProgress;
            })
            .addCase(resetPassword.fulfilled, (state) =>
            {
                state.resetPasswordStatus = RequestStatus.Success;
            })
            .addCase(resetPassword.rejected, (state) =>
            {
                state.resetPasswordStatus = RequestStatus.Failure;
            })
            .addCase(verifyToken.pending, (state) =>
            {
                state.verifyTokenStatus = RequestStatus.InProgress;
            })
            .addCase(verifyToken.fulfilled, (state) =>
            {
                state.verifyTokenStatus = RequestStatus.Success;
            })
            .addCase(verifyToken.rejected, (state) =>
            {
                state.verifyTokenStatus = RequestStatus.Failure;
            })
            .addCase(changePassword.pending, (state) =>
            {
                state.changePasswordStatus = RequestStatus.InProgress;
            })
            .addCase(changePassword.fulfilled, (state) =>
            {
                state.changePasswordStatus = RequestStatus.Success;
            })
            .addCase(changePassword.rejected, (state) =>
            {
                state.changePasswordStatus = RequestStatus.Failure;
            })
            .addCase(activateUser.pending, (state) =>
            {
                state.activateUserStatus = RequestStatus.InProgress;
            })
            .addCase(activateUser.fulfilled, (state) =>
            {
                state.activateUserStatus = RequestStatus.Success;
            })
            .addCase(activateUser.rejected, (state) =>
            {
                state.activateUserStatus = RequestStatus.Failure;
            });
    },
});

function selectState(state: RootState): UserState
{
    return state.user;
}

export const selectForgotPasswordStatus = createSelector(
    selectState,
    state => state.forgotPasswordStatus
);

export const selectResetPasswordStatus = createSelector(
    selectState,
    state => state.resetPasswordStatus
);

export const selectChangePasswordStatus = createSelector(
    selectState,
    state => state.changePasswordStatus
);

export const selectActivateUserStatus = createSelector(
    selectState,
    state => state.activateUserStatus
);

export const selectVerifyTokenStatus = createSelector(
    selectState,
    state => state.verifyTokenStatus
);

export default userSlice.reducer;
