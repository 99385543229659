import React, { Dispatch, SetStateAction } from 'react';
import { ColumnData } from 'src/components/data-table/DataTable';
import ModalExport from 'src/components/export-modal/ExportModal';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { DataType } from 'src/models/dataType.enum';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { noop, processAndGenerateXlsx } from 'src/utils/common';

function exportData<T>(
    columns: ColumnData<DataType>[],
    selection: number[],
    format: TableExportFormat,
    filters:CaptureFilters,
    totalRecordCount:number|undefined,
    dataType: DataType|undefined
): void
{
    const selectedColumns = columns.filter((_, index) => selection.includes(index));
    switch (format)
    {
    case TableExportFormat.ALLXLSX:
        processAndGenerateXlsx(
            totalRecordCount,
            dataType,
            filters,
            selectedColumns
        );
        break;
    default:
        // eslint-disable-next-line no-console
        console.error('Not supported.');
    }
}

export interface ColumnSelectorÁllProps<T> {
    format: TableExportFormat;
    columns: ColumnData<DataType>[];
    data: PaginatedContent<T>;
    onClose?: () => void;
    filename?: string;
    filters:CaptureFilters
    setAllToXlsx?:Dispatch<SetStateAction<boolean>> 
    totalRecordCount :number|undefined
    dataType?:DataType|undefined
}

export function ColumnSelectorAll<T>({
    format,
    columns,
    data,
    onClose = noop,
    filename = 'export',
    filters,
    totalRecordCount,
    setAllToXlsx,
    dataType,
}: Readonly<ColumnSelectorÁllProps<T>>): React.ReactElement
{

    const exportableColumns = columns.filter((column) => column.value);
    const options = exportableColumns.map((column, index) => ({
        label: column.label,
        value: index,
        checked: true,
    }));
    const onCloseModal=():void=>
    {
        if(setAllToXlsx){
        setAllToXlsx(false);
        }
    };
    return (
        <ModalExport
            options={options}
            open={true}
            onExport={(selection) =>
            {
                exportData( exportableColumns, selection, format, filters, totalRecordCount, dataType);
                onCloseModal();
            } }
            onCloseRequest={onCloseModal}
            format={format}
        />
    );
}
