import { ScreenshotData } from 'src/models/captures/screenshotData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import {
    readAPIScreenshotResponse,
    readAPIScreenshotmetaResponse,
} from '../data-transfer-objects/capture/screenshots';

import instance from '../instance';
import { CaptureParams } from 'src/models/captures/capturedData.model';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

export async function getScreenshots(
    params: CaptureParams
): Promise<PaginatedContent<ScreenshotData>>
{
    const response = await instance.get(
        'images/screenshot/automatic',
        { params: transformCaptureParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIScreenshotResponse);
}
export async function getScreenshotsMeta(
    params: CaptureParams
): Promise<PaginatedContent<ScreenshotData>>
{
    const response = await instance.get(
        'images/screenshotmeta/automatic',
        { params: transformCaptureParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIScreenshotmetaResponse);
}
export async function getScreenshotsdeviceid(
    params: CaptureParams
): Promise<PaginatedContent<ScreenshotData>>
{
    const response = await instance.get(
        'images/screenshotDeviceId/automatic',
        { params: transformCaptureParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIScreenshotResponse);
}
export async function getScreenshotsMetadevicebyid(
    params: CaptureParams
): Promise<PaginatedContent<ScreenshotData>>
{
    const response = await instance.get(
        'images/screenshotMetaDeviceById/automatic',
        { params: transformCaptureParams(params) }
    );

    return response.data;
}
