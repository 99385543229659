import instance from '../instance';
import { FlagParams } from 'src/models/captures/flagData.model';
import { dataTypeBaseEndpoint } from 'src/models/dataType.enum';
import { transformFlagParams } from '../data-transfer-objects/capture/flags';

export async function updateFlag(
    params: FlagParams
): Promise<unknown>
{
    const response = await instance.patch(
        `${dataTypeBaseEndpoint(params.dataType)}/${params.id}`,
        transformFlagParams(params)
    );

    return response.data;
}

export async function updateFlagToAll(
    params: FlagParams
): Promise<object>
{
    const response = await instance.patch(
        'images/update/flagAll',
        transformFlagParams(params)
    );
    return response.data;
}
