import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    LANDING_PAGE,
    NOTIFICATIONS_MANAGMENT,
    CALLHISTORY,
    KEYWORDS,
    EMAILS,
    PROCESS,
    EXTERNALDEVICES,
    FILESTRACKING,
    GEOLOCATIONS,
    LOGONEVENTS,
    TEXTMMS,
    PHOTOS,
    PRINTEDDOCUMENTS,
    INSTALLEDPROGRAMS,
    SCREENSHOTS,
    SEARCHES,
    TEXTSMS,
    WEBSITES,
} from 'src/utils/routes';
import {
    getpushNotificationConfiguration,
    selectpushNotification,
    selectpushNotificationStatus,
    updateNotification,
} from 'src/state/administration/administrationSlice';
import { Badge } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import logo from 'src/assets/images/logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { RequestStatus } from 'src/models/requestStatus.enum';

const useStyles = makeStyles(() => ({
    menuItem: {
        position: 'relative',
        '&:hover .close-icon': {
            visibility: 'visible',
        },
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: '10px',
        cursor: 'pointer',
        visibility: 'hidden',
        transition: 'visibility 0s ease-in-out',
    },
    menuContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
    },
}));

export function NotificationsMenu(): React.ReactElement
{
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [notification, setNotification] = useState<any>([]);
    const NotificationData = useSelector(selectpushNotification);
    const NotificationStatus = useSelector(selectpushNotificationStatus);

    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorElement);
    function handleOpen(event: React.MouseEvent<HTMLButtonElement>): void
    {
        setAnchorElement(event.currentTarget);
    }
    function handleClose(): void
    {
        setAnchorElement(null);
    }
    function handleGoToNotifications(): void
    {
        navigate(NOTIFICATIONS_MANAGMENT);
    }

    useEffect(() =>
    {
        if (NotificationStatus === RequestStatus.Success)
        {
            setNotification(NotificationData);
        }
        // eslint-disable-next-line
    }, [NotificationData]);

    useEffect(() =>
    {
        if (!NotificationData)
        {
            dispatch(getpushNotificationConfiguration());
        }
        // eslint-disable-next-line
    }, []);

    const handleRemoveMessage = (id: string): void =>
    {
        const updatedMessages = notification.filter(
            (notificationMessage:
                {
                    flags: {
                        _id: string;
                    };
                }) => notificationMessage.flags._id !== id
        );
        setNotification(updatedMessages);

        dispatch(
            updateNotification({
                id,
                seenStatus: true,
            })
        );
        dispatch(getpushNotificationConfiguration());
    };

    const handleremoveallMessage = (): void =>
    {
        notification.forEach((message:
            {
                flags: {
                    _id: string;
                };
            }) => handleRemoveMessage(message.flags._id));
        setNotification([]);
    };

    const collections = {
        callshistories: CALLHISTORY,
        emails: EMAILS,
        processes: PROCESS,
        externaldevices: EXTERNALDEVICES,
        filestracking: FILESTRACKING,
        geolocations: GEOLOCATIONS,
        keystrokes: KEYWORDS,
        logonevents: LOGONEVENTS,
        textmms: TEXTMMS,
        photos: PHOTOS,
        printeddocuments: PRINTEDDOCUMENTS,
        installedprogramssnapshots: INSTALLEDPROGRAMS,
        imagescreenshots: SCREENSHOTS,
        searches: SEARCHES,
        textsms: TEXTSMS,
        websites: WEBSITES,
    };


    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                sx={{ color: (theme) => theme.palette.common.white }}
            >
                <Badge
                    badgeContent={notification?.length ? notification?.length : 0}
                    color="error"
                >
                    {notification?.length ? (
                        <NotificationsActiveIcon />
                    ) : (
                        <NotificationsIcon />
                    )}
                </Badge>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock
            >
                <div>
                    {notification?.length > 0 ? (
                        <div
                            style={{
                                overflow: 'auto',
                                maxHeight: '300px',
                                width: '350px',
                            }}
                        >
                            {notification
                                .slice(0, 10)
                                .map((notificationMessages: {
                                    flags: {
                                        _id: string;
                                        collection: string,
                                        flag: string;
                                        flaggedOn: string | number | Date;
                                    };
                                }) =>
                                {
                                    return (
                                        <MenuItem
                                            key={notificationMessages?.flags?._id}
                                            className={classes.menuItem}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}

                                                onClick={() =>
                                                {
                                                    const collectionKey =
                                                        notificationMessages
                                                            .flags
                                                            .collection as keyof typeof collections;
                                                    if (collections[collectionKey])
                                                    {
                                                        navigate(collections[collectionKey]);
                                                    }
                                                }}
                                                onKeyDown={(e) => e.preventDefault()}
                                            >
                                                <div>
                                                    <img
                                                        src={logo}
                                                        alt="Logo"
                                                        style={{
                                                            position:
                                                                'relative',
                                                            maxHeight:
                                                                '45px',
                                                            maxWidth:
                                                                '45px',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: '35px',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            whiteSpace:
                                                                'pre-wrap',
                                                        }}
                                                    >
                                                        {t('common.notification.new-message')}
                                                        :
                                                        {' '}
                                                        {
                                                            `Record from ${notificationMessages
                                                                .flags
                                                                ?.collection
                                                            } were marked as "${notificationMessages
                                                                .flags
                                                                .flag
                                                            }"`
                                                        }
                                                    </p>

                                                    <p>
                                                        {formatDistanceToNowStrict(
                                                            new Date(
                                                                notificationMessages.flags.flaggedOn
                                                            ),
                                                            {
                                                                addSuffix:
                                                                    true,
                                                            }
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <CloseIcon
                                                style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '5px',
                                                }}
                                                className={`${classes.closeIcon} close-icon`}
                                                onClick={() =>
                                                {
                                                    handleRemoveMessage(
                                                        notificationMessages.flags._id
                                                    );
                                                }}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            {notification?.length > 10 && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '5px',
                                        marginBottom: '3px',
                                    }}
                                >
                                    <button
                                        onClick={() => navigate(LANDING_PAGE)}
                                        style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {t('common.notification.show-more')}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <MenuItem disabled>
                            {t('notifications.menu.empty')}
                        </MenuItem>
                    )}
                </div>
                <Divider />
                <div className={classes.menuContainer}>
                    <MenuItem onClick={handleGoToNotifications}>
                        {t('notifications.menu.management')}
                    </MenuItem>
                    {notification?.length > 0 && (
                        <MenuItem onClick={handleremoveallMessage}>
                            {t('common.notification.mark-all-as-read')}
                        </MenuItem>
                    )}
                </div>
            </Menu>
        </div>
    );
}
