import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import {
    createUser,
    selectUserCreateStatus,
    selectUserError,
    selectUserUpdateStatus,
    selectUserDeleteStatus,
    updateUser,
    getSupervisor,
    selectSupervisorList,
    selectSuperVisorStatus,
    deleteUser,
    resetUserFormStatus,
} from 'src/state/administration/administrationSlice';
import { ADMIN_SETTINGS, OFFICER_MANAGEMENT, USER_MANAGEMENT } from 'src/utils/routes';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';
import { User } from 'src/models/userModels';
import { Role, roleStringKey } from 'src/models/roles.enum';
import { Typography, SnackbarProps, Snackbar } from '@mui/material';
import { Modal } from 'src/components/modal/Modal';
import {
    getAgencyFilterOptions,
    getDeviceFilterOptions,
    selectAgencyFilterOptions,
    selectAgencyFilterOptionsStatus,
} from 'src/state/captures/capturesSlice';
import { FilterOption, RoleOption } from 'src/models/common/filterOptionData.model';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { ErrorData } from 'src/models/errors/errorData.model';
import { userIsInRole } from 'src/utils/roleUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import { OFFICERPAGE } from 'src/models/alertsType.enum';
import { validateEmail } from 'src/utils/common';
import AgencyDetailsModel from '../agency-details-model/AgencyDetailsModel';
const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

function FormErrors(errorData: Readonly<ErrorData>): JSX.Element
{
    const { t } = useTranslation();
    let errors: string[] = [];
    if (errorData?.messages)
    {
        errors = errorData?.messages;
    }

    if (errorData?.message && !Array.isArray(errorData?.message))
    {
        errors.push(errorData?.message);
    }

    if (errors.length)
    {
        return (
            <Alert severity="error" variant="filled" sx={{ mb: 5 }}>
                <AlertTitle sx={{ fontSize: 16 }}>
                    {t('form.validation-errors')}
                </AlertTitle>
                <ul>
                    {errors.map((error, key) => (
                        <li key={key}>
                            {error}
                        </li>
                    ))}
                </ul>
            </Alert>
        );
    }
    return <></>;
}

export function UsersForm(props: Readonly<User>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const userCreateStatus = useSelector(selectUserCreateStatus);
    const userUpdateStatus = useSelector(selectUserUpdateStatus);
    const userDeleteStatus =useSelector(selectUserDeleteStatus);
    const supervisorFilters = useSelector(selectSupervisorList);
    const supervisorStatus = useSelector(selectSuperVisorStatus);

    const userError = useSelector(selectUserError);
    const [user, setUser] = useState<User>(props);
    const [agency, setAgency] = useState<FilterOption | null>(null);
    const [agencies, setAgencies] = useState<(FilterOption | null)[]>([]);
    // eslint-disable-next-line
    const [supervisors, setSupervisors] = useState<(any)[]>([]);
    const [backNavigation, setBackNavigation] = useState(ADMIN_SETTINGS);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [enableDelete, setEnableDelete] = useState<boolean>(false);
    const location = useLocation();
    const assignMonitor = location.pathname.includes(USER_MANAGEMENT);
    const Token = JSON.parse(localStorage.getItem('Token') as string);
    const userRole = Token.role;
    const [hasEmailBeenBlurred, setHasEmailBeenBlurred] = useState(false);
    const [emailError, setEmailError] = useState<string | null>(null);
    const isOfficerPage = window.location.pathname === OFFICERPAGE;

    useEffect(()=>
    {
        if(props?.email)
        {
            setEnableDelete(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const canAssignAgencies = (): boolean => user?.role ?
        [Role.Monitor, Role.OfficerSupervisor, Role.AgencyAdministrator]
            .includes(user?.role)
        : false;

    const canAssignSupervisor = (): boolean => user?.role ?
        [Role.Officer]
            .includes(user?.role)
        : false;

    useEffect(() =>
    {
        if (!props.id)
        {
            setUser({ ...props, enabled: true });
        }

        if (userIsInRole([Role.Officer, Role.OfficerSupervisor]))
        {
            setBackNavigation(OFFICER_MANAGEMENT);
        }

        dispatch(getAgencyFilterOptions());
        dispatch(getDeviceFilterOptions({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        setAgency(agencyFilters?.find(item => item.value === props.agency) ?? null);
        setAgencies(agencyFilters?.filter(
            item => props.assignations?.agencies?.find(
                data => data === item.value
            )
        ) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, agencyFilters]);

    useEffect(() =>
    {
        if (supervisorStatus === RequestStatus.Success)
        {
            setSupervisors(supervisorFilters?.filter(
                item => props.assignations?.supervisors?.find(
                    data => data === item['value']
                )
            ) ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, supervisorStatus]);

    useEffect(() =>
    {
        let message = null;

        if ((userUpdateStatus === RequestStatus.Success)
            || (userCreateStatus === RequestStatus.Success))
        {
            message = isOfficerPage ? t('user.officer-created')
                : t('user.user-created');
        }
        else if (userDeleteStatus === RequestStatus.Success)
        {
            message = isOfficerPage ? t('user.officer-deleted')
                : t('user.user-deleted');
        }
        else if (userUpdateStatus === RequestStatus.Failure)
        {
            message = t('common.messages.something-wrong');
        }

        if (message)
        {
            const closeFunction = props?.closeModal ?? (() => navigate(backNavigation));
            setNotificationState({ open: true, message });
            if ((userUpdateStatus === RequestStatus.Success ||
                userCreateStatus === RequestStatus.Success) || (userDeleteStatus===RequestStatus.Success))
            {
                setTimeout(() =>
                {
                    closeFunction(false);
                    dispatch(resetUserFormStatus());
                }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userUpdateStatus,
        userCreateStatus,
        userDeleteStatus,
        dispatch,
        t,
        setNotificationState]);

    useEffect(() =>
    {
        if (props.id)
        {
            dispatch(resetUserFormStatus());
            dispatch(getSupervisor(props.agency ?? null));
        }
    }, [dispatch, props]);

    const roles = getRoleOptionList();

    // eslint-disable-next-line
    function getRoleOptionList(): any[]
    {
        if (userIsInRole([Role.Administrator]))
        {
            return Object.values(Role).map((value) =>
            {
                return getRoleOption(value);
            });
        }

        if (userIsInRole([Role.AgencyAdministrator]))
        {
            return [
                getRoleOption(Role.AgencyAdministrator),
                getRoleOption(Role.OfficerSupervisor),
                getRoleOption(Role.Officer),
            ];
        }

        if (userIsInRole([Role.Monitor]) || userIsInRole([Role.Employee]))
        {
            return [
                getRoleOption(Role.Employee),
                getRoleOption(Role.Monitor),
                getRoleOption(Role.OfficerSupervisor),
                getRoleOption(Role.Officer),
            ];
        }

        if (userIsInRole([Role.OfficerSupervisor]))
        {
            return [
                getRoleOption(Role.OfficerSupervisor),
                getRoleOption(Role.Officer),
            ];
        }

        if (userIsInRole([Role.Officer]))
        {
            return [
                getRoleOption(Role.Officer),
            ];
        }

        return [];

    }

    function getRoleOption(role: Role): RoleOption
    {
        return{
            value: role,
            name: t(roleStringKey(role)),
        };
    }
    const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>): void =>
    {
        setHasEmailBeenBlurred(true);
        if (event.target.value.trim() !== '')
        {
            const emailError = validateEmail(event.target.value);
            setEmailError(emailError);
        }
    };
    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        const emailValidationResult = validateEmail(user.email ?? '');
        if (emailValidationResult)
        {
            setEmailError(emailValidationResult);
            return;
        }
        setEmailError(null);
        if(deleteOpen===false){
        if (user.id)
        {
            dispatch(updateUser(user));
        }
        else
        {
            dispatch(createUser(user));
        }
        }
    }
    async function DeleteSubmit(data?: string): Promise<void>
    {
        if (data === user.firstName)
        {
           
        if (user.id)
        {
            dispatch(deleteUser(user.id));
        }
      }
      
    }

    function onDeleteHandler(event: React.FormEvent<HTMLButtonElement>): void
    {
        DeleteModal()
    }
    function isLoading(): boolean
    {
        return RequestStatus.InProgress === userCreateStatus
            || RequestStatus.InProgress === userUpdateStatus;
    }

    function onChangeAgency(option: FilterOption | null): void
    {
        setUser({ ...user, agency: option?.value ?? null });
        setAgency(option);

        setSupervisors([]);

        if (option?.value)
        {
            dispatch(getSupervisor(option?.value ?? null));
        }
    }

    function onChangeAgencies(options: (FilterOption | null)[]): void
    {
        setUser({
            ...user,
            assignations:
            {
                ...user.assignations,
                agencies: options.map(item => item?.value as string),
            },
        });
        setAgencies(options);
    }

    function onChangeSuperVisors(options: (FilterOption | null)[]): void
    {
        if(assignMonitor)
        {
            setUser({
                ...user,
                assignations:
                {
                    supervisors: options.map(item => item?.value as string),
                },
            });
        }
        else
        {
            setUser({
                ...user,
                assignations:
                {
                    ...user.assignations,
                    supervisors: options.map(item => item?.value as string),
                },
            });
        }
        setSupervisors(options);
    }

    function onChangeRole(data: FilterOption | null): void
    {
        if (data?.value === Role.Officer)
        {
            setUser({ ...user, role: data?.value });
        }
        else
        {
            setUser({ ...user, role: data?.value as Role, agency: undefined });
        }
        setAgency(null);
    }



    function isAgencyEnabled(): boolean
    {
        return !(userIsInRole([Role.OfficerSupervisor]) && props?.id)
            || !props?.id;
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    const DeleteModal = (): void =>
    {
        setDeleteOpen(!deleteOpen);
    };
    return (
        <>
            <FormErrors {...userError} />
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                onKeyDown={(event: KeyboardEvent<HTMLFormElement>) =>
                {
                    if (event.key === 'Enter')
                    {
                        event.preventDefault();
                        if (!(event.target as Element).matches('[data-delete-key]'))
                        {
                            handleSubmit(event);
                        }
                    }
                }}

            >
                <Grid container spacing={5}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            fullWidth
                            id="firstName"
                            value={user?.firstName}
                            onChange={(event) =>
                                setUser({
                                    ...user,
                                    firstName: event.target.value,
                                })}
                            label={t('form.firstname')}
                            name="firstName"
                            autoFocus
                            error={handleError(userError, 'firstName')}
                            helperText={handleErrorText(userError, 'firstName')}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            fullWidth
                            id="lastName"
                            value={user?.lastName}
                            onChange={(event) =>
                                setUser({
                                    ...user,
                                    lastName: event.target.value,
                                })}
                            label={t('form.lastname')}
                            name="lastName"
                            error={handleError(userError, 'lastName')}
                            helperText={handleErrorText(userError, 'lastName')}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            fullWidth
                            type={'email'}
                            id="email"
                            value={user?.email}
                            onChange={(event) =>
                                setUser({
                                    ...user,
                                    email: event.target.value,
                                })}
                            onBlur={handleEmailBlur}
                            label={t('form.email')}
                            name="email"
                            autoFocus
                            error={hasEmailBeenBlurred && emailError!== null}
                            helperText={hasEmailBeenBlurred ? emailError : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            fullWidth
                            id="phoneNumber"
                            value={user?.phoneNumber}
                            onChange={(event) =>
                                setUser({
                                    ...user,
                                    phoneNumber: event.target.value,
                                })}
                            label={t('form.phonenumber')}
                            name="phoneNumber"
                            autoFocus
                            error={handleError(userError, 'phoneNumber')}
                            helperText={handleErrorText(userError, 'phoneNumber')}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Autocomplete
                            disablePortal
                            id="role"
                            value={
                                roles.find(
                                    role => user?.role === role.value
                                )
                            }
                            options={roles ?? []}
                            onChange={(_, data) => onChangeRole(data)}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('form.role')}
                                    error={handleError(userError, 'role')}
                                    helperText={handleErrorText(userError, 'role')}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Autocomplete
                            id="agency"
                            value={agency}
                            filterSelectedOptions
                            onChange={(_, data) => onChangeAgency(data)}
                            options={agencyFilters ?? []}
                            disabled={!isAgencyEnabled()}
                            loading={agencyFiltersStatus === RequestStatus.InProgress}
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('form.agency')}
                                    error={handleError(userError, 'agency')}
                                    helperText={handleErrorText(userError, 'agency')}
                                />
                            )}
                            renderOption={(props, option) =>
                            {
                                return (
                                    <li {...props} key={option.value}>
                                        {option.name}
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                color: theme => theme.palette.common.lightGrey,
                                fontSize: '1.25rem', // 20px
                            }}
                        >
                            Assignations
                        </Typography>
                    </Grid>
                    {canAssignAgencies() && (
                        <Grid
                            item
                            sm={12}
                            md={6}
                        >
                            <Autocomplete
                                multiple
                                id="agencies"
                                value={agencies}
                                size="small"
                                options={agencyFilters ?? []}
                                filterSelectedOptions
                                loading={agencyFiltersStatus === RequestStatus.InProgress}
                                onChange={(_, data) => onChangeAgencies(data)}
                                getOptionLabel={option => option?.name ?? ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('form.agencies')}
                                        error={handleError(userError, 'assignations.agencies')}
                                        helperText={
                                            handleErrorText(userError, 'assignations.agencies')
                                        }
                                    />
                                )}
                                renderOption={(props, option) =>
                                {
                                    return (
                                        <li {...props} key={option?.value}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                                ChipProps={{ sx: chipStyle }}
                            />
                        </Grid>
                    )}

                    {canAssignSupervisor() && (
                        <Grid
                            item
                            sm={12}
                            md={6}
                        >
                            <Autocomplete
                                multiple
                                id="supervisor"
                                size="small"
                                value={supervisors}
                                options={supervisorFilters ?? []}
                                filterSelectedOptions
                                loading={supervisorStatus === RequestStatus.InProgress}
                                onChange={(_, data) => onChangeSuperVisors(data)}
                                getOptionLabel={option => option?.name ?? ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('form.supervisor')}
                                        error={handleError(userError, 'assignations.supervisors')}
                                        helperText={
                                            handleErrorText(userError, 'assignations.supervisors')
                                        }
                                    />
                                )}
                                renderOption={(props, option) =>
                                {
                                    return (
                                        <li {...props} key={option?.value}>
                                            {option?.name}
                                        </li>
                                    );
                                }}
                                ChipProps={{ sx: chipStyle }}
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        sm={12}
                        md={6}
                    />
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <FormControlLabel
                            control={(
                                <ToggleSwitch
                                    sx={{ m: 2 }}
                                    checked={user.enabled}
                                    onChange={(_, value) => setUser({
                                        ...user,
                                        enabled: value,
                                    })}
                                />
                            )}
                            label={t('form.user-status')}
                        />
                    </Grid>
                </Grid>
                <>
                    {userRole === 'Administrator' && enableDelete? (
                        <Button
                            sx={{
                                marginRight: 'auto',
                            }}
                            type="submit"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={onDeleteHandler}
                        >
                            {t('common.actions.delete')}
                        </Button>
                    ):null}
                </>
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button
                        sx={{
                            mr: 1,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.common.goldYellow,
                            '&.Mui-disabled': {
                                backgroundColor: 'inherit',
                                color: theme => theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={() =>
                            (props?.closeModal) ?
                                props.closeModal(false) : navigate(backNavigation)
                        }
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            mr: 1,
                            minWidth: '7.5rem',
                            '&.Mui-disabled': {
                                backgroundColor: theme => theme.palette.primary.main,
                                color: theme => theme.palette.primary.contrastText,
                            },
                        }}
                        disabled={isLoading()}
                    >
                        {t('form.save-changes')}
                        {isLoading() && <AnimatedEllipsis />}
                    </Button>
                </Box>
            </Box>
            <Modal
                title={ t('reports.delete-officer-title')}
                open={deleteOpen}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <AgencyDetailsModel
                        data={user}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Officer'}
                    />
                </Box>
            </Modal>
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={userUpdateStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
