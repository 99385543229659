import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
export interface AuthenticationPageProps
{
    children: React.ReactNode;
    image: React.ReactElement;
}

export function AuthenticationPage(
    { children, image }: Readonly<AuthenticationPageProps>
): React.ReactElement
{
    const theme = useTheme();
    return (
        <Box
            sx={{
                [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    minWidth: '100vw',
                    minHeight: '100vh',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: theme => theme.palette.common.darkGrey,
                },
                [theme.breakpoints.down('md')]: {
                    position: 'relative',
                    minWidth: '360px',
                    minHeight: '100vh',
                },
            }}
        >
            <Grid
                container
                sx={{
                    width: '42rem', // 672px
                    margin: { md: '15% auto 0' },
                    borderRadius: '1rem', // 16px
                    boxSizing: 'border-box',
                    padding: '2.25rem 1.25rem 1.25rem', // 36px 20px 20px
                    backgroundColor: theme => theme.palette.common.white,
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                        width: '85vw',
                        mx: 'auto',
                    },
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={3}
                    display="flex"
                    justifyContent="center"
                >
                    {image}
                </Grid>
                <Grid item md={9} xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}
