import { PhotoData } from 'src/models/captures/photoData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import { readAPIScreenshotResponse } from '../data-transfer-objects/capture/photos';

import instance from '../instance';
import { CaptureParams, GetAlertsId } from 'src/models/captures/capturedData.model';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

export async function getPhotos(
    params: CaptureParams
): Promise<PaginatedContent<PhotoData>>
{
    const response = await instance.get(
        'images/photo',
        { params: transformCaptureParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIScreenshotResponse);
}

export async function getPhotosExportIds(
    params: GetAlertsId
): Promise<string[]>
{
    const response = await instance.get(
        'images/photo/getIds',
        { params }
    );
    return response.data;
}
