import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    SearchesTable,
} from 'src/templates/searches-table/SearchesTable';

export function DataTypeSearches(): React.ReactElement
{
    return (
        <DataTypePage>
            <SearchesTable />
        </DataTypePage>
    );
}
