import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    getOfficerDisbaleAWLs,
    resetOfficerAWLCreationStatus,
    selectOfficerAWLCreationStatus,
    updateOfficerAWLToggle,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Officer } from 'src/models/administration/officerData.model';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import { AWLEdit } from 'src/templates/awl-edit/AWLEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';
import { OfficerFormValues, DEFAULT_VALUES } from '../officer-form/OfficerForm';

import { RootState } from 'src/state/store';
import { OfficerAWLCreationForm } from '../officer-awl-creation-form/OfficerAWLCreationForm';
import { OfficerAWLTable } from '../officer-awl-table/OfficerAWLTable';

export interface OfficerAlertWordsModalProps {
    user: Officer
    open?: boolean
    onClose?: () => void
}

export function OfficerAlertWordsModal({
    user,
    open = false,
    onClose = noop,
}: Readonly<OfficerAlertWordsModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [alertId, setAlertId] = useState<string>('');
    const [isModalAddWords, setIsModalAddWords] = useState<boolean>(false);
    const [isModalEditWords, setIsModalEditWords] = useState<boolean>(false);
    const [initialValues, setInitialValues] =
        useState<OfficerFormValues>(DEFAULT_VALUES);

    const data = useSelector(
        (state: RootState) => state.administration.officerDisableAWLs
    );
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(
        data?.disableAWL ?? false
    );
    const status = useSelector(selectOfficerAWLCreationStatus);
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = AWLContext.Officer;
    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };

    const userId = user.id;

    useEffect(() =>
    {
        dispatch(getOfficerDisbaleAWLs({ userId }));
    }, [userId, dispatch]);

    useEffect(() =>
    {
        setIsAddButtonDisabled(data?.disableAWL ?? false);
    }, [data]);

    function onSuccessAdd(): void
    {
        setInitialValues(user);
        setIsModalAddWords(false);
        dispatch(getConfiguration(user.id));
        dispatch(resetOfficerAWLCreationStatus());
    }
    function onFailAdd(): void
    {
        setIsModalAddWords(true);
        dispatch(resetOfficerAWLCreationStatus());
    }

    function selectIsModalEdit(id: string): void
    {
        setAlertId(id);
        setIsModalEditWords(true);
    }
    function onSuccessEdit(): void
    {
        setInitialValues(user);
        setIsModalEditWords(false);
        dispatch(getConfiguration(user.id));
        dispatch(resetOfficerAWLCreationStatus());
    }
    function onFailEdit(): void
    {
        setIsModalEditWords(true);
        dispatch(resetOfficerAWLCreationStatus());
    }
    function onCancel(): void
    {
        onClose();
    }

    function onBackToList(): void
    {
        setInitialValues(user);
        setIsModalAddWords(false);
        setIsModalEditWords(false);
        dispatch(getConfiguration(user.id));
        dispatch(resetOfficerAWLCreationStatus());
    }

    useEffect(() =>
    {
        setInitialValues(user);
    }, [initialValues, setInitialValues, user]);
    useEffect(() =>
    {
        if (user.id)
        {
            dispatch(getConfiguration(user.id));
        }
    }, [dispatch, getConfiguration, user]);

    const handleToggleChange = (): void =>
    {
        const isDisabled = !isAddButtonDisabled;
        setIsAddButtonDisabled(isDisabled);
        saveToggleValue(isDisabled);
    };

    const saveToggleValue = (isDisabled: boolean): void =>
    {
        const payload = {
            disableAWL: isDisabled,
            userId: user.id,
        };
        dispatch(updateOfficerAWLToggle(payload));
    };

    if (role)
    {
        tableContainerProps.canAdd = true;
        tableContainerProps.tableProps = {
            editable: true,
        };
    }
    if (isModalAddWords)
    {
        return (
            <Modal
                title={
                    user.firstName +
                    ' ' +
                    user.lastName +
                    ' ' +
                    t('menu.alert-word-management')
                }
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                {role && (
                    <OfficerAWLCreationForm
                        status={status}
                        assignedTo={user.id}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessAdd}
                        onFailure={onFailAdd}
                    />
                )}
            </Modal>
        );
    }
    else if (isModalEditWords)
    {
        return (
            <Modal
                title={
                    user.firstName +
                    ' ' +
                    user.lastName +
                    ' ' +
                    t('menu.alert-word-management')
                }
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                {role && (
                    <AWLEdit
                        mode={role}
                        context={'officer'}
                        alertId={alertId}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessEdit}
                        onFailure={onFailEdit}
                    />
                )}
            </Modal>
        );
    }
    else
        return (
            <Modal
                title={
                    user.firstName +
                    ' ' +
                    user.lastName +
                    ' ' +
                    t('menu.alert-word-management')
                }
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                <AgencyAWLConfigurationContextProvider>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding={'1em 0'}
                        margin={'-2em 0em 0em 0em'}
                    />
                    <Box m={4}>
                        <TopBar
                            buttons={[
                                isAddButtonDisabled
                                    ? {
                                        text: t('common.actions.add'),
                                        icon: <AddCircleIcon />,
                                    }
                                    : {
                                        text: t('common.actions.add'),
                                        icon: <AddCircleIcon />,
                                        onClick: () =>
                                            setIsModalAddWords(true),
                                    },
                            ]}
                        />
                        <OfficerAWLTable
                            userId={user.id}
                            selectIsModalEdit={selectIsModalEdit}
                            isAddButtonDisabled={isAddButtonDisabled}
                        />
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            padding="1em 0"
                            gap={1}
                        >
                            {userIsInRole([Role.Administrator,
                                Role.AgencyAdministrator,
                                Role.Employee]) ? (
                                    <Grid sx={{ marginRight: 'auto' }}>
                                        {' '}
                                        {t('common.actions.block-officer')}
                                        <ToggleSwitch
                                            sx={{ ml: 1, fontSize: 2 }}
                                            checked={isAddButtonDisabled}
                                            onChange={handleToggleChange}
                                        />
                                    </Grid>
                                ) : null}
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={onCancel}
                            >
                                {t('common.actions.close')}
                            </Button>
                        </Stack>
                    </Box>
                </AgencyAWLConfigurationContextProvider>
            </Modal>
        );
}
