import React from 'react';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flag, flagStringKey } from 'src/models/flag.enum';
import {
    changeAlertsFilter,
    changeFilters,
    changeFilterFlag,
    selectAlertsFilter,
    selectFilters,
    selectaiFlagFilter,
    selectscreenshotGridView,
    changeScreenshotDetailsFilter,
} from 'src/state/captures/capturesSlice';
import { FlagIcon } from 'src/components/flag-icon/FlagIcon';
import {
    DropdownOption,
    DropdownSelector,
}
    from 'src/components/dropdown-selector/DropdownSelector';
import { ALERTSPAGE, SCREENSHOTMETAPAGE, SCREENSHOTPAGE } from 'src/models/alertsType.enum';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { PHOTOS, SCREENCAPTURES, TEXTMMS, SCREENSHOTSMETA } from 'src/utils/routes';

export function DataTypeFlagFilter(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const screenshotModal = window.location.pathname === SCREENSHOTPAGE;
    const screenshotmetaModal = window.location.pathname === SCREENSHOTMETAPAGE;
    const MMS = window.location.pathname === TEXTMMS;
    const Photos = window.location.pathname === PHOTOS;
    const screenCaptures = window.location.pathname === SCREENCAPTURES;
    const aiFlagFiltervalue = useSelector(selectaiFlagFilter);
    const screenshotGridViewData = useSelector(selectscreenshotGridView);
    const isScreenshotMetaPage=window.location.pathname === SCREENSHOTSMETA;
    const flags: DropdownOption[] = Object.values(Flag).map((value) =>
    {
        if (isAlertsPage && value === Flag.NO_FLAG)
        {
            return null;
        }

        return {
            name: t(flagStringKey(value)),
            value,
            icon: <FlagIcon style={{ width: 14, height: 14 }} flag={value} />,
        };
    }).filter(Boolean) as DropdownOption[];

    const AIFilter = (isAlertsPage &&
        (alertsFilter.alertsdatatype === 'MMS'
        || alertsFilter.alertsdatatype === 'Photos'
        || alertsFilter.alertsdatatype === 'Screenshots'
        ||alertsFilter.alertsdatatype === 'Screen Captures'))
        ||(screenshotModal || Photos || screenCaptures || MMS|| screenshotmetaModal);

    if (AIFilter)
    {
        flags.push({
            name: 'AiScanStatus',
            value: 'aiScanStatus',
            icon: <ScoutRed style={{ width: 14, height: 14 }} />,
        });
    }

    function onChangeSelectFilter(value: unknown): void
    {
        if (isAlertsPage)
        {
            dispatch(
                changeAlertsFilter({
                    flags: value as string[],
                })
            );
        }
        else if( filters.isGridView === true &&
                screenshotGridViewData === true &&
                (screenshotModal || screenshotmetaModal)
        )
        {

            dispatch(
                changeFilterFlag(value as string[])
            );
            dispatch(changeScreenshotDetailsFilter(
                { flags: value as string[] }
            ));
        }
        else
        {
            const values = value as string[];
            // eslint-disable-next-line no-mixed-operators
            if((values.length > 0) || (values && values.length === 0) &&
            (filters?.flags?.length === 1))
            {
                dispatch(
                    changeFilters({
                        flags: value as string[],
                    })
                );
            }
        }
    }

    let selectedValue;

    if (AIFilter && screenshotGridViewData === true && !isAlertsPage)
    {
        selectedValue = aiFlagFiltervalue;
    }
    else if (isAlertsPage)
    {
        const afterChangeFilter = sessionStorage.getItem('AlertsFilter');
        const updatedFilters =
        alertsFilter.alertsdatatype && afterChangeFilter
            ? JSON.parse(afterChangeFilter)
            : alertsFilter;
        selectedValue = updatedFilters.flags;
    }
    else
    {
        selectedValue = filters.flags;
    }

    return (
        <DropdownSelector
            options={flags}
            multiple
            fullWidth
            disabled={isScreenshotMetaPage && !screenshotGridViewData}
            checkBoxSelection
            label={t('data-type.filters.filter-by-flag')}
            value={selectedValue}
            onChange={onChangeSelectFilter}
            inputProps={{
                sx: {
                    backgroundColor: (theme: Theme) => theme.palette.common.white,
                },
            }}
        />
    );
}
