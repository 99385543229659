import {
    Profile,
    ProfileParams,
    UpdateAgentParams,
    UpdateAgentParamsResponse,
} from 'src/models/administration/profileData.model';
import { BlockedUserAgent } from 'src/models/administration/blockedUserAgentData.model';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIProfileResponse,
    transformProfileParams,
    transformUpdateProfileParams,
    readBlockedUserAgentResponse,
    readPlatformVersionResponse,
    readAPIAgentUpdateStatus,
} from '../data-transfer-objects/administration/profiles';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import instance from '../instance';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import {
    AdminSearchParams,
} from 'src/models/administration/adminSearchData.model';

export async function createProfile(params: ProfileParams): Promise<Profile>
{
    const response = await instance.post('profile', transformProfileParams(params));

    return readAPIProfileResponse(response.data);
}

export async function getProfiles(params?: AdminSearchParams): Promise<PaginatedContent<Profile>>
{
    const response = await instance.get(
        'profile',
        { params: params ? transformCaptureParams(params) : {} }
    );

    return readAPIPaginatedResponse(response.data, readAPIProfileResponse);
}

export async function getProfile(id: string): Promise<Profile>
{
    const response = await instance.get(`profile/${id}`);

    return readAPIProfileResponse(response.data);
}

export async function updateProfile(profile: Profile): Promise<Profile>
{
    const response = await instance.put(
        `profile/${profile.id}`,
        transformUpdateProfileParams(profile)
    );

    return readAPIProfileResponse(response.data);
}
export async function deleteProfile(id: string): Promise<Profile>
{
    const response = await instance.delete(
        `profile/${id}`
    );

    return readAPIProfileResponse(response.data);
}
export async function getUserAgents(
    params?: AdminSearchParams
): Promise<PaginatedContent<BlockedUserAgent>>
{
    const response = await instance.get(
        'websites/blockedAgents/list',
        { params }
    );

    return readAPIPaginatedResponse(response.data, readBlockedUserAgentResponse);
}

export async function updateUserAgent(
    params: UpdateAgentParams
): Promise<UpdateAgentParamsResponse>
{
    const response = await instance.put(
        `websites/blockedAgents/${params.id}`,
        { id: params.id, isBlocked: params.isBlocked }
    );

    return readAPIAgentUpdateStatus(response.data);
}

export async function getPlatformVersion(
    params?: AdminSearchParams
): Promise<PaginatedContent<PlatformVersion>>
{
    const response = await instance.get(
        'device/platformVersion/list',
        { params: params ? transformCaptureParams(params) : {} }
    );
    return readAPIPaginatedResponse(response.data, readPlatformVersionResponse);
}
