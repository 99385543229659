export enum Flag {
    SOFTWARE = 'Flagged by Software',
    CRIMINAL_ACTIVITY = 'Criminal Activity',
    PROBATION_PAROLE = 'Probation / Parole Violation',
    NOTABLE_INFORMATION = 'Notable Information',
    OFFICER_FLAGGED = 'Flagged by Officer',
    NO_FLAG = 'NONE',
}

export enum FlagAI {
    AISCANSTATUS = 'aiScanStatus',
}

export const FlagWithAI = { ...Flag, ...FlagAI };

export function flagStringKey(flag: Flag): string
{
    switch (flag)
    {
    case Flag.SOFTWARE:
        return 'common.flags.software';
    case Flag.CRIMINAL_ACTIVITY:
        return 'common.flags.criminal-activity';
    case Flag.PROBATION_PAROLE:
        return 'common.flags.probation-parole';
    case Flag.NOTABLE_INFORMATION:
        return 'common.flags.notable-information';
    case Flag.OFFICER_FLAGGED:
        return 'common.flags.officer-flagged';
    case Flag.NO_FLAG:
        return 'common.flags.no-flag';
    default:
        return '';
    }
}
