import React from 'react';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import { ReactComponent as ReportImage } from 'src/assets/images/report.svg';
import {
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportForm } from 'src/templates/report-form/ReportForm';



export function ReportCreate(): JSX.Element
{
    const { t } = useTranslation();

    return (
        <>

            <Header />
            <Box m={4}>
                <TopBar
                    labels={[t('reports.my-reports'), t('reports.create-report')]}
                    icon={<ReportImage />}
                />
                <ReportForm />
            </Box>
        </>
    );
}
