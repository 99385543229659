import React from 'react';
import {
    Link,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FileInfo } from 'src/models/captures/fileInfo.model';
import { useSelector } from 'react-redux';
import { selectEmail } from 'src/state/captures/capturesSlice';
import { formatSize } from 'src/utils/common';

const downloadLink = (fileUrl: string):string =>
    fileUrl + '/download';

export default function AttachmentsTable(
    {
        attachments,
        isLoading,
    }: Readonly<{attachments: FileInfo[], isLoading: boolean}>
): React.ReactElement
{
    const { t } = useTranslation();
    const headerSx = (size: number): SxProps<Theme> => ({
        width: `${size}%`,
        backgroundColor: '#EAEAEA',
        marginRight: -1,
        borderRight: '1px solid white',
        textTransform: 'capitalize',
    });

    const emailData = useSelector(selectEmail);

    return (
        <div>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerSx(6)}>
                                {t('data-type.table.file-name')}
                            </TableCell>
                            <TableCell sx={headerSx(2)}>
                                {t('data-type.table.size')}
                            </TableCell>
                            <TableCell sx={headerSx(2)}>
                                {t('data-type.table.format')}
                            </TableCell>
                            <TableCell sx={headerSx(0.5)}>
                                {t('data-type.table.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : (
                            attachments?.map((attachment, index) => (
                                <TableRow key={attachment.id}>
                                    <TableCell>
                                        {attachment.filename}
                                    </TableCell>
                                    <TableCell>
                                        {formatSize(attachment.length)}
                                    </TableCell>
                                    <TableCell>
                                        {attachment.contentType.split('/').pop()?.toUpperCase()}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            href={
                                                `${downloadLink(
                                                    emailData?.attachments[index] as string
                                                )}`
                                            }
                                            download={attachment.filename}
                                        >
                                            <FileDownloadIcon sx={{ color: 'black' }} />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
