import { InstructionType } from 'src/models/administration/instructionData.model';
import { Platform } from 'src/models/platforms.enum';

export interface instructionRespone {
    _id?: string
    type: string
    platform: Platform
    createdAt: Date
    updatedAt: Date
    id?:string
}

export function readAPIInstructionResponse({
    _id,
    id,
    type,
    platform,
    createdAt,
    updatedAt,
}: instructionRespone): InstructionType
{
    return {
        _id,
        id,
        type,
        platform,
        createdAt,
        updatedAt,
    };
}

export function readAPIInstructionTypeResponse({
    _id,
    id,
    type,
    platform,
    createdAt,
    updatedAt,
}: InstructionType): InstructionType
{
    return {
        _id,
        id,
        type,
        platform,
        createdAt,
        updatedAt,
    };
}
