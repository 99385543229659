import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { GeolocationAlertsTable }
    from 'src/templates/geolocation-alerts-table/GeolocationAlertsTable';

export function DataTypeGeolocations(): React.ReactElement
{
    return (
        <DataTypePage>
            <GeolocationAlertsTable />
        </DataTypePage>
    );
}
