import {
    SuggestedAlertWordsList,
    ClientAWLCreationParams,
    AWLRetrieveParams,
    AWLCreationParams,
    CustomAWLCreationParams,
    CustomAlertWordsList,
    AgencyAWLConfiguration,
    AgencyAWLConfigurationUpdateParams,
    CustomAWLsRetrieveParams,
    AWLClientParams,
    ClientAWLToggleUpdateParams,
    ClientAlertWordsToggleList,
    OfficerAWLToggleUpdateParams,
    OfficerAWLCreationParams,
    AWLOfficerParams,
    OfficerAlertWordsList,
    OfficerAlertWordsToggleList,
} from 'src/models/administration/alertWordListData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';

export interface APIAWLCreationParams {
    name: string;
    words: string[];
    profileId: string;
}

export interface APIAWL extends APIAWLCreationParams {
    id: string;
    userId: string;
    userName: string;
    profileName: string;
    createdAt: string;
    updatedAt: string;
    disableAWL: boolean;
}

export interface APICustomAWL extends APIAWL {
    agencyId: string;
    isEnabled: boolean;
    disableAWL: boolean;
}

export interface APICustomAWLsRetrieveParams extends APIPaginationParams {
    agencyId?: string;
}

export interface APIAgencyAWLConfigurationParams {
    agencyId?: string;
    suggestedAlertWords: string[];
    customAlertWords: string[];
}

export interface APIAgencyAWLConfiguration {
    agencyId: string;
    agencyName: string;
    suggestedAlertWords: APIAWL[];
    customAlertWords: APICustomAWL[];
}

export interface APIClientAWLResponse extends Omit<APIAWL, 'profileId' | 'profileName'> {
    clientId: string;
    clientName: string;
    isEnabled: boolean;
    disableAWL: boolean;
}

export interface APIClientAWLToggleResponse extends Omit<APIAWL, 'profileId' | 'profileName'> {
    client?: string;
    clientName?: string;
    disableAWL: boolean;
}

export interface APIClientAWLCreationParams extends Omit<APIAWLCreationParams, 'profileId'> {
    clientId: string;
    isEnabled?: boolean;
}

export interface APIClientAWLToggleCreationParams {
    clientId: string;
    disableAWL?: boolean;
    client?: string;
}

export interface APIClientAWLParams extends APIPaginationParams {
    clientId?: string;
}

export interface APICustomAWLCreationParams extends Omit<APIAWLCreationParams, 'profileId'> {
    agencyId?: string;
    profileId: string;
}

export interface APIAWLRetrieveParams extends APIPaginationParams {
    startDate?: string;
    endDate?: string;
    searchValue?: string;
}

export function transformAWLCreationParams(
    params: AWLCreationParams
): APIAWLCreationParams
{
    const {
        name,
        words,
        profile,
    } = params;

    return { name, words, profileId: profile };
}

export function transformClientAWLParams(
    params: AWLClientParams
): APIClientAWLParams
{
    const {
        clientId,
        ...paginatedParams
    } = params;

    return {
        clientId,
        ...transformPaginationParams(paginatedParams),
    };
}

export function transformClientAWLCreationParams(
    { client, name, words, isEnabled }: ClientAWLCreationParams
): APIClientAWLCreationParams
{
    return {
        name, words, clientId: client, isEnabled,
    };
}

export function transformClientAWLToggleCreationParams(
    { disableAWL, clientId }: ClientAWLToggleUpdateParams
): APIClientAWLToggleCreationParams
{
    return {
        clientId,
        disableAWL,
    };
}

export function transformCustomAWLCreationParams(
    {
        agency: agencyId,
        profile: profileId,
        ...restParams
    }: CustomAWLCreationParams
): APICustomAWLCreationParams
{
    return {
        agencyId,
        profileId,
        ...restParams,
    };
}

export function transformCustomAWLsRetrieveParams({
    agencyId,
    ...paginationParams
}: CustomAWLsRetrieveParams): APICustomAWLsRetrieveParams
{
    return {
        agencyId,
        ...transformPaginationParams(paginationParams),
    };
}

export function transformAgencyAWLConfigurationParams({
    agency: agencyId,
    suggested: suggestedAlertWords,
    custom: customAlertWords,
}: AgencyAWLConfigurationUpdateParams): APIAgencyAWLConfigurationParams
{
    return {
        agencyId,
        suggestedAlertWords,
        customAlertWords,
    };
}

export function transformAWLRetrieveParams(
    params: AWLRetrieveParams
): APIAWLRetrieveParams
{
    const {
        startCreationDate,
        endCreationDate,
        searchValue,
        ...paginatedParams
    } = params;

    return {
        startDate: startCreationDate,
        endDate: endCreationDate,
        searchValue,
        ...transformPaginationParams(paginatedParams),
    };
}

export function readAPIAWLResponse(data: APIAWL): SuggestedAlertWordsList
{
    const {
        userId,
        userName,
        createdAt,
        updatedAt,
        disableAWL,
        ...rest
    } = data;

    return {
        authorId: userId,
        authorName: userName,
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        disableAWL,
        ...rest,
    };
}

export function readAPIClientAWLToggleResponse({
    client,
    clientName,
    disableAWL,
}: APIClientAWLToggleResponse): ClientAlertWordsToggleList
{
    const newLocal = {
        client,
        clientName,
        disableAWL,
    };
    return newLocal;
}
export interface APIOfficerAWLResponse extends Omit<APIAWL, 'profileId' | 'profileName' > {
    userId: string;
    userName: string;
    isEnabled: boolean;
    disableAWL: boolean;
}
export interface APIOfficerAWLToggleResponse extends Omit<APIAWL, 'profileId' | 'profileName'> {
    user: string;
    userName: string;
    disableAWL: boolean;
}
export interface APIOfficerAWLCreationParams extends Omit<APIAWLCreationParams,
'profileId'|'agency' > {
    userId: string;
    isEnabled?: boolean;
}
export interface APIOfficerAWLToggleCreationParams {
    userId: string;
    disableAWL?: boolean;
    user?: string;
}
export interface APIOfficerAWLParams extends APIPaginationParams {
    userId?: string;
}
export function transformOfficerAWLParams(
    params: AWLOfficerParams
): APIOfficerAWLParams
{
    const {
        userId,
        ...paginatedParams
    } = params;

    return {
        userId,
        ...transformPaginationParams(paginatedParams),
    };
}

export function transformOfficerAWLCreationParams(
    { user, name, words, isEnabled }: OfficerAWLCreationParams
): APIOfficerAWLCreationParams
{
    return {
        name, words, userId: user, isEnabled,
    };
}

export function transformOfficerAWLToggleCreationParams(
    { disableAWL, userId }: OfficerAWLToggleUpdateParams
): APIOfficerAWLToggleCreationParams
{
    return {
        userId,
        disableAWL,
    };
}

export function readAPIOfficerAWLResponse({
    userId: authorId,
    userName: authorName,
    createdAt,
    updatedAt,
    disableAWL,
    ...restParams
}: APIOfficerAWLResponse): OfficerAlertWordsList
{
    return {
        ...restParams,
        authorId,
        authorName,
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        disableAWL,
    };
}

export function readAPIOfficerAWLToggleResponse({
    user,
    userName,
    disableAWL,
}: APIOfficerAWLToggleResponse): OfficerAlertWordsToggleList
{
    const newLocal = {
        user,
        userName,
        disableAWL,
    };
    return newLocal;
}

export function readAPICustomAWLResponse(
    {
        agencyId: agency,
        isEnabled,
        ...restParams
    }: APICustomAWL
): CustomAlertWordsList
{
    return {
        agency,
        isEnabled,
        ...readAPIAWLResponse(restParams),
    };
}

export function readAPIAgencyConfiguration({
    agencyId: id,
    agencyName: name,
    suggestedAlertWords: suggested,
    customAlertWords: custom,
}: APIAgencyAWLConfiguration): AgencyAWLConfiguration
{
    return {
        id,
        name,
        suggested: suggested.map(readAPIAWLResponse),
        custom: custom.map(readAPICustomAWLResponse),
    };
}
