import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@mui/system/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';

import { Router } from 'src/pages/Router';
import { store } from 'src/state/store';
import theme from 'src/theme';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import 'leaflet/dist/leaflet.css';

import 'src/translations/setupTranslations';



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback="Loading...">
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </ThemeProvider>
            </Suspense>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
window.addEventListener('load', () =>
{
    navigator.serviceWorker.register('/service-worker.js');
});
