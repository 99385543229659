// eslint-disable-next-line
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'src/components/top-bar/TopBar';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Role } from 'src/models/roles.enum';
import {
    OfficerCreationFormModal,
} from 'src/templates/officer-creation-modal/OfficerCreationFormModal';
import {
    OfficerEditionFormModal,
} from 'src/templates/officer-edition-modal/OfficerEditionFormModal';
import { User } from 'src/models/userModels';
import { Stack } from '@mui/material';
import {
    OfficerConfigFormModal,
} from 'src/templates/officer-config-form-modal/OfficerConfigFormModal';
import {
    ConfigurationOwner,
} from 'src/models/administration/configurationData.model';
import { OfficerAlertWordsModal } from 'src/templates/officer-alert-words/OfficerAlertWordsModal';
import { Officer } from 'src/models/administration/officerData.model';
import { OfficersTable } from 'src/templates/officers-table/OfficersTable';

export function OfficerAccountList(): JSX.Element
{
    const { t } = useTranslation();
    const [isCreationModalOpen, setIsCreationModalOpen] =
        useState<boolean>(false);
    const [isEditionModalOpen, setIsEditionModalOpen] = useState<boolean>(false);
    const [officerInEdition, setOfficerInEdition] = useState<User>();
    const [officerInConfig, setOfficerInConfig] = useState<User>();
    const [officerInAlert, setOfficerInAlert] = useState<Officer>();

    // create or edit button
    function onModalClose(): void
    {
        setIsCreationModalOpen(false);
        setIsEditionModalOpen(false);
    }
    function onConfigModalClose(): void
    {
        setOfficerInConfig(undefined);
    }
    function onConfig(Officer: User): void
    {
        setOfficerInConfig(Officer);

    }

    // alertwords button
    function onAlertModalClose(): void
    {
        setOfficerInAlert(undefined);
    }
    function onAlert(user: Officer): void
    {
        setOfficerInAlert(user);
    }

    function onEdit(Officer: User): void
    {
        setOfficerInEdition(Officer);
        setIsEditionModalOpen(true);
    }

    function onSaveFinish(success: boolean): () => void
    {
        return () =>
        {
            if (success)
            {
                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }

    return (
        <AdministrationPage>
            <Stack>
                <TopBar
                    labels={[t('menu.settings'), t('menu.account-information')]}
                    icon={(
                        <SettingsRoundedIcon
                            fontSize="large"
                        />
                    )}
                />
            </Stack>

            <OfficersTable
                onEdit={onEdit}
                onConfig={onConfig}
                onAlert={onAlert}
                roles={[Role.Officer, Role.OfficerSupervisor]}
            />
            <OfficerCreationFormModal
                open={isCreationModalOpen}
                onClose={onModalClose}
            />
            {officerInEdition? (
                <OfficerEditionFormModal
                    open={isEditionModalOpen}
                    user={officerInEdition}
                    onClose={onModalClose}
                />
            ) : null}
            {officerInConfig ? (
                <OfficerConfigFormModal
                    user={officerInConfig}
                    context={ConfigurationOwner.Officer}
                    open={!!officerInConfig}
                    onClose={onConfigModalClose}
                    onSuccess={onSaveFinish(true)}
                    onFailure={onSaveFinish(false)}
                />
            ) : null}
            {officerInAlert ? (
                <OfficerAlertWordsModal
                    user={officerInAlert}
                    open={!!officerInAlert}
                    onClose={onAlertModalClose}
                />
            ) : null}
        </AdministrationPage>
    );
}
