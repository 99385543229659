import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAlertsFilter,
    changeFilters,
    selectAlertsFilter,
    selectFilters,
} from 'src/state/captures/capturesSlice';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { ALERTSPAGE, SCREENSHOTMETAPAGE } from 'src/models/alertsType.enum';
import { useTranslation } from 'react-i18next';
export function DataTypeSearchFilter(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertsfilter = useSelector(selectAlertsFilter);
    const filters = useSelector(selectFilters);

    function onChangeSelectFilter(value: string): void
    {
        if(window.location.pathname === ALERTSPAGE)
        {
            dispatch(
                changeAlertsFilter({
                    searchValue: value,
                })
            );
        }
        else
        {
            dispatch(
                changeFilters({
                    searchValue: value,
                })
            );
        }
    }

    return (
        <FormControl fullWidth>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                disabled={window.location.pathname === SCREENSHOTMETAPAGE}
                autoComplete="off"
                value={window.location.pathname === ALERTSPAGE
                    ? alertsfilter?.searchValue
                    : filters?.searchValue
                }
                onChange={event => onChangeSelectFilter(event.target.value)}
            />
        </FormControl>
    );
}
