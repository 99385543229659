import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import {
    TableExportFormat,
    usePDFExportSetter,
} from 'src/components/table-exporter/TableExporterProvider';
import { ActiveData } from 'src/models/captures/alertsData.model';
import { DataType } from 'src/models/dataType.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeactivedatasearchValue,
    getActiveDevices,
    getActiveDevicesId,
    getAllActiveDevices,
    selectActiveData,
    selectActiveDataStatus,
    selectAlertsFilter,
    selectAllActiveData,
    selectactivedatasearchValue,
    selectcardDataIds,
    selectcardDataStatus,
} from 'src/state/captures/capturesSlice';
import { Button, CircularProgress, TextField } from '@mui/material';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { PDFExport } from '../common-export-form/PDFExport';
import DownloadIcon from '@mui/icons-material/Download';
import { ColumnSelector } from '../column-selector/ColumnSelector';

export function ActiveReportersListModal(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activedata = useSelector(selectActiveData);
    const activedataStatus = useSelector(selectActiveDataStatus);
    const allActivedata = useSelector(selectAllActiveData);
    const alertsFilter = useSelector(selectAlertsFilter);
    const activedatasearchValue = useSelector(selectactivedatasearchValue);
    const carddataId = useSelector(selectcardDataIds);
    const cardDataStatus = useSelector(selectcardDataStatus);
    const pdfExportSetter = usePDFExportSetter();

    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);

    useEffect(()=>
    {
        dispatch(getAllActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage,
            searchValue: activedatasearchValue,
            isXLSX: true,

        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertsFilter, activedatasearchValue]);


    function onPageChange(pageNumber: number): void
    {
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber,
            entriesPerPage,
            searchValue: activedatasearchValue,
        }));
    }
    const handleEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setEntriesPerPage(newEntriesPerPage);
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
            searchValue: activedatasearchValue,
        }));
    };

    const columns = [
        {
            label: t('common.nouns.id'),
            size: 5,
            value: (data: ActiveData) => data.id,
        },
        {
            label: t('form.rcom-account'),
            size: 5,
            value: (data: ActiveData) => data.remoteCOMAccount,
        },
        {
            label: t('common.nouns.device'),
            size: 4,
            value: (data: ActiveData) => data.deviceAlias,
        },
        {
            label: t('common.nouns.client'),
            size: 5,
            value: (data: ActiveData) => data.client,
        },
        {
            label: t('common.nouns.softwrae-version'),
            size: 3,
            value: (data: ActiveData) =>
                data.softwareVersion ? data.softwareVersion : '-',
        },
        {
            label: t('data-type.table.last-updated'),
            size: 4,
            value: (data: ActiveData) =>
            {
                if (data.lastReported === 'Not Reported')
                {
                    return 'Not Reported';
                }
                else
                {
                    return timeZoneFormat(new Date(data.lastReported), USER_TIME_ZONE);
                }
            },
        },
    ];
    useEffect(() =>
    {
        if (activedata)
        {
            pdfExportSetter({
                type: DataType.ACTIVE_REPORTER,
                recordIds: activedata.contents.map((item) => item.id),

            });
        }
    }, [activedata, pdfExportSetter]);

    function onChangeSelectFilter(value: string): void
    {
        dispatch(changeactivedatasearchValue(value));
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage,
            searchValue: value,
        }));
    }

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getActiveDevicesId({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
        }));
    }
    async function handlerAllXLSXExport():
        Promise<void>
    {
        setFormatAll('XLSX');
    }


    return (
        <>
            <PDFExport
                selectedValue={handlerAllPDFExport}
                ids={carddataId}
                idStatus={cardDataStatus}
                data={DataType.ACTIVE_REPORTER}
                formatAll={formatAll}
                setFormatAll={setFormatAll}
            />
            {activedata?.contents.length ? (
                <Button
                    variant="text"
                    startIcon={
                        formatAll === TableExportFormat.XLSX
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    disabled={formatAll === TableExportFormat.XLSX}
                    onClick={() =>
                    {
                        handlerAllXLSXExport();
                    }}
                >
                    {t('common.button.all-to-xlsx')}
                </Button>
            ) : null
            }
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                autoComplete="off"
                style={{ float: 'right' }}
                sx={{ mb: 2 }}
                onChange={event => onChangeSelectFilter(event.target.value)}
            />
            <DataTable<ActiveData>
                isLoading={activedataStatus === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={handleEntriesPerPageChange}
                datas={activedata}
                {...activedata}
            />
            {formatAll === TableExportFormat.XLSX &&
                allActivedata &&
                allActivedata?.contents.length > 0 && (
                <ColumnSelector
                    format={TableExportFormat.XLSX}
                    columns={columns}
                    data={{
                        contents: allActivedata?.contents,
                        currentPage: 1,
                        numberOfEntries: 0,
                    }}
                    onClose={() => setFormatAll(undefined)}
                />
            )}
        </>
    );
}
