import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { TextMMSAlertsTable } from 'src/templates/text-mms-alerts-table/TextMMSAlertsTable';

export function DataTypeTextMMS(): React.ReactElement
{
    return (
        <DataTypePage>
            <TextMMSAlertsTable />
        </DataTypePage>
    );
}
