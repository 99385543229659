/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
    getNotificationConfiguration,
    selectNotificationConfiguration,
    selectNotificationConfigurationStatus,
} from 'src/state/administration/administrationSlice';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    NotificationsForm,
} from 'src/templates/notifications/notifications-form/NotificationsForm';

type NotificationEditParams = {
    id: string;
};

export function NotificationEdit(): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams<NotificationEditParams>();

    const notificationConfiguration = useSelector(selectNotificationConfiguration);
    const notificationConfigurationStatus = useSelector(selectNotificationConfigurationStatus);

    useEffect(() =>
    {
        if (!notificationConfiguration?.id && params?.id)
        {
            dispatch(getNotificationConfiguration(params.id));
        }
    }, []);

    return (
        <>
            <Header />
            <Box m={4}>
                <TopBar
                    labels={[
                        t('notifications.my-notifications'),
                        t('notifications.edit-notification'),
                    ]}
                    icon={<NotificationsIcon />}
                />
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        color: theme => theme.palette.grey[500],
                        margin: '20px 0',
                    }}
                >
                    {t('notifications.notification-id')}
                    {' '}
                    {notificationConfiguration?.id}
                    <br />
                </Typography>
                {notificationConfigurationStatus === RequestStatus.Success ? (
                    <NotificationsForm {...notificationConfiguration} />
                ) : (
                    <Box textAlign={'center'}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
}
