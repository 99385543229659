import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputProps, SxProps, Theme } from '@mui/material';

export interface PasswordInputProps extends InputProps
{
    parentSx?: SxProps<Theme>;
    label: string,
}

export function PasswordInput(
    { parentSx, ...props }: Readonly<PasswordInputProps>
): React.ReactElement
{
    const [showPassword, setShowPassword] = useState(false);
    function toggleShowPassword(): void
    {
        setShowPassword(!showPassword);
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth={props.fullWidth}
            size={props.size}
            sx={parentSx}
        >
            <InputLabel htmlFor={props.id ?? 'password-input-field'}>
                {props.label}
            </InputLabel>
            <OutlinedInput
                id="password-input-field"
                type={showPassword && !props.disabled ? 'text' : 'password'}
                endAdornment={(
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Toggle password visibility"
                            onClick={toggleShowPassword}
                            edge="end"
                            disabled={props.disabled}
                            sx={{
                                // -0.5rem = -8px, to compensate for the padding
                                marginRight: -1,
                            }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )}
                {...props}
            />
        </FormControl>
    );
}
