import {
    Activation,
    SendActivationCodeParams,
} from 'src/models/administration/activationData.model';

export interface APISendActivationCodeParams {
    deviceId: string;
    email: string;
}

export interface APISendActivationCodeResponse {
    id: string;
    deviceId: string;
    email: string;
    createdAt: Date;
    activationToken: string;
    isUsed: boolean;
}

export function transformSendActivationParams({
    device,
    email,
}: SendActivationCodeParams): APISendActivationCodeParams
{
    return {
        deviceId: device,
        email,
    };
}

export function readSendActivationResponse({
    id,
    deviceId,
    email,
    createdAt,
    activationToken,
    isUsed,
}: APISendActivationCodeResponse): Activation
{
    return {
        id,
        device: deviceId,
        email,
        createdAt,
        activationToken,
        isUsed,
    };
}
