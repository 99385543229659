import React from 'react';
import { useDispatch } from 'react-redux';
import {
    createClientAWL,
    resetClientAWLCreationStatus,
} from 'src/state/administration/administrationSlice';
import { AWLCreationForm } from '../awl-creation-form/AWLCreationForm';

import { noop } from 'src/utils/common';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';


export interface ClientAWLCreationFormProps {
    status?: RequestStatus,
    assignedTo?: string;
    onBackToList?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function ClientAWLCreationForm({
    status,
    assignedTo,
    onBackToList = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<ClientAWLCreationFormProps>): React.ReactElement
{
    const dispatch = useDispatch();

    function onSubmit({ name, words }: GenericAWL): void
    {
        if (assignedTo)
        {
            dispatch(createClientAWL({
                name,
                words,
                client: assignedTo,
            }));
        }
    }

    function onNotificationClose(): void
    {
        dispatch(resetClientAWLCreationStatus());
    }

    return (
        <AWLCreationForm
            onSubmit={onSubmit}
            onBackToList={onBackToList}
            onSuccess={onSuccess}
            onFailure={onFailure}
            clientField={true}
            profileField={false}
            onNotificationClose={onNotificationClose}
            status={status}
        />
    );
}
