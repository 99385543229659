import { Assignations } from 'src/models/assignationsModel';
import { SessionUser, VerifyTokenResponse } from 'src/models/authorizationModels';
import { Role } from 'src/models/roles.enum';

export interface APIForgotPassword {
    email: string;
}

export interface APIUser
{
    id: string;
    originId: string;
    email: string;
    role: Role;
    assignations: Assignations;
}

export function readAPIUser(user: APIUser): SessionUser
{
    return { username: user.originId, role: user.role, assignations: user.assignations };
}

export function readVerifyTokenResponse(data: VerifyTokenResponse): VerifyTokenResponse
{
    return { message: data.message };
}
