import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTranslation } from 'react-i18next';

import { PasswordRule } from 'src/models/userModels';

export interface PasswordRulesProps {
    passwordRules: PasswordRule[];
    hasError?: boolean;
}

export function PasswordRules({ passwordRules, hasError }: Readonly<PasswordRulesProps>)
    : React.ReactElement
{
    const { t } = useTranslation();

    function getRuleColor(passed?: boolean): (theme: Theme) => string
    {
        if (passed)
        {
            return (theme: Theme) => theme.palette.success.main;
        }

        if (hasError)
        {
            return (theme: Theme) => theme.palette.error.main;
        }

        return (theme: Theme) => theme.palette.common.lightGrey;
    }

    function getRuleIcon(passed?: boolean): React.ReactElement
    {
        if (passed)
        {
            return <CheckIcon sx={{ height: '0.75rem' /* 12px */ }} />;
        }

        if (hasError)
        {
            return <CloseIcon sx={{ height: '0.75rem' /* 12px */ }} />;
        }

        return <RadioButtonUncheckedIcon sx={{ height: '0.75rem' /* 12px */ }} />;
    }

    return (
        <>
            {passwordRules.map(({ name, passed }) => (
                <Box
                    key={name}
                    sx={{
                        fontSize: '0.75rem', // 12px
                        color: getRuleColor(passed),
                    }}
                >
                    {getRuleIcon(passed)}
                    {t(`password-change.rules.${name}`)}
                </Box>
            ))}
        </>
    );
}
