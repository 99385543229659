import { PaginationParams, PaginatedContent } from 'src/models/paginatedResult.model';

export interface APIPaginationParams
{
    page: number;
    sizePerPage: number;
}

export interface APIPaginatedResponse<ContentType>
{
    results: ContentType[],
    page: number,
    total: number,
    totalActive?:number,
    totalInActive:number
    resultCount?: number;
}

export function transformPaginationParams(
    paginatedParams: PaginationParams
): APIPaginationParams
{
    const { pageNumber, entriesPerPage } = paginatedParams;
    return {
        page: pageNumber,
        sizePerPage: entriesPerPage,
    };
}

export function readAPIPaginatedResponse<APIContentType, ContentType>(
    paginatedData: APIPaginatedResponse<APIContentType>,
    readResultData: (data: APIContentType) => ContentType
): PaginatedContent<ContentType>
{
    const { results, page, total, totalActive, totalInActive, resultCount } = paginatedData;
    return {
        contents: results.map(result => readResultData(result)),
        currentPage: page,
        numberOfEntries: total,
        totalActive,
        totalInActive,
        resultCount,
    };
}
