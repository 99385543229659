import { Extension, ExtensionParams } from 'src/models/administration/extensionData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIExtensionResponse,
    transformExtensionParams,
    transformUpdateExtensionParams,
} from '../data-transfer-objects/administration/extensions';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import instance from '../instance';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import { AdminSearchParams } from 'src/models/administration/adminSearchData.model';

export async function createExtension(params: ExtensionParams): Promise<Extension>
{
    const response = await instance.post('extension', transformExtensionParams(params));
    return readAPIExtensionResponse(response.data);
}

export async function getExtensions(
    params?: AdminSearchParams
): Promise<PaginatedContent<Extension>>
{
    const response = await instance.get(
        'extension',
        { params: params ? transformCaptureParams(params) : {} }
    );

    return readAPIPaginatedResponse(response.data, readAPIExtensionResponse);
}

export async function updateExtension(extension: Extension): Promise<Extension>
{
    const response = await instance.put(
        `extension/${extension.id}`,
        transformUpdateExtensionParams(extension)
    );

    return readAPIExtensionResponse(response.data);
}

export async function deleteExtension(id: string): Promise<Extension>
{
    const response = await instance.delete(
        `extension/${id}`
    );
    return readAPIExtensionResponse(response.data);
}
