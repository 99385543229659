import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    getClientDisbaleAWLs,
    resetClientAWLCreationStatus,
    selectClientAWLCreationStatus,
    updateClientAWLToggle,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { ClientFormValues, DEFAULT_VALUES } from '../client-form/ClientForm';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Client } from 'src/models/administration/clientData.model';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import {
    ClientAWLCreationForm,
} from 'src/templates/client-awl-creation-form/ClientAWLCreationForm';
import {
    AWLEdit,
} from 'src/templates/awl-edit/AWLEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ClientAWLTable } from 'src/templates/client-awl-table/ClientAWLTable';

import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';

import { RootState } from 'src/state/store';

export interface ClientAlertWordsModalProps {
    client: Client;
    open?: boolean;
    onClose?: () => void;
}

export function ClientAlertWordsModal({
    client,
    open = false,
    onClose = noop,
}: Readonly<ClientAlertWordsModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [alertId, setAlertId] = useState<string>('');
    const [isModalAddWords, setIsModalAddWords] = useState<boolean>(false);
    const [isModalEditWords, setIsModalEditWords] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<ClientFormValues>(DEFAULT_VALUES);

    const data = useSelector((state : RootState) => state.administration.clientDisableAWLs);
    const [isAddButtonDisabled, setIsAddButtonDisabled] =
     useState<boolean>(data?.disableAWL?? false);
    const status = useSelector(selectClientAWLCreationStatus);
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = AWLContext.Client;
    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };

    const clientId = client.id;

    useEffect(() =>
    {
        dispatch (getClientDisbaleAWLs({ clientId }));

    }, [clientId, dispatch]);

    useEffect(() =>
    {
        setIsAddButtonDisabled(data?.disableAWL ?? false);
    }, [data]);

    function onSuccessAdd(): void
    {
        setInitialValues(client);
        setIsModalAddWords(false);
        dispatch(getConfiguration(client.id));
        dispatch(resetClientAWLCreationStatus());
    }
    function onFailAdd(): void
    {
        setIsModalAddWords(true);
        dispatch(resetClientAWLCreationStatus());
    }

    function selectIsModalEdit(id: string ): void
    {
        setAlertId(id);
        setIsModalEditWords(true);
    }
    function onSuccessEdit(): void
    {
        setInitialValues(client);
        setIsModalEditWords(false);
        dispatch(getConfiguration(client.id));
        dispatch(resetClientAWLCreationStatus());
    }
    function onFailEdit(): void
    {
        setIsModalEditWords(true);
        dispatch(resetClientAWLCreationStatus());
    }
    function onCancel(): void
    {
        onClose();
    }

    function onBackToList(): void
    {
        setInitialValues(client);
        setIsModalAddWords(false);
        setIsModalEditWords(false);
        dispatch(getConfiguration(client.id));
        dispatch(resetClientAWLCreationStatus());
    }

    useEffect(() =>
    {
        setInitialValues(client);
    }, [initialValues, setInitialValues, client]);
    useEffect(() =>
    {
        if (client.id)
        {
            dispatch(getConfiguration(client.id));
        }
    }, [dispatch, getConfiguration, client]);

    const handleToggleChange = (): void =>
    {
        const isDisabled = !isAddButtonDisabled;
        setIsAddButtonDisabled(isDisabled);
        saveToggleValue(isDisabled);
    };

    const saveToggleValue = (isDisabled: boolean): void =>
    {
        const payload = {
            disableAWL: isDisabled,
            clientId: client.id,
        };
        dispatch(updateClientAWLToggle(payload));
    };




    if(role)
    {
        tableContainerProps.canAdd = true;
        tableContainerProps.tableProps = {
            editable: true,
        };
    }
    if(isModalAddWords)
    {
        return (
            <Modal
                title={client.firstName+ ' ' +client.lastName+ ' ' +t('menu.alert-word-management')}
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                { role && (
                    <ClientAWLCreationForm
                        status={status}
                        assignedTo={client.id}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessAdd}
                        onFailure={onFailAdd}
                    />
                ) }
            </Modal>
        );
    }
    else if(isModalEditWords)
    {
        return (
            <Modal
                title={client.firstName+ ' ' +client.lastName+ ' ' +t('menu.alert-word-management')}
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                { role && (
                    <AWLEdit
                        mode={role}
                        context={'client'}
                        alertId={alertId}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessEdit}
                        onFailure={onFailEdit}
                    />
                ) }
            </Modal>
        );
    }
    else return (
        <Modal
            title={client.firstName+ ' ' +client.lastName+ ' ' +t('menu.alert-word-management')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <AgencyAWLConfigurationContextProvider>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    padding={'1em 0'}
                    margin={'-2em 0em 0em 0em'}
                />
                <Box m={4}>
                    <TopBar
                        buttons={[
                            isAddButtonDisabled
                                ? {
                                    text: t('common.actions.add'),
                                    icon: <AddCircleIcon />,
                                }
                                : {
                                    text: t('common.actions.add'),
                                    icon: <AddCircleIcon />,
                                    onClick: () => setIsModalAddWords(true),
                                },
                        ]}
                    />
                    <ClientAWLTable
                        clientId={client.id}
                        selectIsModalEdit={selectIsModalEdit}
                        isAddButtonDisabled={isAddButtonDisabled}
                    />
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding="1em 0"
                        gap={1}
                    >

                        {
                            (userIsInRole([Role.Administrator,
                                Role.AgencyAdministrator,
                                Role.Employee]))? (
                                    <Grid
                                        sx={{ marginRight: 'auto' }}
                                    >
                                        {t('common.actions.block-officer')}
                                        <ToggleSwitch
                                            sx={{ ml: 1, fontSize: 2 }}
                                            checked={isAddButtonDisabled}
                                            onChange={ handleToggleChange }
                                        />
                                    </Grid>
                                ) : null
                        }
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={onCancel}
                        >
                            {t('common.actions.close')}
                        </Button>
                    </Stack>
                </Box>
            </AgencyAWLConfigurationContextProvider>
        </Modal>
    );
}
