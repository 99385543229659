import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Button, Checkbox, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
    selectUpdateVersionStatus,
    selectVersionUpdateError,
    createVersion,
    resetVersionFormStatus,
    selectCreateVersionStatus,
    updatedownloadurl,
} from 'src/state/administration/administrationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    AnimatedEllipsis,
} from 'src/components/animated-ellipsis/AnimatedEllipsis';
import {
    PlatformVersion,
} from 'src/models/administration/platformVersionData.model';
import {
    Platform, platformStringKey,
} from 'src/models/platforms.enum';
import {
    FilterOption, PlatformOption,
} from 'src/models/common/filterOptionData.model';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { ErrorData } from 'src/models/errors/errorData.model';

function FormErrors(errorData: Readonly<ErrorData>): JSX.Element
{
    const { t } = useTranslation();
    let errors: string[] = [];
    if(errorData?.messages)
    {
        errors = errorData?.messages;
    }

    if(errorData?.message && !Array.isArray(errorData?.message))
    {
        errors.push(errorData?.message);
    }

    if(errors.length)
    {
        return (
            <Alert severity="error" variant="filled" sx={{ mb: 5 }}>
                <AlertTitle sx={{ fontSize: 16 }}>
                    {t('form.validation-errors')}
                </AlertTitle>
                <ul>
                    {errors.map((error, key) => (
                        <li key={key}>
                            {error}
                        </li>
                    ))}
                </ul>
            </Alert>
        );
    }

    return <></>;
}

export function VersionUpdateForm(props: Readonly<PlatformVersion>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const versionUpdateStatus = useSelector(selectUpdateVersionStatus);
    const versionCreateStatus = useSelector(selectCreateVersionStatus);
    const versionError = useSelector(selectVersionUpdateError);
    const [platformData, setPlatformData] = useState<PlatformVersion>(props);

    const platforms = Object.values(Platform).map((value) =>
    {
        return getRoleOption(value);
    });

    function getRoleOption (platform: Platform) : PlatformOption
    {
        return {
            value: platform,
            name: t(platformStringKey(platform)),
        };
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        if(platformData)
        {
            if(!platformData.id)
            {
                dispatch(createVersion(platformData));
            }
            else
            {
                const formData = {
                    downloadUrl: platformData.downloadUrl,
                    id: platformData.id,
                };
                dispatch(updatedownloadurl(formData));
            }
        }
        if(platformData.downloadUrl && platformData.platform && platformData.version )
        {
            props.closeModal(false);
            props.onComplete(true);
        }
    }

    function isLoading(): boolean
    {
        return RequestStatus.InProgress === versionUpdateStatus ||
            RequestStatus.InProgress === versionCreateStatus;
    }

    function onChangePlatform(data: FilterOption | null): void
    {
        // TO DO
        if (data)
        {
            setPlatformData((prevData) => ({
                ...prevData,
                platform: data.value,
            }));
        }
    }

    const onChangeDowngrade = (downgrade?: boolean): void =>
    {
        setPlatformData((prevState) =>
        {
            return {
                ...prevState,
                ddowngrade: downgrade ?? !prevState.downgrade,
            };
        });
    };

    return (
        <>
            <FormErrors {...versionError}/>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Grid container spacing={5}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Autocomplete
                            disablePortal
                            id="platform"
                            value={
                                platforms.find(
                                    platform => platformData?.platform === platform.value
                                )
                            }
                            options={platforms ?? []}
                            onChange={(_, data) => onChangePlatform(data)}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('common.nouns.platform')}
                                    error={handleError(versionError, 'platform')}
                                    helperText={handleErrorText(versionError, 'platform')}
                                />
                            )}
                            disabled={!!platformData.id}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            fullWidth
                            id="version"
                            value={platformData?.version}
                            onChange={(event) =>
                                setPlatformData({
                                    ...platformData,
                                    version: event.target.value,
                                })}
                            label={t('data-type.table.version')}
                            name="version"
                            autoFocus
                            error={handleError(versionError, 'version')}
                            helperText={handleErrorText(versionError, 'version')}
                            disabled={!!platformData.id}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={12}
                    >
                        <TextField
                            fullWidth
                            id="downloadUrl"
                            value={platformData?.downloadUrl}
                            onChange={(event) =>
                                setPlatformData({
                                    ...platformData,
                                    downloadUrl: event.target.value,
                                })}
                            label={t('data-type.table.downloadUrl')}
                            name="downloadUrl"
                            error={handleError(versionError, 'downloadUrl')}
                            helperText={handleErrorText(versionError, 'downloadUrl')}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        marginRight: 'auto',
                        mt: 3,
                    }}
                >
                    <Checkbox
                        value={platformData?.downgrade}
                        onClick={() =>
                        {
                            onChangeDowngrade(!platformData?.downgrade);
                        }}
                        checked={platformData?.downgrade}
                        disabled={!!platformData.id}
                    />
                    {t('common.actions.down-version')}
                </Grid>
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button
                        sx={{
                            mr: 1,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.common.goldYellow,
                            '&.Mui-disabled': {
                                backgroundColor: 'inherit',
                                color: theme => theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={ ()=>
                        {
                            props.closeModal(false);
                            dispatch(resetVersionFormStatus());
                        }}
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            mr: 1,
                            minWidth: '7.5rem',
                            '&.Mui-disabled': {
                                backgroundColor: theme => theme.palette.primary.main,
                                color: theme => theme.palette.primary.contrastText,
                            },
                        }}
                        disabled={isLoading()}
                    >
                        {t('form.save-changes')}
                        {isLoading() && <AnimatedEllipsis />}
                    </Button>
                </Box>
            </Box>
        </>
    );
}
