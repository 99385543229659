
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import logo from 'src/assets/images/logo.svg';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

export function LoginHeader(): React.ReactElement
{
    const theme = useTheme();
    return (
        <AppBar
            sx={{
                [theme.breakpoints.up('md')]: {
                    borderColor: theme => theme.palette.common.goldYellow,
                    borderStyle: 'solid',
                    borderWidth: '0 0 0.25rem',
                    padding: '0.625rem 2.1875rem',
                },
                [theme.breakpoints.down('md')]: {
                    boxShadow: 'none',
                    position: 'relative',
                    zIndex: 0,
                },
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center',
                        width: '100vw',
                    },
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 100,
                        [theme.breakpoints.down('md')]: {
                            margin: '81px 0 87.87px 0',
                            height: 122,
                            width: 300,
                        },
                    }}
                    alt="Logo"
                    src={logo}
                />
                <Typography
                    variant="h3"
                    sx={{
                        color: theme => theme.palette.common.goldYellow,
                        padding: '0 1.5rem',
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    |
                </Typography>
                <Typography
                    variant="h3"
                    sx={{
                        color: theme => theme.palette.common.white,
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    SCOUT
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
