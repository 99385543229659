import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    createClient,
    resetClientSaveStatus,
    selectClientSaveStatus,
    clearRemoteComError,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { ClientForm, ClientFormValues, DEFAULT_VALUES } from '../client-form/ClientForm';

export interface ClientCreationFormModalProps {
    open?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function ClientCreationFormModal({
    open = false,
    onClose = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<ClientCreationFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectClientSaveStatus);
    const [initialValues, setInitialValues] = useState<ClientFormValues>(DEFAULT_VALUES);

    function onSubmit(data: ClientFormValues): void
    {
        dispatch(createClient(data));
    }

    function onCancel(): void
    {
        onClose();
    }

    useEffect(() =>
    {
        dispatch(clearRemoteComError());
        if (status === RequestStatus.Success)
        {
            setInitialValues({ ...DEFAULT_VALUES });
            onSuccess();
            dispatch(resetClientSaveStatus());
        }
        else if (status === RequestStatus.Failure)
        {
            onFailure();
            dispatch(resetClientSaveStatus());
        }
    }, [status, setInitialValues, onSuccess, onFailure, dispatch]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(resetClientSaveStatus());
        };
    }, [dispatch]);

    return (
        <Modal
            title={t('clients.new-client')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <ClientForm
                    buttonLabel="create"
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    loading={status === RequestStatus.InProgress}
                />
            </Box>
        </Modal>
    );
}
