import { APICaptureData, readAPICaptureData } from './captures';
import { ExternalDeviceData } from 'src/models/captures/externalDeviceData.model';


export interface APIExternalDeviceResponse extends APICaptureData {
    activity: string;
    deviceName: string;
    deviceClass: string;
    fileName: string;
    fileSize: string;
    oldFileName?: string;
    alertWords?: string[];
}

export function readAPIExternalDeviceResponse(
    data: APIExternalDeviceResponse
): ExternalDeviceData
{
    const {
        activity,
        deviceName,
        deviceClass,
        fileName,
        fileSize,
        oldFileName,
        alertWords,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        activity,
        deviceName,
        deviceClass,
        fileName,
        fileSize,
        oldFileName,
        alertWords,
    };
}
