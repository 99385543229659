/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectReport,
    getReportFiles,
    selectReportFiles,
    selectReportFilesStatus,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { ReportFile, ReportsListFileResponse } from 'src/models/administration/reportData.model';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CircularProgress, IconButton } from '@mui/material';
import { downloadFile as downloadFileRequest } from 'src/services/files/files';
import { PDF, XLSX } from 'src/utils/environment';

export function ReportDetails(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [reportData, setReportData] = useState<ReportsListFileResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const reportMeta = useSelector(selectReport);
    const items = useSelector(selectReportFiles);
    const status = useSelector(selectReportFilesStatus);

    useEffect(() =>
    {
        if(reportMeta)
        {
            dispatch(getReportFiles({ pageNumber: 1, entriesPerPage, id: reportMeta.id }));
        }
    }, [dispatch, entriesPerPage, reportMeta]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getReportFiles({ pageNumber, entriesPerPage, id: reportMeta?.id }));
    }

    async function downloadFile(reports: ReportsListFileResponse): Promise<void>
    {

        setReportData(reports);
        const totalFiles = reports.files?.length;

        for (let file = 0; file < totalFiles; file++)
        {
            const report = reports.files[file];
            if (report?.fileUrl)
            {
                const fileMeta = await downloadFileRequest(report.fileUrl);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(fileMeta);
                const fileType = report.fileName?.split('.').pop() === PDF
                    ? PDF : XLSX;
                const reportCount = (report.fileName?.match(/\((\d+-\d+)\)/)?.[1]) ?? '';
                const count = reportCount ? `-(${reportCount})` : '';
                const reportDate = report?.createdAt ?
                    new Date(report.createdAt).toISOString().split('T')[0] : '';
                link.download =
                    `${reportMeta?.name}-${reportDate}${count}.${fileType}`;
                link.click();
                setProgress(Math.round(((file + 1) / totalFiles) * 100));
            }
        }
        setProgress(0);
        setIsLoading(false);
        setReportData(undefined);
    }

    function isDownloading(reportDate: Date): boolean
    {
        return reportData?.reportDate === reportDate && isLoading;
    }

    const getActionColumn = (data: ReportsListFileResponse): React.ReactNode =>
    {
        return (
            <IconButton
                aria-label="edit"
                onClick={() =>
                {
                    setIsLoading(true);
                    downloadFile(data);
                }}
            >
                {
                    isDownloading(data.reportDate)
                        ? (
                            <span style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>
                                <span>
                                    {progress}
                                    {' '}
                                    %
                                </span>
                                {' '}
                                <CircularProgress size={18} />
                            </span>
                        )
                        : <FileDownloadIcon />
                }
            </IconButton>
        );
    };

    const getFileNameColumn = (files: ReportFile[]): string | undefined =>
    {
        if(files.length === 1)
        {
            return files[0]?.fileName;
        }
        else
        {
            const [firstFileName, lastFileName] =
                [files[0]?.fileName, files[files.length - 1]?.fileName];

            if (!firstFileName || !lastFileName) return '';
            const reportName = firstFileName.split('(')[0];
            const startRange = firstFileName.match(/\((\d+)-/)?.[1];
            const endRange = lastFileName.match(/-(\d+)\)/)?.[1];

            return startRange && endRange ?
                `${reportName}(${startRange}-${endRange}).xlsx`: reportName;
        }
    };

    const columns: ColumnData<ReportsListFileResponse>[] = [
        {
            label: t('reports.reports-recorded'),
            size: 8,
            value: (data: ReportsListFileResponse) => getFileNameColumn(data.files),
        },
        {
            label: t('data-type.table.date'),
            size: 4,
            value: (data: ReportsListFileResponse) => data.files[0]?.createdAt?.toLocaleString(),
        },
        {
            label: t('data-type.table.format'),
            size: 4,
            value: (data: ReportsListFileResponse) => data.files[0].fileName?.split('.').pop(),
        },
        {
            label: t('data-type.table.action'),
            size: 1,
            align: 'center' as const,
            format: (data: ReportsListFileResponse) => getActionColumn(data),
        },
    ];

    return (
        <DataTable<ReportsListFileResponse>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            {...items}
        />
    );
}
