import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PasswordInput } from 'src/components/password-input/PasswordInput';
import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';

export interface LoginFormProps
{
    handleLogin: (email: string, password: string) => void;
    forgotPasswordRoute: string;
    isLoggingIn?: boolean;
    hasLoginError?: boolean;
}

export function LoginForm(
    {
        handleLogin,
        forgotPasswordRoute,
        isLoggingIn = false,
        hasLoginError = false,
    }: Readonly <LoginFormProps>
): React.ReactElement
{
    const { t } = useTranslation();

    async function handleSubmit(
        event: React.FormEvent<HTMLFormElement>
    ): Promise<void>
    {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        handleLogin(
            formData.get('email') as string,
            formData.get('password') as string
        );
    }

    const [displayError, setDisplayError] = useState(hasLoginError);
    function clearError(): void
    {
        setDisplayError(false);
    }

    useEffect(() =>
    {
        setDisplayError(hasLoginError);
    }, [hasLoginError]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                height: '18.5rem' /* 296px */,
                mt: { xs: 4 },
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: theme => theme.palette.common.lightGrey,
                        fontSize: '1.25rem', // 20px
                    }}
                >
                    {t('login-form.title')}
                </Typography>

                <Grid container>
                    <Grid item md={9} xs={12}>
                        <TextField
                            sx={{
                                mt: 2, // margin-top 16px
                                mb: 3, // margin-bottom 24px
                                '& input, & label': {
                                    color: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                '& svg': {
                                    fill: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                opacity: isLoggingIn ? 0.5 : 1,
                            }}
                            fullWidth
                            id="email-field"
                            label={t('login-form.email-input-label')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isLoggingIn}
                            onChange={clearError}
                        />

                        <PasswordInput
                            fullWidth
                            name="password"
                            id="password-field"
                            label={t('login-form.password-input-label')}
                            autoComplete="current-password"
                            parentSx={{
                                '& input, & label': {
                                    color: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                '& svg': {
                                    fill: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                opacity: isLoggingIn ? 0.5 : 1,
                            }}
                            disabled={isLoggingIn}
                            onChange={clearError}
                        />
                    </Grid>

                    {displayError && (
                        <Grid item xs={11}>
                            <Box
                                sx={{
                                    mt: 1, // margin-top 8px
                                    fontSize: '0.75rem', // 12px
                                    color: theme => theme.palette.error.main,
                                }}
                            >
                                {t('login-form.failed-login-message')}
                            </Box>
                        </Grid>
                    )}
                </Grid>

                <Box
                    sx={{
                        fontSize: '0.75rem', // 12px
                        color: theme => theme.palette.common.lightGrey,
                        mt: 2, // margin-top 16px
                        opacity: isLoggingIn ? 0.5 : 1,
                    }}
                >
                    {t('login-form.forgot-password-message')}
                    {' '}
                    <Link
                        underline={isLoggingIn ? 'none' : 'hover'}
                        sx={{
                            display: 'inline',
                            fontSize: '0.75rem', // 12px
                            color: theme =>
                                theme.palette.common.goldYellow,
                        }}
                        component={isLoggingIn ? Box : RouterLink}
                        to={forgotPasswordRoute}
                    >
                        {t('login-form.forgot-password-link')}
                    </Link>
                </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button
                    type="reset"
                    sx={{
                        mr: 1, // margin-right: 8px
                        backgroundColor: 'transparent',
                        color: theme => theme.palette.common.goldYellow,
                        opacity: isLoggingIn ? 0.5 : 1,
                        '&.Mui-disabled': {
                            backgroundColor: 'inherit',
                            color: theme => theme.palette.common.goldYellow,
                        },
                    }}
                    disabled={isLoggingIn}
                >
                    {t('login-form.clear-button')}
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: '7.5rem', // 120px
                        '&.Mui-disabled': {
                            backgroundColor: theme => theme.palette.primary.main,
                            color: theme => theme.palette.primary.contrastText,
                        },
                    }}
                    disabled={isLoggingIn}
                >
                    {t(isLoggingIn
                        ? 'login-form.login-button-loading'
                        : 'login-form.login-button')}
                    {isLoggingIn && <AnimatedEllipsis />}
                </Button>
            </Box>
        </Box>
    );
}
