import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { noop } from 'src/utils/common';
import {
    selectSuggestedAWLNoPageable,
    selectSuggestedAWLRetrieveStatus,
    getSuggestedAWLsNoPageable,
    deleteAgency,
    selectAgencyDeleteStatus,
    resetAgencyDeleteStatus,
    agencyMonitorList,
    getAgencyMonitorList,
    agencyassignedMonitor,
    agencyassignedMonitorStatus,
    getAssignedAgencyMonitor,
} from 'src/state/administration/administrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SuggestedAlertWordsList } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Modal } from 'src/components/modal/Modal';
import { MonitorAgency } from 'src/models/administration/agencyData.model';
import AgencyDetailsModel from '../agency-details-model/AgencyDetailsModel';
import { DELETE_AGENCY_DURATION } from 'src/utils/environment';
export interface AgencyFormValues {
    id: string,
    agencyName: string;
    enabled: boolean;
    activated?: boolean;
    description: string;
    logo: string;
    logoUrl: string;
    suggestedAlertWords?: string[];
    deleted?: boolean;
    expirationTime?: Date;
    monitoruser?: string[];
    ccContacts?: string[];
}
export const DEFAULT_VALUES: AgencyFormValues = {
    id: '',
    agencyName: '',
    enabled: true,
    activated: true,
    description: '',
    logo: '',
    logoUrl: '',
    suggestedAlertWords: [],
    deleted: false,
    expirationTime: undefined,
    monitoruser: [],
    ccContacts: [],
};
interface AgencyFormProps {
    buttonLabel: string,
    loading?: boolean;
    initialValues?: AgencyFormValues;
    onSubmit?: (agency: AgencyFormValues, logo?: File) => void;
    onCancel?: () => void;
    onDeleteFinish?: () => void
    onUndo?: () => void;
}
interface FormErrors {
    [key: string]: string[] | undefined;
    monitoruser?:string[];
}

function LogoPreview({ url }: Readonly<{ url: string }>): JSX.Element
{
    return (
        <img
            src={url}
            style={{
                maxHeight: 200,
                maxWidth: 200,
            }}
            alt="Thumb"
        />
    );
}

export function AgencyForm({
    buttonLabel = '',
    loading = false,
    initialValues = DEFAULT_VALUES,
    onSubmit = noop,
    onCancel = noop,
    onDeleteFinish = noop,
    onUndo = noop,
}: Readonly<AgencyFormProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const suggestedAWL = useSelector(selectSuggestedAWLNoPageable);
    const suggestedAWLStatus = useSelector(selectSuggestedAWLRetrieveStatus);
    const agencyMonitorStatus = useSelector(agencyassignedMonitorStatus);
    const assignMonitor = useSelector(agencyassignedMonitor);
    const monitorList = useSelector(agencyMonitorList);
    const [values, setValues] = useState<AgencyFormValues>(initialValues);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const Token = JSON.parse(localStorage.getItem('Token') as string);
    const userRole = Token.role;
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    function onChange(event: React.ChangeEvent<HTMLInputElement>): void
    {
        const { value, name } = event.target;

        setValues((current) => ({
            ...current,
            [name]: value,
        }));

        setFormErrors((current) => ({
            ...current,
            [name]: value ? undefined : [t('common.form-messages.required')],
        }));
    }
    function onBlur(event: React.FocusEvent<HTMLInputElement>): void
    {
        const { value, name } = event.target;
        const trimmedValue = value.trim();

        setValues((current) => ({
            ...current,
            [name]: trimmedValue,
        }));

        setFormErrors((current) => ({
            ...current,
            [name]: trimmedValue ? undefined : [t('common.form-messages.required')],
        }));
    }
    function hasErrors(field: string): boolean
    {
        return !!formErrors[field];
    }

    function getErrorMessages(field: string): string
    {
        return formErrors[field]?.join('\n') ?? '';
    }
    function handleImageChange(selectorFiles: FileList | null): void
    {
        if (selectorFiles && selectorFiles.length > 0)
        {
            setSelectedImage(selectorFiles.item(0));
        }
        setFormErrors((current) => ({
            ...current,
            logo: selectorFiles ? undefined : [t('agency.logo')],
        }));
    }

    function onToggleChange(x: boolean): void
    {
        setValues((current) => ({
            ...current,
            enabled: x,
        }));
        setFormErrors((current) => ({
            ...current,
            enabled: x !== null ? undefined : [t('agency.toggle')],
        }));
    }
    function onToggleChangeSuggested(y: boolean): void
    {
        setValues((current) => ({
            ...current,
            activated: y,
        }));
        setFormErrors((current) => ({
            ...current,
            activated: y !== null ? undefined : [t('agency.toggle')],
        }));
    }
    function onSubmitHandler(event: React.FormEvent): void
    {
        event.preventDefault();

        setFormErrors((current) => ({
            ...current,
            logo: (values.logoUrl || selectedImage) ? undefined : [t('agency.logo')],
        }));
        if (!values.monitoruser || values.monitoruser.length === 0)
        {
            setFormErrors((current) => ({
                ...current,
                monitoruser: [t('agency.monitor')],
            }));
            return;
        }
        setFormErrors((current) => ({ ...current, monitoruser: undefined }));
        if (!Object.values(formErrors).find((error) => error))
        {
            onSubmit(values, selectedImage ?? undefined);
        }
    }
    function onChangeAwl(selectedAWLs: SuggestedAlertWordsList[]): void
    {
        setValues(
            {
                ...values,
                suggestedAlertWords: selectedAWLs.map(awl => awl.id),
            }
        );

        setFormErrors((current) => ({
            ...current,
            logo: selectedAWLs ? undefined : [''],
        }));
    }
    function onChangeOfficerMail(
        event: React.SyntheticEvent<Element, Event>,
        // eslint-disable-next-line
        value: any
    ): void
    {
        setValues(
            {
                ...values,
                ccContacts: value,
            }
        );
        setFormErrors((current) => ({
            ...current,
            ccContacts: value ? undefined : [''],
        }));
    }

    function onChangeMonitor(selectedmonitors: MonitorAgency[]): void
    {
        setValues(
            {
                ...values,
                monitoruser: selectedmonitors.map(awl => awl.id),
            }
        );
        setFormErrors((current) => ({
            ...current,
            monitoruser: selectedmonitors.length > 0
                ? undefined
                : [t('common.form-messages.required')],
        }));
    }
    useEffect(() =>
    {
        setValues(initialValues ?? DEFAULT_VALUES);
        dispatch(getSuggestedAWLsNoPageable());
        dispatch(getAgencyMonitorList());
        if (values.id)
        {
            dispatch(getAssignedAgencyMonitor(values.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, initialValues]);
    useEffect(() =>
    {
        if (values.id && assignMonitor)
        {
            setValues(
                {
                    ...values,
                    monitoruser: assignMonitor.map(awl => awl.id),
                }
            );

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignMonitor]);

    useEffect(() =>
    {
        const errors = Object.values(formErrors);

        setCanSubmit(errors.length !== 0 && !errors.find((error) => error));
    }, [formErrors]);

    const [deleteOpen, setDeleteOpen] = useState(false);
    const saveStatus = useSelector(selectAgencyDeleteStatus);
    const DeleteModal = (): void =>
    {
        setDeleteOpen(!deleteOpen);
    };
    const [call, setCall] = useState(false);
    async function DeleteSubmit(data?: string): Promise<void>
    {
        if (data === values.agencyName)
        {
            setValues(
                {
                    ...values,
                    deleted: true,
                    expirationTime: new Date(Date.now() + DELETE_AGENCY_DURATION),
                }
            );
            setCall(true);
        }
    }

    async function undoFun(): Promise<void>
    {
        setValues(
            {
                ...values,
                deleted: false,
                expirationTime: new Date(0 * 0 * 0 * 1000),
            }
        );
        setCall(true);
    }

    useEffect(() =>
    {
        if (call === true)
        {
            dispatch(deleteAgency({ agency: values }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, call]);

    useEffect(() =>
    {
        if (saveStatus === RequestStatus.Success && values.deleted === true)
        {
            onDeleteFinish();
            dispatch(resetAgencyDeleteStatus());
            setTimeout(() =>
            {
                setDeleteOpen(false);
            }, 2000);
        }
        else if (saveStatus === RequestStatus.Success && values.deleted === false)
        {
            onUndo();
            dispatch(resetAgencyDeleteStatus());
            setTimeout(() =>
            {
                setDeleteOpen(false);
            }, 2000);
        }
        else if (saveStatus === RequestStatus.Failure)
        {
            dispatch(resetAgencyDeleteStatus());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saveStatus, t]);

    const [countdown, setCountdown] = useState<number>(0);

    useEffect(() =>
    {
        if (values.expirationTime)
        {
            const expirationDate = new Date(values.expirationTime).getTime();
            // eslint-disable-next-line
            const calculateCountdown = (): any => {
                const currentTime = new Date().getTime();
                const timeRemaining = Math.max(0, (expirationDate - currentTime) / 1000);
                setCountdown(timeRemaining);
            };
            calculateCountdown();
            const interval = setInterval(calculateCountdown, 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line
    const formatCountdown = (countdown: number): any =>
    {
        const hours = Math.floor(countdown / 3600);
        const minutes = Math.floor((countdown % 3600) / 60);
        const seconds = Math.floor(countdown % 60);
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        <form onSubmit={onSubmitHandler}>
            <Grid container spacing={5}>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <TextField
                        fullWidth
                        name="agencyName"
                        label={t('form.name')}
                        id="name"
                        autoComplete="off"
                        value={values.agencyName}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={hasErrors('agencyName')}
                        helperText={getErrorMessages('agencyName')}
                        required
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <TextField
                        fullWidth
                        name="description"
                        label={t('form.description')}
                        id="description"
                        autoComplete="off"
                        value={values.description}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={hasErrors('description')}
                        helperText={getErrorMessages('description')}
                        required
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <Button variant="contained" component="label">
                        {t('form.upload-logo')}
                        <input
                            onChange={(e) => handleImageChange(e.target.files)}
                            name="logo"
                            hidden
                            accept="image/*"
                            type="file"
                        />
                    </Button>
                    <br />
                    <div style={{ color: 'red' }}>
                        {getErrorMessages('logo')}
                    </div>
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <Autocomplete
                        multiple
                        id="suggestedAlertWords"
                        value={monitorList?.filter(
                            awl => values.monitoruser?.includes(awl.id)
                        ) ?? []}
                        size="small"
                        options={monitorList ?? []}
                        filterSelectedOptions
                        onChange={(event, data) =>
                        {
                            onChangeMonitor(data);
                        }}
                        getOptionLabel={option => option?.name ?? ''}
                        loading={agencyMonitorStatus === RequestStatus.InProgress}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t('common.role.monitor')+ ' *'}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        color: 'white',
                                    },
                                }}
                                error={!!formErrors.monitoruser?.length}
                                helperText={getErrorMessages('monitoruser')}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                key={option?.name}
                                style={{
                                    color: 'primary.main',
                                }}
                            >
                                {option?.name}
                            </li>
                        )}
                        ChipProps={{
                            sx: {
                                color:
                                    'white',
                                '.MuiSvgIcon-root': {
                                    color: '#FFFFFF73',
                                },
                                '.MuiSvgIcon-root:hover': {
                                    color: '#FFFFFFAB',
                                },
                                backgroundColor:
                                    'primary.main',
                            },
                        }}
                    />
                </Grid>

                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    {selectedImage && (
                        <LogoPreview
                            url={URL.createObjectURL(selectedImage as Blob)}
                        />
                    )}
                    {(!selectedImage && values.logoUrl) && (
                        <LogoPreview
                            url={values.logoUrl}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    {values.id && (
                        <Autocomplete
                            multiple
                            id="suggestedAlertWords"
                            value={suggestedAWL?.filter(
                                awl => values.suggestedAlertWords?.includes(awl.id)
                            ) ?? []}
                            size="small"
                            options={suggestedAWL ?? []}
                            filterSelectedOptions
                            onChange={(event, data) =>
                            {
                                if (values.activated)
                                {
                                    onChangeAwl(data);
                                }
                            }}
                            getOptionLabel={option => option?.name ?? ''}
                            loading={suggestedAWLStatus === RequestStatus.InProgress}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t('form.suggested-alert-words')}
                                    disabled={!values.id || !values.activated}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            color: values.activated ? 'black' : 'lightgray',
                                        },
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    key={option?.name}
                                    style={{
                                        color: values.activated ? 'primary.main' : 'lightgray',
                                    }}
                                >
                                    {option?.name}
                                </li>
                            )}
                            ChipProps={{
                                sx: {
                                    color: 'white',
                                    '.MuiSvgIcon-root':
                                    {
                                        color: '#FFFFFF73',
                                    },
                                    '.MuiSvgIcon-root:hover':
                                    {
                                        color: '#FFFFFFAB',
                                    },
                                    backgroundColor: values.activated ?
                                        'primary.main' : 'lightgray',
                                },
                            }}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <Grid item>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={[]}
                            filterSelectedOptions
                            freeSolo
                            onChange={onChangeOfficerMail}
                            value={values.ccContacts}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('form.cc-mail')}
                                    placeholder={t('form.cc-mail')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={6}
                />
                <Grid
                    item
                    sm={12}
                    md={6}
                >
                    <FormControlLabel
                        control={(
                            <ToggleSwitch
                                sx={{ m: 2 }}
                                checked={values.enabled}
                                onChange={(_, value) => onToggleChange(value)}
                            />
                        )}
                        name="enabled"
                        label={t('form.agency-status')}
                    />
                </Grid>
                {buttonLabel === 'create' ? ('') : (
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <FormControlLabel
                            control={(
                                <ToggleSwitch
                                    sx={{ m: 2 }}
                                    checked={values.activated}
                                    onChange={(_, value) => onToggleChangeSuggested(value)}
                                />
                            )}
                            name="activated"
                            label={t('form.agency-global-alertwords-status')}
                        />
                    </Grid>
                )}
            </Grid>

            <Modal
                title="Delete Agency"
                open={deleteOpen}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <AgencyDetailsModel
                        data={values}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Agency'}
                    />
                </Box>
            </Modal>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={1}
                padding="1rem 0"
            >
                <>
                    {values.id && userRole === 'Administrator' && values.deleted === true && (
                        <>
                            <Button
                                type="button"
                                style={{ marginRight: 'auto' }}
                                variant="outlined"
                                disabled={loading}
                                color="info"
                                onClick={() => undoFun()}
                            >
                                {t('common.actions.undo')}
                            </Button>
                            {countdown === 0 ? (
                                <span
                                    style={{
                                        position: 'absolute',
                                        right: isSmallScreen ? '78%' : '83%',
                                        bottom: '2%',
                                    }}
                                >
                                    {t('data-type.details.record-deleted')}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        position: 'absolute',
                                        right: isSmallScreen ? '61%' : '70%',
                                        bottom: '2%',
                                    }}
                                >
                                    {t('data-type.details.record-delete-within')}
                                    :
                                    {formatCountdown(countdown)}
                                </span>
                            )}
                        </>
                    )}
                    {values.id && userRole === 'Administrator' && !values.deleted && (
                        <Button
                            type="button"
                            style={{ marginRight: 'auto' }}
                            variant="outlined"
                            disabled={loading}
                            color="error"
                            onClick={() => DeleteModal()}
                        >
                            {t('common.actions.delete')}
                        </Button>
                    )}
                </>

                {
                    loading
                        ? <CircularProgress />
                        : (
                            <>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    disabled={loading}
                                    onClick={onCancel}
                                >
                                    {t('common.actions.cancel')}
                                </Button>
                                {
                                    buttonLabel === 'create' ? (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={!canSubmit}
                                        >
                                            {t('common.actions.create')}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={!canSubmit}
                                        >
                                            {t('common.actions.save')}
                                        </Button>
                                    )}
                            </>
                        )
                }
            </Stack>
        </form>
    );
}
