export enum Frequency {
    ONCE = 'Once',
    EVERY_DAY = 'Every day',
    EVERY_WEEK = 'Every week',
    EVERY_2_WEEKS = 'Every 2 weeks',
    EVERY_MONTH = 'Every month',
}

export function frequencyTranslationKey(frequency: Frequency): string
{
    switch (frequency)
    {
    case Frequency.ONCE:
        return 'common.frequency.once';
    case Frequency.EVERY_DAY:
        return 'common.frequency.every-day';
    case Frequency.EVERY_WEEK:
        return 'common.frequency.every-week';
    case Frequency.EVERY_2_WEEKS:
        return 'common.frequency.every-2-weeks';
    case Frequency.EVERY_MONTH:
        return 'common.frequency.every-month';
    default:
        return '';
    }
}

