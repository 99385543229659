
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const API_URL = process.env?.REACT_APP_API_URL ?? 'http://localhost:8083';
export const API_CONFIG_URL = process.env?.REACT_APP_CONFIG_URL ?? 'http://localhost:8082';
export const FILES_BASE_URL = process.env?.REACT_APP_FILES_URL ?? 'http://localhost:8083/attachment/files';
export const MilliSECONDS = 1 * 60 * 1000;
export const INITIALCONFIGURATION_ID='62ba59ca3b52833cc0cad2dc';
export const VAPID_PUBLIC_KEY =
    'BOWw24PZ0i01_gjjsB4wPPARdILeIYG9VanMSwpHFEjk-9TK78Vge8HXQAd2fAZ3JilI8HtKOFD3iqby74urYAw';
export const MINUTES_FOR_MONTH = 43200;
export const BATCH_LIMIT = 100;
export const SMS_BATCH_LIMIT = 500;
export const REPORT_BATCH_LIMIT = 500;
export const ALL_TO_EXPORT_BATCH_LIMIT = 500;
export const USER_TIME_ZONE= Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DELETE_AGENCY_DURATION = 24 * 60 * 60 * 1000;
export const PDF='pdf';
export const XLSX = 'xlsx';
