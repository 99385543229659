import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { ConfigurationBot } from 'src/models/administration/profileData.model';
import { useSelector } from 'react-redux';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { selectProfileError } from 'src/state/administration/administrationSlice';
import { useTranslation } from 'react-i18next';
const containerStyle = {
    textAlign: 'left',
};

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export interface BotSelectorProps {
    onInputChange: (newbot: ConfigurationBot[]) => void;
    bots?: ConfigurationBot[];
}

export function BotSelector({
    onInputChange,
    bots,
}: Readonly<BotSelectorProps>): React.ReactElement
{
    const { t } = useTranslation();
    const profileErrorValue = useSelector(selectProfileError);

    const botList = [
        { id: 1, name: 'Nudity' },
        { id: 2, name: 'Drug' },
        { id: 3, name: 'Terrorism' },
        { id: 4, name: 'Weapon' },
        { id: 5, name: 'Identity Theft' },
        { id: 6, name: 'Roses' },
        { id: 7, name: 'Children' },
    ];

    return (
        <Stack direction="column" sx={containerStyle}>
            <Typography
                variant="h3"
                sx={{
                    color: theme => theme.palette.common.lightGrey,
                    fontSize: '1.25rem',
                }}
            />
            <Autocomplete
                multiple
                disablePortal
                id="bots"
                value={bots ?? []}
                options={botList}
                onChange={(_, data) => onInputChange(data)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={({ name }) => name}
                fullWidth
                renderInput={(params) => (
                    <TextField
                        label={t('form.artificialIntelligenceBots')}
                        {...params}
                        error={handleError(profileErrorValue, 'bots')}
                        helperText={handleErrorText(profileErrorValue, 'bots')}
                    />
                )}
                renderOption={(props, option) =>
                {
                    return (
                        <li {...props}>
                            {option?.name}
                        </li>
                    );
                }}
                ChipProps={{ sx: chipStyle }}
            />
        </Stack>
    );
}
