import { SearchData } from 'src/models/captures/searchData.model';
import { readAPICaptureData, APICaptureData } from './captures';

export interface APISearchesResponse extends APICaptureData {
    createdAt: string,
    clientCreatedAt: string,
    searchEngine: string;
    urlEngine: string;
    userAgent: string;
    query: string;
    alertWords: string[];
}

export function readAPISearchesResponse(
    data: APISearchesResponse
): SearchData
{
    const {
        searchEngine,
        urlEngine,
        userAgent,
        query,
        alertWords,
        ...captureData
    } = data;

    return {
        searchEngine,
        urlEngine,
        userAgent,
        query,
        alertWords,
        ...readAPICaptureData(captureData),
    };
}
