import { Button, CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { downloadImages } from 'src/utils/common';
import { useTranslation } from 'react-i18next';

interface JPEGExportProps {
    selectedValue: () => Promise<void> | void;
    // eslint-disable-next-line
    ids: any;
    idStatus: RequestStatus | undefined;
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
}

export function JPEGExport({
    selectedValue,
    ids,
    idStatus,
    isLoading,
    setIsLoading,
}: Readonly<JPEGExportProps>): React.ReactElement
{

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() =>
    {
        if (idStatus === RequestStatus.Success
            && ids && ids?.length > 0)
        {
            downloadImages(
                ids,
                setIsLoading,
                null,
                undefined,
                dispatch
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids, idStatus]);

    const handleALLJPEGExport = async (): Promise<void> =>
    {
        await selectedValue();
    };

    return (
        <Button
            variant="text"
            startIcon={
                isLoading ?
                    <CircularProgress size="1rem" /> :
                    <DownloadIcon />
            }
            onClick={() =>
            {
                handleALLJPEGExport();
            }}
        >
            {t('common.button.all-to-jpeg')}
        </Button>
    );
}
