import {
    UserLogResponse,
    UserSessionResponse,
} from 'src/models/administration/userSessionData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIUserSessionLogsResponse,
    readAPIUserSessionResponse,
    transformUserlogsParams,
} from '../data-transfer-objects/administration/session';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import {
    SessionParams,
    SessionlogParams,
} from 'src/models/administration/assignedOfficerData.model';
import instance from '../instance';

import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

export async function getUserSession(params?: SessionParams):
    Promise<PaginatedContent<UserSessionResponse>>
{
    const response = await instance.get(
        'usersession/logs',
        {
            params: params ? transformCaptureParams(params) : {},
        }
    );
    return readAPIPaginatedResponse(response.data, readAPIUserSessionResponse);
}

export async function getAssignedOfficerSession(params?: SessionParams):
    Promise<PaginatedContent<UserSessionResponse>>
{
    const response = await instance.get(
        'usersession/assignedOfficerLogs',
        { params: params ? transformCaptureParams(params) : {} }
    );
    return readAPIPaginatedResponse(response.data, readAPIUserSessionResponse);
}

export async function getUserlogDetails(params: SessionlogParams):
    Promise<PaginatedContent<UserLogResponse>>
{

    const response = await instance.get(
        'usersession/userlogdetails',
        { params: transformUserlogsParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIUserSessionLogsResponse);
}
