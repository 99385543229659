import React from 'react';
import Box from '@mui/material/Box';
import { keyframes } from '@emotion/react';

export function AnimatedEllipsis(): React.ReactElement
{
    const dotAnimation = keyframes`
        0% { opacity: 0.3; }
        50% { opacity: 1; }
        100% { opacity: 0.3; }
    `;

    const animatedEllipsisStyles = {
        '& .animated-ellipsis-first-dot': {
            opacity: 0,
            animation: `${dotAnimation} 1.3s ease infinite`,
            animationDelay: '0.0s',
        },
        '& .animated-ellipsis-second-dot': {
            opacity: 0,
            animation: `${dotAnimation} 1.3s ease infinite`,
            animationDelay: '0.2s',
        },
        '& .animated-ellipsis-third-dot': {
            opacity: 0,
            animation: `${dotAnimation} 1.3s ease infinite`,
            animationDelay: '0.3s',
        },

    };

    return (
        <Box sx={animatedEllipsisStyles}>
            <span className="animated-ellipsis-first-dot">
                .
            </span>
            <span className="animated-ellipsis-second-dot">
                .
            </span>
            <span className="animated-ellipsis-third-dot">
                .
            </span>
        </Box>
    );
}
