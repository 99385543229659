import { Flag } from 'src/models/flag.enum';
import { FlagParams } from 'src/models/captures/flagData.model';
import { DataType } from 'src/models/dataType.enum';

export interface APIFlagParams {
    flag?: Flag;
    ids?: string[];
    dataType?: DataType;
    filters?: object;
    alertsDataType?: DataType;
}

export function transformFlagParams(
    flagParams: FlagParams
): APIFlagParams
{
    const { flag, ids, dataType, alertsDataType, filters } = flagParams;
    return {
        flag,
        ids,
        dataType,
        filters,
        alertsDataType,
    };
}
