import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { downloadFile as downloadFileRequest } from 'src/services/files/files';
import { RootState } from '../store';

interface FileState {
    file?: Blob,
    fileStatus: RequestStatus,
}

const initialState: FileState = {
    fileStatus: RequestStatus.NotStarted,
};

export const getFile = createAsyncThunk(
    'files/download',
    async (fileUrl: string) => downloadFileRequest(fileUrl)
);

export const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        clearFile: (state) =>
        {
            state.file = undefined;
            state.fileStatus = RequestStatus.NotStarted;
        },
    },
    extraReducers: (builder) =>
    {
        builder
            .addCase(getFile.pending, (state) =>
            {
                state.fileStatus = RequestStatus.InProgress;
            })
            .addCase(getFile.fulfilled, (state, action) =>
            {
                state.fileStatus = RequestStatus.Success;
                state.file = action.payload;
            })
            .addCase(getFile.rejected, (state) =>
            {
                state.fileStatus = RequestStatus.Failure;
                state.file = undefined;
            });
    },
});

function selectState(state: RootState): FileState
{
    return state.files;
}

export const selectFile = createSelector(selectState, state => state.file);
export const selectFileStatus = createSelector(selectState, state => state.fileStatus);

export const {
    clearFile,
} = fileSlice.actions;

export default fileSlice.reducer;
