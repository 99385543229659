import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { PhotoAlertsTable } from 'src/templates/photo-alerts-table/PhotoAlertsTable';

export function DataTypePhotos(): React.ReactElement
{
    return (
        <DataTypePage>
            <PhotoAlertsTable />
        </DataTypePage>
    );
}
