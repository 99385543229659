import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConfigurationBlockedApp, ConfigurationOwner,
} from 'src/models/administration/configurationData.model';
import { InstalledProgramsData } from 'src/models/captures/installedProgramsData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getInstalledProgramsByDevice,
    selectInstalledProgramsByDevice,
    selectInstalledProgramsByDeviceStatus,
} from 'src/state/captures/capturesSlice';
import { ADMIN_SETTINGS } from 'src/utils/routes';

const containerStyle = {
    textAlign: 'left',
};

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export interface ProgramSelectorProps {
    context?: ConfigurationOwner
    deviceId: string
    agencyId: string
    clientId: string
    officerId: string
    ownBlockedApps: ConfigurationBlockedApp[],
    fallbackBlockedApps?: ConfigurationBlockedApp[],
    isConfigurationExists: boolean,
    onChange: (programs: ConfigurationBlockedApp[]) => void
}

export function ProgramSelector({
    context,
    deviceId,
    agencyId,
    clientId,
    officerId,
    ownBlockedApps=[],
    fallbackBlockedApps=[],
    isConfigurationExists,
    onChange,
}: Readonly<ProgramSelectorProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const programs = useSelector(selectInstalledProgramsByDevice);
    const status = useSelector(selectInstalledProgramsByDeviceStatus);
    const [programOptions, setProgramOptions] = useState<InstalledProgramsData[]>([]);

    const blockedApps = isConfigurationExists ? ownBlockedApps : fallbackBlockedApps;
    const [programApps, setProgramApps] = useState(blockedApps);

    function toConfigurationBlockedApp({
        programName,
        packageName,
        path,
        processName,
    }: InstalledProgramsData): ConfigurationBlockedApp
    {
        const name = programName || 'No program installed';

        return {
            programName,
            name,
            packageName,
            path,
            processName,
        };
    }

    function onChangeHandler(value: ConfigurationBlockedApp[]): void
    {
        setProgramApps(value);
        onChange(value);
    }

    useEffect(() =>
    {
        setProgramApps(blockedApps);
    }, [blockedApps]);

    useEffect(() =>
    {
        setProgramOptions([]);
    }, []);

    useEffect(() =>
    {
        if(Array.isArray(programs))
        {
            setProgramOptions(programs);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programs]);

    useEffect(() =>
    {
        if(window.location.pathname === ADMIN_SETTINGS)
        {
            dispatch(
                getInstalledProgramsByDevice({
                    defaultPrograms: true,
                })
            );
        }
        else
        {
            if (context === ConfigurationOwner.Device)
            {
                dispatch(
                    getInstalledProgramsByDevice({
                        deviceId: deviceId || undefined,
                    })
                );
            }
            else if (context === ConfigurationOwner.Agency)
            {
                dispatch(
                    getInstalledProgramsByDevice({
                        agencyId: agencyId || undefined,
                    })
                );
            }
            else if (context === ConfigurationOwner.Client)
            {
                dispatch(
                    getInstalledProgramsByDevice({
                        clientId: clientId || undefined,
                    })
                );
            }
            else if (context === ConfigurationOwner.Officer)
            {
                dispatch(
                    getInstalledProgramsByDevice({
                        officerId: officerId || undefined,
                    })
                );
            }
        }
    }, [dispatch, context, deviceId, agencyId, clientId, officerId]);

    return (
        <Stack direction="column" sx={containerStyle}>
            <Typography
                variant="h3"
                sx={{
                    color: theme => theme.palette.common.lightGrey,
                    fontSize: '1.25rem',
                }}
            >
                {t('settings.blocked-apps')}
            </Typography>
            <Autocomplete
                multiple
                disablePortal
                id="program"
                value={programApps}
                options={programOptions
                    ?.map(toConfigurationBlockedApp)
                    ?.filter((option) => !programApps.some((selectedOption) =>
                        selectedOption.name === option.name)) ?? []
                }
                onChange={(_, data) => onChangeHandler(data)}
                loading={status === RequestStatus.InProgress}
                getOptionLabel={({ name }: ConfigurationBlockedApp) => name}
                fullWidth
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
                renderOption={(props, option) =>
                {
                    return (
                        <li {...props}>
                            {option?.name}
                        </li>
                    );
                }}
                ChipProps={{ sx: chipStyle }}
            />
        </Stack>
    );
}
