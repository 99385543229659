import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from 'src/services/authorization';
import { MenuOptionProps } from 'src/components/menu-option/MenuOption';
import {
    CHANGE_PASSWORD,
    AGENCY_MANAGEMENT,
    CLIENT_MANAGEMENT,
    DATA_TYPE,
    REPORTS_MANAGMENT,
    OFFICER_MANAGEMENT,
    ADMIN_SETTINGS,
    SESSION_LOGS,
    ALERT_WORDS_PAGE,
    LANDING_PAGE,
} from 'src/utils/routes';
import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { hasUserAssignations } from 'src/utils/userUtils';

function HeaderMenuOptions({ options }: Readonly<MenuOptionProps>): React.ReactElement
{
    return(
        <>
            {options?.map(({ label, onClick, visible = true }) => (
                visible && (
                    <MenuItem onClick={onClick} key={label} >
                        {label}
                    </MenuItem>
                )
            ))}
            <Divider />
        </>
    );
}

export function UserMenu(): React.ReactElement
{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    const options: MenuOptionProps[] = [
        {
            label: t('menu.view-data'),
            onClick: () => navigate(DATA_TYPE),
        },
        {
            label: t('menu.alerts'),
            onClick: () => navigate(LANDING_PAGE),
        },
        {
            label: t('menu.my-reports'),
            onClick: () => navigate(REPORTS_MANAGMENT),
        },
        {
            label: `${t('menu.settings')} - ${t('menu.admin-settings')}`,
            onClick: () => navigate(ADMIN_SETTINGS),
            visible: userIsInRole([Role.Administrator]),
        },
        {
            label: `${t('menu.settings')} - ${t('menu.agency-management')}`,
            onClick: () => navigate(AGENCY_MANAGEMENT),
            visible: userIsInRole([
                Role.Employee,
                Role.Administrator,
                Role.AgencyAdministrator,
            ]),
        },
        {
            label: `${t('menu.settings')} - ${t('menu.officer-and-supervisors-management')}`,
            onClick: () => navigate(OFFICER_MANAGEMENT),
            visible: (hasUserAssignations() && userIsInRole([Role.OfficerSupervisor]))
                || userIsInRole([
                    Role.Employee,
                    Role.Monitor,
                    Role.Administrator,
                    Role.AgencyAdministrator,
                ]),
        },
        {
            label: `${t('menu.settings')} - ${t('menu.clients-management')}`,
            onClick: () => navigate(CLIENT_MANAGEMENT),
        },
    ];

    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorElement);
    function handleOpen(event: React.MouseEvent<HTMLButtonElement>): void
    {
        setAnchorElement(event.currentTarget);
    }
    function handleClose(): void
    {
        setAnchorElement(null);
    }
    function handleChangePassword(): void
    {
        navigate(CHANGE_PASSWORD);
    }
    function alertPage(): void
    // eslint-disable-next-line
    {   
        navigate(ALERT_WORDS_PAGE);
    }

    function handleSessionLog(): void
    {
        navigate(SESSION_LOGS);
    }

    function handleLogout(): void
    {
        handleClose();
        logout();
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                sx={{ color: theme => theme.palette.common.white }}
            >
                <AccountCircleIcon sx={{ fontSize: '2.5rem' /* 40px */ }} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock
            >
                {!isDesktopScreen && <HeaderMenuOptions options={options}/>}
                <MenuItem onClick={alertPage}>
                    {t('menu.account-information')}
                </MenuItem>
                <MenuItem onClick={handleChangePassword}>
                    {t('user-menu.change-password')}
                </MenuItem>
                <MenuItem onClick={handleSessionLog}>
                    {t('user-menu.session-logs')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    {t('user-menu.logout')}
                </MenuItem>
            </Menu>
        </div>
    );
}
