import axios from 'axios';

import { FileInfo } from 'src/models/captures/fileInfo.model';

export interface FileInfoResponse {
    id: string;
    file: FileInfo;
}

export async function getFilesInfo(urls: string[]): Promise<FileInfo[]>
{
    const responses = await Promise.all(
        urls.map(async (url) => axios.get<FileInfoResponse>(url + '/info'))
    );
    return responses
        .filter(response => response.status === 200)
        .map(response => ({ ...response.data.file, id: response.data.id }));
}
