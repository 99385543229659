import { Box, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';

const rootStyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
};

const headContainer = {
    borderBottom: 1,
    borderColor: 'divider',
    flex: '0 0 auto',
};

const bodyContainer = {
    flex: '1 1 auto',
};

interface TabPanelContainerProps {
    children: React.ReactNode,
    active: boolean,
}

function TabPanelContainer({
    children,
    active = false,
}: Readonly<TabPanelContainerProps>): React.ReactElement
{
    const [isFirstActivation, setIsFirstActivation] = useState(true);

    useEffect(() =>
    {
        if (active && isFirstActivation)
        {
            setIsFirstActivation(false);
        }
    }, [active, setIsFirstActivation, isFirstActivation]);

    return (
        <div
            role="tabpanel"
            hidden={!active}
        >
            {
                (active || !isFirstActivation)
                    ? children
                    : null
            }
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode[];
    tabs: { id: string, text: string }[];
    selectedTab?: string;
}

export function TabPanel({
    children,
    tabs,
    selectedTab: selected = '',
}: Readonly<TabPanelProps>): React.ReactElement
{
    const [selectedTab, setSelectedTab] = useState<number>(
        Math.max(tabs.findIndex((tab) => tab.id === selected), 0)
    );

    function onChange(event: React.SyntheticEvent<Element, Event>, value: number): void
    {
        setSelectedTab(value);
    }

    return (
        <Box sx={rootStyle}>
            <Box sx={headContainer}>
                <Tabs value={selectedTab} onChange={onChange}>
                    {
                        tabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                label={tab.text}
                            />
                        ))
                    }
                </Tabs>
            </Box>
            <Box sx={bodyContainer}>
                {
                    children?.map((child, index) => (
                        <TabPanelContainer
                            key={tabs[index].id}
                            active={index === selectedTab}
                        >
                            {child}
                        </TabPanelContainer>
                    ))
                }
            </Box>
        </Box>
    );
}
