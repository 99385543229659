import React, { useState } from 'react';
import { Modal, Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import CheckboxList, { CheckboxOption } from 'src/components/checkbox-list/CheckboxList';
import { useTranslation } from 'react-i18next';
import { TableExportFormat } from '../table-exporter/TableExporterProvider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    maxHeight: '50vh',
    width: '27vw',
    minWidth: '400px',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    justifyContent: 'space-between',
    border: '2px solid #444',
    borderRadius: '1rem',
    background: 'white',
};

const descriptionStyle = {
    padding: '1em 0',
};

export interface ModalExportProps {
    options: CheckboxOption<number>[];
    open: boolean;
    onCloseRequest: () => void;
    onExport: (selection: number[]) => void;
    format: TableExportFormat;
}

export default function ModalExport({
    options,
    open,
    onCloseRequest,
    onExport,
    format,
}: Readonly<ModalExportProps>): JSX.Element
{
    const [selection, setSelection] = useState<number[]>([]);
    const { t } = useTranslation();

    function onChangeHandler(selection: number[]): void
    {
        setSelection(selection);
    }

    function onExportHandler(): void
    {
        onExport(selection);
    }

    return (
        <Modal open={open}>
            <Box sx={style}>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="start"
                    fontSize={'1.2em'}
                    fontWeight={500}
                >
                    <FileDownloadIcon />
                    <Box>
                        {t('data-type.export.title', { format })}
                    </Box>
                </Stack>
                <Box sx={descriptionStyle}>
                    {t('data-type.export.description')}
                </Box>
                <CheckboxList<number>
                    options={options}
                    onChange={onChangeHandler}
                />
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    sx={descriptionStyle}
                >
                    <Button
                        disabled={ selection.length === 0 }
                        variant="contained"
                        onClick={onExportHandler}
                    >
                        {t('common.actions.export')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onCloseRequest}
                    >
                        {t('common.actions.cancel')}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
}
