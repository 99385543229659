import {
    Client,
    ClientBasic,
    ClientParams,
    DeleteClientParams,
} from 'src/models/administration/clientData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { ClientFormValues } from 'src/templates/client-form/ClientForm';
import {
    APIClientBasic,
    readAPIClientBasicResponse,
    readAPIClientResponse,
    transformClientCreationParams,
    transformClientParams,
    transformDeleteClientParams,
} from '../data-transfer-objects/administration/clients';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import { readAPIDeviceResponse } from '../data-transfer-objects/administration/devices';
import { Device } from 'src/models/administration/deviceData.model';

export async function getAllClients(): Promise<ClientBasic[]>
{
    const response = await instance.get('clients/no-pageable');

    return response.data.map((item: APIClientBasic) => readAPIClientBasicResponse(item));
}

export async function getClients(params: ClientParams): Promise<PaginatedContent<Client>>
{
    const response = await instance.get(
        'clients',
        { params: transformClientParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIClientResponse);
}

export async function getClient(clientId: string): Promise<Client>
{
    const response = await instance.get(`clients/${clientId}`);

    return readAPIClientResponse(response.data);
}

export async function createClient(values: ClientFormValues): Promise<Client>
{
    const response = await instance.post(
        'clients',
        transformClientCreationParams(values)
    );

    return readAPIClientResponse(response.data);
}

export async function updateClient(values: Client): Promise<Client>
{
    const response = await instance.put(
        `clients/${values.id}`,
        transformClientCreationParams(values)
    );

    return readAPIClientResponse(response.data);
}

export async function deleteClient(values: Client): Promise<Client>
{
    const response = await instance.delete(`clients/${values.id}`);
    return readAPIClientResponse(response.data);
}

export async function getAssignedClientActiveDevice(
    params: DeleteClientParams
): Promise<PaginatedContent<Device>>
{
    const response = await instance.get(
        'clients/active-device',
        { params: transformDeleteClientParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIDeviceResponse);
}
