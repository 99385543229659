import {
    InstalledProgramsData,
    ProgramData, StatusType,
} from 'src/models/captures/installedProgramsData.model';
import { readAPICaptureData, APICaptureData } from './captures';

export interface APIInstalledProgramsResponse extends APICaptureData {
    programs: Array<{
        version: string;
        installedAt: Date;
        programName: string;
        status:StatusType
    }>;
    createdAt: string,
    clientCreatedAt: string,
    updatedAt: Date;
    programName: string;
    packageName?: string;
    path?: string;
    processName?: string;
    version: string;
    installedAt: Date;
    status:StatusType
}

export function readAPIInstalledProgramsResponse(
    data: APIInstalledProgramsResponse
): InstalledProgramsData
{
    const {
        updatedAt,
        programName,
        packageName,
        path,
        processName,
        ...captureData
    } = data;
    const programs = data.programs || [];
    return {
        ...readAPICaptureData(captureData),
        programName,
        updatedAt,
        programs,
        packageName,
        path,
        processName,
    };
}

export interface APIProgramDataResponse {
    currentProgram: {
        programName: string;
        status: StatusType;
        version: string;
        installedAt:Date;
        isBlocked: boolean;
    };
    previousProgram: {
        programName: string;
        status: StatusType;
        version: string;
    };
}

export function readAPIProgramDataResponse(
    data: APIProgramDataResponse
): ProgramData
{
    const {
        currentProgram: {
            programName: currentProgramName,
            status: currentStatus,
            version: currentVersion,
            installedAt: currentDate,
            isBlocked,
        },
        previousProgram: {
            programName: previousProgramName,
            status: previousStatus,
            version: previousVersion,
        },
    } = data;

    return {
        currentProgram: {
            programName: currentProgramName,
            status: currentStatus,
            version: currentVersion,
            installedAt: currentDate,
            isBlocked,
        },
        previousProgram: {
            programName: previousProgramName,
            status: previousStatus,
            version: previousVersion,
        },
    };
}

