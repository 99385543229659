import React, { useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    SxProps,
    Theme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { noop } from 'src/utils/common';
import { ServiceConfiguration } from 'src/models/administration/configurationData.model';

const intervalContainerStyle = {
    width: '17%',
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
};

const labelStyle = (override: boolean):SxProps => ({
    textAlign: 'start',
    fontSize: '0.625em',
    flex: '1 0',
    textTransform: 'uppercase',
    fontWeight: 700,
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    opacity: override ? 1 : 0.5,
});

const rootStyle = {
    width: '100%',
    height: '1.75em',
    fontSize: '2rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '30px',
    color: (theme: Theme) => theme.palette.common.lightGrey,
};

const toggleContainer = {
    display: 'flex',
    alignItems: 'center',
};

export interface ServiceConfigurationItem extends ServiceConfiguration {
    label: string;
    override: boolean;
}

export interface ServiceConfiguratorProps {
    configuration: ServiceConfigurationItem;
    onChange?: (item: ServiceConfigurationItem) => void;
    disableConfig: boolean;
}

export function ServiceConfigurator({
    configuration: initialConfiguration,
    onChange = noop,
    disableConfig,
}: Readonly<ServiceConfiguratorProps>): JSX.Element
{
    const { t } = useTranslation();
    const [
        configuration,
        setConfiguration,
    ] = useState<ServiceConfigurationItem>(initialConfiguration);

    const intervalOptions: { label: string, seconds: number }[] = [
        { label: '', seconds: 0 },
        { label: t('common.time.seconds', { seconds: 10 }), seconds: 10 },
        { label: t('common.time.seconds', { seconds: 20 }), seconds: 20 },
        { label: t('common.time.seconds', { seconds: 30 }), seconds: 30 },
        { label: t('common.time.seconds', { seconds: 120 }), seconds: 120 },
        { label: t('common.time.minutes', { minutes: 1 }), seconds: 60 },
        { label: t('common.time.minutes', { minutes: 5 }), seconds: 60 * 5 },
        { label: t('common.time.minutes', { minutes: 10 }), seconds: 60 * 10 },
        { label: t('common.time.minutes', { minutes: 15 }), seconds: 60 * 15 },
        { label: t('common.time.minutes', { minutes: 30 }), seconds: 60 * 30 },
        { label: t('common.time.hours', { hours: 1 }), seconds: 60 * 60 },
        { label: t('common.time.hours', { hours: 6 }), seconds: 60 * 60 * 6 },
        { label: t('common.time.hours', { hours: 12 }), seconds: 60 * 60 * 12 },
        { label: t('common.time.hours', { hours: 24 }), seconds: 60 * 60 * 24 },
    ];

    function onToggleChange(fieldname: keyof ServiceConfigurationItem):
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    {
        return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
        {
            const newConf = { ...configuration, [fieldname]: checked };

            setConfiguration(newConf);
            onChange(newConf);
        };
    }
    function onSelectionChange(fieldname: keyof ServiceConfigurationItem):
        (event: SelectChangeEvent<number>, child: React.ReactNode) => void
    {
        return (event: SelectChangeEvent<number>, child: React.ReactNode): void =>
        {
            const selectedValue = Number(event.target.value);
            const newConf = { ...configuration, [fieldname]: selectedValue };

            setConfiguration(newConf);
            onChange(newConf);
        };
    }

    useEffect(() =>
    {
        setConfiguration(initialConfiguration);
    }, [initialConfiguration]);

    return (
        <Stack
            direction="row"
            sx={rootStyle}
        >
            <SettingsIcon fontSize="inherit" sx={{ opacity: configuration.override ? 1 : 0.5 }} />
            <Box sx={labelStyle(configuration.override)}>
                {configuration.label}
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.capture-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.capture-interval')}
                        value={configuration.captureInterval}
                        onChange={onSelectionChange('captureInterval')}
                        disabled={!configuration.override}
                    >
                        {
                            intervalOptions.map(({ label, seconds }) => (
                                <MenuItem
                                    key={seconds}
                                    value={seconds}
                                >
                                    {label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={intervalContainerStyle}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {t('settings.sync-interval')}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        label={t('settings.sync-interval')}
                        value={configuration.syncInterval}
                        onChange={onSelectionChange('syncInterval')}
                        disabled={!configuration.override}
                    >
                        {
                            intervalOptions.map(({ label, seconds }) => (
                                <MenuItem
                                    key={seconds}
                                    value={seconds}
                                >
                                    {label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={toggleContainer}>
                <ToggleSwitch
                    sx={{ m: 1 }}
                    checked={configuration.enabled}
                    onChange={onToggleChange('enabled')}
                    disabled={!configuration.override}
                />
                <Checkbox
                    checked={configuration.override}
                    onChange={onToggleChange('override')}
                    title={t('settings.override-configuration')}
                />
            </Box>
        </Stack>
    );
}
