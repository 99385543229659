import {
    UserParams,
    UserFilters,
} from 'src/models/administration/usersData.model';
import { Role } from 'src/models/roles.enum';
import { User } from 'src/models/userModels';
import { APIPaginationParams, transformPaginationParams } from '../pagination';

export interface APIUserFilters {
    startDate?: string
    endDate?: string
    role?: Role[]
    searchValue?: string
}

export interface APIUserParams extends APIPaginationParams, APIUserFilters {}

export interface APIUserRequest {
    id?: string
    userName?: string
    email?: string
    role?: Role
    agency?: string | null
    firstName?: string
    lastName?: string
    phoneNumber?: string
    assignations?: {
    agencies?: string[]
    clients?: string[]
    devices?: string[]
    }
    enabled?: boolean
    activated?: boolean
}

export interface APIUserResponse extends APIUserRequest {
    id: string
}

export function transformUserFilters(params: UserFilters): APIUserFilters
{
    const { searchValue, startDate, endDate, role: roles } = params;

    return {
        searchValue,
        startDate,
        endDate,
        role: roles,
    };
}

export function transformUserParams(params: UserParams): APIUserParams
{
    return {
        ...transformUserFilters(params),
        ...transformPaginationParams(params),
    };
}

export function readAPIUserResponse(userResponse: APIUserResponse): User
{
    return {
        id: userResponse.id,
        firstName: userResponse.firstName ?? '',
        lastName: userResponse.lastName ?? '',
        email: userResponse.email ?? '',
        role: userResponse.role as Role,
        agency: userResponse.agency,
        assignations: userResponse.assignations,
        phoneNumber: userResponse.phoneNumber,
        enabled: userResponse.enabled,
        activated: userResponse.activated,
    };
}

export function transformAPIRequest(data: User): APIUserRequest
{
    return {
        id: data.id,
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        email: data.email ?? '',
        role: data.role as Role,
        agency: data.agency,
        assignations: data.assignations,
        phoneNumber: data.phoneNumber,
        enabled: data.enabled,
        activated: data.activated,
    };
}

