import { CaptureParams } from 'src/models/captures/capturedData.model';
import { ProcessData } from 'src/models/captures/processData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPIProcessesResponse } from '../data-transfer-objects/capture/process';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import instance from '../instance';

export async function getProcesses(
    captureParams: CaptureParams
): Promise<PaginatedContent<ProcessData>>
{
    const response = await instance.get(
        'process/grouped',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIProcessesResponse);
}

export async function getProcess(id: string): Promise<ProcessData>
{
    const response = await instance.get(`process/${id}`).then(x => x.data);
    return readAPIProcessesResponse(response);
}
