import { CaptureParams } from 'src/models/captures/capturedData.model';
import { EmailData } from 'src/models/captures/emailData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPIEmailResponse } from '../data-transfer-objects/capture/emails';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getEmails(
    captureParams: CaptureParams
): Promise<PaginatedContent<EmailData>>
{
    const response = await instance.get(
        'emails',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIEmailResponse);
}
