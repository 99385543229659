export enum NotificationType {
    ALERT_WORD = 'Alert word',

    /* The types below aren't functional yet */

    // CUSTOM_WORD = 'Custom word',
    // WEBSITE = 'Website',
    // WEBSITE_CATEGORY = 'Website category',
    // PHONE_NUMBER = 'Phone number',
    // NON_REPORTING = 'Non reporting',
}

export function notificationTypeTranslationKey(type: NotificationType): string
{
    if (type === NotificationType.ALERT_WORD)
    {
        return 'notifications.notification-type.alert-word';
    }
    return '';
}
