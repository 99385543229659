import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Snackbar,
    SnackbarProps,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    getComments,
    selectComment,
    selectComments,
    selectCommentsStatus,
    createComment,
    selectCommentError,
    clearComment,
    selectSaveCommentStatus,
    deleteComments,
    selectDeleteCommentStatus,
    editComments,
    selectEditCommentStatus,
    resetEditCommentStatus,
    resetDeleteCommentStatus,
} from 'src/state/comments/commentsSlice';
import { CommentType } from 'src/models/commentType.enum';
import { CommentData, CommentParams } from 'src/models/comments/commentData.model';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { RequestStatus } from 'src/models/requestStatus.enum';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'src/components/modal/Modal';
import { ConfirmationSelection } from '../confirmation-selection/ConfirmationSelection';

type CommentItemProps = {
    userName?: string,
    agency?: string,
    message?: string,
    date?: Date,
    id?: string,
    createdId?: string
}

export type DataDetailCommentProps = {
    contentId?: string,
    type: CommentType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?:any
}

export type CommentMessage = {
    id?: string,
    text?: string,
    userName?: string,
    agency?: string,
    date: Date,
}

const CommentItem = (props: CommentItemProps): React.ReactElement =>
{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [hide, setHide] = useState(false);
    const [newComment, setNewComment] = useState<string>();
    const [commentId, setCommentId] = useState<string>();
    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const Token = JSON.parse(localStorage.getItem('Token') as string);
    const userId = Token.userId;

    const handleDeleteConfirmation = (value: boolean): void =>
    {
        if (value === false)
        {
            setOpenAlertModal(false);
        }
        else if (value === true && commentId)
        {
            dispatch(deleteComments(commentId));
            setOpenAlertModal(false);
        }
        setCommentId(undefined);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {hide ? (
                <>
                    <TextField
                        defaultValue={props.message}
                        sx={{
                            mb: 1,
                        }}
                        onChange={(event) =>
                            setNewComment( event.target.value )}
                        fullWidth
                        multiline
                    />
                    <Button
                        variant="contained"
                        sx={{ mb: 1, float: 'right' }}
                        size="small"
                        onClick={() =>
                        {
                            setHide(false);
                            const formData = {
                                id: props.id,
                                message: newComment,
                            };
                            if (newComment && newComment.length > 0)
                            {
                                dispatch(editComments(formData));
                            }
                        }
                        }
                    >
                        {'Save'}
                    </Button>
                </>
            )
                : (
                    <Box
                        sx={{
                            padding: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#E8E8E8',
                            marginBottom: '5px',
                            wordWrap: 'break-word',
                            alignItems: 'center',
                        }}
                    >
                        {props.message}
                        {props.createdId === userId ? (
                            <>
                                <IconButton
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        marginTop: '-6px',
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                    onClick={() => setHide(true) }
                                >
                                    <EditIcon color="disabled" fontSize="small"/>
                                </IconButton>
                                <IconButton
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        mx: 3,
                                        marginTop: '-6px',
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                    onClick={() =>
                                    {
                                        setCommentId(props.id);
                                        setOpenAlertModal(true);
                                    }}
                                >
                                    <DeleteIcon color="disabled" fontSize="small"/>
                                </IconButton>

                                <Modal
                                    title={t('data-type.table.delete-comment')}
                                    open={openAlertModal}
                                    maxWidth="md"
                                    fullWidth
                                    showCloseButton={false}
                                >
                                    <Box>
                                        <ConfirmationSelection
                                            text={t('data-type.filters.confirmation-comment-statement')}
                                            onSubmit={handleDeleteConfirmation}
                                        />
                                    </Box>
                                </Modal>
                            </>
                        ) : null }
                    </Box>
                )}

            <Grid container>
                <Grid item sm={6}>
                    <Box display="flex" justifyContent="flex-start" sx={{ color: '#BDBDBD' }}>
                        <small>
                            {props.userName}
                            {' '}
                            |
                            {' '}
                            {props.agency}
                        </small>
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    {props.date && (
                        <Box display="flex" justifyContent="flex-end" sx={{ color: '#BDBDBD' }}>
                            <small>
                                {new Date(props.date)?.toLocaleString()}
                            </small>
                        </Box>
                    )}
                </Grid>

            </Grid>
        </Box>
    );
};

const CommmentsBlock = ({ data }:{data: CommentData[]} ): React.ReactElement =>
{
    const { t } = useTranslation();
    if(data.length)
    {
        return (
            <>
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    COMMENTS
                </Typography>
                <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                    <Stack spacing={2}>
                        {data.map((message) => (
                            <CommentItem
                                key={message.id}
                                id={message.id}
                                message={message.message}
                                userName={message.createdBy}
                                agency={message.createdByAgency}
                                date={message.createdAt}
                                createdId={message.createdId}
                            />
                        )) }

                    </Stack>
                </div>
            </>
        );
    }
    else
    {
        return (
            <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                {t('common.nouns.no-comment')}
            </Typography>
        );
    }
};

export function DataDetailComment({ contentId, type }: Readonly<DataDetailCommentProps>)
    : React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const comment = useSelector(selectComment);
    const comments = useSelector(selectComments);
    const commentsStatus = useSelector(selectCommentsStatus);
    const deleteStatus = useSelector(selectDeleteCommentStatus);
    const editStatus = useSelector(selectEditCommentStatus);
    const saveCommentStatus = useSelector(selectSaveCommentStatus);
    const commentError = useSelector(selectCommentError);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();

    const [allComments, setAllComments] = useState<CommentData[]>([]);
    const [newComment, setNewComment] = useState<CommentParams>({ contentId, commentType: type });

    useEffect(() =>
    {
        dispatch(getComments({ contentId, commentType: type }));
        // eslint-disable-next-line
    }, [contentId,
        dispatch,
        type,
        // eslint-disable-next-line
        deleteStatus === RequestStatus.Success, editStatus === RequestStatus.Success]);

    useEffect(() =>
    {
        setAllComments(comments ?? []);
    }, [comments]);

    useEffect(() =>
    {
        if(!allComments.some(data => data.id === comment?.id)
            && comment
            && saveCommentStatus === RequestStatus.Success)
        {
            setNewComment({ ...newComment, message: '' });
            dispatch(clearComment());
            dispatch(getComments({ contentId, commentType: type }));
        }
    }, [allComments, comment, contentId, dispatch, newComment, saveCommentStatus, type]);

    function handleSave(): void
    {
        dispatch(createComment({ ...newComment, contentId }));
        setNewComment({ contentId, commentType: type, message: '' });
    }

    function onClose(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    function handleClear (): void
    {
        setNewComment({ contentId, commentType: type, message: '' });
    }

    useEffect(() =>
    {
        let message = '';
        dispatch(resetEditCommentStatus());
        dispatch(resetDeleteCommentStatus());

        if (editStatus === RequestStatus.Success)
        {
            const profileString = t('comments.comment');
            message = t('common.messages.Edit-successfully', { subject: profileString });
        }

        if(deleteStatus === RequestStatus.Success)
        {
            const profileString = t('comments.comment');
            message = t('common.messages.Delete-successfully', { subject: profileString });
        }

        if (message)
        {
            setNotificationState({ open: true, message });
        }
    }, [editStatus, deleteStatus, setNotificationState, t, dispatch]);

    return(
        <>
            <Box sx={{ pb: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    {t('common.nouns.add-comment')}
                </Typography>
                <TextField
                    value={newComment.message}
                    onChange={(event) =>
                        setNewComment({
                            ...newComment,
                            message: event.target.value,
                        })}
                    sx={{
                        mt: 2,
                        mb: 3,
                        '& input, & label': {
                            color: theme =>
                                theme.palette.common.lightGrey,
                        },
                        '& svg': {
                            fill: theme =>
                                theme.palette.common.lightGrey,
                        },
                    }}
                    fullWidth
                    id="comment-message-field"
                    label={t('form.message-input-label')}
                    name="comment-message"
                    multiline
                    rows={4}
                    error={handleError(commentError, 'message')}
                    helperText={handleErrorText(commentError, 'message')}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="reset"
                        onClick={()=> handleClear()}
                        sx={{
                            mr: 1, // margin-right: 8px
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.common.goldYellow,
                        }}
                    >
                        {t('login-form.clear-button')}
                    </Button>

                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={!(newComment.message && newComment.message.length > 0)}
                        onClick={() => handleSave()}
                        sx={{
                            minWidth: '7.5rem', // 120px
                        }}
                    >
                        {t('login-form.save-button')}
                    </Button>
                </Box>
                {commentsStatus !== RequestStatus.Success ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )

                    : <CommmentsBlock data={allComments} />
                }
            </Box>
            <Snackbar
                open={notificationState?.open}
                autoHideDuration={6000}
                onClose={onClose}
            >
                <Alert
                    severity={editStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
