import { ColumnData } from '../data-table/DataTable';
import { WorkSheet, utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';
import { generateReport } from 'src/services/administration/reports';
import { GenerateReportParams } from 'src/models/captures/report.model';

type ReactNodeWithChildren = React.ReactElement<{
    children?: React.ReactNode, title?: React.ReactNode
}>;

function isReactElementWithChildren(node: React.ReactNode): node is ReactNodeWithChildren
{
    return (
        typeof node === 'object' &&
        node !== null &&
        'props' in node &&
        typeof (node as ReactNodeWithChildren).props.children !== 'undefined'
    );
}

function jsxToString(jsxElement: React.ReactNode): string
{
    if (typeof jsxElement === 'string')
    {
        return jsxElement;
    }

    if (Array.isArray(jsxElement))
    {
        return jsxElement.map((child) => jsxToString(child)).join('');
    }

    if (isReactElementWithChildren(jsxElement))
    {
        if (jsxElement.props?.title)
        {
            return jsxToString(jsxElement.props.title);
        }
        if (jsxElement.props?.children)
        {
            return jsxToString(jsxElement.props.children);
        }
    }

    return '';
}

function getSheet<AlertDataType>(
    data: AlertDataType[],
    columns: ColumnData<AlertDataType>[]
): WorkSheet
{
    const exportData: object[] = [];
    data.forEach((dataItem): void =>
    {
        exportData.push(columns.reduce((acc, column) =>
        {
            const columnValue = column.value?.(dataItem);

            const formattedValue = typeof columnValue === 'number' ?
                columnValue : jsxToString(columnValue);

            return {
                ...acc,
                [column.label]: formattedValue ?? '',
            };
        }, {}));

    });
    return utils.json_to_sheet(exportData);
}

class TableExporter
{
    static loading = false;

    static asCSV<AlertDataType>(
        data: AlertDataType[],
        columns: ColumnData<AlertDataType>[],
        filename: string
    ): void
    {
        this.loading = true;
        const worksheet = getSheet(data, columns);
        const csv = utils.sheet_to_csv(worksheet);
        const blob = new Blob([csv]);

        saveAs(blob, `${filename}.csv`);
        this.loading = false;
    }

    static asXLSX<AlertDataType>(
        data: AlertDataType[],
        columns: ColumnData<AlertDataType>[],
        filename: string
    ): void
    {
        this.loading = true;
        const worksheet = getSheet(data, columns);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, '');
        writeFile(workbook, `${filename}.xlsx`);
        this.loading = false;
    }

    static async asPDF(params: GenerateReportParams): Promise<void>
    {
        this.loading = true;
        const reportBlob = await generateReport(params);
        saveAs(reportBlob, `${params.type}.pdf`);
        this.loading = false;
    }

    static async asAllPDF(params: GenerateReportParams): Promise<void>
    {
        this.loading = true;
        const reportBlob = await generateReport(params);
        saveAs(reportBlob, `${params.type +'(' + params.dataRange + ')'}.pdf`);
        this.loading = false;
    }
}

export default TableExporter;
