import React from 'react';
import Highlighter from 'react-highlight-words';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

export interface HighlightTextProps
{
    readonly textToHighlight: string;
    readonly highlightWords: string[];
    readonly highlightSx?: SxProps<Theme>;
}

export function HighlightText(
    {
        textToHighlight,
        highlightWords,
        highlightSx,
    }: HighlightTextProps
): React.ReactElement
{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function highlightTag({ children }: { children: React.ReactNode }): React.ReactElement
    {
        return (
            <Box
                sx={{
                    display: 'inline',
                    color: theme => theme.palette.error.main,
                    ...highlightSx,
                }}
            >
                {children}
            </Box>
        );
    }

    return (
        <Highlighter
            textToHighlight={textToHighlight}
            searchWords={highlightWords}
            highlightTag={highlightTag}
            autoEscape={true}
        />
    );
}
