import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    InstalledProgramsAlertsTable,
} from 'src/templates/installed-programs-alerts-table/InstalledProgramsAlertsTable';

export function DataTypeInstalledPrograms(): React.ReactElement
{
    return (
        <DataTypePage>
            <InstalledProgramsAlertsTable />
        </DataTypePage>
    );
}
