import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import {
    ExtensionAddForm,
} from 'src/templates/extension-add-form/ExtensionAddForm';

import { ExtensionManagement } from 'src/models/administration/extensionData.model';

export interface ExtensionManagementModalProps {
    data?: ExtensionManagement;
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
}

export function ExtensionEditionModal({
    data,
    open = true,
    onClose = noop,
    onActionComplete = noop,
}: Readonly<ExtensionManagementModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    function closeAddModal():void
    {
        setTimeout(() => onClose(), 500);
    }

    function onComplete():void
    {
        onActionComplete();
    }

    return (
        <Modal
            title={t('Extension')}
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <ExtensionAddForm
                    {...data}
                    closeAddModal={closeAddModal}
                    onComplete={onComplete}
                />
            </Box>
        </Modal>
    );
}
