import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useQuery from 'src/hooks/useQuery';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { AWLContext, AWLCreationTabs } from 'src/templates/awl-creation-tabs/AWLCreationTabs';

export function AWLCreationPage(): React.ReactElement
{
    const { t } = useTranslation();
    const query = useQuery();

    return (
        <AdministrationPage>
            <AdministrationHeader
                labels={[
                    t('alert-word.alert-words'),
                    t('alert-word.new'),
                ]}
            />
            <Box>
                <AWLCreationTabs
                    selectedTab={query.get('tab') as AWLContext ?? undefined}
                />
            </Box>
        </AdministrationPage>
    );
}
