import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColumnData, DataTable, DataTableProps } from 'src/components/data-table/DataTable';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';

export interface ToggleTableProps<DataType> extends DataTableProps<DataType> {
    toggleTitle: string;
    initialSelection?: string[];
    getIdentifier: (data: DataType) => string;
    onChange: (selection: string[]) => void;
    blocked?: boolean;
}

const switchContainerStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.5rem',
    justifyContent: 'center',
};

export function ToggleTable<DataType>({
    toggleTitle,
    initialSelection,
    getIdentifier,
    onChange,
    blocked = false,
    columns,
    ...tableParams
}: Readonly<ToggleTableProps<DataType>>): React.ReactElement
{
    const [selection, setSelection] = useState<Set<string>>(new Set(initialSelection));
    const [changed, setChanged] = useState<Set<string>>(new Set());
    const isSwitchedOn = (data: DataType): boolean => selection.has(getIdentifier(data));
    const isSyncing = (data: DataType): boolean => changed.has(getIdentifier(data));
    const onToggle = (data: DataType, checked: boolean): void =>
    {
        const id = getIdentifier(data);
        const newSelection = new Set(selection);

        if (checked)
        {
            newSelection.add(id);
        }
        else
        {
            newSelection.delete(id);
        }

        setSelection(newSelection);
        setChanged((current) => current.add(id));

        onChange(Array.from(newSelection));
    };

    useEffect(() =>
    {
        setSelection(new Set(initialSelection));
        setChanged(new Set());
    }, [initialSelection]);

    const getToggleColumn = (data: DataType): React.ReactNode =>
    {
        const syncing = isSyncing(data);
        return (
            <div style={switchContainerStyle}>
                <ToggleSwitch
                    checked={isSwitchedOn(data)}
                    disabled={syncing || blocked}
                    onChange={(_, checked) => onToggle(data, checked)}
                />
                {
                    syncing
                        ? <CircularProgress size="1.5em" />
                        : null
                }
            </div>
        );
    };

    const columnsWithToggle: ColumnData<DataType>[] = [
        ...columns,
        {
            label: toggleTitle,
            size: 5,
            format: (data: DataType) => getToggleColumn(data),
        },
    ];

    return (
        <DataTable<DataType>
            {...tableParams}
            columns={columnsWithToggle}
        />
    );
}
