import React, { SVGProps } from 'react';
import { Flag } from 'src/models/flag.enum';
import { ReactComponent as FlagSoftwareIcon } from 'src/assets/icons/flag-software.svg';
import { ReactComponent as FlagCriminalIcon } from 'src/assets/icons/flag-criminal.svg';
import { ReactComponent as FlagNotableIcon } from 'src/assets/icons/flag-notable.svg';
import { ReactComponent as FlagOfficerIcon } from 'src/assets/icons/flag-officer.svg';
import { ReactComponent as FlagProbationIcon } from 'src/assets/icons/flag-probation.svg';
import { ReactComponent as FlagNoFlagIcon } from 'src/assets/icons/flag-no-flag.svg';

export interface FlagIconProps extends SVGProps<SVGSVGElement> {
    flag?: Flag;
}

export function FlagIcon({ flag, ...props }: Readonly<FlagIconProps>): React.ReactElement
{
    if(flag)
    {
        switch (flag)
        {
        case Flag.SOFTWARE: return <FlagSoftwareIcon {...props} />;
        case Flag.CRIMINAL_ACTIVITY: return <FlagCriminalIcon {...props}/>;
        case Flag.NOTABLE_INFORMATION: return <FlagNotableIcon {...props} />;
        case Flag.OFFICER_FLAGGED: return <FlagOfficerIcon {...props}/>;
        case Flag.PROBATION_PAROLE: return <FlagProbationIcon {...props}/>;
        }
    }

    return <FlagNoFlagIcon {...props} />;
}
