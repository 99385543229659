export enum AdminSettings {
    SuggestedAlertWords,
    ProfileManagement,
    Users,
    AgentManagement,
    VersionManagement,
    Extensions,
    DeviceConfigurationManagement,
    Instruction
}
