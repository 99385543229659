import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getAllProfiles,
    selectAllProfiles,
    selectAllProfilesRetrieveStatus,
} from 'src/state/administration/administrationSlice';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import { changeFilters, changeAlertsFilter } from 'src/state/captures/capturesSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { ALERTSPAGE } from 'src/models/alertsType.enum';

export function DataTypeProfileSelect(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [profileList, setProfileList] = useState<object[]>([]);
    const profiles = useSelector(selectAllProfiles);
    const profilesStatus = useSelector(selectAllProfilesRetrieveStatus);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;

    let selectedValue = 'none';

    const filter = JSON.parse(sessionStorage.getItem('Filters') as string);
    const alertFilter = JSON.parse(sessionStorage.getItem('AlertsFilter') as string);

    if (isAlertsPage && alertFilter?.profileValue)
    {
        selectedValue = alertFilter.profileValue;
    }

    if (!isAlertsPage && filter?.profileValue)
    {
        selectedValue = filter.profileValue;
    }

    useEffect(() =>
    {
        if (!profiles)
        {
            dispatch(getAllProfiles());
        }

        if(profilesStatus === RequestStatus.Success)
        {
            setProfileList(profileList=>[...profileList,
                { value: 'none', name: 'All' }]);

            profiles?.contents?.map((value, index)=>
            {
                setProfileList(profileList=>[...profileList,
                    { value: value.id, name: value.name }]);
                return true;
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profiles]);

    function handleTabChange(newValue: unknown): void
    {
        if(newValue)
        {
            if(isAlertsPage)
            {
                dispatch(
                    changeAlertsFilter({
                        profileValue: newValue === 'none' ? '' as string: newValue as string,
                    })
                );
            }
            else
            {
                dispatch(
                    changeFilters({
                        profileValue: newValue === 'none' ? '' as string: newValue as string,
                    })
                );
            }
        }
    }

    return (
        <DropdownSelector
            options={profileList}
            fullWidth
            label={t('data-type.filters.profile')}
            onChange={handleTabChange}
            value={selectedValue}
        />
    );
}
