import { APICaptureData, readAPICaptureData } from './captures';
import { LogonEventData } from 'src/models/captures/logonEventData.model';
import { LogonType } from 'src/models/logonType.enum';


export interface APILogonEventResponse extends APICaptureData {
    local: boolean;
    type: LogonType;
}

export function readAPILogonEventResponse(
    data: APILogonEventResponse
): LogonEventData
{
    const {
        local,
        type,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        local,
        type,
    };
}
