import { CallHistoryType } from './../../../models/callHistoryType.enum';
import { APICaptureData, readAPICaptureData } from './captures';
import { CallHistoryData } from 'src/models/captures/callHistoryData.model';


export interface APICallHistoryResponse extends APICaptureData {
    date: Date;
    duration: number;
    type: CallHistoryType;
    deviceNumber: string;
    externalNumber: string;
    contactName: string;
}

export function readAPICallHistoryResponse(
    data: APICallHistoryResponse
): CallHistoryData
{
    const {
        date,
        duration,
        type,
        deviceNumber,
        externalNumber,
        contactName,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        date,
        duration,
        type,
        deviceNumber,
        externalNumber,
        contactName,
    };
}
