import React from 'react';
import {
    Box,
    Theme,
} from '@mui/material';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SessionLogsSelect } from 'src/templates/session-logs-select/SessionLogsSelect';
import { SessionLogs } from 'src/models/sessionLogs.enum';
import { selectSessionLogsMenu } from 'src/state/administration/administrationSlice';
import { UserSessionLogs } from 'src/templates/session-logs/SessionLogs';
import { AssignedOfficerLogs } from 'src/templates/assigned-officer-logs/AssignedOfficerLogs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import {
    selectSessionFilters,
    changeSessionFilters,
} from 'src/state/captures/capturesSlice';
import { SessionSearchFilter } from 'src/templates/session-search-filter/SessionSearchFilter';
import Grid from '@mui/material/Grid';
import { AssignedOfficerSession } from 'src/models/administration/assignedOfficerData.model';
import { DateFilterChange } from 'src/utils/dateUtils';

export function SessionLog(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const SessionLogsMenu = useSelector(selectSessionLogsMenu);
    const filters = useSelector(selectSessionFilters);

    const onChangeDateFilter = (
        field: keyof AssignedOfficerSession,
        // eslint-disable-next-line
        newdate?: any
    ): void =>
    {
        const dateTime = DateFilterChange(field, newdate, filters.startDate, filters.endDate);
        if (field === 'startDate')
        {
            dispatch(changeSessionFilters({
                startDate: dateTime.startDateTime,
            }));
        }
        else
        {
            dispatch(changeSessionFilters({
                endDate: dateTime.endDateTime,
            }));
        }
    };

    return (
        <>
            <Header />
            <Box m={4} >
                <TopBar
                    labels={[
                        t('User Session'),
                        t('Logs'),
                    ]}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                    <Box sx={{ width: '20em', mb: 4 }}>
                        <SessionLogsSelect/>
                    </Box>

                    <Box sx={{ ml: 10 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('data-type.filters.start-date')}
                                value={filters.startDate ? new Date(filters.startDate) : null}
                                onChange={(newValue) => onChangeDateFilter('startDate', newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        onKeyDown={(e)=> e.preventDefault()}
                                        sx={{
                                            backgroundColor:
                                             (theme: Theme) => theme.palette.common.white,
                                            borderRadius: 1,
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box sx={{ ml: 5 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('data-type.filters.end-date')}
                                value={filters.endDate ? new Date(filters.endDate) : null}
                                onChange={(newValue) => onChangeDateFilter('endDate', newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        onKeyDown={(e)=> e.preventDefault()}
                                        sx={{
                                            backgroundColor:
                                         (theme: Theme) => theme.palette.common.white,
                                            borderRadius: 1,
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box sx={{ ml: 8 }}>
                        {SessionLogsMenu === SessionLogs.AssignedOfficersSessionLogs && (
                            <Grid
                                item
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <SessionSearchFilter />
                            </Grid>
                        )}
                    </Box>
                </Box>
                {
                    (SessionLogsMenu === SessionLogs.UserSessionLogs) && (
                        <UserSessionLogs/>
                    )
                }
                {
                    (SessionLogsMenu === SessionLogs.AssignedOfficersSessionLogs) && (
                        <AssignedOfficerLogs/>
                    )
                }
            </Box>
        </>
    );
}
