import { SessionUser, VerifyTokenResponse } from 'src/models/authorizationModels';
import { PasswordRule } from 'src/models/userModels';

import instance from './instance';
import {
    APIForgotPassword,
    readAPIUser,
    readVerifyTokenResponse,
}
    from './data-transfer-objects/userDTO';

export async function forgotPassword(
    email: string
): Promise<APIForgotPassword>
{
    const response = await instance.post(
        '/users/forgot-password',
        { email }
    );
    return response.data;
}

export async function checkPassword(
    password: string,
    passwordConfirmation: string
): Promise<PasswordRule[]>
{
    const response = await instance.patch('/password/check', { password, passwordConfirmation });
    return response.data;
}

export async function resetPassword(
    forgotPasswordToken: string,
    password: string,
    passwordConfirmation: string
): Promise<SessionUser>
{
    const response = await instance.patch(
        '/users/reset-password',
        { forgotPasswordToken, password, passwordConfirmation }
    );
    return readAPIUser(response.data);
}

export async function changePassword(
    password: string,
    passwordConfirmation: string
): Promise<SessionUser>
{
    const response = await instance.patch(
        '/users/change-password',
        { password, passwordConfirmation }
    );
    return readAPIUser(response.data);
}

export async function activateUser(
    forgotPasswordToken: string,
    password: string,
    passwordConfirmation: string
): Promise<SessionUser>
{
    const response = await instance.patch(
        '/users/activate',
        { forgotPasswordToken, password, passwordConfirmation }
    );
    return readAPIUser(response.data);
}

export async function verifyUser(
    forgotPasswordToken: string
): Promise<VerifyTokenResponse>
{
    const response = await instance.post(
        '/users/verify-token',
        { forgotPasswordToken }
    );
    return readVerifyTokenResponse(response.data);
}
