import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDevices,
    selectDeviceFilters,
    selectDevices,
    selectDeviceStatus,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BuildIcon from '@mui/icons-material/Build';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Device } from 'src/models/administration/deviceData.model';
import { DEVICE_SETTINGS_MANAGEMENT } from 'src/utils/routes';
import { generatePath, useNavigate } from 'react-router-dom';

export function DevicesTable(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectDevices);
    const filters = useSelector(selectDeviceFilters);
    const status = useSelector(selectDeviceStatus);
    const navigate = useNavigate();

    function onPageChange(pageNumber: number): void
    {
        dispatch(getDevices({ pageNumber, entriesPerPage, ...filters }));
    }

    useEffect(() =>
    {
        dispatch(getDevices({ pageNumber: 1, entriesPerPage, ...filters }));
    }, [dispatch, entriesPerPage, filters]);

    const getStatusColumn = (data: Device): React.ReactNode =>
    {
        const textStatus: string = data.activated
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: theme => data.activated
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                }}
            >
                {textStatus}
            </Box>
        );
    };
    const getActionColumn =(id:string): React.ReactNode => (
        <Button
            onClick={() => navigate(generatePath(DEVICE_SETTINGS_MANAGEMENT, { id }))}
        >
            <BuildIcon />
        </Button>
    );

    const columns: ColumnData<Device>[] = [
        {
            label: t('common.nouns.device'),
            size: 3,
            value: (data: Device) => data.id,
        },
        {
            label: t('common.nouns.agency'),
            size: 1,
            value: (data: Device) => data.agencyName,
        },
        {
            label: t('data-type.details.device-alias'),
            size: 2,
            value: (data: Device) => data.alias,
        },
        {
            label: t('data-type.table.device-type'),
            size: 2,
            value: (data: Device) => data.platform,
        },
        {
            label: t('common.nouns.status'),
            size: 2,
            value: (data: Device) =>
            {
                if (!data.enabled)
                {
                    return t('common.status.disabled');
                }
                if (!data.activated)
                {
                    return t('common.status.inactive');
                }
                return t('common.status.active');
            },
            format: (data) => getStatusColumn(data),
        },
        {
            label: t('data-type.table.action'),
            size: 1,
            format: ({ id }) => getActionColumn(id),
        },
    ];

    return (
        <DataTable<Device>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            {...items}
        />
    );
}
