import { Button, CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    TableExportFormat,
} from 'src/components/table-exporter/TableExporterProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { processAndGenerateAllPdf } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import {
    selectFilters,
    selectTableDatatype,
    selectTableTotalCount,
    selectExportAllRecords,
    selectcolumnValue,
    selectscreenshotGridView,
    selectAlertsFilter
} from 'src/state/captures/capturesSlice';
import { ColumnSelectorAll } from '../column-selector/ColumnSelectorAll';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
interface ALLtoExportProps {
    selectedValue: () => Promise<void>;
    formatAll: string | undefined;
    setFormatAll: Dispatch<SetStateAction<string | undefined>>;
    xlsxExportClick: () => Promise<void>;
}

export function ALLtoExport({
    selectedValue,
    formatAll,
    setFormatAll,
    xlsxExportClick,

}: Readonly<ALLtoExportProps>): React.ReactElement {
    const { t } = useTranslation();
    const exportAllData = useSelector(selectExportAllRecords);
    const screenshotGridView = useSelector(selectscreenshotGridView);
    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const dataType = useSelector(selectTableDatatype);
    const totalRecordCount = useSelector(selectTableTotalCount);
    const [allToXlsx, setAllToXlsx] = useState(false);
    const columnsData = useSelector(selectcolumnValue);
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const handlePDFExport = async (): Promise<void> => {
        await selectedValue();
        const filterValue = isAlertsPage? alertsFilter:filters
        processAndGenerateAllPdf(
            totalRecordCount,
            dataType,
            filterValue,
            setDownloadPercentage,
            null,
            setFormatAll
        );
    };
    const handleXLSXExport = async (): Promise<void> => {
        setAllToXlsx(true);
        await xlsxExportClick();

    };

    return (
        <>
            {filters.isGridView === false || screenshotGridView=== false ?
                <Button
                    variant="text"
                    startIcon={
                        formatAll === TableExportFormat.PDF
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    disabled={formatAll === TableExportFormat.PDF}

                    onClick={handlePDFExport}
                >
                    {t('common.button.all-to-pdf')}
                    {' '}
                    {formatAll === TableExportFormat.PDF &&
                        Math.round(downloadPercentage) + '%'}
                </Button> : ''
            }            <Button
                variant="text"
                startIcon={
                    formatAll === TableExportFormat.XLSX
                        ? <CircularProgress size="1rem" />
                        : <DownloadIcon />
                }
                disabled={formatAll === TableExportFormat.XLSX}
                onClick={() => {
                    handleXLSXExport();
                }}
            >
                {t('common.button.all-to-xlsx')}
            </Button>
            {allToXlsx &&
                exportAllData &&
                exportAllData?.contents.length > 0 && (
                    <ColumnSelectorAll
                        format={TableExportFormat.ALLXLSX}
                        columns={columnsData}
                        data={{
                            contents: exportAllData?.contents,
                            currentPage: 1,
                            numberOfEntries: 0,
                        }}
                        onClose={() => setFormatAll(undefined)}
                        filters={isAlertsPage? alertsFilter:filters}
                        setAllToXlsx={setAllToXlsx}
                        totalRecordCount={totalRecordCount}
                        dataType={dataType}
                    />
                )}
        </>
    );
}
