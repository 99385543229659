import {
    Agency,
    AgencyParams,
    DeleteAgencyParams,
    MonitorAgency,
    NonCompliantAgencyResponse,
} from 'src/models/administration/agencyData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';

export interface APIAgencyParams extends APIPaginationParams {
    startDate?: string;
    endDate?: string;
    searchValue?: string;
}

export interface VersionsParams {
    currentVersions?: PlatformVersion[];
}

export interface APIAgencyRequest {
    id: string;
    agencyName: string;
    description: string;
    logo: string;
    enabled:boolean;
    activated?:boolean;
    suggestedAlertWords?: string[];
    searchValue?: string;
    deleted?: boolean;
    expirationTime?: Date;
    monitoruser?: string[];
    ccContacts?:string[];
}
export interface APIMonitorRequest{
    id:string;
    name:string;
}
export interface NonComplianceRequest{
    agency:string;
    activeDevice:string;
    platforms?:string[];
    particularPlatform?:string[];
    currentVersion?:string[];
}
export interface APIAgencyResponse extends APIAgencyRequest {
    logoUrl: string;
    createdAt: string;
}

export function transformAgencyParams(params: AgencyParams): APIAgencyParams
{
    const {
        searchValue,
        createdEndDate,
        createdStartDate,
        ...paginatedParams
    } = params;

    return {
        startDate: createdStartDate,
        endDate: createdEndDate,
        searchValue,
        ...transformPaginationParams(paginatedParams),
    };
}

export function transformDeleteAgencyParams(params: DeleteAgencyParams): DeleteAgencyParams
{
    const {
        agencyId,
        pageNumber,
        entriesPerPage,
        officerId
    } = params;
    return {
        agencyId,
        pageNumber,
        entriesPerPage,
        officerId
    };
}
export function transformAgency(data: Agency): APIAgencyRequest
{
    const {
        id,
        agencyName,
        description,
        logo,
        enabled,
        activated,
        suggestedAlertWords,
        deleted,
        expirationTime,
        monitoruser,
        ccContacts,
    } = data;

    return {
        id,
        agencyName,
        description,
        enabled,
        activated,
        logo,
        suggestedAlertWords,
        deleted,
        expirationTime,
        monitoruser,
        ccContacts,
    };
}

export function readAPIAgencyResponse(data: APIAgencyResponse): Agency
{
    return {
        id: data.id,
        agencyName: data.agencyName,
        description: data.description,
        enabled: data.enabled,
        activated: data.activated,
        logo: data.logo,
        logoUrl: data.logoUrl,
        suggestedAlertWords: data.suggestedAlertWords,
        deleted: data.deleted,
        expirationTime: data.expirationTime,
        monitoruser: data.monitoruser,
        ccContacts: data.ccContacts,
    };
}

export function readAPIAWLResponse(data: APIMonitorRequest): MonitorAgency
{
    const {
        id,
        name,
    } = data;

    return {
        id,
        name,
    };
}
export function readAPIMonitorResponse(
    response: APIMonitorRequest[]
): MonitorAgency[]
{
    return response.map(({ id, name }) => ({
        id,
        name,
    }));
}

export function readAPINonCompliantAgency(
    response: NonComplianceRequest[]
): NonCompliantAgencyResponse []
{
    return response.map(({
        agency,
        activeDevice,
        platforms,
        particularPlatform,
        currentVersion,
    }) => ({
        agency,
        activeDevice,
        platforms,
        particularPlatform,
        currentVersion,
    }));
}
