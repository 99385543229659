import { CaptureParams } from 'src/models/captures/capturedData.model';
import { TextSMSData, TextSMSForDetails } from 'src/models/captures/textSMSData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPITextSMSResponse } from '../data-transfer-objects/capture/textSMS';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';
import { GenerateReportParamsIds } from 'src/models/captures/report.model';
import { transformGenerateReportIdParams } from '../data-transfer-objects/capture/reports';

export async function getTextSMSs(
    captureParams: CaptureParams
): Promise<PaginatedContent<TextSMSData>>
{
    const response = await instance.get(
        'text-sms',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPITextSMSResponse);
}

export async function getTextSMSsForDetails(
    params: TextSMSForDetails
): Promise<PaginatedContent<TextSMSData>>
{
    const response = await instance.get(
        'text-sms/by-id-paginated',
        { params }
    );
    return readAPIPaginatedResponse(response.data, readAPITextSMSResponse);
}

export async function getSMSIds(
    data: GenerateReportParamsIds
)
// eslint-disable-next-line
: Promise<any>
{
    const response = await instance.post(
        'text-sms/getId',
        transformGenerateReportIdParams(data)
    );

    return response.data;
}
