import { APICaptureData, readAPICaptureData } from './captures';
import { GeolocationData } from 'src/models/captures/geolocationData.model';


export interface APIGeolocationResponse extends APICaptureData {
    latitude: number;
    longitude: number;
}

export function readAPIGeolocationResponse(
    data: APIGeolocationResponse
): GeolocationData
{
    const {
        latitude,
        longitude,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        latitude,
        longitude,
    };
}
