import React from 'react';
import Box from '@mui/material/Box';
import { Header } from '../header/Header';
import { ReportsFilters } from '../reports-filters/ReportsFilters';

export interface ReportsPageProps {
    children?: React.ReactNode;
}

export function ReportsPage({ children = null }: Readonly<ReportsPageProps>): JSX.Element
{
    return (
        <>
            <Header />
            <ReportsFilters />
            <Box m={4}>
                {children}
            </Box>
        </>
    );
}
