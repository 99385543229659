import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { Client } from 'src/models/administration/clientData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    createDevice,
    resetDeviceSaveStatus,
    selectDeviceSaveStatus,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { DEFAULT_VALUES, DeviceForm, DeviceFormValues } from '../device-form/DeviceForm';

export interface DeviceCreationModalProps {
    client: Client,
    open?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function DeviceCreationModal({
    client,
    open = false,
    onClose = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<DeviceCreationModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState<DeviceFormValues>(DEFAULT_VALUES);
    const status = useSelector(selectDeviceSaveStatus);

    function onSubmit(data: DeviceFormValues): void
    {
        dispatch(createDevice({
            ...data,
            client: client.id,
        }));
    }

    function onCancel(): void
    {
        onClose();
    }

    useEffect(() =>
    {
        if (status === RequestStatus.Success)
        {
            setInitialValues({ ...DEFAULT_VALUES });
            onSuccess();
            dispatch(resetDeviceSaveStatus());
        }
        else if (status === RequestStatus.Failure)
        {
            onFailure();
            dispatch(resetDeviceSaveStatus());
        }
    }, [status, setInitialValues, onSuccess, onFailure, dispatch]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(resetDeviceSaveStatus());
        };
    }, [dispatch]);

    return (
        <Modal
            open={open}
            title={t('devices.new-device')}
            showCloseButton={false}
            maxWidth="sm"
            fullWidth
        >
            <Box>
                <DeviceForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    loading={status === RequestStatus.InProgress}
                />
            </Box>
        </Modal>
    );
}
