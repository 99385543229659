import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from 'src/models/roles.enum';
import {
    createCustomsAWL,
    resetCustomAWLCreationStatus,
    selectCustomAWLCreationStatus,
} from 'src/state/administration/administrationSlice';
import { AWLCreationForm } from '../awl-creation-form/AWLCreationForm';
import { noop } from 'src/utils/common';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';


export interface CustomAWLCreationFormProps {
    mode: Role;
    assignedTo?: string;
    onBackToList?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function CustomAWLCreationForm({
    mode,
    assignedTo,
    onBackToList = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<CustomAWLCreationFormProps>): React.ReactElement
{
    const dispatch = useDispatch();
    const status = useSelector(selectCustomAWLCreationStatus);

    function onSubmit({ name, words, profileId: profile }: GenericAWL): void
    {
        if ((assignedTo || mode === Role.OfficerSupervisor) && profile)
        {
            dispatch(createCustomsAWL({
                name,
                words,
                profile,
                agency: assignedTo,
            }));
        }
    }

    function onNotificationClose(): void
    {
        dispatch(resetCustomAWLCreationStatus());
    }

    return (
        <AWLCreationForm
            onSubmit={onSubmit}
            onBackToList={onBackToList}
            onSuccess={onSuccess}
            onFailure={onFailure}
            status={status}
            profileField={true}
            onNotificationClose={onNotificationClose}
            agencyField={mode !== Role.OfficerSupervisor}
        />
    );
}
