import React from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';

export interface ConfirmationSelectionProps {
    text: string,
    onSubmit:(value: boolean) => void;
}

export function ConfirmationSelection({
    text,
    onSubmit,
}: ConfirmationSelectionProps): React.ReactElement
{
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Typography
                    component="span"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
                >
                    {text}
                </Typography>
            </Grid>
            <Grid item>
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => onSubmit(false)}
                    >
                        No
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={() => onSubmit(true)}
                    >
                        Yes
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
