import { CaptureParams } from 'src/models/captures/capturedData.model';
import { KeystrokeData, KeystrokeDetailsData } from 'src/models/captures/keystrokeData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import {
    readAPIKeystrokeResponse,
    readAPIKeystrokeDetailsResponse,
} from '../data-transfer-objects/capture/keystrokes';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getKeystrokes(
    captureParams: CaptureParams
): Promise<PaginatedContent<KeystrokeData>>
{
    const response = await instance.get(
        'keystrokes',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIKeystrokeResponse);
}

export async function getKeystrokeDetails(
    keystrokeId: string
): Promise<KeystrokeDetailsData>
{
    const response = await instance.get(`keystrokes/${keystrokeId}`);
    return readAPIKeystrokeDetailsResponse(response.data);
}

