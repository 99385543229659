import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GenericAWL } from 'src/models/administration/alertWordListData.model';
import {
    createSuggestedAWL,
    resetAWLCreationStatus,
    selectAWLCreationStatus,
} from 'src/state/administration/administrationSlice';
import { ADMIN_SETTINGS, AWL_MANAGEMENT } from 'src/utils/routes';
import { AWLCreationForm } from '../awl-creation-form/AWLCreationForm';

export function SuggestedAWLCreationForm(): React.ReactElement
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector(selectAWLCreationStatus);

    function onSubmit({ name, words, profileId: profile }: GenericAWL): void
    {
        if (profile)
        {
            dispatch(createSuggestedAWL({
                name,
                words,
                profile,
            }));
            setTimeout(() =>
            {
                navigate(AWL_MANAGEMENT as string);
                dispatch(resetAWLCreationStatus());
            }, 2000);
        }
    }

    function onNotificationClose(): void
    {
        dispatch(resetAWLCreationStatus());
    }

    return (
        <AWLCreationForm
            onSubmit={onSubmit}
            profileField={true}
            status={status}
            onNotificationClose={onNotificationClose}
            onBackToList={() => navigate(ADMIN_SETTINGS)}
        />
    );
}
