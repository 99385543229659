import {
    APILogParams,
    SessionlogParams,
} from 'src/models/administration/assignedOfficerData.model';
import {
    UserLogResponse,
    UserSessionResponse,
} from 'src/models/administration/userSessionData.model';
import {
    transformPaginationParams,
} from '../pagination';

export interface APIuserSessionResponse {
    id: string;
    loggedInAt: Date;
    userName: string;
    userId: string;
    loggedOutAt: Date;
    updatedAt: Date;
    trackedAt: Date
}
export interface APIUserLogResponse {
    id: string;
    userId: string;
    action: string;
    action_type: string;
    createdAt: Date;
    info?:DeleteUser;
}
export interface DeleteUser {
    deleteRequestFrom: string;
    trackingType: string;
    deletedObjectId: string;
    deletedObjectName: string;
    deletedObjectEmail?: string;
}
export function readAPIUserSessionResponse({
    id,
    loggedInAt,
    userName,
    userId,
    loggedOutAt,
    trackedAt,
}: APIuserSessionResponse): UserSessionResponse
{
    return {
        id,
        userId,
        userName,
        loggedInAt,
        loggedOutAt,
        trackedAt,

    };
}
export function readAPIUserSessionLogsResponse({
    id,
    userId,
    action,
    action_type,
    createdAt,
    info,

}: APIUserLogResponse): UserLogResponse
{
    return {
        id,
        userId,
        action,
        action_type,
        createdAt,
        info,
    };
}
export function transformUserlogsParams(params: SessionlogParams): APILogParams
{
    const {
        fromDate,
        toDate,
        userId,
        ...paginatedParams
    } = params;

    return {
        fromDate,
        toDate,
        userId,
        ...transformPaginationParams(paginatedParams),
    };
}
