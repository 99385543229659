import {
    Notification,
    NotificationConfiguration,
    NotificationConfigurationParams,
    NotificationParams,
    UpdateNotificationParams,
    PushnotificationParams,
} from 'src/models/administration/notificationData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPINotificationConfigurationResponse,
    readAPINotificationResponse,
    transformNotificationConfigurationParams,
    transformNotificationConfigurationRequest,
    transformNotificationParams,
} from '../data-transfer-objects/administration/notifications';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import { PushNotificationConfiguration } from '../../models/administration/notificationData.model';

export async function getNotificationConfigurations(
    params: NotificationConfigurationParams
): Promise<PaginatedContent<NotificationConfiguration>>
{
    const response = await instance.get('notifications/configurations', {
        params: transformNotificationConfigurationParams(params),
    });

    return readAPIPaginatedResponse(response.data, readAPINotificationConfigurationResponse);
}

export async function getNotificationConfiguration(
    notificationConfigurationId: string
): Promise<NotificationConfiguration>
{
    const response = await instance.get(
        `notifications/configurations/${notificationConfigurationId}`
    );

    return readAPINotificationConfigurationResponse(response.data);
}

export async function getNotificationConfigurationNotifications(
    params: NotificationParams
): Promise<PaginatedContent<Notification>>
{
    const response = await instance.get(
        `notifications/configurations/${params.notificationConfigurationId}/notifications`,
        {
            params: transformNotificationParams(params),
        }
    );

    return readAPIPaginatedResponse(response.data, readAPINotificationResponse);
}

export async function createNotificationConfiguration(
    data: NotificationConfiguration
): Promise<NotificationConfiguration>
{
    const response = await instance.post(
        'notifications/configurations',
        transformNotificationConfigurationRequest(data)
    );

    return readAPINotificationConfigurationResponse(response.data);
}

export async function updateNotificationConfiguration(
    data: NotificationConfiguration
): Promise<NotificationConfiguration>
{
    const response = await instance.patch(
        `notifications/configurations/${data.id}`,
        transformNotificationConfigurationRequest(data)
    );

    return readAPINotificationConfigurationResponse(response.data);
}

export async function deleteNotificationConfiguration(
    notificationConfigurationId: string
): Promise<NotificationConfiguration>
{
    const response = await instance.delete(
        `notifications/configurations/${notificationConfigurationId}`
    );
    return response.data;
}

export async function getNotifications(): Promise<PaginatedContent<Notification>>
{
    const response = await instance.get('/notifications');

    return readAPIPaginatedResponse(response.data, readAPINotificationResponse);
}



// eslint-disable-next-line
export async function sendpushnotification(data : PushnotificationParams ) : Promise<void>
{
    const response = await instance.post('/notifications/Send', data);
    return response.data;
}

export async function getNotification() : Promise<PushNotificationConfiguration>
{
    const response = await instance.get('/notifications/getNotification');
    return response.data;
}

export async function DeleteNotification(
    params: UpdateNotificationParams
): Promise<unknown>
{
    const response = await instance.post(
        `/notifications/deleteNotification/${params.id}`,
        params
    );
    return response.data;
}
