import { Button, CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    TableExportFormat,
} from 'src/components/table-exporter/TableExporterProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from 'react-redux';
import { processAndGeneratePDFs } from 'src/utils/common';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { selectExportAllRecords } from 'src/state/captures/capturesSlice';
interface PDFExportProps {
    selectedValue: () => Promise<void>;
    // eslint-disable-next-line
    ids: any;
    idStatus: RequestStatus | undefined;
    data: string;
    formatAll: string | undefined;
    setFormatAll: Dispatch<SetStateAction<string | undefined>>;
    alltoExport?: boolean;
    islandingPage?:boolean;
}

export function PDFExport({
    selectedValue,
    ids,
    idStatus,
    data,
    formatAll,
    setFormatAll,
    alltoExport,
    islandingPage
}: Readonly<PDFExportProps>): React.ReactElement {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const exportAllData = useSelector(selectExportAllRecords);
    const [downloadPercentage, setDownloadPercentage] = useState(0);

    useEffect(() => {
        if (alltoExport === true && exportAllData?.contents.length) {
            setFormatAll('PDF');
            const Ids = exportAllData.contents.map((item) => {
                return item.id;
            });
            processAndGeneratePDFs(
                Ids,
                data,
                setDownloadPercentage,
                setFormatAll,
                null,
                dispatch
            );
        }
        else if (idStatus === RequestStatus.Success &&
            ids && ids?.length > 0) {
            setFormatAll('PDF');
            const propertyName = ids[0]._id ? '_id' : 'id';
            const recordIds = ids.map((item: { [key: string]: string }) => item[propertyName]);
            processAndGeneratePDFs(
                recordIds,
                data,
                setDownloadPercentage,
                setFormatAll,
                null,
                dispatch
            );
        }
        else if(islandingPage){
            processAndGeneratePDFs(
                ids,
                data,
                setDownloadPercentage,
                setFormatAll,
                null,
                dispatch
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids, idStatus, exportAllData]);

    const handlePDFExport = async (): Promise<void> => {
        await selectedValue();
    };

    return (
        <Button
            variant="text"
            startIcon={
                formatAll === TableExportFormat.PDF
                    ? <CircularProgress size="1rem" />
                    : <DownloadIcon />
            }
            disabled={formatAll === TableExportFormat.PDF}

            onClick={handlePDFExport}
        >
            {t('common.button.all-to-pdf')}
            {' '}
            {formatAll === TableExportFormat.PDF &&
                Math.round(downloadPercentage) + '%'}
        </Button>
    );
}
