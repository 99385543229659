import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { noop } from 'src/utils/common';
import Box from '@mui/system/Box';

interface ModalProps extends DialogProps {
    children?: React.ReactElement;
    loading?: boolean;
    showCloseButton?: boolean;
}

export function Modal({
    children,
    loading = false,
    showCloseButton = true,
    onClose = noop,
    ...props
}: Readonly<ModalProps>): React.ReactElement
{
    return (
        <Dialog sx={{ zIndex: (theme) => theme.zIndex.modal }} {...props}>
            <DialogTitle>
                <Stack
                    display="flex"
                    direction="row"
                    justifyContent="space-between"
                    sx={{ borderBottom: '1px solid #E8E8E8' }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        align="right"
                        sx={{
                            padding: '3px 40px 10px 0',
                            color: (theme) => theme.palette.grey[400],
                            textTransform: 'none',
                        }}
                    >
                        {props.title}
                    </Typography>
                    {
                        showCloseButton
                            ? (
                                <IconButton
                                    aria-label="close"
                                    onClick={() => onClose({}, 'escapeKeyDown')}
                                    sx={{
                                        color: (theme) => theme.palette.grey[400],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )
                            : null
                    }
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mt: 1,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {
                        loading
                            ? <CircularProgress />
                            : children
                    }
                </Box>

            </DialogContent>
        </Dialog>
    );
}
