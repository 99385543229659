import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'src/styles/svg.css';
import { ScreenshotData } from 'src/models/captures/screenshotData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getScreenshots,
    selectFilters,
    changeScreenshot,
    selectScreenshot,
    selectScreenshots,
    selectScreenshotsStatus,
    getScreenshotsdeviceid,
    changeFilters,
    selectScreenshotsDeviceId,
    changeScreenshotDeviceId,
    resetscreenshotFilterFlag,
    changescreenshotStatus,
    updateSeenListStatus,
    changescreenshotGridViewFilters,
    selectallPageData,
    changeScreenshotDetailsData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { ScreenshotDetailsGrid } from './ScreenshotDetailsGrid';
import { DataDetailsGalleryModal } from '../data-type-detail/DataDetailGalleryModal';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange, isFilterApplied } from 'src/utils/checkFilterChange';
import { SCREENSHOTS } from 'src/models/alertsType.enum';
import { endOfDay, startOfDay } from 'date-fns';

export function ScreenshotAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const screenshotsStatus = useSelector(selectScreenshotsStatus);
    const screenshotsData = useSelector(selectScreenshots);
    const screenshotData = useSelector(selectScreenshot);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [imageError, setImageError] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState({});
    const [dataId, setDataId] = useState<string | undefined>(undefined);
    const screenshotsDataDeviceId = useSelector(selectScreenshotsDeviceId);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);

    const prevPage = useRef(1);

    function onPageChange(pageNumber: number): void
    {
        if (screenshotsData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(screenshotsData?.contents[
                    screenshotsData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        setImageError([]);
        prevPage.current = pageNumber;
    }
    const [datas, setDatas] = useState<string[] |
        undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleGalleryOpen = (data: ScreenshotData): void =>
    {
        openRecordData(data.id);
        const mutableImages = [];
        mutableImages.push({
            id: data.id,
            clientCreatedAt:
                filters.isDateFilterChanged === true ?
                    data.clientCreatedAtFirst :
                    data.createdAtFirst,
        });
        mutableImages.push({
            id: data.lastId,
            clientCreatedAt: filters.isDateFilterChanged === true ?
                data.clientCreatedAtLast :
                data.createdAtLast,
        });
        const sortedImages = mutableImages.sort((a, b) =>
            new Date(
                a?.clientCreatedAt as Date
            ).getTime() -
            new Date(
                b?.clientCreatedAt as Date
            ).getTime());
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: data.id,
                dataType: DataType.SCREENSHOTS,
            }));
        }
        setGalleryOpen(true);
        const isFilterChanged = isFilterApplied(filters);
        const startDate = filters.isDateFilterChanged === true ?
            new Date(data.capturedDate):new Date(data.receivedDate);
        const endDate = filters.isDateFilterChanged === true ?
            new Date(data.capturedDate):new Date(data.receivedDate);
        dispatch(getScreenshotsdeviceid({
            id: data.deviceId,
            dateFirst: isFilterChanged ? startOfDay(startDate) : sortedImages[0].clientCreatedAt,
            dateLast: isFilterChanged ? endOfDay(endDate) : sortedImages[1].clientCreatedAt,
            pageNumber: 1,
            entriesPerPage: 0,
            isDateFilterChanged: filters.isDateFilterChanged,
            flags: filters.flags,
            searchValue: filters.searchValue,
            sortBy: filters.sortBy,
            device: filters.device,
        }));
        dispatch(changeScreenshot(screenshotsDataDeviceId?.contents[0]));
        dispatch(changeScreenshotDetailsData({
            id: data.deviceId,
            dateFirst: isFilterChanged ? startOfDay(startDate) : sortedImages[0].clientCreatedAt,
            dateLast: isFilterChanged ? endOfDay(endDate) : sortedImages[1].clientCreatedAt,
            pageNumber: 1,
            entriesPerPage: 0,
            isDateFilterChanged: filters.isDateFilterChanged,
            flags: filters.flags,
            searchValue: filters.searchValue,
            sortBy: filters.sortBy,
            device: filters.device,
            alertwordsOnly: filters.alertwordsOnly,
        }));
    };

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);

        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    const prevFilters = useRef(filters);
    useEffect(() =>
    {
        dispatch(changescreenshotGridViewFilters(false));
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() =>
    {

        if (dataId == null || undefined)
        {
            if (screenshotsStatus === RequestStatus.Success)
            {
                if (screenshotsData && screenshotsData.contents.length > 0)
                {

                    const dataIdLast = screenshotsData.contents[0].lastId;
                    setDataId(dataIdLast);

                }
            }
        }
        // eslint-disable-next-line
    }, [dataId, screenshotsData, filters]);

    useEffect(() =>
    {
        if (galleryOpen === false)
        {
            dispatch(changescreenshotStatus());
            dispatch(changeScreenshotDeviceId(undefined));
            dispatch(changeScreenshot(undefined));
        }
        // eslint-disable-next-line
    }, [galleryOpen]);

    useEffect(() =>
    {
        if (galleryOpen === false)
        {
            const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
            // eslint-disable-next-line
            const filtersChanged = checkFilterChange(filters, prevFilters.current,
                SCREENSHOTS
            );

            if (filtersChanged)
            {
                dispatch(getScreenshots({
                    dataId,
                    skipRecordId,
                    pageNumber: checkFilterChange(currentFilter, filters, SCREENSHOTS)
                        ? currentPage : 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                }));

            }

            else
            {
                dispatch(getScreenshots({
                    pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
                    entriesPerPage: rowsPerPage,
                    ...filters,
                }));
            }
            prevFilters.current = filters;
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const detailItems = [
        {
            label: t('data-type.table.captured-date'),
            text: (data: ScreenshotData) =>
                new Date(
                    data.images[data.imageIndex].clientCreatedAt.toLocaleString()
                ).toLocaleString(),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ScreenshotData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ScreenshotData) => data.username,
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            text: (data: ScreenshotData) => data.filesCount,
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: ScreenshotData) => data.alertWords?.join(', '),
        },
    ];

    const getAiScanStatusColumn = (data: ScreenshotData): React.ReactNode =>
    {
        if (data.aiScanStatus === 2) return <ScoutRed />;
        else if (data.aiScanStatus === 0) return (
            <HourglassEmptyOutlinedIcon
                sx={{ fill: 'lightGrey' }}
            />
        );
        else return ('');
    };
    const getFlagColumn = (data: ScreenshotData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.SCREENSHOTS}
            enabled={false}
        />
    );
    const getImageColumn = (data: ScreenshotData): React.ReactNode => (
        imageError.includes(data.id) ? <AccessTimeIcon /> : (
            <img
                height="50"
                width="100"
                src={data.images[0].fileUrl}
                onError={() => setImageError([...imageError, data.id])}
                alt={new Date(data.capturedDate).toLocaleString()}
            />
        )
    );
    const openDetailColumn = (data: ScreenshotData): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => handleGalleryOpen(data)}
        >
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: ScreenshotData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: ScreenshotData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: ScreenshotData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: ScreenshotData) =>
                data.capturedDate ? new Date(data.capturedDate)?.toLocaleString() : '',
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: ScreenshotData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: ScreenshotData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.program'),
            size: 4,
            value: (data: ScreenshotData) => '',
            sortByType: 'appOnFocus',
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            align: 'right' as const,
            size: 3,
            value: (data: ScreenshotData) => data.filesCount,
        },
        {
            label: t('data-type.table.image'),
            size: 1,
            align: 'center' as const,
            format: (data: ScreenshotData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: ScreenshotData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            align: 'center' as const,
            size: 1,
            format: (data: ScreenshotData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (screenshotsData)
        {
            setLastPage(Math.ceil(
                screenshotsData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.SCREENSHOTS,
                recordIds: screenshotsData.contents.map((item) => item.id),
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(screenshotsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.SCREENSHOTS)
            );
        }
    }, [screenshotsData, pdfExportSetter]);

    return (
        <>
            <DataDetailsGalleryModal<ScreenshotData>
                title={t('data-type.details.screenshots-title')}
                open={galleryOpen}
                data={screenshotsDataDeviceId?.contents[0]}
                detailItems={detailItems}
                onClose={() =>
                {
                    setGalleryOpen(false);
                    dispatch(resetscreenshotFilterFlag());
                    dispatch(changescreenshotGridViewFilters(false));
                }
                }
                comments={{
                    contentId:
                        screenshotData?.images[screenshotData?.imageIndex ?? 0]?.id,
                    type: CommentType.SCREENSHOTS,
                }}
            >
                <ScreenshotDetailsGrid
                    {...screenshotData as ScreenshotData}
                />
            </DataDetailsGalleryModal>
            {useDesktopScreen() ? (
                <DataTable<ScreenshotData>
                    isLoading={screenshotsStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={updateEntriesPerPage}
                    datas={datas}
                    {...screenshotsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={screenshotsStatus === RequestStatus.InProgress}
                    contents={screenshotsData?.contents || []}
                    setOpenModal={handleGalleryOpen}
                    onChangeEntriesPerPage={updateEntriesPerPage}
                    entriesPerPage={entriesPerPage}
                    onPageChange={onPageChange}
                    numberOfEntries={screenshotsData?.numberOfEntries}
                    currentPage={screenshotsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
