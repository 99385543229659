/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-image-gallery/styles/css/image-gallery.css';

import { TextMMSData } from 'src/models/captures/textMMSData.model';
import { selectTextMMS } from 'src/state/captures/capturesSlice';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { Button, CircularProgress } from '@mui/material';
import {
    downloadImages,
    handleDragStart,
    toggleFullScreen,
    zoomImage,
} from 'src/utils/common';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { USER_TIME_ZONE } from 'src/utils/environment';

export function TextMMSDetails({
    open,
    onClose,
}: {
    readonly open: boolean
    readonly onClose: () => void
}): // data: TextMMSData
React.ReactElement
{
    const { t } = useTranslation();
    const textMMS = useSelector(selectTextMMS);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const handleScroll = (event: any): void =>
    {
        zoomImage(setScale, setImagePosition, event);
    };

    useEffect(() =>
    {
        window.addEventListener('wheel', handleScroll);
        return () =>
        {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isFullScreen]);

    useEffect(() =>
    {
        setIsFullScreen(false);
        setScale(1);
    }, [open]);

    const dragFunction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void =>
    {
        handleDragStart(scale, imagePosition, setImagePosition, event);
    };

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: TextMMSData) =>
                timeZoneFormat(new Date(data.receivedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: TextMMSData) =>
                data.date ? timeZoneFormat(new Date(data.receivedDate), USER_TIME_ZONE) : '',
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: TextMMSData) => data.device,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: TextMMSData) => data.platform,
        },
    ];

    const imageUrl = textMMS?.fileUrl;

    return (
        <DataDetailsModal<TextMMSData>
            title={t('data-type.details.text-mms-title')}
            open={open}
            data={textMMS}
            detailItems={detailItems}
            onClose={onClose}
            comments={{
                contentId: textMMS?.id,
                type: CommentType.MMS,
            }}
        >
            {textMMS && (
                <>
                    <Button
                        variant="text"
                        startIcon={
                            isLoading ?
                                <CircularProgress size="1rem" /> :
                                <DownloadIcon />
                        }
                        onClick={() =>
                        {
                            if (imageUrl)
                            {
                                downloadImages(
                                    imageUrl,
                                    setIsLoading,
                                    null,
                                    textMMS.fileId
                                );
                            }
                        }}
                    >
                        JPEG
                    </Button>
                    <br />
                    <div>
                        {isFullScreen ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                    cursor: 'pointer',
                                }}
                                onMouseDown={dragFunction}
                            >
                                <button
                                    onClick={() =>
                                    {
                                        toggleFullScreen(
                                            isFullScreen,
                                            setIsFullScreen,
                                            setScale,
                                            setImagePosition
                                        );
                                    }}
                                    style={{
                                        position: 'fixed',
                                        top: '5%',
                                        right: '5%',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: 'xx-large',
                                        color: 'white',
                                        zIndex: 999,
                                    }}
                                >
                                    X
                                </button>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        transform: `scale(${scale}) 
                                            translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                                        transition: 'transform 0.3s',
                                        cursor: imagePosition.x > 0 || imagePosition.y > 0 ?
                                            'grabbing' : 'pointer',
                                    }}
                                    src={textMMS.fileUrl}
                                    alt={textMMS.capturedDate}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                {
                                    toggleFullScreen(
                                        isFullScreen,
                                        setIsFullScreen,
                                        setScale,
                                        setImagePosition
                                    );
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            >
                                <img
                                    style={{ width: '100%', height: 'auto' }}
                                    src={textMMS.fileUrl}
                                    alt={textMMS.capturedDate}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </DataDetailsModal>
    );
}
