import {
    Client,
    ClientBasic,
    ClientParams,
    DeleteClientParams,
} from 'src/models/administration/clientData.model';
import { Agency } from 'src/models/administration/agencyData.model';
import { ClientFormValues } from 'src/templates/client-form/ClientForm';
import { APIPaginationParams, transformPaginationParams } from '../pagination';

export interface APIClientParams extends APIPaginationParams {
    startDate?: string;
    endDate?: string;
    searchValue?: string;
}

// Used for no-pageable responses
export interface APIClientBasic {
    id: string;
    fullName: string;
}

export interface OfficersParams {
    name?: string;
    value?: string;
    id?: string;
    _id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    cellPhone?: string[];
    remoteCOMAccount?: string;
    agencyId?: string;
    agencyInfo?: Agency;
    profiles?: string[];
    officers?: OfficersParams[];
    createdAt?: string;

}
export interface APIClientResponse {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    cellPhone: string[];
    remoteCOMAccount: string;
    agencyId: string;
    officerId: string
    agencyInfo: Agency;
    profiles: string[];
    officers: OfficersParams[];
    createdAt: string;
    disableClient: boolean;
    activated:boolean;
    enabled?:boolean;
}

export interface APIClientCreationPayload {
    firstName: string;
    lastName: string;
    email: string;
    cellPhone: string[];
    agencyId: string;
    officerId: string
    profiles: string[];
    officers: OfficersParams[];
    remoteCOMAccount: string;
    disableClient: boolean;
}

export function transformClientParams(params: ClientParams): APIClientParams
{
    const {
        createdStartDate,
        createdEndDate,
        searchValue,
        ...paginatedParams
    } = params;

    return {
        startDate: createdStartDate,
        endDate: createdEndDate,
        searchValue,
        ...transformPaginationParams(paginatedParams),
    };
}

export function readAPIClientResponse(data: APIClientResponse): Client
{
    return {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        agencyId: data.agencyId,
        officerId: data.officerId,
        agencyInfo: data.agencyInfo,
        profiles: data.profiles,
        officers: data.officers,
        cellPhone: data.cellPhone,
        remoteCOMAccount: data.remoteCOMAccount,
        disableClient: data.disableClient,
        enabled: data.enabled,
    };
}

export function readAPIClientBasicResponse({ id, fullName }: APIClientBasic): ClientBasic
{
    return {
        id,
        fullname: fullName,
    };
}

export function transformDeleteClientParams(params: DeleteClientParams): DeleteClientParams
{
    const {
        clientId,
        pageNumber,
        entriesPerPage,
    } = params;
    return {
        clientId,
        pageNumber,
        entriesPerPage,
    };
}

export function transformClientCreationParams(data: ClientFormValues): APIClientCreationPayload
{
    const {
        firstName,
        lastName,
        email,
        cellPhone,
        agencyId,
        officerId,
        profiles,
        officers,
        remoteCOMAccount,
        disableClient,
    } = data;

    return {
        firstName,
        lastName,
        email,
        officerId,
        cellPhone,
        agencyId,
        profiles,
        officers,
        remoteCOMAccount,
        disableClient,
    };
}
