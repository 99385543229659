import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { ScreenCaptureAlertsTable }
    from 'src/templates/screen-capture-alerts-table/ScreenCaptureAlertsTable';

export function DataTypeScreenCaptures(): React.ReactElement
{
    return (
        <DataTypePage>
            <ScreenCaptureAlertsTable />
        </DataTypePage>
    );
}
