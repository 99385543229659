import { CaptureParams } from 'src/models/captures/capturedData.model';
import { CallHistoryData } from 'src/models/captures/callHistoryData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import { readAPICallHistoryResponse } from '../data-transfer-objects/capture/callHistory';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getCallHistory(
    captureParams: CaptureParams
): Promise<PaginatedContent<CallHistoryData>>
{
    const response = await instance.get(
        'calls-history',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPICallHistoryResponse);
}

export async function getCallHistoryById (id: string): Promise<CallHistoryData>
{
    const response = await instance.get(`calls-history/${id}`).then(x => x.data);
    return readAPICallHistoryResponse(response);
}
