import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { noop } from 'src/utils/common';

const listStyle = {
    overflow: 'auto',
    padding: 0,
};

const listItemStyle = {
    padding: 0,
};

export interface CheckboxOption<T> {
    value: T;
    label: string;
    checked?: boolean;
}

export interface CheckboxListProps<T> {
    options: CheckboxOption<T>[];
    onChange?: (selectedOptions: T[]) => void;
}

function CheckboxList<T extends string | number> ({
    options,
    onChange = noop,
}:Readonly<CheckboxListProps<T>>): React.ReactElement
{
    const [checkedOptions, setCheckedOptions] = useState<T[]>([]);

    const checkToggle = (value: T) => () =>
    {
        const checkedIndex = checkedOptions.indexOf(value);
        const newCheckedList = [...checkedOptions];

        if (checkedIndex === -1)
        {
            newCheckedList.push(value);
        }
        else
        {
            newCheckedList.splice(checkedIndex, 1);
        }

        setCheckedOptions(newCheckedList);
    };

    useEffect(() =>
    {
        const checkedOptions = options
            .filter((option) => option.checked)
            .map((option) => option.value);

        setCheckedOptions(checkedOptions);
    }, [options]);

    useEffect(() =>
    {
        onChange(checkedOptions);
    }, [checkedOptions, onChange]);

    return (
        <List sx={listStyle}>
            {
                options.map((option) =>
                {
                    return (
                        <ListItem key={option.value} sx={listItemStyle}>
                            <ListItemButton
                                onClick={checkToggle(option.value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checkedOptions.indexOf(option.value) > -1}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.label}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })
            }
        </List>
    );
}

export default CheckboxList;
