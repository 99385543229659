import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import {
    CallHistoryAlertsTable,
} from 'src/templates/call-history-alerts-table/CallHistoryAlertsTable';

export function DataTypeCallHistory(): React.ReactElement
{
    return (
        <DataTypePage>
            <CallHistoryAlertsTable />
        </DataTypePage>
    );
}
