import React from 'react';

import { DataTypePage } from 'src/templates/data-type-page/DataTypePage';
import { EmailAlertsTable } from 'src/templates/email-alerts-table/EmailAlertsTable';

export function DataTypeEmails(): React.ReactElement
{
    return (
        <DataTypePage>
            <EmailAlertsTable />
        </DataTypePage>
    );
}
