import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTheme, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';

import { ReactComponent as FilterIcon } from 'src/assets/icons/filter.svg';
import { useTranslation } from 'react-i18next';

export interface FiltersBarProps {
    children: React.ReactNode;
}
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        laptop: true;
    }
}
// eslint-disable-next-line
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            laptop: 1920,
        },
    },
});

interface ChangeIconByScreenSizeProps {
    isDesktopScreen: boolean;
    expanded: boolean;
}

function ChangeIconByScreenSize({
    isDesktopScreen,
    expanded,
}: Readonly<ChangeIconByScreenSizeProps>)
    : React.ReactElement
{
    const { t } = useTranslation();

    if (isDesktopScreen)
    {
        return expanded
            ? (
                <RemoveCircleIcon
                    sx={{ height: '1rem', width: '1rem' }}
                />
            )
            : (
                <AddCircleIcon
                    sx={{ height: '1rem', width: '1rem' }}
                />
            );
    }
    else
    {
        return (
            <Typography
                variant="h6"
                component="div"
                color={theme => theme.palette.common.lightGrey}
                sx={{ ml: '0.625rem', flexGrow: 1 }}
            >
                {t('common.filter.filter')}
            </Typography>
        );
    }
}

export function FiltersBar(
    { children }: Readonly<FiltersBarProps>
): React.ReactElement
{
    const [expanded, setExpanded] = useState(true);
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('laptop'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() =>
    {
        isMobileScreen ? setExpanded(true) : setExpanded(false);
    }, [isMobileScreen]);

    function toggleExpanded(): void
    {
        setExpanded(!expanded);
    }

    return (
        <Box
            sx={{
                padding: expanded ? '0.625rem 2.1875rem 1rem' : '0.625rem 2.1875rem',
                backgroundColor: theme => theme.palette.common.softGrey,
                width: '100%',
                display: 'flex',

                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    alignItems: 'center',
                },
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                },
            }}
        >
            <Box sx={{ marginRight: '1rem', flex: '0 0 auto' }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        sx={{ marginTop: '0.375rem', marginBottom: '-0.375rem' }}
                    >
                        <IconButton onClick={toggleExpanded}>
                            <FilterIcon />
                            <ChangeIconByScreenSize
                                isDesktopScreen={isDesktopScreen}
                                expanded={expanded}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            { isDesktopScreen ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Box sx={{ flex: '1 1 auto', mt: { xs: '1.125rem' } }}>
                        <Grid container spacing={2}>
                            {React.Children.map(children, (child, key) => (
                                <Grid
                                    key={`row-${key + 1}`}
                                    item
                                    sx={{
                                        [theme.breakpoints.down('md')]: {
                                            marginTop: '0.375rem',
                                            marginBottom: '-0.375rem',
                                        },
                                    }}
                                >
                                    {child}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            )
                : (
                    <Collapse in={expanded} >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Box sx={{ flex: '1 1 auto', mt: { xs: '1.125rem' } }}>
                                <Grid container spacing={2}>
                                    {React.Children.map(children, (child, key) => (
                                        <Grid
                                            key={`row-${key + 1}`}
                                            item
                                            sx={{
                                                marginTop: '0.375rem',
                                                marginBottom: '-0.375rem',
                                            }}
                                        >
                                            {child}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Collapse>
                )}

        </Box>
    );
}
