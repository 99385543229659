import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';

export interface ForgotPasswordFormProps
{
    handleCancel: () => void;
    handleForgotPassword: (email: string) => void;
    isSending?: boolean;
    hasForgotPasswordError?: boolean;
}

export function ForgotPasswordForm(
    {
        handleCancel,
        handleForgotPassword,
        isSending = false,
        hasForgotPasswordError = false,
    }: Readonly<ForgotPasswordFormProps>
): React.ReactElement
{
    const { t } = useTranslation();

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        handleForgotPassword(
            formData.get('email') as string
        );
    }

    const [displayError, setDisplayError] = useState(hasForgotPasswordError);
    function clearError(): void
    {
        setDisplayError(false);
    }

    useEffect(() =>
    {
        setDisplayError(hasForgotPasswordError);
    }, [hasForgotPasswordError]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                height: '18.5rem' /* 296px */,
                mt: { xs: 4 },
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: theme => theme.palette.common.lightGrey,
                        fontSize: '1.25rem', // 20px
                    }}
                >
                    {t('forgot-password.title')}
                </Typography>

                <Grid container>
                    <Grid item md={9} xs={12}>
                        <TextField
                            sx={{
                                mt: 2, // margin-top 16px
                                mb: 3, // margin-bottom 24px
                                '& input, & label': {
                                    color: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                '& svg': {
                                    fill: theme =>
                                        theme.palette.common.lightGrey,
                                },
                                opacity: isSending ? 0.5 : 1,
                            }}
                            fullWidth
                            id="email-field"
                            label={t('forgot-password.email-input-label')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSending}
                            onChange={clearError}
                            error={displayError}
                            helperText={displayError && t('forgot-password.failed-message')}
                        />

                        <Box
                            sx={{
                                mt: 1, // margin-top 8px
                                fontSize: '0.75rem', // 12px
                                color: theme => theme.palette.common.lightGrey,
                            }}
                        >
                            {t('forgot-password.details')}
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button
                    type="reset"
                    sx={{
                        mr: 1, // margin-right: 8px
                        backgroundColor: 'transparent',
                        color: theme => theme.palette.common.goldYellow,
                        opacity: isSending ? 0.5 : 1,
                        '&.Mui-disabled': {
                            backgroundColor: 'inherit',
                            color: theme => theme.palette.common.goldYellow,
                        },
                    }}
                    disabled={isSending}
                    onClick={handleCancel}
                >
                    {t('forgot-password.cancel-button')}
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        minWidth: '7.5rem', // 120px
                        '&.Mui-disabled': {
                            backgroundColor: theme => theme.palette.primary.main,
                            color: theme => theme.palette.primary.contrastText,
                        },
                    }}
                    disabled={isSending}
                >
                    {t(isSending
                        ? 'forgot-password.send-button-loading'
                        : 'forgot-password.send-button')}
                    {isSending && <AnimatedEllipsis />}
                </Button>
            </Box>
        </Box>
    );
}
