import React from 'react';
import { Box, Stack, Theme } from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useTranslation } from 'react-i18next';

const boxStyle = {
    whiteSpace: 'nowrap',
    '&::before': {
        content: '"|"',
        color: (theme: Theme) => theme.palette.common.goldYellow,
        margin: '0 1rem',
    },
};

const iconStyle = {
    marginRight: '1rem',
};

export interface AdministrationHeaderProps {
    labels: string[],
    // eslint-disable-next-line
    icon?: any
}

export function AdministrationHeader({ labels }: Readonly<AdministrationHeaderProps>): JSX.Element
{
    const { t } = useTranslation();

    return (
        <Stack direction="row" sx={{ margin: '1rem 0' }}>
            <Stack
                direction="row"
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: theme => theme.palette.common.lightGrey,
                    alignItems: 'center',
                    '@media (max-width: 768px)': {
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <SettingsRoundedIcon
                    sx={iconStyle}
                    fontSize="large"
                />
                <Box>
                    {t('menu.settings')}
                </Box>
                {
                    labels.map((label) => (
                        <Box
                            key={label}
                            sx={boxStyle}
                        >
                            {label}
                        </Box>
                    ))
                }
            </Stack>
        </Stack>
    );
}
