import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';

import { TopBar } from 'src/components/top-bar/TopBar';
import { NOTIFICATIONS_CREATE } from 'src/utils/routes';
import {
    NotificationsPage,
} from 'src/templates/notifications/notifications-page/NotificationsPage';
import {
    NotificationsTable,
} from 'src/templates/notifications/notifications-table/NotificationsTable';

export function Notifications(): JSX.Element
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <NotificationsPage>
            <TopBar
                labels={[t('notifications.my-notifications')]}
                icon={<NotificationsIcon />}
                buttons={[
                    {
                        text: t('notifications.create-new-notification'),
                        icon: <AddCircleIcon />,
                        onClick: () => navigate(NOTIFICATIONS_CREATE),
                    },
                ]}
            />
            <NotificationsTable />
        </NotificationsPage>
    );
}
