import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    KEYWORDS,
    SCREENSHOTS,
    PHOTOS,
    TEXTSMS,
    TEXTMMS,
    EXTERNALDEVICES,
    FILESTRACKING,
    SCREENCAPTURES,
    INSTALLEDPROGRAMS,
    CALLHISTORY,
    GEOLOCATIONS,
    LOGONEVENTS,
    EMAILS,
    PRINTEDDOCUMENTS,
    PROCESS,
    WEBSITES,
    SEARCHES,
    ALL_DATA_TYPE,
}
    from 'src/models/alertsType.enum';
import { DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import {
    changeAlertsFilter,
    selectAlertsFilter,
} from 'src/state/captures/capturesSlice';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { Flag, FlagWithAI } from 'src/models/flag.enum';


export interface DataTypeSelectAlertsProps {
    selectedValue: string;
    setSelectedValue: (value: string) => void;
  }

export function DataTypeSelectAlerts({ selectedValue, setSelectedValue }
    : Readonly<DataTypeSelectAlertsProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertsFilter = useSelector(selectAlertsFilter);

    function handleTabChange(newValue: unknown): void
    {
        setSelectedValue(newValue as string);

        const updatedFilters: CaptureFilters = {
            ...alertsFilter,
            alertsdatatype: newValue as string,
            searchValue: '' as string,
            isGridView: false,
            alertwordsOnly: false,
        };


        if(newValue as string === TEXTMMS ||
                newValue as string === PHOTOS ||
                newValue as string === SCREENCAPTURES||
                newValue as string === SCREENSHOTS
        )
        {
            updatedFilters.flags =
                Object.values(FlagWithAI)
                    .map((flag) => flag)
                    .filter((flag) => flag !== Flag.NO_FLAG);
        }
        else
        {
            updatedFilters.flags =
                Object.values(Flag)
                    .map((flag) => flag)
                    .filter((flag) => flag !== Flag.NO_FLAG);
        }
        dispatch(changeAlertsFilter(updatedFilters));
    }



    const menuItems = [
        // { value: ALL_DATA_TYPE, name: t('data-type.tabs.alldatatypes') },
        { value: KEYWORDS, name: t('data-type.tabs.keystrokes') },
        { value: SCREENSHOTS, name: t('data-type.tabs.Screenshots') },
        { value: SCREENCAPTURES, name: t('data-type.tabs.ScreenCaptures') },
        { value: PHOTOS, name: t('data-type.tabs.Photos') },
        { value: TEXTMMS, name: t('MMS') },
        { value: TEXTSMS, name: t('data-type.tabs.SMS') },
        { value: EXTERNALDEVICES, name: t('data-type.tabs.ExternalDevices') },
        { value: FILESTRACKING, name: t('data-type.tabs.FilesTracking') },
        { value: INSTALLEDPROGRAMS, name: t('data-type.tabs.InstalledPrograms') },
        { value: GEOLOCATIONS, name: t('data-type.tabs.Geolocation') },
        { value: EMAILS, name: t('data-type.tabs.Emails') },
        { value: LOGONEVENTS, name: t('data-type.tabs.LogonEvents') },
        { value: CALLHISTORY, name: t('data-type.tabs.CallHistory') },
        { value: PRINTEDDOCUMENTS, name: t('data-type.tabs.PrintedDocuments') },
        { value: PROCESS, name: t('data-type.tabs.ExecutionTime') },
        { value: WEBSITES, name: t('data-type.tabs.websites') },
        { value: SEARCHES, name: t('data-type.tabs.searches') },
    ];

    return (
        <DropdownSelector
            options={menuItems}
            fullWidth
            label={t('data-type.filters.data-types')}
            onChange={handleTabChange}
            value={selectedValue}
        />
    );
}



