import {
    CommentData,
    CommentListParams,
    CommentParams,
} from 'src/models/comments/commentData.model';
import { CommentType } from 'src/models/commentType.enum';

export interface APICommentListRequest {
    contentId?: string;
    commentType?: CommentType;
}

export interface APICommentRequest {
    message?: string,
    commentType?: CommentType;
    contentId?: string;
}

export interface APICommentResponse extends APICommentRequest{
    id?: string,
    createdBy?: string,
    createdByAgency?: string,
    createdAt?: Date,
    createdId?: string,
    seemore?: boolean
}

export function transformCommentsParams(
    data: CommentListParams
): APICommentListRequest
{
    const {
        contentId,
        commentType,
    } = data;

    return {
        contentId,
        commentType,
    };
}

export function transformCommentAPIRequest(
    data: CommentParams
): APICommentRequest
{
    const {
        contentId,
        commentType,
        message,
    } = data;

    return {
        contentId,
        commentType,
        message,
    };
}

export function transformeditCommentAPIRequest(
    data: CommentParams
): APICommentRequest
{
    const {
        message,
    } = data;

    return {
        message,
    };
}

export function readAPICommentResponse(
    data: APICommentResponse
): CommentData
{
    const {
        id,
        contentId,
        commentType,
        message,
        createdBy,
        createdByAgency: createByAgency,
        createdAt,
        createdId,
    } = data;

    return {
        id,
        contentId,
        commentType,
        message,
        createdBy,
        createdByAgency: createByAgency,
        createdAt,
        createdId,
    };
}

export function readAPIfilterCommentResponse(
    data: APICommentResponse
): CommentData
{
    const {
        id,
        contentId,
        commentType,
        message,
        createdBy,
        createdByAgency: createByAgency,
        createdAt,
        createdId,
        seemore,
    } = data;

    return {
        id,
        contentId,
        commentType,
        message,
        createdBy,
        createdByAgency: createByAgency,
        createdAt,
        createdId,
        seemore,
    };
}
