import { PaginatedContent } from 'src/models/paginatedResult.model';
import { UserFilters, UserParams } from 'src/models/administration/usersData.model';
import { User } from 'src/models/userModels';
import {
    APIUserResponse,
    readAPIUserResponse,
    transformAPIRequest,
    transformUserFilters,
    transformUserParams,
} from '../data-transfer-objects/administration/users';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';

export async function getUser(userId: string): Promise<User>
{
    const response = await instance.get(`users/${userId}`);

    return readAPIUserResponse(response.data);
}
export async function getUsers(params: UserParams): Promise<PaginatedContent<User>>
{
    const response = await instance.get(
        'users',
        { params: transformUserParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIUserResponse);
}
export async function getOffUser(params: UserParams): Promise<PaginatedContent<User>>
{
    const response = await instance.get(
        'users/officer',
        { params: transformUserParams(params) }
    );
    return readAPIPaginatedResponse(response.data, readAPIUserResponse);
}


export async function getUsersNoPageable(params: UserFilters): Promise<User[]>
{
    const response = await instance.get<APIUserResponse[]>(
        'users/no-pageable',
        { params: transformUserFilters(params) }
    );

    return response.data.map(readAPIUserResponse);
}

export async function createUser(data: User): Promise<User>
{
    const response = await instance.post(
        'users',
        transformAPIRequest(data)
    );

    return readAPIUserResponse(response.data);
}

export async function updateUser(data: User): Promise<User>
{
    const response = await instance.put(
        `users/${data.id}`,
        transformAPIRequest(data)
    );

    return readAPIUserResponse(response.data);
}
export async function deleteUser(userId: string): Promise<User>
{
    const response = await instance.delete(`users/${userId}`);
    return readAPIUserResponse(response.data);
}
export async function getSupervisor(id: string | null): Promise<[]>
{
    const response = await instance.get(
        `agency/supervisor/${id}`
    );

    return response.data.results;
}
