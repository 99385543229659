import React from 'react';
import { DataDetailCommentProps } from './DataDetailComment';
import { ContentModal, ContentModalButtonProps } from 'src/components/content-modal/ContentModal';

export interface DetailsGalleryModalProps<DetailModalType> {
    open: boolean,
    title?: string,
    detailsTitle?: string,
    data?: DetailModalType,
    detailItems?: DetailedItem<DetailModalType>[],
    onClose?: () => void,
    children?: React.ReactElement
    isLoading?: boolean,
    buttons?: ContentModalButtonProps[],
    comments?: DataDetailCommentProps,
}

export interface DetailedItem<DetailModalType> {
    label: string;
    text?: ((data: DetailModalType) => string | number);
}

export function DataDetailsGalleryModal<DetailModalType>(
    {
        open,
        title,
        detailsTitle,
        onClose,
        detailItems,
        data,
        children,
        isLoading,
        buttons,
        comments,
    }: Readonly<DetailsGalleryModalProps<DetailModalType>>
): React.ReactElement
{
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() =>
    {
        if (open)
        {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null)
            {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <ContentModal
            { ...{ open, title, isLoading, onClose, buttons } }
        >
            {children}
        </ContentModal>
    );
}
