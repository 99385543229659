import { ErrorData } from './../models/errors/errorData.model';

export function handleError(errorData?: ErrorData, fieldName?: string ,profileField?:string): boolean
{
    if(Array.isArray(errorData?.message))
    {
        const fieldError = errorData?.message?.find(message => message.property === fieldName);
        return fieldError !== undefined;
    }
    if(profileField && errorData?.message){
        return profileField !== undefined;
    }


    return false;
}

export function handleErrorText(errorData?: ErrorData, fieldName?: string ,profileField?:string): string
{
    if(Array.isArray(errorData?.message))
    {
        const fieldError = errorData?.message?.find(message => message.property === fieldName);
        const errors: string[] = [];
        if (fieldError)
        {
            const vall = fieldError.constraints;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Object.entries(vall as any).forEach(el => errors.push(el[1] as string));
        }

        return errors.join('\n');
    }
    if(profileField){
        return errorData?.message ?? ""
    }

    return '';
}
