import React from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FlagIcon } from 'src/components/flag-icon/FlagIcon';
import { Flag } from 'src/models/flag.enum';
import { useTranslation } from 'react-i18next';

export interface UpdateFlagAllModelProps {
    flag: Flag;
    count?: number;
    onSubmit:(value: boolean) => void;
}

export function UpdateFlagAllModel({
    flag,
    onSubmit,
    count,
}: UpdateFlagAllModelProps): React.ReactElement
{
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Typography
                    component="span"
                    sx={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
                >
                    {t('data-type.filters.update-flag-statement')}
                    {' '}
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            ml: '0.8rem',
                            mr: '0.8rem',
                        }}
                    >
                        <FlagIcon flag={ flag === Flag.NO_FLAG ? undefined : flag } />
                        <span style={{ marginLeft: '0.5rem', fontSize: '17px' }}>
                            {flag === Flag.NO_FLAG ? 'No Flag' : flag}
                        </span>
                    </Typography>
                    {' '}
                    {t('data-type.filters.update-flag-statement-2')}
                    {' '}
                    {count ? count : ''}
                    {' '}
                    {t('data-type.filters.update-flag-statement-3')}
                </Typography>
            </Grid>
            <Grid item>
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => onSubmit(false)}
                    >
                        No
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={() => onSubmit(true)}
                    >
                        Yes
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
