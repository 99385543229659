import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TabPanel } from 'src/components/tab-panel/TabPanel';
import { Role } from 'src/models/roles.enum';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { ClientAWLCreationForm } from '../client-awl-creation-form/ClientAWLCreationForm';
import { CustomAWLCreationForm } from '../custom-awl-creation-form/CustomAWLCreationForm';
import { SuggestedAWLCreationForm } from '../suggested-awl-creation-form/SuggestedAWLCreationForm';
import { OfficerAWLCreationForm } from '../officer-awl-creation-form/OfficerAWLCreationForm';

export enum AWLContext {
    Suggested = 'suggested',
    Agency = 'agency',
    Client = 'client',
    Officer = 'officer',
}

const contextLabels: { [key in AWLContext]: string } = {
    [AWLContext.Suggested]: 'common.nouns.suggested',
    [AWLContext.Agency]: 'common.nouns.agency',
    [AWLContext.Client]: 'common.nouns.client',
    [AWLContext.Officer]: 'common.nouns.officer',
};


export function getAWLContexts(role: Role): AWLContext[]
{
    switch (role)
    {
    case Role.Administrator:
    case Role.Employee:
    case Role.OfficerSupervisor:
    case Role.AgencyAdministrator:
        return [
            AWLContext.Suggested,
            AWLContext.Agency,
            AWLContext.Client,
            AWLContext.Officer,
        ];
    case Role.Officer:
        return [AWLContext.Officer];
    default:
        return [];
    }
}

export function getAWLTabs(contexts: AWLContext[]): { id: string, text: string }[]
{
    return contexts.map((context: AWLContext) => ({
        id: context,
        text: contextLabels[context],
    }));
}

export interface AWLCreationTabsProps {
    selectedTab?: AWLContext;
}

export function AWLCreationTabs({
    selectedTab = undefined,
}: Readonly<AWLCreationTabsProps>): React.ReactElement
{
    const { t } = useTranslation();
    const role = useSelector(selectRole);
    const contexts = role ? getAWLContexts(role) : [];
    const tabs = getAWLTabs(contexts).map((tab) => ({
        ...tab,
        text: t(tab.text),
    }));

    function renderForm(context: AWLContext): React.ReactElement | null
    {
        switch (context)
        {
        case AWLContext.Suggested:
            return <SuggestedAWLCreationForm />;
        case AWLContext.Agency:
            return role ? <CustomAWLCreationForm mode={role} /> : null;
        case AWLContext.Client:
            return <ClientAWLCreationForm />;
        case AWLContext.Officer:
            return <OfficerAWLCreationForm />;
        default:
            return null;
        }
    }

    return (
        <TabPanel
            selectedTab={selectedTab ?? ''}
            tabs={tabs}
        >
            {
                contexts.map((context) => (
                    <Box padding={'2em 0'} key={context}>
                        { renderForm(context) }
                    </Box>
                ))
            }
        </TabPanel>
    );
}
