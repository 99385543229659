import { CaptureParams } from 'src/models/captures/capturedData.model';
import {
    InstalledProgramsData, ProgramData,
} from 'src/models/captures/installedProgramsData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIPaginatedResponse,
} from '../data-transfer-objects/pagination';
import {
    APIInstalledProgramsResponse,
    APIProgramDataResponse,
    readAPIInstalledProgramsResponse,
    readAPIProgramDataResponse,
}
    from '../data-transfer-objects/capture/installedPrograms';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';


export async function getInstalledPrograms(
    captureParams: CaptureParams
): Promise<PaginatedContent<InstalledProgramsData>>
{
    const response = await instance.get(
        'installed-apps',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIInstalledProgramsResponse);
}

export async function getInstalledProgramsByDevice(params: {
    deviceId?: string
    agencyId?: string
    clientId?: string
    officerId?: string
    defaultPrograms?: boolean
}): Promise<InstalledProgramsData[]>
{
    const response = await instance.get('installed-apps/no-pageable', {
        params,
    });

    return response.data.map(
        (item: APIInstalledProgramsResponse) => readAPIInstalledProgramsResponse(item)
    );
}

export async function getAppsCompareDetails(
    recordId: string
): Promise<ProgramData[]>
{
    const response = await instance.get(`installed-apps/${recordId}`);
    return response.data.map((item: APIProgramDataResponse) =>
        readAPIProgramDataResponse(item));
}
