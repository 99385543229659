import { CaptureParams } from 'src/models/captures/capturedData.model';
import { PrintedDocumentData } from 'src/models/captures/printedDocumentData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import { readAPIPrintedDocumentsResponse } from '../data-transfer-objects/capture/printedDocuments';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getPrintedDocuments(
    captureParams: CaptureParams
): Promise<PaginatedContent<PrintedDocumentData>>
{
    const response = await instance.get(
        'printed-documents',
        { params: transformCaptureParams(captureParams) }
    );
    return readAPIPaginatedResponse(response.data, readAPIPrintedDocumentsResponse);
}
