import { minutesToMilliseconds } from 'date-fns';

import { LoginResponse, Token } from 'src/models/authorizationModels';
import * as storage from 'src/utils/storage';

import instance from './instance';
import {
    readAPILoginResponse,
    readAPIRefreshTokenResponse,
} from './data-transfer-objects/authorizationDTO';

let refreshTokenTimeoutId: NodeJS.Timeout;

const initialToken = storage.read<Token>(storage.Keys.Token);
if (initialToken)
{
    configureToken(initialToken);
}

function configureAuthorizationHeader(accessToken: string): void
{
    instance.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
    };
}

async function doRefreshToken(
    refreshToken: string
): Promise<Token | undefined>
{
    try
    {
        const response = await instance.post('/refresh-token', { refreshToken });
        return readAPIRefreshTokenResponse(response.data);
    }
    catch (error)
    {
        logout();
    }
}

export function configureToken(token: Token): void
{
    storage.write(storage.Keys.Token, token);
    configureAuthorizationHeader(token.accessToken);

    clearTimeout(refreshTokenTimeoutId);
    refreshTokenTimeoutId = setTimeout(
        async () =>
        {
            const newToken = await doRefreshToken(token.refreshToken);
            if (newToken?.expirationDate)
            {
                configureToken(newToken);
            }
        },
        // Refreshes the token a minute before expiring, or now if no expiration date is given
        new Date(token.expirationDate ?? 0).getTime()
        - new Date().getTime()
        - minutesToMilliseconds(1)
    );
}


export async function login(
    email: string,
    password: string
): Promise<LoginResponse>
{
    const response = await instance.post(
        '/auth/login',
        { email, password }
    );
    configureToken(response.data);
    return readAPILoginResponse(response.data);
}

export async function logout():Promise<void>
{
    try
    {
        await instance.put('/usersession/logs');
        storage.clear();
        window.location.reload();
    }
    catch (error)
    {
        storage.clear();
        window.location.reload();
    }
}

