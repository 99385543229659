import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LOGIN } from 'src/utils/routes';
import { LoginHeader } from 'src/templates/login-header/LoginHeader';
import { PasswordChangeForm } from 'src/templates/password-change-form/PasswordChangeForm';
import { AuthenticationPage } from 'src/templates/authentication-page/AuthenticationPage';
import { ReactComponent as PadlockImage } from 'src/assets/images/padlock.svg';
import { activateUser, selectActivateUserStatus } from 'src/state/user/userSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';

export function ActivateUser(): JSX.Element
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { activateUserToken = '' } = useParams();

    const activateUserStatus = useSelector(selectActivateUserStatus);

    function handleCancel(): void
    {
        navigate(LOGIN);
    }

    function handleChangePassword(password: string, confirmPassword: string): void
    {
        dispatch(activateUser({ activateUserToken, password, confirmPassword }));
    }

    if (activateUserStatus === RequestStatus.Success)
    {
        navigate(LOGIN);
    }

    return (
        <>
            <LoginHeader />
            <AuthenticationPage image={<PadlockImage />}>
                <PasswordChangeForm
                    title={t('password-change.reset-password-title')}
                    handleCancel={handleCancel}
                    handleChangePassword={handleChangePassword}
                    isChangingPassword={activateUserStatus === RequestStatus.InProgress}
                    hasError={activateUserStatus === RequestStatus.Failure}
                />
            </AuthenticationPage>
        </>
    );
}
