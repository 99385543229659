import {
    Alert, AlertProps, Button, Chip,
    IconButton, Snackbar, SnackbarProps, Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { Device } from 'src/models/administration/deviceData.model';
import {
    getDevices,
    selectDevices,
    selectDeviceStatus,
    selectDeviceError,
    selectAdminFilters,
    selectDeleteDeviceStatus,
    resetDeviceSaveStatus,
    resetDeviceDeleteStatus,
} from 'src/state/administration/administrationSlice';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Client } from 'src/models/administration/clientData.model';
import { DeviceEditionModal } from '../device-edition-modal/DeviceEditionModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DeviceCreationModal } from '../device-creation-modal/DeviceCreationModal';
import { DeviceConfigFormModal } from '../device-config-form-modal/DeviceConfigFormModal';
import { ConfigurationOwner } from 'src/models/administration/configurationData.model';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DeviceAttachments from './ClientDevicesAttachment';
import DevicePermissionAlerts from '../device-permissions-table/DevicePermissionsTable';
import { resetDeleteCommentStatus } from 'src/state/comments/commentsSlice';

export interface ClientDevicesTableProps {
    client: Client,
}

export function ClientDevicesTable({
    client,
}: Readonly<ClientDevicesTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const items = useSelector(selectDevices);
    const status = useSelector(selectDeviceStatus);
    const [page, setPage] = useState<number>(1);
    const deviceError = useSelector(selectDeviceError);
    const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
    const [deviceInEdition, setDeviceInEdition] = useState<Device>();
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const [deviceInConfig, setDeviceInConfig] = useState<Device>();
    const filters = useSelector(selectAdminFilters);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState<Device>();
    const [openPermissions, setOpenPermissions] = React.useState(false);
    const [permissionData, setPermissionData] = useState<Device>();
    const deleteStatus = useSelector(selectDeleteDeviceStatus);
    const urlpath = window.location.pathname;
    const urlSegments = urlpath.split('/');
    function onPageChange(pageNumber: number): void
    {
        setPage(pageNumber);
    }

    function onSendActivationCodeFinish(success: boolean): (device: Device, email :string) => void
    {
        return (device: Device, email: string) =>
        {
            setNotificationState({
                open: true,
                message: success
                    ? t('activation.code-successfully-sent', { email, device: device.alias })
                    : t('common.messages.something-wrong'),
                severity: success ? 'success' : 'error',
            });
        };
    }

    function openCreationModal(): void
    {
        setIsCreationModalOpen(true);
    }

    function onModalClose(): void
    {
        setIsCreationModalOpen(false);
        setDeviceInEdition(undefined);
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    function onSaveFinish(success: boolean): () => void
    {
        const subject = t('common.nouns.device');

        return () =>
        {
            setNotificationState({
                open: true,
                message: success
                    ? t('common.messages.saved-successfully', { subject })
                    : deviceError?.message as string ?? t('common.messages.something-wrong'),
                severity: success ? 'success' : 'error',
            });

            if (deleteStatus === RequestStatus.Success)
            {
                setNotificationState({
                    open: true,
                    message: success
                        ? t('common.messages.Delete-successfully', { subject })
                        : deviceError?.message as string ?? t('common.messages.something-wrong'),
                    severity: success ? 'success' : 'error',
                });
                dispatch(resetDeviceDeleteStatus());
            }
            if (success)
            {
                dispatch(getDevices({ pageNumber: page, entriesPerPage, clientId: client.id }));
                onModalClose();
                onConfigModalClose();
                dispatch(resetDeviceSaveStatus());
                dispatch(resetDeleteCommentStatus());
            }
        };
    }

    function onConfigModalClose(): void
    {
        setDeviceInConfig(undefined);
    }

    function getChipColor(
        enabled: boolean,
        activated: boolean
    ): 'error' | 'success' | 'warning' | undefined
    {
        if (!enabled)
        {
            return 'error';
        }

        if (!activated)
        {
            return 'warning';
        }

        return 'success';
    }

    function getChipLabel(enabled: boolean, activated: boolean): string
    {
        if (!enabled)
        {
            return t('common.adjectives.disabled');
        }

        if (!activated)
        {
            return t('common.status.inactive');
        }

        return t('common.status.active');
    }

    const handleOpen = (data: Device): void =>
    {
        setOpen(true);
        setData(data);
    };

    const handlePermissions = (data: Device): void =>
    {
        setOpenPermissions(true);
        setPermissionData(data);
    };


    useEffect(() =>
    {
        if(client.id === urlSegments[3])
        {
            dispatch(getDevices({
                pageNumber: page,
                entriesPerPage,
                clientId: client.id,
                ...filters,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, dispatch, entriesPerPage, client, filters]);

    const getStatusColumn = (device: Device): React.ReactNode => (
        <Chip
            label={getChipLabel(device.enabled, device.activated)}
            color={getChipColor(device.enabled, device.activated)}
            size="small"
        />
    );
    const getEditColumn = (device: Device): React.ReactNode => (
        <Button
            onClick={() => setDeviceInEdition(device)}
        >
            <EditIcon />
        </Button>
    );
    const getConfigurationColumn = (device: Device): React.ReactNode => (
        <Button
            onClick={() => setDeviceInConfig(device)}
        >
            <BuildIcon />
        </Button>
    );
    const getLogsColumn = (device: Device): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(device)}>
            <ManageSearchIcon />
        </IconButton>
    );
    const getPermissionsColumn = (device: Device): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handlePermissions(device)}>
            <WarningAmberIcon />
        </IconButton>
    );

    const columns: ColumnData<Device>[] = [
        {
            label: t('common.nouns.id'),
            size: 1,
            value: (device: Device) => device.id,
        },
        {
            label: t('data-type.table.device-alias'),
            size: 3,
            value: (device: Device) => device.alias,
        },
        {
            label: t('common.nouns.agency'),
            size: 3,
            value: (device: Device) => device.agencyName,
        },
        {
            label: t('data-type.table.device-type'),
            size: 3,
            value: (device: Device) => device.platform,
        },
        {
            label: t('common.nouns.status'),
            size: 3,
            value: (device: Device) => getChipLabel(device.enabled, device.activated),
            format: (device: Device) => getStatusColumn(device),
        },
        {
            label: t('data-type.table.last-connect-date'),
            size: 3,
            dateFormat: (device: Device) =>
            {
                if (device.lastConnectAt)
                {
                    const dateObject = new Date(device.lastConnectAt);
                    return dateObject.toLocaleString();
                }
                else
                {
                    return ('-');
                }
            },
        },
        {
            label: t('common.actions.edit'),
            size: 1,
            format: (device: Device) => getEditColumn(device),
        },
        {
            label: t('common.nouns.configuration'),
            size: 1,
            format: (device) => getConfigurationColumn(device),
        },
        {
            label: t('common.nouns.Logs'),
            size: 1,
            format: (device: Device) => getLogsColumn(device),
        },
        {
            label: t('data-type.table.permissions'),
            size: 1,
            format: (device: Device) => getPermissionsColumn(device),
        },
    ];

    return (
        <>
            <DeviceAttachments
                open={open}
                data={data}
                onClose={() => setOpen(false)}
            />
            <DevicePermissionAlerts
                open={openPermissions}
                data={permissionData}
                onClose={() => setOpenPermissions(false)}
            />
            <Stack
                direction="row"
                justifyContent="flex-end"
                padding={'1em 0'}
                marginTop={-8}
            >
                <Button
                    startIcon={<AddCircleIcon />}
                    onClick={openCreationModal}
                >
                    {t('common.actions.add')}
                </Button>
            </Stack>
            <DataTable<Device>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...items}
            />
            {
                deviceInEdition
                    && (
                        <DeviceEditionModal
                            open={!!deviceInEdition}
                            device={deviceInEdition}
                            activationEmail={client.email}
                            onClose={onModalClose}
                            onEditSuccess={onSaveFinish(true)}
                            onSendingActivationCodeSuccess={
                                onSendActivationCodeFinish(true)
                            }
                            onSendingActivationCodeFailure={
                                onSendActivationCodeFinish(false)
                            }
                        />
                    )
            }
            {
                deviceInConfig
                    ? (
                        <DeviceConfigFormModal
                            device={deviceInConfig}
                            context={ConfigurationOwner.Device}
                            open={!!deviceInConfig}
                            onClose={onConfigModalClose}
                            onSuccess={onSaveFinish(true)}
                            onFailure={onSaveFinish(false)}
                        />
                    )
                    : null
            }
            <DeviceCreationModal
                client={client}
                open={isCreationModalOpen}
                onClose={onModalClose}
                onSuccess={onSaveFinish(true)}
                onFailure={onSaveFinish(false)}
            />
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
