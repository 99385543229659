import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { LOGIN } from 'src/utils/routes';
import { LoginHeader } from 'src/templates/login-header/LoginHeader';
import { ForgotPasswordForm } from 'src/templates/forgot-password-form/ForgotPasswordForm';
import { AuthenticationPage } from 'src/templates/authentication-page/AuthenticationPage';
import { ReactComponent as PadlockImage } from 'src/assets/images/padlock.svg';
import { forgotPassword, selectForgotPasswordStatus } from 'src/state/user/userSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';

export function ForgotPassword(): JSX.Element
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const forgotPasswordStatus = useSelector(selectForgotPasswordStatus);

    function handleCancel(): void
    {
        navigate(LOGIN);
    }

    function handleForgotPassword(email: string): void
    {
        dispatch(forgotPassword({ email }));
    }

    if (forgotPasswordStatus === RequestStatus.Success)
    {
        navigate(LOGIN);
    }

    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.common.darkGrey,
            }}
        >
            <LoginHeader />
            <AuthenticationPage image={<PadlockImage />}>
                <ForgotPasswordForm
                    handleCancel={handleCancel}
                    handleForgotPassword={handleForgotPassword}
                    isSending={forgotPasswordStatus === RequestStatus.InProgress}
                    hasForgotPasswordError={forgotPasswordStatus === RequestStatus.Failure}
                />
            </AuthenticationPage>
        </Box>
    );
}
