import { CaptureParams, GetAlertsId } from 'src/models/captures/capturedData.model';
import { TextMMSData } from 'src/models/captures/textMMSData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import { readAPITextMMSResponse } from '../data-transfer-objects/capture/textMMS';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';

import instance from '../instance';

export async function getTextMMSs(
    params: CaptureParams
): Promise<PaginatedContent<TextMMSData>>
{
    const response = await instance.get('text-mms', {
        params: transformCaptureParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPITextMMSResponse);
}

export async function getTextMMSIds(
    params: GetAlertsId
): Promise<string[]>
{
    const response = await instance.get('text-mms/getIds', {
        params,
    });
    return response.data;
}
