import format from 'date-fns/format';
import {
    isAfter,
    isBefore,
    endOfDay,
    addMinutes,
    subMinutes,
    startOfDay,
    subDays,
    differenceInMinutes,
} from 'date-fns';
import { MINUTES_FOR_MONTH } from './environment';
import { SCREENSHOTMETAPAGE } from 'src/models/alertsType.enum';
export const compareDates = (date: Date, otherDate: Date): boolean =>
{
    const dateFormat = 'yyyy-MM-dd';

    const formattedDate = format(
        date,
        dateFormat
    );
    const formattedOtherDate = format(
        otherDate,
        dateFormat
    );

    return formattedDate !== formattedOtherDate;
};

export const convertHMS = (value: number): string =>
{
    const sec = value; // convert value to number if it's string
    const hours = Math.floor(sec / 3600); // get hours
    const minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    const seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    return hours.toString().padStart(2, '0')
    + ':'
    + minutes.toString().padStart(2, '0')
    + ':'
    + seconds.toString().padStart(2, '0'); // Return is HH : MM : SS
};


export const secondsToHHMMSS = (milliseconds: number): string =>
{
    const seconds = milliseconds / 1000;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${paddedHours}h ${paddedMinutes}m ${paddedSeconds}s`;
};



// eslint-disable-next-line
export const DateFilterChange = (field: string, newdate: Date, startDate: any, endDate: any) => {
    // eslint-disable-next-line
  let adjustedStartDate: any;
    // eslint-disable-next-line
  let adjustedEndDate: any;

    if (field === 'startDate')
    {
        adjustedStartDate = newdate;
        const currentDate = new Date();
        const newEndDate = addMinutes(newdate, MINUTES_FOR_MONTH);
        adjustedEndDate = isAfter(newEndDate, endDate || newEndDate) ? endDate : newEndDate;

        if (isAfter(newdate, adjustedEndDate))
        {
            adjustedEndDate = newEndDate;
        }

        if (isAfter(adjustedEndDate, currentDate))
        {
            adjustedEndDate = endOfDay(currentDate);
        }

        if (isAfter(newdate, adjustedEndDate || endDate))
        {
            adjustedStartDate = startOfDay(adjustedEndDate || endDate);
        }
    }
    else
    {
        adjustedEndDate = newdate;
        const currentDate = new Date();
        const newStartDate = subMinutes(newdate, MINUTES_FOR_MONTH);
        adjustedStartDate = isBefore(newStartDate, startDate || newStartDate)
            ? startDate : newStartDate;

        if (isBefore(newdate, adjustedStartDate))
        {
            adjustedStartDate = newStartDate;
        }

        if (isAfter(newdate, currentDate))
        {
            adjustedEndDate = endOfDay(currentDate);
            adjustedStartDate =
        differenceInMinutes(currentDate, startDate) > MINUTES_FOR_MONTH || startDate === undefined ?
            subDays(startOfDay(currentDate), 2)
            : startDate;
        }
    }
    if(window.location.pathname === SCREENSHOTMETAPAGE)
    {
        adjustedStartDate=startOfDay(adjustedStartDate);
        adjustedEndDate= endOfDay(adjustedEndDate);
    }
    return { startDateTime: adjustedStartDate, endDateTime: adjustedEndDate };
};

export const timeZoneFormat = (date: Date, timeZone: string): string =>
{
    const timeZoneAbbreviation = new Date(date)
        .toLocaleTimeString('en-US', {
            timeZone,
            timeZoneName: 'short',
        })
        .split(' ')[2];
    const formattedDate = new Date(date).toLocaleString('en-US', { timeZone });
    return `${formattedDate} (${timeZoneAbbreviation})`;
};
